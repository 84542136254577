import React from "react";
import SearchFilterInput from "./SearchFilterInput";
import { useSelector } from "react-redux";

const CustomSubHeaderComponent = ({
  filterText,
  setFilterText,
  setFilteredData,
  trafficSource,
  handleTrafficChange,
  attribute,
  handleSelectChange,
  tableData,
  setSelectedAdList,
}) => {
  const { allTrafficeSource } = useSelector((state) => state.trafficSource);

  return (
    <>
      <div className="attribute-flex">
        <SearchFilterInput
          filterText={filterText}
          setFilterText={setFilterText}
          setFilteredData={setFilteredData}
          tableData={tableData}
          setSelectedAdList={setSelectedAdList}
        />
        <div>
          <label className="label-name">Traffic Source</label>
          <select
            value={trafficSource}
            className="select-box-value-trafficSource"
            id="select-box"
            onChange={handleTrafficChange}
          >
            <option value="Facebook" className="option">
              Facebook
            </option>
            <option value="Google" className="option">
              Google
            </option>
            <option value="LinkedIn" className="option">
              LinkedIn
            </option>
            {allTrafficeSource?.map((source, i) => (
              <option key={source + i} value={source} className="option">
                {source}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="label-name">Attribute Type</label>
          <select
            value={attribute}
            className="select-box-value-attribute"
            id="select-box"
            onChange={handleSelectChange}
          >
            <option value="first" className="option">
              First
            </option>
            <option value="last" className="option">
              Last
            </option>
          </select>
        </div>
      </div>
    </>
  );
};

export default CustomSubHeaderComponent;
