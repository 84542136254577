import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getTrafficSource } from '../../services/api/tableDataApis';

const initialState = {
  trafficSource: "Facebook",
  isFetching: false,
  isError: false,
  error: null,
  allTrafficeSource: null
};


export const fetchTrafficSources = createAsyncThunk(
  "source/fetchTrafficSources",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getTrafficSource();
      if (response.status === 200) {
        return response.data;
      }
      // else throw new Error("Failed to fetch attribute report data");
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.message || "Failed"
      );
    }
  }
);

const trafficSourceSlice = createSlice({
  name: 'trafficSource',
  initialState,
  reducers: {
    setTrafficSource(state, action) {
      state.trafficSource = action.payload;
    }
  },
  extraReducers: {
    [fetchTrafficSources.pending]: (state) => {
      state.isFetching = true;
      // state.isError = false;
    },
    [fetchTrafficSources.fulfilled]: (state, { payload }) => {

      state.isFetching = false;
      state.isError = false;
      state.allTrafficeSource = payload;
      state.error = null;
    },
    [fetchTrafficSources.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
  },
});

export const { setTrafficSource } = trafficSourceSlice.actions;

export default trafficSourceSlice.reducer;
