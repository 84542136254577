import React, { useState } from "react";
import { closestCorners, DndContext } from "@dnd-kit/core";
import Column from "./Column";
import { arrayMove } from "@dnd-kit/sortable";
function CustomizeDataThree() {
  const [cols, setCols] = useState([
    { id: 1, col: "Delivery" },
    { id: 2, col: "Ad set name" },
    { id: 3, col: "Ad set name" },
    { id: 4, col: "Bid strategy" },
    { id: 5, col: "Budget" },
    { id: 6, col: "Last significant edit" },
  ]);

  const getTaskPos = (id) => cols.findIndex((task) => task.id === id);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id === over.id) return;
    setCols((cols) => {
      const originalPos = getTaskPos(active.id);
      const newPos = getTaskPos(over.id);
      return arrayMove(cols, originalPos, newPos);
    });
  };
  return (
    <div className="custom-three-container">
      <h4 className="custom-three-part-one">17 columns selected</h4>
      <div className="task"> Ad</div>
      <DndContext onDragEnd={handleDragEnd} collisionDetection={closestCorners}>
        <Column cols={cols}></Column>
      </DndContext>
    </div>
  );
}

export default CustomizeDataThree;
