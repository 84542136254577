import React, { useEffect } from "react";
import "./styles.css";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthContainer = () => {
  const navigate = useNavigate();
  const workSpaceId = localStorage.getItem("t_workSpaceId");
  const accessToken = localStorage.getItem("t_accessToken");

  useEffect(() => {
    const previousPath = localStorage.getItem("previousPath");
    if (accessToken && workSpaceId) {
      if (previousPath && !["/sign-in", "/sign-up"].includes(previousPath)) {
        navigate(previousPath);
      }
    }
  }, [accessToken, workSpaceId, navigate]);

  return (
    <div className="auth-container">
      <Outlet />
    </div>
  );
};

export default AuthContainer;
