import { toastSetter } from "../../components/ToastContainer";
import { formatDate } from "../../utils";
import catchAsync from "../../utils/catchAsync";
import http from "./http";

// reporting/graphsales

export const getDashboardGraphData = catchAsync(async (values) => {

  try {
    const { startdate, enddate } = values;
    const response = await http.get(`/reporting/dashboardgraphsales`, {
      params: { startdate, enddate },
    });
    // Handle response data if needed
    // console.log(response);
    if (response.status === 200) {
      return response; // Return response data
    }
  } catch (error) {
    // console.log("Error fetching Facebook table data:", error);
    // Display error message to the user
    // toastSetter("error", errorMessage);
    throw error; // Re-throw the error to handle it in the calling code
  }
});

export const getDashboardTrafficData = catchAsync(async (values) => {
  try {
    const { startdate, enddate } = values;

    // Format the startdate and enddate strings
    // const formattedStartDate = formatDate(startdate);
    // const formattedEndDate = formatDate(enddate);

    // Make the API call with formatted startdate and enddate
    const response = await http.get(`/reporting/dashboardtraffic`, {
      params: { startdate: startdate, enddate: enddate },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    throw error;
  }
});

export const getDashboardMetricData = catchAsync(async (values) => {
  
  try {
    const { startdate, enddate } = values;


    // Make the API call with formatted startdate and enddate
    const response = await http.get(`/reporting/dashboardmetric`, {
      params: { startdate, enddate },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    throw error;
  }
});
