import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCohortTableData } from "../../services/api/predictiveBehaviorApis";

const initialState = {
    tableData: null,
    isFetching: false,
    isError: false,
    error: null,
};

export const cohortTableData = createAsyncThunk(
    "cohortTableData/getcohortTableData",
    async (values, { rejectWithValue }) => {
        try {
            const response = await getCohortTableData(values);
            if (response.status === 200) {
                return response.data;
            }

            // else throw new Error("Failed to fetch attribute report data");
        } catch (error) {
            return rejectWithValue(
                error.response?.data?.message || error.message || "Failed"
            );
        }
    }
);

export const tableDataSlice = createSlice({
    name: "tableData",
    initialState,
    // reducers: {

    // },
    extraReducers: {
        [cohortTableData.pending]: (state) => {
            state.isFetching = true;
            state.isError = false;
        },
        [cohortTableData.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = false;
            state.tableData = payload;
            state.error = null;
        },
        [cohortTableData.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.error = payload;
        },
    },
});
// export const { } = tableDataSlice.actions;
export default tableDataSlice.reducer;
