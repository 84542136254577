import { toastSetter } from "../../components/ToastContainer";
import catchAsync from "../../utils/catchAsync";
import http from "./http";

export const getAgency = catchAsync(async () => {
  try {
    const response = await http.get(`/auth/switchclient`);

    if (response.status === 200) {
      return response;            
    } else {
      throw new Error(`Failed to fetch trackid, status: ${response.status}`);
    }
  } catch (error) {
    const errorMessage = error && error.response.data.message;
    toastSetter("error", errorMessage);
    // console.log("Error in userLogin:", error); // Log any errors that occur
    // throw error; // Rethrow the error to be caught elsewhere if necessary
  }
});
export const getAgencyAll = catchAsync(async () => {
  try {
    const response = await http.get(`/auth/getclient`);

    if (response.status === 200) {
      return response;
    } else {
      throw new Error(`Failed to fetch trackid, status: ${response.status}`);
    }
  } catch (error) {
    const errorMessage = error && error.response.data.message;

    // console.log("Error in userLogin:", error); // Log any errors that occur
    // throw error; // Rethrow the error to be caught elsewhere if necessary
  }
});

export const createClient = catchAsync(async (values) => {
  // const accessToken = localStorage.getItem("t_accessToken");
  const t_workSpaceId = localStorage.getItem("t_workSpaceId");
  // console.log('value ', values);
  // console.log('accessToken ', accessToken);
  // console.log('agency_id ', agency_id);
  try {
    const response = await http.post(`/auth/createclient`, values, {
      // headers: {
      //   Authorization: `Bearer ${accessToken}`,
      //   workspaceId: agency_id, // Include the workspaceId in headers
      // },
    });
    // const response = await http.post(`/auth/createclient`, values);
    console.log(response, "CREATE USER");
    if (response.status === 201) {
      // setSession(data);
      if (t_workSpaceId === 'undefined' || t_workSpaceId === undefined) {
        localStorage.setItem('t_workSpaceId', response?.data?.user_id)
      }
      return response;
    }
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "not created";
    throw new Error(errorMessage); // Rethrow the error with a message
  }
});
