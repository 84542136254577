import axios from "axios";
import { refreshLoggedInUser, userLogout } from "./authApis";
import { toastSetter } from "../../components/ToastContainer";

let isRefreshing = false; // Flag to track if token refresh is in progress


axios.interceptors.request.use(async (config) => {
  config.baseURL = process.env.REACT_APP_BASE_URL;
  const workSpaceId = localStorage.getItem("t_workSpaceId")
  const accessToken = localStorage.getItem("t_accessToken");
  const agency_id = localStorage.getItem("t_agency_id");
  const edit_id = localStorage.getItem("n_workspace_id");


  if (config.url.includes("/refresh")) {
    const refreshToken = localStorage.getItem("t_refreshToken");
    if (refreshToken) {
      config.headers = {
        Authorization: `Bearer ${refreshToken}`,
        workspaceId: workSpaceId,
      };
    }
  } else {
    if (accessToken && workSpaceId) {

      config.headers = {
        Authorization: `Bearer ${accessToken}`,
        workspaceId: config.url.includes("/auth/getclient")
          ? agency_id
          : config.url.includes("/auth/createclient")
            ? agency_id
            : config.url.includes("/auth/getprofile") && agency_id && !edit_id
              ? agency_id
              : config.url.includes("/auth/getprofile") && agency_id && edit_id
                ? edit_id
                : config.url.includes("/auth/updateprofile") && agency_id && !edit_id
                  ? agency_id
                  : config.url.includes("/auth/updateprofile") && agency_id && edit_id
                    ? edit_id
                    : workSpaceId,
      };
    } else {
      delete config.headers.Authorization;
      delete config.headers.workspaceId;
    }
  }
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    if (response && response.status) {
      return response;
    } else {
      return response;
    }
  },
  async (error) => {
    const status = error.response ? error.response.status : null;
    const originalRequest = error.config;

    if (status === 401) {
      const refreshToken = localStorage.getItem("t_refreshToken");
      const workSpaceId = localStorage.getItem("t_workSpaceId");
      if (refreshToken && workSpaceId) {
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            const res = await refreshLoggedInUser(workSpaceId, refreshToken);
            if (!res || res.status !== 200) {
              handleRefreshTokenFailure();
              return Promise.reject(error);
            } else {
              const newAccessToken = res.data.tokens.access || res.data.access;
              console.log(newAccessToken, "NEW ACCESS TOKEN");
              originalRequest.headers[
                "Authorization"
              ] = `Bearer ${newAccessToken}`;
              // Retry the original request
              return axios.request(originalRequest);
            }
          } catch (refreshError) {
            console.log("Error refreshing token:", refreshError);
            handleRefreshTokenFailure();
            return Promise.reject(error);
          } finally {
            isRefreshing = false; // Reset the flag after token refresh attempt
          }
        }
      }
    } else if (status === 403) {
      console.log(error, "403 error"); // Handle 403 errors
      const paymentLink = error.response.data.payment_link;
      if (paymentLink) {
        // Display a toast message for 2 seconds, then redirect to the payment link
        toastSetter("info", "Subscription Expired. Redirecting to payment page...");
        setTimeout(() => {
          userLogout();
          redirectPayment(paymentLink);
        }, 2000);
      } else {
        console.log("Subscription expired, but no payment link provided.");
      }
      return Promise.reject(error);
    } else {
      console.log("Unexpected error occurred:", error);
      return Promise.reject(error);
    }
  }
);

function handleRefreshTokenFailure() {
  userLogout();
  // window.location.reload();
}

function redirectPayment(paymentLink) {
  window.location.href = paymentLink; // Redirect to the payment page
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};
