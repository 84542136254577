import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SharedForm from "./SharedForm"; // Adjust the import path
import { getAgencyDataAll, signClient } from "../../redux/reducers/agencySlice";

const NewClient = ({ setNewPopUp }) => {
  const dispatch = useDispatch(); // Get the dispatch function
  const [initialValues, setInitialValues] = useState({
    email: "",
    name: "",
    phone: "",
    company: "",
    currency: "",
    timezone: "", // Set as an empty string initially
  });

  // useEffect(() => {
  //   // Get client's timezone and set it as the default
  //   const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  //   setInitialValues((prevValues) => ({
  //     ...prevValues,
  //     timezone: clientTimezone, // Set client's timezone as default
  //   }));
  // }, []);

  const handleSubmit = async (values) => {
    console.log("values", values);
    const payload = {
      company: values.company || null,
      currency: values.currency || null,
      email: values.email,
      name: values.name,
      phone: values.phone || null,
      timezone: values.timezone || null,
    };

    const actionResult = await dispatch(signClient(payload));
    console.log("Action Result:", actionResult);
    dispatch(getAgencyDataAll());
    setNewPopUp(false);
  };

  const handleCancel = () => {
    setNewPopUp(false);
  };

  return (
    <SharedForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    />
  );
};

export default NewClient;
