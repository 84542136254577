import React from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ButtonDots from "../ButtonDots/ButtonDots";

function Cols({ id, title }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };
  return (
    <>
      <div
        style={style}
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        className="task"
      >
        <ButtonDots />
        {title}
        <img className="crossc" src="/cross.png"></img>
      </div>
    </>
  );
}

export default Cols;
