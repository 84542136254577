import React from "react";
import { setShopify } from "../../services/api/integrationApis"; // Import API function to set Shopify integration
import { useFormik } from "formik"; // Import Formik for form handling
import * as Yup from "yup"; // Import Yup for form validation
import { useSelector } from "react-redux"; // (Currently unused) Import Redux for state management
import { shoIntegration } from "../../services/api/integrationApis"; // Import shoIntegration function
import { toastSetter } from "../../components/ToastContainer";

const Shopify = ({ setIsPopupOpen, setPopupChildren }) => {
  // Retrieve workspace ID and access token from local storage
  const workSpaceId = localStorage.getItem("t_workSpaceId");
  const accessToken = localStorage.getItem("t_accessToken");

  // Validation schema for form fields
  const validationSchema = Yup.object().shape({
    siteUrl: Yup.string().required("Field is required"), // Site URL is required
    accessToken: Yup.string().required("Field is required"), // Access token is required
  });

  // Formik setup for managing form state
  const formik = useFormik({
    initialValues: {
      siteUrl: "", // Initial value for Site URL
      accessToken: "", // Initial value for Access Token
    },
    validationSchema, // Apply the validation schema
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true); // Set submitting state to true

      // Submit the Shopify API details
      setShopify({
        site_url: values.siteUrl, // Prepare data for submission
        access_token: values.accessToken,
      })
        .then((res) => {
          console.log("Shopify configuration submitted:", res);

          // After successful Shopify setup, call shoIntegration to fetch updated connection status
          shoIntegration() // Re-fetch the integration status
            .then((integrationStatus) => {
              // Update localStorage based on the integration status
              const shopifyConnected = integrationStatus.shopify || false;
              localStorage.setItem(
                "Shopify_connected",
                shopifyConnected ? "true" : "false"
              );

              // Optionally, you can also update other app connections if needed
              console.log("Updated Integration Status:", integrationStatus);

              // Show success toast
            })
            .catch((error) => {
              console.error("Error fetching integration status:", error);
              toastSetter("error", "Error updating integration status");
            });
        })
        .catch((error) => {
          console.error("Error setting Shopify configuration:", error);
          toastSetter("error", "Error submitting Shopify configuration");
        })
        .finally(() => {
          setSubmitting(false); // Reset submitting state
          setIsPopupOpen(false); // Close the popup
          setPopupChildren(""); // Clear popup content
        });
    },
  });

  return (
    <div className="integration-popup-container">
      <h2 className="integration-popup-heading">Shopify</h2>
      <form className="integration-popup-form">
        {/* Site URL Input */}
        <span style={{ width: "100%" }}>
          <label className="integration-popup-form-label">Site URL</label>
          <input
            id="siteUrl" // Unique ID for the input field
            name="siteUrl" // Name used by Formik to manage this field
            type="text"
            className="integration-popup-input"
            value={formik.values.siteUrl} // Controlled input value
            onChange={formik.handleChange} // Handle change event
            onBlur={formik.handleBlur} // Handle blur event
          />
        </span>
        {/* Access Token Input */}
        <span style={{ width: "100%" }}>
          <label className="integration-popup-form-label">Access Token</label>
          <input
            id="accessToken" // Unique ID for the input field
            name="accessToken" // Name used by Formik to manage this field
            type="text"
            className="integration-popup-input"
            value={formik.values.accessToken} // Controlled input value
            onChange={formik.handleChange} // Handle change event
            onBlur={formik.handleBlur} // Handle blur event
          />
        </span>
      </form>
      {/* Submit Button */}
      <button
        type="button"
        className="integration-popup-submit"
        disabled={formik.isSubmitting} // Disable button if form is submitting
        onClick={formik.handleSubmit} // Trigger Formik's submit handler
      >
        Submit
      </button>
    </div>
  );
};

export default Shopify;
