import React from "react"; // Import React library
import IntegrationCardTitle from "./IntegrationCardTitle"; // Import title component for each section
import { appData } from "./appData"; // Import application data from an external file
import AppCard from "./AppCard"; // Import the card component for each app

// Define the AppsTab functional component
const AppsTab = (props) => {
  const {
    popupHandler,
    connectGoogleAdAccounts,
    connectMetaAdAccounts,
    connectLinkedInAdAccounts,
    inte,
  } = props; // Destructure props for ease of use

  return (
    <>
      {/* Map over appData to create sections for each category of apps */}
      {appData.map((section, index) => (
        <div key={index}>
          {" "}
          {/* Unique key for each section */}
          <IntegrationCardTitle title={section.title} />{" "}
          {/* Render the title of the section */}
          <div className="integration-app-container">
            {" "}
            {/* Container for the apps in the section */}
            {/* Map over the apps in the current section to create individual app cards */}
            {section.apps.map((app, appIndex) => (
              <AppCard
                key={appIndex} // Unique key for each app card
                app={app} // Pass the app object to the AppCard component
                popupHandler={popupHandler} // Pass popup handler function as prop
                connectGoogleAdAccounts={connectGoogleAdAccounts} // Pass Google ad accounts connect function
                connectMetaAdAccounts={connectMetaAdAccounts}
                connectLinkedInAdAccounts={connectLinkedInAdAccounts}
                inte={inte} // Pass Meta ad accounts connect function
              />
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default AppsTab; // Export the AppsTab component for use in other parts of the application
