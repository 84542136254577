import React from "react";
import Chart from "react-apexcharts";



const CreativeChart = ({ graphData }) => {

  const creativeGraphData = [
    { x: '0%', y: graphData.engagement },
    { x: '25%', y: graphData.p25 },
    { x: '50%', y: graphData.p50 },
    { x: '100%', y: graphData.p100 }
  ]


  const categories = creativeGraphData.map((item) => item.x);
  const seriesData = creativeGraphData.map((item) => parseInt(item.y, 10));

  // Chart options
  const chartOptions = {
    chart: {
      type: "area",
      toolbar: {
        show: false, // Hide toolbar
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          fontSize: "0.8rem",
          fontWeight: "500",
          colors: "white",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: "0.8rem",
          fontWeight: "500",
          colors: "#9c9c9d",
        },
      },
    },
    colors: ["#c7cb00"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex }) {
        const value = series[seriesIndex][dataPointIndex];
        return `
          <div class="arrow_box">
            <span class="itemtool">
              <span class="titletool">Value:</span> <span class="content">${value}</span>
            </span>
          </div>
        `;
      },
    },
    grid: {
      show: true,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
  };

  // Series data for the chart
  const series = [{ name: "Percent", data: seriesData }];

  return (
    <Chart
      options={chartOptions}
      series={series}
      type="area"
      width="100%"
      height="100%"
      className="mini-chart"
    />
  );
};

export default CreativeChart;
