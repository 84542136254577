import React, { useState } from "react";
import "./styles.css"; // Keep this for other styling
import { setJourney } from "../../redux/reducers/graphDataSlice";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "../../components";
import { setJourneyDate } from "../../redux/reducers/dateSlice";
import { setJourneys } from "../../redux/reducers/journeySlice";
import DataTable from "react-data-table-component"; // Import the DataTable component

function Journey() {
  const data = [
    {
      date: "2024-09-10 21:09",
      name: "xyz",
      mail: "xyhhhhhz@email.com",
      phone: "9999999",
      revenue: "1000",
      origin: "Google Video Ad1",
      last: "FB Ads Image 1",
      touchpoint: "12",
      customer: "ID4385783473",
    },
    {
      date: "2024-09-10 21:09",
      name: "xyz",
      mail: "xyz@email.com",
      phone: "9999999",
      revenue: "1000",
      origin: "Google Video Ad1",
      last: "FB Ads Image 1",
      touchpoint: "12",
      customer: "ID4385783473",
    },
    {
      date: "2024-09-10 21:09",
      name: "xyz",
      mail: "xyz@email.com",
      phone: "9999999",
      revenue: "1000",
      origin: "Google Video Ad1",
      last: "FB Ads Image 1",
      touchpoint: "12",
      customer: "ID4385783473",
    },
    {
      date: "2024-09-10 21:09",
      name: "xyz",
      mail: "xyz@email.com",
      phone: "9999999",
      revenue: "1000",
      origin: "Google Video Ad1",
      last: "FB Ads Image 1",
      touchpoint: "12",
      customer: "ID4385783473",
    },
    {
      date: "2024-09-10 21:09",
      name: "xyz",
      mail: "xyz@email.com",
      phone: "9999999",
      revenue: "1000",
      origin: "Google Video Ad1",
      last: "FB Ads Image 1",
      touchpoint: "12",
      customer: "ID4385783473",
    },
    {
      date: "2024-09-10 21:09",
      name: "xyz",
      mail: "xyz@email.com",
      phone: "9999999",
      revenue: "1000",
      origin: "Google Video Ad1",
      last: "FB Ads Image 1",
      touchpoint: "12",
      customer: "ID4385783473",
    },
    {
      date: "2024-09-10 21:09",
      name: "xyz",
      mail: "xyz@email.com",
      phone: "9999999",
      revenue: "1000",
      origin: "Google Video Ad1",
      last: "FB Ads Image 1",
      touchpoint: "12",
      customer: "ID4385783473",
    },
    {
      date: "2024-09-10 21:09",
      name: "xyz",
      mail: "xyz@email.com",
      phone: "9999999",
      revenue: "1000",
      origin: "Google Video Ad1",
      last: "FB Ads Image 1",
      touchpoint: "12",
      customer: "ID4385783473",
    },
    {
      date: "2024-09-10 21:09",
      name: "xyz",
      mail: "xyz@email.com",
      phone: "9999999",
      revenue: "1000",
      origin: "Google Video Ad1",
      last: "FB Ads Image 1",
      touchpoint: "12",
      customer: "ID4385783473",
    },
    {
      date: "2024-09-10 21:09",
      name: "xyz",
      mail: "xyz@email.com",
      phone: "9999999",
      revenue: "1000",
      origin: "Google Video Ad1",
      last: "FB Ads Image 1",
      touchpoint: "12",
      customer: "ID4385783473",
    },
    {
      date: "2024-09-10 21:09",
      name: "xyz",
      mail: "xyz@email.com",
      phone: "9999999",
      revenue: "1000",
      origin: "Google Video Ad1",
      last: "FB Ads Image 1",
      touchpoint: "12",
      customer: "ID4385783473",
    },
    // Add the rest of your data here
  ];

  const dispatch = useDispatch();
  const { journeydate } = useSelector((state) => state.date);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);
  const [timeInterval, setTimeInterval] = useState("weekly");
  const [trafficSource, setTrafficSource] = useState(() => {
    const savedTrafficSource = localStorage.getItem("trafficSource");
    return savedTrafficSource ? savedTrafficSource : "All";
  });

  const handleTrafficSourceChange = (event) => {
    let selectedTrafficSource = event.target.value;
    setTrafficSource(selectedTrafficSource);
  };

  const [selectedDates, setSelectedDates] = useState([]);
  const updateSelectedDates = (dates) => {
    setSelectedDates(dates);
    dispatch(setJourneyDate(dates));
    closePopup();
  };

  const cancelSelectedDates = () => {
    setSelectedDates([]);
  };

  // Column definitions for the DataTable
  const columns = [
    {
      name: "Order Date",
      selector: (row) => row.date,

      cell: (row) => (
        <div title={row.date} style={{ cursor: "pointer" }}>
          {row.date}
        </div>
      ), // Adding cursor style
    },
    {
      name: "Name",
      selector: (row) => row.name,

      cell: (row) => (
        <div title={row.name} style={{ cursor: "pointer" }}>
          {row.name}
        </div>
      ), // Adding cursor style
    },
    {
      name: "Mail ID",
      selector: (row) => row.mail,

      cell: (row) => (
        <div title={row.mail} style={{ cursor: "pointer" }}>
          {row.mail}
        </div>
      ), // Adding cursor style
    },
    {
      name: "Phone",
      selector: (row) => row.phone,

      cell: (row) => (
        <div title={row.phone} style={{ cursor: "pointer" }}>
          {row.phone}
        </div>
      ), // Adding cursor style
    },
    {
      name: "Origin Source",
      selector: (row) => row.origin,

      cell: (row) => (
        <div title={row.origin} style={{ cursor: "pointer" }}>
          {row.origin}
        </div>
      ), // Adding cursor style
    },
    {
      name: "Last Source",
      selector: (row) => row.last,

      cell: (row) => (
        <div title={row.last} style={{ cursor: "pointer" }}>
          {row.last}
        </div>
      ), // Adding cursor style
    },
    {
      name: "Total Source",
      selector: (row) => row.revenue,

      cell: (row) => (
        <div title={row.revenue} style={{ cursor: "pointer" }}>
          {row.revenue}
        </div>
      ), // Adding cursor style
    },
    {
      name: "Total Touchpoint",
      selector: (row) => row.touchpoint,

      cell: (row) => (
        <div title={row.touchpoint} style={{ cursor: "pointer" }}>
          {row.touchpoint}
        </div>
      ), // Adding cursor style
    },
    {
      name: "Customer Journey",
      selector: (row) => row.customer,

      cell: (row) => (
        <div title={row.customer} style={{ cursor: "pointer" }}>
          {row.customer}
        </div>
      ), // Adding cursor style
    },
  ];

  return (
    <>
      <div className="journey-body">
        <button
          onClick={() => dispatch(setJourneys(false))}
          className="back-button"
        >
          <IoIosArrowBack /> Back
        </button>
        <div className="journey">
          <div className="journey-head">
            <div className="component">
              <div>
                <label className="label-name">Last Source</label>
                <select
                  onChange={(e) => setTimeInterval(e.target.value)}
                  className="select-box-value-trafficSource"
                  style={{ border: "1px solid #D5D900" }}
                >
                  <option value="weekly" className="option">
                    Weekly
                  </option>
                  <option value="monthly" className="option">
                    Monthly
                  </option>
                </select>
              </div>
              <div>
                <label className="label-name">CustomerType</label>
                <select
                  value={trafficSource}
                  className="select-box-value-trafficSource"
                  onChange={handleTrafficSourceChange}
                  style={{ border: "1px solid #D5D900" }}
                >
                  <option value="all" className="option">
                    All
                  </option>
                  <option value="Facebook" className="option">
                    Lorem Two
                  </option>
                  <option value="Google" className="option">
                    Lorem Three
                  </option>
                </select>
              </div>
              <div style={{ marginLeft: "20px" }}>
                <DatePicker
                  openPopup={openPopup}
                  date={journeydate}
                  selectedDates={selectedDates}
                  isPopupOpen={isPopupOpen}
                  closePopup={closePopup}
                  updateSelectedDates={updateSelectedDates}
                  cancelSelectedDates={cancelSelectedDates}
                  setDate={setJourneyDate}
                />
              </div>
            </div>
          </div>

          {/* DataTable for displaying journey data */}
          <div style={{ flex: 1, margin: "20px" }}>
            <DataTable
              columns={columns}
              data={data}
              pagination
              highlightOnHover
              responsive
              paginationComponentOptions={{
                noRowsPerPage: true, // This will disable the rows-per-page dropdown
              }}
              customStyles={{
                headCells: {
                  style: {
                    backgroundColor: "#1b1c1e",
                    color: "#D5D900", // Set column names color to #D5D900
                    fontWeight: "bold",
                    textAlign: "left",
                    padding: "10px",
                  },
                },
                cells: {
                  style: {
                    backgroundColor: "#1b1c1e", // Set the table background color to #1b1c1e
                    color: "white", // Set text color to white for the cells
                    padding: "10px",
                    textAlign: "left",
                  },
                },
                table: {
                  style: {
                    backgroundColor: "#1b1c1e", // Set background color of the table
                    width: "100%",
                    height: "100%",
                  },
                },
                pagination: {
                  style: {
                    color: "white", // Make pagination text white
                  },
                },
                paginationSelect: {
                  style: {
                    color: "white", // Make the "Rows per page" dropdown text white
                    borderColor: "#D5D900", // Optional: You can also style the border of the dropdown
                  },
                },
                paginationSelectIcon: {
                  style: {
                    color: "white", // Make the dropdown arrow icon white
                  },
                },
                paginationDropdown: {
                  style: {
                    color: "white", // Make the dropdown options text white
                    backgroundColor: "#1b1c1e", // Keep the background color consistent
                  },
                },
                paginationDropdownItem: {
                  style: {
                    color: "white", // Ensure the numbers in the dropdown are white
                    backgroundColor: "#1b1c1e", // Keep the background color consistent
                    padding: "8px", // Optional: Adjust padding for better visual appearance
                  },
                },
                paginationSelectBox: {
                  style: {
                    backgroundColor: "#1b1c1e", // Set background color of the select box
                    color: "white", // Set the text color of the select box to white
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Journey;
