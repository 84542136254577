import React, { useEffect, useState } from "react";
import { setGoogleAds } from "../../services/api/integrationApis";
import { toastSetter } from "../../components/ToastContainer";
import { fetchGoogleAdAccounts } from "../../redux/reducers/googleAdAccountsSlice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { normalizePath } from "../../utils";

const GoogleAds = ({
  googleCustomers,
  googleRefreshToken,
  setIsPopupOpen,
  setPopupChildren,
  setGadsaccnt,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // State to hold selected Google Ads accounts
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Retrieve input type and system ID from local storage
  const inputType = localStorage.getItem("t_popupInputType");
  const systemid = localStorage.getItem("t_systemid");

  useEffect(() => {
    if (googleCustomers && googleCustomers.length === 1) {
      setSelectedOptions([googleCustomers[0]]); // Automatically select the first (and only) option
    }
  }, [googleCustomers]);

  // Function to handle option selection
  const handleOptionChange = (item) => {
    if (inputType === "radio") {
      // For radio input, allow only one selection
      setSelectedOptions([item]);
    } else {
      // For checkbox input, allow up to 3 selections
      if (selectedOptions.includes(item)) {
        // If already selected, remove it
        setSelectedOptions(selectedOptions.filter((option) => option !== item));
      } else {
        // If not selected and less than 3 selected, add it
        if (selectedOptions.length < 3) {
          setSelectedOptions([...selectedOptions, item]);
        } else {
          // Alert if trying to select more than 3 accounts
          const infoMessage =
            "You can only select up to 3 Google Ads accounts.";
          toastSetter("info", infoMessage);
        }
      }
    }
  };

  // Form submission handler
  const formSubmitHandler = (e) => {
    e.preventDefault();

    // Call the API to set Google Ads
    setGoogleAds({
      systemid: systemid ? systemid : null,
      customerId: selectedOptions.join(","), // Join selected IDs into a string
      refresh_token: googleRefreshToken,
    })
      .then((res) => {
        if (res.status && res.status === 200) {
          // Successful response handling
          console.log(res, "ycqutdvuwdvwpd");
          toastSetter("success", `Google Ads Success`);
          dispatch(fetchGoogleAdAccounts()).unwrap(); // Fetch updated accounts
          dispatch(setGadsaccnt(true)); // Set account status to true
          setIsPopupOpen(false);
          setPopupChildren("");
          normalizePath(location, navigate); // Normalize the path after submission
          window.location.reload()
        }
      })
      .catch((error) => {
        // Error handling
        console.log("Login Error:", error);
        toastSetter("error", `Google Ads Error`);
        dispatch(setGadsaccnt(false)); // Set account status to false
      })
      .finally(() => {
        // Close the popup and reset children
        setIsPopupOpen(false);
        setPopupChildren("");
        normalizePath(location, navigate); // Normalize the path after submission
        // window.location.reload()
      });
  };

  // Handle clicks on the disabled button
  const handleDisabledButtonClick = () => {
    if (selectedOptions.length < 1) {
      const alertMessage = "Please select at least one Google Ads account.";
      toastSetter("info", alertMessage); // Alert if no account is selected
    }
  };

  return (
    <div className="integration-popup-container">
      <h2 className="integration-popup-heading">GoogleAds</h2>
      <label className="integration-popup-form-label">
        Select Google Ads Account (
        {inputType === "radio" ? "only 1" : "up to 3"})
      </label>
      <form className="integration-popup-form">
        {googleCustomers && // Render checkboxes for each Google customer
          googleCustomers.map((item, index) => (
            <span style={{ width: "100%" }} key={index}>
              <div className="integration-popup-input input-checkbox">
                <input
                  type={inputType} // Input type based on the selection mode (radio/checkbox)
                  value={item}
                  checked={selectedOptions.includes(item)} // Check if the option is selected
                  onChange={() => handleOptionChange(item)} // Handle option change
                  style={{ cursor: "pointer", marginRight: "10px" }}
                  disabled={googleCustomers.length === 1} // Disable input if only one item exists
                />
                <span>{item}</span> {/* Display account name */}
              </div>
            </span>
          ))}
      </form>
      <button
        type="button"
        className={
          selectedOptions.length < 1
            ? "integration-popup-submit integration-popup-submit-disabled"
            : "integration-popup-submit"
        }
        onClick={
          selectedOptions.length < 1
            ? handleDisabledButtonClick // Show alert if button is disabled
            : formSubmitHandler // Submit the form if valid
        }
      >
        Submit
      </button>
    </div>
  );
};

export default GoogleAds;
