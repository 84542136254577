import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import "./leftstyles.css";
import { useSelector } from "react-redux";
import { CohortTableLoader } from "../../components/loadingComponent/SkeletonLoader";
import { formatBigNumber } from "../../utils";



// Custom styles for DataTable
const customStyles = {
  headRow: {
    style: {
      backgroundColor: "#27292D", // Dark background for the header row
      color: "#DBFF00", // Yellow text color
      border: "none", // No border for the header row
      fontSize: "12px",
    },
  },
  headCells: {
    style: {
      color: "#DBFF00", // Yellow text color for header cells
      border: "none", // Remove border from header cells
      fontSize: "12px",
      whiteSpace: 'nowrap'
    },
  },
  cells: {
    style: {
      backgroundColor: "#1b1c1e;", // Dark background for data rows
      color: "white", // White text color for data cells
      border: "none", // Remove border from data cells
      padding: "0 20px",
      fontSize: "12px",
    },
  },
};



const columnConfigs = [
  { name: "New customer", field: "new_customer" },
  { name: "nRevenue", field: "nrev" },
  { name: "nAOV", field: "naov" },
  { name: "nLTV", field: "nltv" },
  { name: "Multi Order Customer %", field: "multiorder" },
];



// Define columns for the LeftTable
const columns = [
  {
    name: "First Purchase",
    selector: (row) => row._period,
    cell: (row) => (
      <div className="sticky-header-cell">
        <span className="sticky-column" title={row?._period}>
          {row?._period}
        </span>
      </div>
    ),
    minWidth: "100px",
    style: {
      position: "sticky",
      top: "0px",
      left: "48px",
      zIndex: 1,
      // whiteSpace: 'normal',
      // textAlign: 'center'
    },
    headerStyle: {
      position: "sticky",
      top: 0,
      zIndex: 1,
    },
  },

  ...columnConfigs.map(({ name, field }) => ({
    name: name,
    selector: (row) => {
      return row[field]
    },
    cell: (row) => formatBigNumber(row[field]),
    minWidth: "80px",
    conditionalCellStyles: [
      {
        when: (row) => row[field],
        style: {
          height: '60px',
          // outline: '1px solid ',
          display: 'grid',
          placeContent: 'center',
        },
      },
      {
        when: (row) => !row[field],
        style: {
          background: "#1B1E1C",
          color: "white",
          height: "60px",
          alignItems: "normal",
          // outline: '1px solid',
          "&:hover": {
            cursor: "pointer",
          },
        },
      }
    ],

  }))
];


const LeftTable = () => {

  const { tableData, isFetching, isError, error } = useSelector((state) => state.cohortTableData);
  let leftTableData = tableData?.map(({ new_customer, nrev, naov, nLTV, nltv, multiorder, _period
  }) => ({
    new_customer,
    nrev,
    naov,
    nltv,
    multiorder,
    _period
  }));


  const memoizedData = useMemo(() => leftTableData?.reverse(), [leftTableData]);

  // const handleRowClick = (row) => {
  //   console.log("Row clicked:", row);
  // };

  return (
    <div className="behavior-left">
      <div className="left-table" style={{ width: "100%" }}>
        {
          isFetching ? <CohortTableLoader /> :
            <DataTable
              columns={columns}
              data={memoizedData}
              noHeader
              striped
              // onRowClicked={handleRowClick}
              pointerOnHover
              customStyles={customStyles} // Apply custom styles here
            />
        }
      </div>
    </div>
  );
};




export default LeftTable;
