import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <header className="privacy-policy-header">
        <h1 className="h1">Privacy Policy for Trackocity</h1>
        <h4>Last Updated 05/04/2023</h4>
      </header>
      <section>
        <p className="p">
          This Privacy Policy describes how Trackocity ("we", "us", or "our")
          collects, uses, and shares information about you through our online
          interfaces (e.g., websites and mobile applications) owned and
          controlled by us, including trackocity.io (collectively referred to
          herein as the "Site"). Please read this notice carefully to understand
          what we do. If you do not understand any aspects of our Privacy
          Policy, please feel free to contact us at{" "}
          <Link to="mailto:contact@trackocity.io">contact@trackocity.io</Link>.
          Your use of our Site is also governed by our{" "}
          <Link to="/terms-and-conditions">Terms of Service</Link>.
        </p>
      </section>
      <section>
        <h2 className="h2">1. Information We Collect</h2>
        <ol type="A" style={{ marginLeft: "20px" }} className="ol">
          <li className="li">
            <h3>Information You Provide to Us</h3>
            <ul>
              <li className="li">
                Account Information: When you sign up for a Trackocity account,
                we require certain information such as your name, email address,
                and company details.
              </li>
              <li className="li">
                Payment Information: If you purchase a subscription, we collect
                payment and billing information necessary to process your
                charges.
              </li>
            </ul>
          </li>
          <li className="li">
            <h3>Information We Collect Automatically</h3>
            <ul>
              <li className="li">
                Browser and Device Information: We collect your browser type and
                Internet Protocol (IP) address. We also gather information about
                the device you are using to access the Service, such as whether
                it is a personal computer or a mobile device, and any
                identifiers associated with your handheld or mobile device .
              </li>
              <li className="li">
                Usage Data: We record the actions you take on our Service and
                the content, features, and activities that you access and
                participate in.
              </li>
              <li className="li">
                Referral Data: We note the website you visited immediately
                before accessing our Service to understand how you were directed
                to us.
              </li>
            </ul>
          </li>
          <li className="li">
            <h3>Information from Third Parties</h3>
            <p className="p">
              We may receive information about you from third parties, including
              marketing partners and analytics providers.
            </p>
          </li>
        </ol>
      </section>
      <section>
        <h2 className="h2">2. How We Use Your Information</h2>
        <p className="p">We use the information we collect to:</p>
        <ul style={{ marginLeft: "20px" }}>
          <li className="li">Provide, maintain, and improve our services.</li>
          <li className="li">Process your transactions.</li>
          <li className="li">
            Send you technical notices, updates, security alerts, and support
            and administrative messages.
          </li>
          <li className="li">
            Respond to your comments, questions, and requests and provide
            customer service.
          </li>
          <li className="li">
            Communicate with you about products, services, offers, and events
            offered by Trackocity and others, and provide news and information
            we think will be of interest to you.
          </li>
          <li className="li">
            Monitor and analyze trends, usage, and activities in connection with
            our Site.
          </li>
          <li className="li">
            Detect, investigate, and prevent fraudulent transactions and other
            illegal activities and protect the rights and property of Trackocity
            and others.
          </li>
        </ul>
      </section>
      <section>
        <h2 className="h2">3. Sharing Your Information</h2>
        <p className="p">We may share information about you as follows:</p>
        <ul style={{ marginLeft: "20px" }}>
          <p className="p">With service providers who assist us.</p>
          <li className="li">In response to legal requests.</li>
          <li className="li">
            Among Trackocity and our affiliates for operational purposes.
          </li>
          <li className="li">With your consent or direction.</li>
          <p className="p">
            Aggregated or de-identified information, which cannot identify you,
            may also be shared.
          </p>
        </ul>
        <p className="p">
          We may also share aggregated or de-identified information, which
          cannot reasonably be used to identify you.
        </p>
      </section>
      <section>
        <h2 className="h2">4. Your Choices</h2>
        <h3>Account Information</h3>
        <p className="p">
          You may update, correct, or delete information about you at any time
          by logging into your online account or emailing us at{" "}
          <Link to="mailto:contact@trackocity.io">contact@trackocity.io</Link>.
        </p>
        <h3>Cookies</h3>
        <p className="p">
          Most web browsers are set to accept cookies by default. If you prefer,
          you can usually choose to set your browser to remove or reject browser
          cookies. Please note that if you choose to remove or reject cookies,
          this could affect the availability and functionality of our Site.
        </p>
      </section>
      <section>
        <h2 className="h2">5. Security</h2>
        <p className="p">
          We take reasonable measures to help protect information about you from
          loss, theft, misuse and unauthorized access, disclosure, alteration,
          and destruction.
        </p>
      </section>
      <section>
        <h2 className="h2">6. Changes to This Privacy Policy</h2>
        <p className="p">
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. The updated policy will be posted on our website, and the
          date of the latest revision will be indicated..
        </p>
      </section>
      <section>
        <h2 className="h2">7. Google User Data</h2>
        <p className="p">
          Our application integrates with Google Ads, and we handle Google user
          data as described in this Privacy Policy and in compliance with the
          <Link to="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
            Google API Service: User Data Policy
          </Link>
          , including the Limited Use requirements. Our use of Google user data
          is limited to the purposes stated here.
        </p>
      </section>
      <section>
        <h2 className="h2">8. Contact Us</h2>
        <p className="p">
          If you have any questions about this Privacy Policy, please contact us
          at:{" "}
          <Link to="mailto:contact@trackocity.io">contact@trackocity.io</Link>.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
