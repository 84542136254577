import React from "react";
import "./styles.css";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <p className="oops-text">OOPS!</p>
      <h1 className="not-found-text">404</h1>
      <p className="not-found-text-desc">We can't find the page you're looking for :(</p>
    </div>
  );
};

export default NotFound;
