import React, { useEffect, useState } from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { userLogout } from "../../services/api/authApis";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import { FiEdit } from "react-icons/fi";
import {
  fetchProfileData,
  updateProfileData,
} from "../../redux/reducers/profileDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { sendEmailChangeOTP } from "../../services/api/profileApis";
import { DropDownComp, Popup } from "../../components";
import ChangeEmailForm from "./ChangeEmailForm";
import ProfileDropDown from "../../components/profileDropDown/ProfileDropDown";
import { ErrorPage1,ErrorPage2 } from "../../components/errorPages/ErrorPage";

const labelStyle = "original";
const timezones = {
  ...allTimezones,
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.number()
    .typeError("must be a number")
    .positive("Phone number must be a positive number")
    .integer("Phone number must be an integer")
    .min(10000, "must be at least 5 digits")
    .required("Phone number is required"),

  name: Yup.string().required("Name is required"),
  company: Yup.string()
    .min(3, "at least 3 char")
    .max(20, "at most 20 char")
    .nullable(),
});

const Profile = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);
  const [profileUpdated, setProfileUpdated] = useState(false);
  const { profileData } = useSelector((state) => state.profile);
  const [profileIcon, setProfileIcon] = useState(false);

  

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const dispatch = useDispatch();
  const [editProfile, setEditProfile] = useState({
    email: false,
    phone: false,
    name: false,
    company: false,
    timezone: false,
    currency: false,
  });

  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones,
  });
  const [agency, setAgency] = useState("");
  useEffect(() => {
    const newdata = localStorage.getItem("t_agency_id");
    setAgency(newdata);
  }, []);
  console.log(agency);
  const navigate = useNavigate();
  const signOutHandler = () => {
    userLogout();
    navigate("/sign-in");
  };

  const toggleEditMode = (field) => {
    setEditProfile({
      email: false,
      phone: false,
      name: false,
      company: false,
      timezone: false,
      currency: false,
    });
    setEditProfile((prevProfile) => ({
      ...prevProfile,
      [field]: !prevProfile[field],
    }));
  };

  const handleProfilebtnClick = () => {
    setProfileIcon(false);
    navigate("/profile");
  };

  const handleClientbtnClick = () => {
    setProfileIcon(false);
    navigate("/client-profile");
  };
  const formik = useFormik({
    initialValues: {
      email: profileData && profileData.email ? profileData.email : "",
      phone: profileData && profileData.phone ? profileData.phone : "",
      name: profileData && profileData.name ? profileData.name : "",
      company: profileData && profileData.company ? profileData.company : "",
      timezone: profileData && profileData.timezone ? profileData.timezone : "",
      currency: profileData && profileData.currency ? profileData.currency : "",
      plan_till: profileData && profileData.plan_till ? profileData.plan_till : "",
      
    },
    validationSchema,

    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);

      setProfileUpdated(false);
      const selectedTimezone = options?.find(
        (option) => values.timezone === option.value
      );

      dispatch(
        updateProfileData({
          email: values.email && values.email !== "" ? values.email : null,
          phone: values.phone && values.phone !== "" ? values.phone : null,
          name: values.name && values.name !== "" ? values.name : null,
          company:
            values.company && values.company !== "" ? values.company : null,
          timezone:
            values.timezone && values.timezone !== ""
              ? selectedTimezone.label
              : null,

          currency:
            values.currency && values.currency !== "" ? values.currency : null,
        })
      ).finally(() => {
        setSubmitting(false);
        setEditProfile({
          email: false,
          phone: false,
          name: false,
          company: false,
          timezone: false,
          currency: false,
        });
        setProfileUpdated(true);
        console.log("form submitted !!!!");
      });
    },
  });

  const emailChangeHandler = (e) => {
    e.preventDefault();
    console.log("Current Email Value:", formik.values.email);
    sendEmailChangeOTP({ email: formik.values.email })
      .then((res) => {
        console.log(res, "jhas jhs j");
      })
      .then(() => {
        openPopup();
        setEditProfile({
          email: false,
          phone: false,
          name: false,
          company: false,
          timezone: false,
          currency: false,
        });
      });
  };

  useEffect(() => {
    const nId = localStorage.getItem("n_workspace_id");
    console.log(nId);
    if (nId !== null) {
      localStorage.removeItem("n_workspace_id");
    }
  }, []);

  useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
      console.log("Form submission errors:", formik.errors);
      console.log("touched", formik.touched);
    }
  }, [formik.errors, formik.touched]);

  useEffect(() => {
    dispatch(fetchProfileData());
  }, [
    formik.isSubmitting === false,
    profileUpdated === true,
    emailChanged === true,
  ]);

  useEffect(() => {
    if (profileData) {
      const selectedTimezone = options?.find(
        (option) => profileData?.timezone === option.label
      );

      formik.setValues({
        email: profileData.email || null,
        phone: profileData.phone || null,
        name: profileData.name || null,
        company: profileData.company || null,
        timezone: selectedTimezone?.value || null,
        currency: profileData.currency || null,
        plan_till: profileData.plan_till || null,
      });
    }
  }, [profileData, editProfile]);
  const handleProfileClick = () => {
    setProfileIcon((prev) => !prev);
  };

  const [refreshCount, setRefreshCount] = useState(
    localStorage.getItem("refreshcalled")
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshCount(localStorage.getItem("refreshcalled"));
    }, 1000); // Polling interval for localStorage updates

    return () => clearInterval(interval);
  }, []);
  
  return (
    <>
      <Popup isOpen={isPopupOpen} onClose={closePopup}>
        <ChangeEmailForm
          setIsPopupOpen={setIsPopupOpen}
          profileData={profileData}
          newEmail={formik.values.email}
          setEmailChanged={setEmailChanged}
        />
      </Popup>

      <div className="profile-container">
        <div
          className="profile-header-section"
          style={{ paddingRight: "60px" }}
        >
          <div className="client-heading">
            <h2 className="profile-heading">Profile</h2>
            {/* {agency !== null && (
              <div className="icon">
                <button onClick={handleProfileClick} className="profile-btn">
                  <img
                    alt="profile-icon"
                    className="profile-icon"
                    src="Client.png"
                  />
                </button>
                {profileIcon && (
                  <div className="dropdown-client">
                    <button
                      onClick={handleProfilebtnClick}
                      className="toggle-btn-one"
                    >
                      Profile
                    </button>
                    <button
                      onClick={handleClientbtnClick}
                      className="toggle-btn-two"
                    >
                      Client
                    </button>
                  </div>
                )}
              </div>
            )} */}
            
          </div>
          <ProfileDropDown />
        </div>
        <div
          className="profile-body-section"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          

          {refreshCount >= 90 && refreshCount < 100 && (
            <div
              className="error-in-page"
              style={{  top: "0", zIndex: "97",marginTop:"50px",marginBottom:"50px",marginLeft:"-10px",width:"100%",}}
            >
              <ErrorPage1 />
            </div>
          )}

          {refreshCount >= 100 && (
            <div
              className="error-in-page"
              style={{  top: "0", zIndex: "97",marginBottom:"50px",marginLeft:"-10px",width:"100%",}}
            >
              <ErrorPage2 />
            </div>
          )}  

          <form onSubmit={formik.handleSubmit}>
            <div className="profile-form-section-flex">
              <div className="profile-form-section">
                <div>
                  <div className="profile_input_label-container">
                    <label className="profile-form-label">
                      <label htmlFor="name-field" className="input-label">
                        {formik.errors.email ? (
                          <span id="nameSpan" className="error">
                            {formik.errors.email && formik.errors.email}
                          </span>
                        ) : (
                          "Email"
                        )}
                      </label>
                    </label>
                    <button
                      className={`profile-edit-wrapper ${
                        editProfile.email === true &&
                        "profile-edit-wrapper-cancel"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        editProfile.email === true
                          ? setEditProfile({
                              email: false,
                              phone: false,
                              name: false,
                              company: false,
                              timezone: false,
                              currency: false,
                            })
                          : toggleEditMode("email");
                      }}
                    >
                      <p
                        className={`profile-edit-icon ${
                          editProfile.email === true &&
                          "profile-edit-icon-cancel"
                        }`}
                      >
                        {editProfile.email === false ? "Edit" : "Cancel"}
                      </p>
                    </button>
                  </div>

                  <div className="profile-form-input-container">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="profile-form-input"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={!editProfile.email}
                      placeholder="Please Enter a Valid Email Id"
                      // readOnly
                    />
                    <button
                      className="save-profile-btn"
                      disabled={!editProfile.email}
                      hidden={!editProfile.email}
                      onClick={emailChangeHandler}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div>
                  <div className="profile_input_label-container">
                    <label className="profile-form-label">
                      {" "}
                      <label htmlFor="name-field" className="input-label">
                        {formik.errors.phone ? (
                          <span id="nameSpan" className="error">
                            {formik.errors.phone && formik.errors.phone}
                          </span>
                        ) : (
                          "Phone no"
                        )}
                      </label>
                    </label>
                    <button
                      className={`profile-edit-wrapper ${
                        editProfile.phone === true &&
                        "profile-edit-wrapper-cancel"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        editProfile.phone === true
                          ? setEditProfile({
                              email: false,
                              phone: false,
                              name: false,
                              company: false,
                              timezone: false,
                              currency: false,
                            })
                          : toggleEditMode("phone");
                      }}
                    >
                      <p
                        className={`profile-edit-icon ${
                          editProfile.phone === true &&
                          "profile-edit-icon-cancel"
                        }`}
                      >
                        {editProfile.phone === false ? "Edit" : "Cancel"}
                      </p>
                    </button>
                  </div>
                  <div className="profile-form-input-container">
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      className="profile-form-input"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={!editProfile.phone}
                      placeholder="Please Enter a Valid Phone Number"
                    />
                    <button
                      type="submit"
                      className="save-profile-btn"
                      disabled={!editProfile.phone}
                      hidden={!editProfile.phone}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <div className="profile-form-section">
                <div>
                  <div className="profile_input_label-container">
                    <label className="profile-form-label">
                      {" "}
                      <label htmlFor="name-field" className="input-label">
                        {formik.errors.name ? (
                          <span id="nameSpan" className="error">
                            {formik.errors.name && formik.errors.name}
                          </span>
                        ) : (
                          "Name"
                        )}
                      </label>
                    </label>
                    <button
                      className={`profile-edit-wrapper ${
                        editProfile.name === true &&
                        "profile-edit-wrapper-cancel"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        editProfile.name === true
                          ? setEditProfile({
                              email: false,
                              phone: false,
                              name: false,
                              company: false,
                              timezone: false,
                              currency: false,
                            })
                          : toggleEditMode("name");
                      }}
                    >
                      <p
                        className={`profile-edit-icon ${
                          editProfile.name === true &&
                          "profile-edit-icon-cancel"
                        }`}
                      >
                        {editProfile.name === false ? "Edit" : "Cancel"}
                      </p>
                    </button>
                  </div>
                  
                  <div className="profile-form-input-container">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="profile-form-input"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={!editProfile.name}
                      placeholder="Please Enter Your Name"
                    />
                    <button
                      type="submit"
                      className="save-profile-btn"
                      disabled={!editProfile.name}
                      hidden={!editProfile.name}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div>

                  
               
                  <div className="profile_input_label-container">
                    <label className="profile-form-label">
                      {" "}
                      <label htmlFor="name-field" className="input-label">
                        {formik.errors.company ? (
                          <span id="nameSpan" className="error">
                            {formik.errors.company && formik.errors.company}
                          </span>
                        ) : (
                          "Company Name"
                        )}
                      </label>
                    </label>
                    <button
                      className={`profile-edit-wrapper ${
                        editProfile.company === true &&
                        "profile-edit-wrapper-cancel"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        editProfile.company === true
                          ? setEditProfile({
                              email: false,
                              phone: false,
                              name: false,
                              company: false,
                              timezone: false,
                              currency: false,
                            })
                          : toggleEditMode("company");
                      }}
                    >
                      <p
                        className={`profile-edit-icon ${
                          editProfile.company === true &&
                          "profile-edit-icon-cancel"
                        }`}
                      >
                        {editProfile.company === false ? "Edit" : "Cancel"}
                      </p>
                    </button>
                  </div>
                  <div className="profile-form-input-container">
                    <input
                      type="text"
                      name="company"
                      id="company"
                      className="profile-form-input"
                      value={formik.values.company}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={!editProfile.company}
                      placeholder="Please Enter Your Company Name"
                    />
                    <button
                      type="submit"
                      className="save-profile-btn"
                      disabled={!editProfile.company}
                      hidden={!editProfile.company}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <div className="profile-form-section">
                <div>
                  <div className="profile_input_label-container">
                    <label className="profile-form-label">
                      {" "}
                      <label htmlFor="name-field" className="input-label">
                        {formik.errors.timezone && formik.touched.timezone
                          ? ""
                          : "Time Zone"}
                      </label>
                      <span id="nameSpan" className="error">
                        {formik.errors.timezone &&
                          formik.touched.timezone &&
                          formik.errors.timezone}
                      </span>
                    </label>
                    <button
                      className={`profile-edit-wrapper ${
                        editProfile.timezone === true &&
                        "profile-edit-wrapper-cancel"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        editProfile.timezone === true
                          ? setEditProfile({
                              email: false,
                              phone: false,
                              name: false,
                              company: false,
                              timezone: false,
                              currency: false,
                            })
                          : toggleEditMode("timezone");
                      }}
                    >
                      <p
                        className={`profile-edit-icon ${
                          editProfile.timezone === true &&
                          "profile-edit-icon-cancel"
                        }`}
                      >
                        {editProfile.timezone === false ? "Edit" : "Cancel"}
                      </p>
                    </button>
                  </div>
                  <div className="profile-form-input-container">
                    <select
                      className="profile-form-input"
                      value={
                        formik.values.timezone === null
                          ? ""
                          : formik.values.timezone
                      } // Bind to formik values
                      onChange={(e) => {
                        const selectedTimezone = options.find(
                          (option) => option.value === e.currentTarget.value
                        );
                        const value = selectedTimezone?.value;
                        formik.setFieldValue("timezone", value);
                      }}
                      disabled={!editProfile.timezone}
                    >
                      <option className="option" value="" disabled hidden>
                        Please Select Your TimeZone
                      </option>
                      {options.map((option, i) => (
                        <option className="option" value={option.value} key={i}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <button
                      type="submit"
                      className="save-profile-btn"
                      disabled={!editProfile.timezone}
                      hidden={!editProfile.timezone}
                    >
                      Save
                    </button>
                  </div>
                  
                  
                </div>
                
                
                
                <div>
                  <div className="profile_input_label-container">
                    <label className="profile-form-label">
                      {" "}
                      <label htmlFor="name-field" className="input-label">
                        {formik.errors.currency && formik.touched.currency
                          ? ""
                          : "Currency"}
                      </label>
                      <span id="nameSpan" className="error">
                        {formik.errors.currency &&
                          formik.touched.currency &&
                          formik.errors.currency}
                      </span>
                    </label>
                    <button
                      className={`profile-edit-wrapper ${
                        editProfile.currency === true &&
                        "profile-edit-wrapper-cancel"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        editProfile.currency === true
                          ? setEditProfile({
                              email: false,
                              phone: false,
                              name: false,
                              company: false,
                              timezone: false,
                              currency: false,
                            })
                          : toggleEditMode("currency");
                      }}
                    >
                      <p
                        className={`profile-edit-icon ${
                          editProfile.currency === true &&
                          "profile-edit-icon-cancel"
                        }`}
                      >
                        {editProfile.currency === false ? "Edit" : "Cancel"}
                      </p>
                    </button>
                  </div>
                  <div className="profile-form-input-container">
                    <select
                      className="profile-form-input"
                      value={
                        formik.values.currency === null
                          ? ""
                          : formik.values.currency
                      }
                      onChange={(e) =>
                        formik.setFieldValue("currency", e.target.value)
                      }
                      disabled={!editProfile.currency}
                    >
                      <option className="option" value="" disabled hidden>
                        Please Select Your Currency
                      </option>
                      <option className="option" value="USD">
                        $ USD
                      </option>
                      <option className="option" value="GBP">
                        £ GBP
                      </option>
                      <option className="option" value="INR">
                        ₹ INR
                      </option>
                    </select>

                    <button
                      type="submit"
                      className="save-profile-btn"
                      disabled={!editProfile.currency}
                      hidden={!editProfile.currency}
                    >
                      Save
                    </button>
                  </div>
                  
                   
                </div>

                
                
                
              </div>
              <div className="profile-form-section">
                <div>
                  <div className="profile_input_label-container">
                    <label className="profile-form-label">
                      <label htmlFor="name-field" className="input-label">
                        {formik.errors.email ? (
                          <span id="nameSpan" className="error">
                            {formik.errors.email && formik.errors.email}
                          </span>
                        ) : (
                          "Plan Till"
                        )}
                      </label>
                    </label>
                    
                  </div>

                  <div className="profile-form-input-container">
                    <input
                      type="text"
                      name="plan_till"
                      id="plan_till"
                      className="profile-form-input"
                      value={formik.values.plan_till}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={!editProfile.plan_till}
                      placeholder="Plan Expiry Date"
                       readOnly
                    />
                   
                  </div>
                </div>
                <div>
                <div className="profile_input_label-container">
                 <label className="profile-form-label"></label>
                </div>
                <div className="profile-form-input-container">
                <input
                  type="text"
                  className="profile-form-input"
                  style={{ visibility: "hidden" }} /* Hide input but keep space */
                  disabled
                />
              </div>
                  
                </div>
              </div>
              <div className="profile-form-section">
                <button className="change-password-btn">Change Password</button>
                <button className="sign-out-btn" onClick={signOutHandler}>
                  Sign Out
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Profile;
