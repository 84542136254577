import React, { useState } from "react";
import { resetPassword } from "../../services/api/authApis";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import { IoMdEye, IoMdEyeOff } from "react-icons/io";

const ResetPassword = ({ initialToken }) => {
  const [showPswd, setShowPswd] = useState(false)
  const [showConfirmPswd, setShowConfirmPswd] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();

  const validationSchema = Yup.object().shape({
    newpassword: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\]:;<>,.?~\\/])\S{6,}$/,
        "Password must contain at least 1 lowercase, 1 uppercase, and 1 special character"
      )
      .required("Password is required"),
    confirmpassword: Yup.string()
      .oneOf([Yup.ref('newpassword'), null], 'Passwords must match') // Ensure it matches new password
      .required('Confirm Password is required')
  });

  const formik = useFormik({
    initialValues: {
      newpassword: "",
      confirmpassword: "",
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      resetPassword({
        refreshtoken: initialToken,
        newpassword: values.newpassword,
      })
        .then((res) => {
          // console.log(res);
          if (res.status && res.status === 200) {
            formik.resetForm();
            navigate("/sign-in");
          }
        })
        .catch((error) => {
          // console.log(error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    <form className="signin-form-container" onSubmit={formik.handleSubmit}>
      <h1 className="signin-heading">Reset Your Password</h1>

      <div className="input-box-container" style={{ position: "relative" }}>
        <label className="signin-label" htmlFor="signin-newpswd">
          {formik.errors.newpassword && formik.touched.newpassword
            ? ""
            : "New Password"}
        </label>
        <span id="signinEmailSpan" className="error">
          {" "}
          {formik.errors.newpassword &&
            formik.touched.newpassword &&
            formik.errors.newpassword}
        </span>
        <br />
        <input
          type={showPswd ? "text" : "password"}
          value={formik.values.newpassword}
          name="newpassword"
          className="signin-input-field"
          id="signin-newpswd"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
         <span className="login-pwd-wiew" onClick={() => setShowPswd(!showPswd)}>
            {showPswd ? <IoMdEyeOff /> : <IoMdEye />}
          </span>
      </div>

      <div className="input-box-container" style={{ position: "relative" }}>
        <label className="signin-label" htmlFor="signin-confirmpswd">
          {formik.errors.confirmpassword && formik.touched.confirmpassword
            ? ""
            : "Confirm Password"}
        </label>
        <span id="signinConfirmPswdSpan" className="error">
          {" "}
          {formik.errors.confirmpassword &&
            formik.touched.confirmpassword &&
            formik.errors.confirmpassword}
        </span>
        <br />
        <input
          type={showConfirmPswd ? "text" : "password"}
          value={formik.values.confirmpassword}
          name="confirmpassword"
          className="signin-input-field"
          id="signin-confirmpswd"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
         <span className="login-pwd-wiew" onClick={() => setShowConfirmPswd(!showConfirmPswd)}>
            {showConfirmPswd ? <IoMdEyeOff /> : <IoMdEye />}
          </span>
      </div>

      <button
        type="submit"
        className="signin-button"
        disabled={formik.isSubmitting}
      >
        Submit
      </button>
    </form>
  );
};

export default ResetPassword;
