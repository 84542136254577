import catchAsync from "../../utils/catchAsync";
import http from "./http";

export const fetchTrackId = catchAsync(async (values) => {
  console.log(values);
  try {
    // Extract trackid from values
    const trackid = values;
    console.log(trackid);
    const response = await http.get(`/reporting/tablesalejourney`, {
      params: { trackid }, // Make sure this is correct
    });

    if (response.status === 200) {
      return response;
    } else {
      throw new Error(`Failed to fetch trackid, status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching trackid:", error);
    throw error;
  }
});
