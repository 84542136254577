import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./styles.css";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { signUp } from "../../redux/reducers/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { BiUser } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { MenuItem } from "@mui/material";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

const SignUp = () => {
  const { isError, error, isFetching } = useSelector((state) => state.auth);

  const [accountType, setAccountType] = useState(""); // Default 'Individual'
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Dropdown toggle state

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Handle account type selection
  const handleAccountTypeClick = (type) => {
    setAccountType(type); // Update selected type
    setIsDropdownOpen(false); // Close dropdown
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".custom-dropdown")) {
        setIsDropdownOpen(false); // Close the dropdown if the click is outside
      }
    };

    // Attach event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Facebook Pixel ID
    const pixelId = "341914418975930"; // Replace with your actual Facebook Pixel ID

    // Function to initialize Facebook Pixel
    const initializeFacebookPixel = () => {
      // Immediately-invoked function expression (IIFE) to initialize fbq
      (function (f, b, e, v, n, t, s) {
        if (f.fbq) {
          return;
        }
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = true;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = true;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);

        console.log("Facebook Pixel script added to document");
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );

      // Initialize Facebook Pixel with the provided ID
      if (window.fbq) {
        window.fbq("init", pixelId);
        window.fbq("track", "PageView");
        console.log("Facebook Pixel initialized with ID:");
      } else {
        console.error("fbq is not defined after script load");
      }
    };

    // Execute the initialization function
    initializeFacebookPixel();

    // Cleanup function to remove the script on component unmount
    return () => {
      const existingScript = document.getElementById("fb-pixel");
      if (existingScript) {
        document.head.removeChild(existingScript);
        console.log("Facebook Pixel script removed from document");
      }
    };
  }, []); // Empty dependency array ensures this runs only once

  // useEffect(() => {
  //   // running our script
  //   // Create a script element
  //   const script = document.createElement("script");
  //   script.type = "text/javascript";
  //   script.src =
  //     "https://dt7eje67l7nhtt2qpr25lhduxm0annqu.lambda-url.ap-south-1.on.aws/987654321";

  //   // Append the script to the head
  //   document.head.appendChild(script);

  //   // Cleanup function to remove the script when the component unmounts
  //   return () => {
  //     document.head.removeChild(script);
  //   };
  // }, []);
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'module';

    // Dynamically set the src based on the current website
    const currentHost = window.location.origin;
    if (currentHost === 'https://app.trackocity.io') {
      script.src = 'https://l4vepgbcmj4wuph3mzg3x575l40iiyel.lambda-url.ap-south-1.on.aws/987654321';
    } else {
      script.src = 'https://rbu2yxzyycb5gy23ttyodqirgq0czfaz.lambda-url.ap-south-1.on.aws/111111111';
    }

    document.head.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPswd, setShowPswd] = useState(false);
  const [typedText, setTypedText] = useState("");
  const [typedTextTwo, setTypedTextTwo] = useState("");
  const [typedTextThree, setTypedTextThree] = useState("");

  const typeSpeed = 100;
  const useTypingAnimation = (textToType, setText) => {
    useEffect(() => {
      const timeoutId = setTimeout(() => {
        let currentIndex = 0;

        const intervalId = setInterval(() => {
          if (currentIndex <= textToType.length) {
            setText(textToType.slice(0, currentIndex));
            currentIndex++;
          } else {
            clearInterval(intervalId);
          }
        }, typeSpeed);

        return () => clearInterval(intervalId);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }, [textToType, setText]);
  };

  useTypingAnimation("John Smith", setTypedText);
  useTypingAnimation("john@email.com", setTypedTextTwo);
  useTypingAnimation("9999999999", setTypedTextThree);

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    countryCode: Yup.string().required("*"),
    phonenumber: Yup.string().required("Phone number is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\]:;<>,.?~\\/])\S{6,}$/,
        "Password must contain at least 1 lowercase, 1 uppercase, and 1 special character"
      )
      .required("Password is required"),
    accountType: Yup.string().required("Account type is required"),
  });

  // console.log(accountType.toLowerCase().trim());

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      countryCode: "91",
      phonenumber: "",
      password: "",
      accountType: accountType.toLowerCase().trim(),
    },

    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      console.log(values);
      setSubmitting(true); // Set submitting to true before dispatching action
      dispatch(
        signUp({
          name: values.username,
          email: values.email,
          phone: values.countryCode + values.phonenumber,
          password: values.password,
          account_type: values.accountType,
        })
      )
        .then((res) => {
          if (res.payload.status && res.payload.status === 201) {
            formik.resetForm();
            navigate("/sign-in");
          }
        })
        .catch((error) => {
          // console.error("Register Error:", error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  // Update accountType in Formik when it changes
  useEffect(() => {
    formik.setFieldValue("accountType", accountType.toLowerCase().trim());
  }, [accountType]);

  console.log(formik.values.accountType);
  // const onChangeAreaCode = (value) => {
  //   console.log(value);
  //   formik.setFieldValue("countryCode", `${value}`);
  // };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-container">
        <div className="header-container">
          <h1 className="heading">Sign up</h1>
          <Link to="/sign-in" className="login-link">
            Already have an account?
          </Link>
        </div>

        <div className="form-starting-signup">
          <div className="input-field-container">
            <label htmlFor="name-field" className="input-label" >

              {formik.errors.username && formik.touched.username ? "" : "Name*"}
            </label>
            <span id="nameSpan" className="error">
              {formik.errors.username &&
                formik.touched.username &&
                formik.errors.username}
            </span>

            <div className="input-field-with-icon">
              <input
                type="text"
                className="input-field"
                placeholder={typedText}
                id="name-field"
                name="username"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.username}
                style={{ marginBottom: "10px" }}
              />
              <BiUser className="input-icon" />
            </div>
          </div>

          <div className="input-field-container">
            <label htmlFor="email-field" className="input-label">
              {formik.errors.email && formik.touched.email ? "" : "Email*"}
            </label>
            <span id="emailSpan" className="error">
              {formik.errors.email &&
                formik.touched.email &&
                formik.errors.email}
            </span>

            <div className="input-field-with-mailicon">
              <input
                type="email"
                className="input-field"
                placeholder={typedTextTwo}
                id="email-field"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                style={{ marginBottom: "10px" }}
              />
              <MdEmail className="input-icon" />
            </div>
          </div>

          <div className="input-field-container">
            <label htmlFor="phonenumber-field" className="input-label">
              {formik.errors.phonenumber && formik.touched.phonenumber
                ? ""
                : "Phone Number"}
            </label>
            <span className="error">
              {formik.errors.phonenumber &&
                formik.touched.phonenumber &&
                formik.errors.phonenumber}
            </span>

            <div
              className="input-field-container-phonenumber"
              style={{ marginBottom: "14px" }}
            >
              <PhoneInput
                inputStyle={{
                  color: formik.values.phonenumber ? "#fff" : "#716F6F",
                  width: "120px",
                  backgroundColor: "transparent",
                  height: "40px",
                  fontSize: "18px",
                }}
                containerStyle={{
                  width: "20px",
                  marginLeft: "0px",
                  // marginBottom: "20px",
                }}
                dropdownStyle={{ height: "100px" }}
                defaultCountry="IN"
                value={formik.values.countryCode}
                onChange={formik.handleChange}
                className="phone-input-override"
                readOnly
                placeholder=""
                inputProps={{
                  required: true,
                }}
              />

              <input
                type="tel"
                placeholder={typedTextThree}
                minLength={7}
                maxLength={16}
                className="contactnumber-input-field"
                name="phonenumber"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phonenumber}
                style={{ height: "40px" }}
              />
            </div>
            <div className="contactnumber-small">
              <select
                className="contactnumber-input-small"
                // className="contactnumber-input-field-digit"
                name="countryCode"
                value={formik.values.countryCode}
                onChange={formik.handleChange}
                type="text"
                style={{
                  color: formik.values.phonenumber ? "#fff" : "#716F6F",
                  maxHeight: "50px",
                  overflowY: "auto",
                }}
              >
                <option value="+91">91</option>
                <option value="+315">315</option>
                <option value="+56">56</option>
                <option value="+45">45</option>
                <option value="+25">25</option>
              </select>
              <input
                type="tel"
                placeholder={typedTextThree}
                minLength={7}
                maxLength={16}
                className="contactnumber-input-field-phoneno"
                name="phonenumber"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phonenumber}
              />
            </div>
          </div>

          <div className="input-field-container">
            <label className="input-label">
              {formik.errors.accountType && formik.touched.accountType
                ? ""
                : "Account Type"}
            </label>
            {formik.errors.accountType && formik.touched.accountType && (
              <span className="error">{formik.errors.accountType}</span>
            )}
            <div
              className="custom-dropdown"
              style={{
                width: "100%",
                maxWidth: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px 15px",
                border: "0.848px solid #fff",
                marginTop: "10px",
                marginBottom: "14px",
                borderRadius: "5px",
                backgroundColor: "transparent",
                color: accountType ? "#fff" : "#aaa", // White for selected value, #aaa for placeholder
                cursor: "pointer",
                position: "relative",
              }}
              onClick={toggleDropdown}
              onBlur={() => formik.setFieldTouched("accountType", true)} // Mark as touched on blur
              tabIndex="0" // Ensure it can be focused for blur detection
            >
              <div
                className={`dropdown-selected ${accountType ? "" : "dropdown-placeholder"
                  }`}
              >
                {accountType ? accountType : "Please Select Account Type"}
              </div>
              {isDropdownOpen && (
                <div
                  className="dropdown-options"
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "16px",
                    width: "90%",
                    backgroundColor: "#6c7070",
                    zIndex: "1000",
                  }}
                >
                  <div
                    className="dropdown-item"
                    onClick={() => handleAccountTypeClick("Individual")}
                  >
                    Individual
                  </div>
                  <hr
                    style={{ color: "white", width: "93%", marginLeft: "12px" }}
                  />
                  <div
                    className="dropdown-item"
                    onClick={() => handleAccountTypeClick("Agency")}
                  >
                    Agency
                  </div>
                </div>
              )}
              {isDropdownOpen ? (
                <IoMdArrowDropup size={24} className="input-icon" />
              ) : (
                <IoMdArrowDropdown size={24} className="input-icon" />
              )}
            </div>
          </div>

          <div
            className="input-field-container"
            style={{ position: "relative" }}
          >
            <label htmlFor="password-field" className="input-label">
              {formik.errors.password && formik.touched.password
                ? ""
                : "Password"}
            </label>
            <span className="error">
              {formik.errors.password &&
                formik.touched.password &&
                formik.errors.password}
            </span>

            <input
              type={showPswd ? "text" : "password"}
              className="input-field"
              id="password-field"
              name="password"
              placeholder="Enter Your Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            <span
              className="reg-pwd-wiew"
              onClick={() => setShowPswd(!showPswd)}
            >
              {showPswd ? (
                <IoMdEyeOff className="eye-open-signup" />
              ) : (
                <IoMdEye />
              )}
            </span>
          </div>

          {/* <p className="error" id="error-msg">
            {isError && error ? error.message : null}
          </p> */}
          <div className="term-div">
            <div className="term-links">
              <Link to="/terms-and-conditions" className="link-style">
                Terms and Service
              </Link>
              <span className="and">and</span>
              <Link to="/privacy-policy" className="link-style">
                Privacy Policy
              </Link>
            </div>

            <button
              type="submit"
              className="signin-button"
              disabled={formik.isSubmitting}
            >
              {isFetching ? "Creating New Account..." : "Create Account"}
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          ></div>
        </div>
      </div>
    </form>
  );
};

export default SignUp;
