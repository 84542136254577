import React from "react";
import "./styles.css";

const planOneAccess = [
  {
    image: "disc.svg",
    text: "Page Views Up to 100K",
  },
  {
    image: "disc.svg",
    text: "Users: 1",
  },
  {
    image: "disc.svg",
    text: "Workspaces: 1",
  },
  {
    image: "disc.svg",
    text: "Historical Data: Access to 1 year of data",
  },
];
const planOneAccessTwo = [
  {
    image: "disc.svg",
    text: "Page Views Up to 500K",
  },
  {
    image: "disc.svg",
    text: "Users: 3",
  },
  {
    image: "disc.svg",
    text: "Workspaces: 5",
  },
  {
    image: "disc.svg",
    text: "Historical Data: Access to 2 year of data",
  },
];

const planTwoAccess = [
  {
    image: "disc.svg",
    text: "Dashboard",
  },
  {
    image: "disc.svg",
    text: "ROI Analysis",
  },
  {
    image: "disc.svg",
    text: "Event Tracking",
  },
  {
    image: "disc.svg",
    text: "Traffic & Session",
  },
  {
    image: "disc.svg",
    text: "Ad Spend Optimization",
  },
  {
    image: "disc.svg",
    text: "Advanced Reporting",
  },
  {
    image: "disc.svg",
    text: "Data Export",
  },
  {
    image: "disc.svg",
    text: "Chat Support",
  },
];
const planTwoAccessTwo = [
  {
    image: "disc.svg",
    text: "Everything in Standard Plan",
  },
  {
    image: "disc.svg",
    text: "Conversion API",
  },
  {
    image: "disc.svg",
    text: "Multi-Touch Attribution",
  },
  {
    image: "disc.svg",
    text: "User Journey",
  },
  {
    image: "disc.svg",
    text: "Phone Support",
  },
  {
    image: "disc.svg",
    text: "Dedicated Onboarding Member",
  },
  //   {
  //     image: "disc.svg",
  //     text: "Data Export",
  //   },
  //   {
  //     image: "disc.svg",
  //     text: "Chat Support",
  //   },
];

function Pricing() {
  return (
    <>
      <div className="pricing">
        <div className="header">
          <img className="image" src="/Trackocity-5---Favicon.png"></img>
        </div>
        <div className="header-text">
          <p className="header-one">Simple Pricing for Powerful Insights</p>
          <p className=" header-two">
            Get full access to all features for one affordable price.
          </p>
        </div>
        <div className="plan">
          <div className="plan-one">
            <p className="plan-head">Standard Plan</p>
            <p className="plan-cost">
              ₹5,999<span className="mon">/mon</span>{" "}
              <span className="plus-pricing">+</span>{" "}
              <span className="tax">Tax</span>
            </p>

            <p className="plan-line"></p>
            <div className="plan-access">
              <p className="access-text"> Access Details</p>
              <div className="plan-listOne">
                {planOneAccess.map((item) => (
                  <div className="list" key={item.text}>
                    <img src={item.image}></img>
                    <div>{item.text}</div>
                  </div>
                ))}
              </div>
              <div className="feature-access">
                <p className="features-text">Features</p>
                <div className="plan-listTwo">
                  {planTwoAccess.map((item) => (
                    <div className="list" key={item.text}>
                      <img src={item.image}></img>
                      <div>{item.text}</div>
                    </div>
                  ))}
                </div>
                <button className="button">Start Your Trial</button>
              </div>
            </div>
          </div>
          <div className="plan-two">
            <p className="plan-head">Growth Plan</p>
            <p className="plan-cost">
              ₹15,999<span className="mon">/mon</span>{" "}
              <span className="plus-pricing">+</span>{" "}
              <span className="tax">Tax</span>
            </p>

            <p className="plan-line"></p>
            <div className="plan-access">
              <p className="access-text"> Access Details</p>
              <div className="plan-listOne">
                {planOneAccessTwo.map((item) => (
                  <div className="list" key={item.text}>
                    <img src={item.image}></img>
                    <div>{item.text}</div>
                  </div>
                ))}
              </div>
              <div className="feature-access">
                <p className="features-text">Features</p>
                <div className="plan-listTwo">
                  {planTwoAccessTwo.map((item) => (
                    <div className="list" key={item.text}>
                      <img src={item.image}></img>
                      <div>{item.text}</div>
                    </div>
                  ))}
                </div>
                <button className="buttonTwo">Buy now</button>
              </div>
            </div>
          </div>
        </div>
        <div className="text">
          <p className="bottom">No credit card required</p>
          <p>Exprience the full power of Trakocity today</p>
        </div>
      </div>
      <div>
        <div className="secondPricing">
          <div className="questions"></div>
          <div className="main-questions">
            <p>Have questions? We're here to help.</p>
            <p className="email">trackocity101@gmail.com</p>
            <p className="number">+91 9413453720</p>
          </div>
        </div>
      </div>
      <div className="payment">
        <p className="payment-text">Payment Methods</p>
        <p className="payment-texttwo">Secure and Flexible Payment Options</p>
        <div className="payment-options">
          <img src="visa-logo-png-2018.png"></img>
          <img src="google-pay-icon.png"></img>
          <img src="bhim-app-icon.png"></img>
          <img src="razorpay-icon.png"></img>
        </div>
      </div>

      <div className="pricing-end">
        <p>© 2024 Trackocity Services Pvt. Ltd | All Rights Reserved</p>
        <p>Privacy Policy Terms & Conditions</p>
      </div>
    </>
  );
}

export default Pricing;
