import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDashboardMetricData } from "../../services/api/dashboardApis";

const initialState = {
  dashboardMetricData: null,
  isFetching: false,
  isError: false,
  error: null,
};

function formatMetricDataResponse(inputData) {
  // Define the order and keys we want in the output
  const order = [
    "spend",
    "revenue",
    "sales",
    "roi",
    "profit",
    "aov",
    "clicks",
    "cpc",
    "cpa",
    "cpm",
  ];

  // Helper function to calculate CPM
  function calculateCpm(spend, impression) {
    return impression ? (spend / impression) * 1000 : 0;
  }

  // Create the output structure
  const output = [];

  for (const key in inputData) {
    if (inputData.hasOwnProperty(key)) {
      const data = inputData[key];

      const formattedData = {
        spend: data.spend,
        revenue: data.revenue,
        sales: data.sales,
        roi: data.roi,
        profit: data.profit,
        aov: data.aov,
        clicks: data.clicks,
        cpc: data.cpc,
        cpa: data.cpa,
        cpm: data.cpm,
      };

      // Order the data according to the specified order
      const orderedData = {};
      order.forEach((k) => {
        orderedData[k] = formattedData[k];
      });

      output.push({
        title: key,
        data: [orderedData],
      });
    }
  }

  let newOutput = output.map((item,i) => {
    // console.log('item',item.title )
    let values = {
      title: item.title,
      data: convertNewDataToOldFormat(item.data[0])
    }
    if (item.title === 'meta' || item.title === 'google'){
      values.accountPresent = inputData[item.title].accountpresent
    }
    return values
  })
 

  return newOutput;
}

function convertNewDataToOldFormat(newData) {
  
  // Define the desired order of the titles
  const order = ["ROI", "REVENUE", "SALES", "AOV", "SPEND", "PROFIT", "CPA", "CR",];


  // Convert newData to oldData format
  const oldDataFormat = Object.entries(newData).map(([key, value]) => {
    if (newData[key]) {
      return {
        title: key.toUpperCase(),
        subtitle: key.charAt(0).toUpperCase() + key.slice(1),
        data: value.data,
        total: value.total,
        compare: value.compare
      };
    }
  }).filter(item => item !== undefined)


  // Sort the array based on the desired order
  oldDataFormat.sort((a, b) => order.indexOf(a.title) - order.indexOf(b.title));
  return oldDataFormat;

}

export const fetchDashboardMetricData = createAsyncThunk(
  "dashboardMetricData/fetchDashboardMetricData",
  async (values, { rejectWithValue }) => {
    try {
      const response = await getDashboardMetricData(values);
      if (response.status === 200) {
        return response.data;
      } 
      // else throw new Error("Failed to fetch traffic data");
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.message || "Failed"
      );
    }
  }
);

export const dashboardMetricDataSlice = createSlice({
  name: "dashboardMetricData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardMetricData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchDashboardMetricData.fulfilled, (state, action) => {
        state.isFetching = false;
        state.dashboardMetricData = formatMetricDataResponse(action.payload);
      })
      .addCase(fetchDashboardMetricData.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.error = action.error.message;
      });
  },
});

export default dashboardMetricDataSlice.reducer;
