import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { persistedStore, store } from "./redux/store";
// import { PersistGate } from "reduxjs-toolkit-persist/integration/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
const PersistGate = lazy(() =>
  import("reduxjs-toolkit-persist/integration/react").then((module) => ({
    default: module.PersistGate,
  }))
);

root.render(
  <Provider store={store}>
    <Suspense fallback={<div></div>}>
      {(window.location.pathname === "/" ||
      window.location.pathname === "/sign-up" ||
      window.location.pathname === "/sign-in") ? (
        <App />
      ) : (
        <PersistGate persistor={persistedStore}>
          <App />
        </PersistGate>
      )}
    </Suspense>
  </Provider>
);
