import React from "react";
import { MdClear } from "react-icons/md";
import "./styles.css";
import { useDispatch } from "react-redux";
import { setUserdata } from "../../redux/reducers/userJourneySlice";

const Popup = ({ isOpen, onClose, children, size, top, right }) => {
  // console.log(onClose);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setUserdata(""));
    onClose();
  };
  return (
    isOpen && (
      <div className="popup-overlay"
      // onClick={onClose}
      >
        <div className="popup-content" onClick={(e) => e.stopPropagation()}>
          <button className="close-button" onClick={handleClose} style={{ top: top, right: right }}>
            <MdClear size={size ? size : 28} />
          </button>
          {children}
        </div>
      </div>
    )
  );
};

export default Popup;
