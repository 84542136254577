import React from "react";
import ApexCharts from "react-apexcharts";

const HorizontalBarChart = ({ data, categories }) => {
  const series = [
    {
      data: data, // Use the prop data here
    },
  ];

  const options = {
    chart: {
      type: "bar",
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "50%",
      },
    },
    xaxis: {
      categories: categories, // Use the prop categories here
      title: {
        style: { fontSize: "14px", fontWeight: "bold" },
      },
      labels: {
        formatter: (value) => `${value}%`,
        style: { colors: "#818283", fontWeight: "normal" },
      },
      min: 0,
      max: 100,
    },
    yaxis: {
      title: {
        style: { fontSize: "14px", fontWeight: "bold" },
      },
      labels: {
        style: { colors: "#fff", fontWeight: "500" },
      },
    },
    fill: {
      colors: ["#ff7b00"],
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: true,
      borderColor: "#454547",
      strokeDashArray: 0,
      strokeWidth: 0.5,
      xaxis: { lines: { show: true } },
      yaxis: { lines: { show: false } },
    },
    tooltip: {
      enabled: false,
      y: {
        formatter: (value) => `${value}%`,
      },
    },
  };

  return <ApexCharts options={options} series={series} type="bar" height={250} width="100%" />;
};

export default HorizontalBarChart;
