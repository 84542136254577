import { createSlice } from "@reduxjs/toolkit";

const ladsaccntSlice = createSlice({
  name: "ladsaccnt",
  initialState: false,
  reducers: {
    setLadsaccnt(state, action) {
      return action.payload;
    },
  },
});

export const { setLadsaccnt } = ladsaccntSlice.actions;
export default ladsaccntSlice.reducer;
