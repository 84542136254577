import React from 'react'
import { forgotPassword } from '../../services/api/authApis'
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";

const Forgotpassword = () => {

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    username: Yup.string().email("Invalid email").required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true); // Set submitting to true before dispatching action
      forgotPassword({
        username: values.username,
        })
        // .then((res) => {
        //   // if (res.payload.status && res.payload.status === 201) {
        //   //   formik.resetForm();
        //   //   navigate("/sign-in");
        //   // }
        //   console.log(res)
        // })
        // .catch((error) => {
        //   console.log( error);
        // })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    <form className="signin-form-container" 
    onSubmit={formik.handleSubmit}
    >
    <h1 className="signin-heading">Forgot Your Password</h1>

    <div className="input-box-container">
      <label className="signin-label" htmlFor="signin-email">
        {formik.errors.username && formik.touched.username
          ? ""
          : "Email"}
      </label>
      <span id="signinEmailSpan" className="error">
        {" "}
        {formik.errors.username &&
          formik.touched.username &&
          formik.errors.username}
      </span>
      <br />
      <input
        type="email"
        value={formik.values.username}
        name="username"
        className="signin-input-field"
        id="signin-email"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </div>


    {/* <p className="error" id="error-msg">
      {isError && error ? error.message : null}
    </p> */}

    <button
      type="submit"
      className="signin-button"
      disabled={formik.isSubmitting}
    >
      {/* {isFetching ? "Signing in..." : "Sign in"} */}
      Submit
    </button>
  </form>
  )
}

export default Forgotpassword