// The First or Root Component of the React App

import { Navigate, Outlet, useLocation } from "react-router-dom";

const NavigateComponent = ({ navigatePath }) => {
  const location = useLocation();
  return (
    <>
      {location.pathname === "/" && location.pathname !== navigatePath && (
        <Navigate to={navigatePath} replace={true} />
      )}
      <Outlet />
    </>
  );
};

export default NavigateComponent;
