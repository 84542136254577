import React, { useState } from "react";
import "./SalesVelocityGraph.css";
import ToggleSwitch from "../Settings/Toggle";
import RadialBarChart from "../../components/charts/RadialBarChart";
import HorizontalBarChart from "../../components/charts/HorizontalBarChart";
import CircularOutlineChart from "./CircularOutlineChart";
import { useSelector } from "react-redux";

const SalesVelocityGraph = ({
  traffic,
  value,
  isZeroDayIncluded,
  handleToggle,
}) => {
  const { salesVelocityTableData } = useSelector(
    (state) => state.predictiveData
  );

  let data = salesVelocityTableData?.table_data?.map(
    (item) => item?.percentage_sales
  );
  let categories = salesVelocityTableData?.table_data?.map(
    (item) => item?.days_to_first_sale
  );
  // console.log("graphCategories :", graphCategories)
  // Data and categories based on toggle switch state
  // const data = isZeroDayIncluded
  //   ? [10, 60, 30, 50, 80, 40, 70, 90, 20, 10] // Including "0 Day Sales"
  //   : [60, 30, 50, 80, 40, 70, 90, 20, 10]; // Excluding "0 Day Sales"

  // const categories = isZeroDayIncluded
  //   ? [
  //     "0 Day",
  //     "1 Day",
  //     "2 to 7 Days",
  //     "8 to 14 Days",
  //     "15 to 30 Days",
  //     "31 to 60 Days",
  //     "61 to 90 Days",
  //     "91 to 180 Days",
  //     "More than 180 Days",
  //     "Never",
  //   ]
  //   : [
  //     "1 Day",
  //     "2 to 7 Days",
  //     "8 to 14 Days",
  //     "15 to 30 Days",
  //     "31 to 60 Days",
  //     "61 to 90 Days",
  //     "91 to 180 Days",
  //     "More than 180 Days",
  //     "Never",
  //   ];

  return (
    <div className="sales-vel-container">
      <h4 className="sales-vel-title">Sales Velocity</h4>
      <div className="sales-vel-head-switch">
        <h4
          className={isZeroDayIncluded ? "switch-text" : "switch-text-selected"}
        >
          Exclude 0 Day Sales
        </h4>
        <ToggleSwitch isToggled={isZeroDayIncluded} onToggle={handleToggle} />
        <h4
          className={
            !isZeroDayIncluded ? "switch-text" : "switch-text-selected"
          }
        >
          Include 0 Day Sales
        </h4>
      </div>
      <div className="sales-velchart-container">
        <div className="sales-rad-chart-wrapper">
          {value && traffic && (
            <CircularOutlineChart value={value} traffic={traffic} />
          )}
        </div>
        <div className="sales-horz-chart-wrapper">
          {categories && data && (
            <HorizontalBarChart data={data} categories={categories} />
          )}{" "}
          {/* Pass the data and categories */}
        </div>
      </div>
      {/*<h4 className="sales-vel-title">Reports represent only a customer's 1st Order</h4>*/}
    </div>
  );
};

export default SalesVelocityGraph;
