import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getLinkedInAdsAccounts,
  getLinkedInAccounts,
} from "../../services/api/integrationApis";

const initialState = {
  linkedInAccounts: null,
  isFetching: false,
  isError: false,
  errorMessage: null,
};

export const fetchLinkedInAccounts = createAsyncThunk(
  "linkedInAccounts/fetchLinkedInAccounts",
  async (values, { rejectWithValue }) => {
    try {
      const response = await getLinkedInAccounts(values);
      if (response.status === 200) {
        return response.data;
      }
      // else throw new Error("Failed to fetch linkedIn ad accounts");
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.message || "Failed"
      );
    }
  }
);

// const formatAccounts = (data) => {
//   return Object.entries(data.accounts).flatMap(([key, values]) => {
//     console.log(values);
//     // Use a Set to remove duplicates from values
//     const uniqueValues = [...new Set(values)];
//     return uniqueValues.map((value) => ({
//       id: key,
//       accountname: value,
//       accountid: value,
//     }));
//   });
// };

export const linkedInAccountsSlice = createSlice({
  name: "linkedInAccounts",
  initialState,
  extraReducers: {
    [fetchLinkedInAccounts.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
    },
    [fetchLinkedInAccounts.fulfilled]: (state, { payload }) => {
      console.log("Payload:", payload); // Debugging line
      state.isFetching = false;
      state.isError = false;
      state.linkedInAccounts = payload;
      state.errorMessage = null;
    },
    [fetchLinkedInAccounts.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
  },
});

export default linkedInAccountsSlice.reducer;
