import SearchInput from "../../pages/Creatives/SearchInput";
import { setCreativeSource } from "../../redux/reducers/creativeTableDataSlice";
import { useDispatch, useSelector } from "react-redux";
import CommonTableDropdown from "../CommonTableDropdown";


let SourceOptions = [
  { value: 'facebook', label: 'Facebook' },
  { value: 'youtube', label: 'Youtube' },
];



const CreativeSearch = ({
  filterCreativeText,
  setFilterCreativeText,
  filterType,
  setFilterType,
  handleSelectChange,
}) => {

  let TypeOptions = [
    { value: 'all', label: 'All' },
    { value: 'video', label: 'Video' },
    { value: 'share', label: 'Image' },
  ];


  const dispatch = useDispatch()

  const { source } = useSelector((state) => state.creativeTableData);

  const handleDropdownChange = (value) => {
    dispatch(setCreativeSource(value));
  };



  if (source === 'youtube') {
    TypeOptions = TypeOptions.filter((type) => type.value !== 'share')
  }

  return (
    <>
      <div className="attribute-flex">
        <SearchInput
          filterCreativeText={filterCreativeText}
          setFilterCreativeText={setFilterCreativeText}
          filterType={filterType}
          setFilterType={setFilterType}
        />

        {/* type dropdown */}
        <CommonTableDropdown
          label="Type"
          options={TypeOptions}
          selectedValue={filterType}
          onChange={handleSelectChange}
        />

        {/* source dropdown */}
        <CommonTableDropdown
          label="Source"
          options={SourceOptions}
          selectedValue={source}
          onChange={handleDropdownChange}
        />
      </div>

    </>
  );
};

export default CreativeSearch;

