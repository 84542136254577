import React, { useEffect, useState } from "react";
import { IoMdVideocam } from "react-icons/io"; // Import video camera icon
import { FaArrowRightLong } from "react-icons/fa6"; // Import arrow icon for button
import { shoIntegration } from "../../services/api/integrationApis";
import { useSelector } from "react-redux";

const AppCard = (props) => {
  const {
    app,
    popupHandler,
    connectGoogleAdAccounts,
    connectMetaAdAccounts,
    connectLinkedInAdAccounts,
    inte,
  } = props;
  console.log(inte);
  const [change, setChange] = useState(false);
  const { currentClient } = useSelector((state) => state.agency);

  // State to track the connection status of the app
  const [isConnected, setIsConnected] = useState(false);

  // useEffect to check localStorage for the integration status when the component mounts
  useEffect(() => {
    const appStatusFromLocalStorage = localStorage.getItem(
      `${app.name}_connected`
    );
    if (appStatusFromLocalStorage) {
      setIsConnected(appStatusFromLocalStorage === "true");
    }
  }, [app.name]);

  // Function to fetch integration status and update localStorage
  useEffect(() => {
    const fetchIntegrationStatus = async () => {
      try {
        const integrationStatus = await shoIntegration();
        let appConnected = false;
        // Check integration status based on app name
        if (app.name === "Shopify" && integrationStatus.shopify) {
          appConnected = true;
        } else if (
          app.name === "WooCommerce" &&
          integrationStatus.woocommerce
        ) {
          appConnected = true;
        } else if (app.name === "Razorpay" && integrationStatus.razorpay) {
          appConnected = true;
        } else if (app.name === "Cashfree" && integrationStatus.cashfree) {
          appConnected = true;
        }
        // Save the connection status to localStorage and update state
        localStorage.setItem(
          `${app.name}_connected`,
          appConnected ? "true" : "false"
        );
        setIsConnected(appConnected);
      } catch (error) {
        console.error("Error fetching integration status:", error);
      }
    };
    fetchIntegrationStatus();
  }, [app.name, currentClient, inte]);

  // Function to handle video popup when the video icon is clicked
  const handleVideoPopup = (app) => {
    const { videoTutorial } = app;
    if (!videoTutorial) return;
    popupHandler(videoTutorial);
  };

  // Function to handle connection based on app integration type
  const handleConnect = (app) => {
    const { integrationPopup } = app;
    if (!integrationPopup) return;

    // Handle the connection logic for Google Ads, Meta Ads, or any other integrations
    if (integrationPopup === "Google Ads") {
      connectGoogleAdAccounts(); // Connect to Google Ads
    } else if (integrationPopup === "Linkedin") {
      connectLinkedInAdAccounts();
    } else if (integrationPopup === "Meta Ads") {
      connectMetaAdAccounts(); // Connect to Meta Ads
    } else {
      popupHandler(integrationPopup); // Call popupHandler for other integrations
    }

    // Update the connection status in localStorage after connecting
    // localStorage.setItem(`${app.name}_connected`, "true");
    // setIsConnected(true);
  };

  return (
    <div className="integration-app-cards" style={{ position: "relative" }}>
      {/* Display video icon if the app has a video tutorial */}
      {app.videoTutorial && (
        <IoMdVideocam
          className="onboard-video-icon"
          onClick={() => handleVideoPopup(app)} // Call handleVideoPopup when icon is clicked
        />
      )}
      {/* Display the app logo */}
      <img src={app.logo} alt={app.name.toLowerCase()} />
      {/* Display the app title */}
      <div className="app-title">{app.name}</div>
      {/* Display the app description */}
      <div className="app-description">{app.description}</div>
      {/* Button to connect the app */}
      <button
        className={isConnected ? "app-button connected-btn" : "app-button"}
        onClick={() => handleConnect(app)}
        disabled={isConnected}
      >
        <span className="span">{isConnected ? "Connected" : "Connect"}</span>
        {(app.name === "Google Ads" || app.name === "Meta" || app.name === "LinkedIn") && (
          <FaArrowRightLong />
        )}
      </button>
    </div>
  );
};

export default AppCard;
