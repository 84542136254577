import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCreativeTableData } from "../../services/api/creativeTableApis";

const initialState = {
  tableData: null,
  source: "facebook",
  isFetching: false,
  isError: false,
  error: null,
  creativesClick: false,
};

export const creativeTableData = createAsyncThunk(
  "creativeTableData/getCreativeTableData",
  async (values, { rejectWithValue }) => {
    try {
      const response = await getCreativeTableData(values);
      if (response.status === 200) {
        return response.data;
      }

      // else throw new Error("Failed to fetch attribute report data");
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.message || "Failed"
      );
    }
  }
);

export const tableDataSlice = createSlice({
  name: "tableData",
  initialState,
  reducers: {
    // Reducer to set the creative state
    setCreativeState: (state, action) => {
      state.creativesClick = action.payload;
    },

    setCreativeSource: (state, action) => {
      state.source = action.payload
    }
  },
  extraReducers: {
    [creativeTableData.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
    },
    [creativeTableData.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = false;
      state.tableData = payload;
      state.error = null;
    },
    [creativeTableData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
  },
});
export const { setCreativeState, setCreativeSource } = tableDataSlice.actions;
export default tableDataSlice.reducer;
