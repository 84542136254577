/**
 *
 * @param {Function} fn
 * @returns {Response | Error}
 */

// const catchAsync =
//   (fn) =>
//   (...rest) =>
//     fn(...rest).catch((error) =>
//       error.response
//         ? error.response
//         : error.response.data
//         ? error.response.data
//         : error
//     );

// export default catchAsync;

const catchAsync = (fn) => async (...rest) => {
  try {
    return await fn(...rest);
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      // Handle the error if there is no response
      console.log("Unhandled error:", error);
      return error; // Or return null, throw an error, etc., depending on your needs
    }
  }
};

export default catchAsync;
