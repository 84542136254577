import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTableCompareData } from "../../services/api/tableDataApis";

const initialState = {
  tableCompareData: null,
  isCompareFetching: false,
  isCompareError: false,
  compareerror: null,
};

export const fetchTableDataCompare = createAsyncThunk(
  "tableData/fetchTableCompareData",
  async (values, { rejectWithValue }) => {
    try {
      const response = await getTableCompareData(values);
      if (response.status === 200) {
        return response.data;
      }
      // else throw new Error("Failed to fetch attribute report data");
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.message || "Failed"
      );
    }
  }
);

export const tableCompareDataSlice = createSlice({
  name: "tableCompareData",
  initialState,
  reducers: {
    clearTableCompareData: (state) => {
      state.tableCompareData = null;
    }
  },
  extraReducers: {
    [fetchTableDataCompare.pending]: (state) => {
      state.isCompareFetching = true;
      state.isCompareError = false;
    },
    [fetchTableDataCompare.fulfilled]: (state, { payload }) => {
      state.isCompareFetching = false;
      state.isCompareError = false;
      state.tableCompareData = payload;
      state.compareerror = null;
    },
    [fetchTableDataCompare.rejected]: (state, { payload }) => {
      state.isCompareFetching = false;
      state.isCompareError = true;
      state.compareerror = payload;
    },
  },
});

export const { clearTableCompareData } = tableCompareDataSlice.actions;

export default tableCompareDataSlice.reducer;
