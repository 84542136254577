import React from "react";
import { calendarPlusLogo, downArrowLogo } from "../../assets";
import Popup from "../Popup/Popup";
import CalenderCard from "../calenderCard/CalenderCard";

const DatePicker = ({
  openPopup,
  date,
  selectedDates,
  isPopupOpen,
  closePopup,
  updateSelectedDates,
  cancelSelectedDates,
  setDate,
  isPredictive,
}) => {
  return (
    <>
      <button onClick={openPopup} className="selected-dates-style">
        <img src={calendarPlusLogo} alt="calendar" />
        <p>
          {date && date !== null ? date.join(" - ") : selectedDates.join(" - ")}
        </p>
        <img src={downArrowLogo} alt="down-arrow" />
      </button>
      <Popup isOpen={isPopupOpen} onClose={closePopup}>
        <CalenderCard
          isPredictive={isPredictive}
          onClose={closePopup}
          onApply={updateSelectedDates}
          onCancel={cancelSelectedDates}
          setDate={setDate}
          date={date}
        />
      </Popup>
    </>
  );
};

export default DatePicker;
