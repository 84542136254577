import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = () => {
  const navigate = useNavigate();
  const workSpaceId = localStorage.getItem("t_workSpaceId");
  const accessToken = localStorage.getItem("t_accessToken");

  useEffect(() => {
    localStorage.setItem("previousPath", window.location.pathname);
    if (!accessToken || !workSpaceId) {
      navigate("/sign-in");
    }
  }, [accessToken, workSpaceId, navigate, window.location.pathname]);

  return;
};

export default ProtectedRoute;