import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./styles.css";
import { userLogout } from "../../services/api/authApis";
import { FaSignOutAlt } from "react-icons/fa";

const ProfileDropDown = () => {
  const [profileIcon, setProfileIcon] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Get the current path

  const showClient = localStorage.getItem("t_agency_id");

  const handleProfileClick = () => {
    setProfileIcon((prev) => !prev);
  };

  const handleProfilebtnClick = () => {
    setProfileIcon(false);
    navigate("/profile");
  };

  const handleClientbtnClick = () => {
    setProfileIcon(false);
    navigate("/client-profile");
  };

  const signOutHandler = () => {
    userLogout();
    navigate("/sign-in");
  };

  const isProfilePage = location.pathname === "/profile"; // Check if on the profile page

  return (
    <div className="icon">
      <img
        alt="profile-icon"
        className="profile-icon"
        src="Client.png"
        onClick={handleProfileClick}
      />

      {profileIcon && showClient && (
        <div className="dropdown-client">
          {!isProfilePage && (
            <button onClick={handleProfilebtnClick} className="toggle-btn-one">
              Profile
            </button>
          )}
          <button onClick={handleClientbtnClick} className="toggle-btn-two">
            Client
          </button>
          <button onClick={signOutHandler} className="toggle-btn-three">
            Sign Out <FaSignOutAlt color="red" style={{ marginLeft: "66px" }} />
          </button>
        </div>
      )}

      {profileIcon && !showClient && (
        <div className="dropdown-client">
          {!isProfilePage && (
            <button onClick={handleProfilebtnClick} className="toggle-single">
              Profile
            </button>
          )}
          <button onClick={signOutHandler} className="toggle-btn-three">
            Sign Out <FaSignOutAlt color="red" style={{ marginLeft: "66px" }} />
          </button>
        </div>
      )}
    </div>
  );
};

export default React.memo(ProfileDropDown);
