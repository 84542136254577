import React, { useEffect, useRef } from "react"; // Import React and useRef hook
import { FiCopy } from "react-icons/fi"; // Import icon for copy functionality
import { IoIosArrowBack, IoMdVideocam } from "react-icons/io"; // Import icons for back and video
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux"; // Redux hook for dispatching actions
import { toastSetter } from "../../components/ToastContainer"; // Toast notification utility
import { deleteFacebookAccount } from "../../services/api/integrationApis";
import { fetchMetaAccounts } from "../../redux/reducers/IntegrationSlice";

const MetaAdsFetched = (props) => {
  const { setMadsaccnt, metaAccounts, popupHandler } = props; // Destructure props
  console.log("metaAccounts", metaAccounts);
  const dispatch = useDispatch(); // Initialize dispatch function from Redux
  const scriptRef = useRef(null); // Create a reference for the script element

  // Function to copy UTM parameters to clipboard
  const copyMetaUtm = () => {
    const utmText = `ad_source=facebook&ad_id={{ad.id}}`; // Define UTM text

    // Create a temporary textarea element to copy the text
    const tempTextarea = document.createElement("textarea");
    tempTextarea.value = utmText; // Set the textarea value to UTM text
    document.body.appendChild(tempTextarea); // Append it to the body
    tempTextarea.select(); // Select the textarea content
    document.execCommand("copy"); // Copy the selected content
    document.body.removeChild(tempTextarea); // Remove the temporary element
    const infoMessage = "Utm copied to clipboard!"; // Success message
    toastSetter("info", infoMessage); // Show toast notification
  };

  useEffect(() => {
    if (metaAccounts === undefined) dispatch(setMadsaccnt(false));
  }, [metaAccounts]);

  // Function to handle account deletion
  const handleDeleteAccount = async (id) => {
    try {
      const res = await deleteFacebookAccount({ id: id });
      console.log(res);

      // Check if the delete operation was successful
      if (res && res.data.message === "Account successfully deleted") {
        // Fetch the updated meta accounts
        dispatch(fetchMetaAccounts());

        // Dispatch based on the metaAccounts length
        setTimeout(() => {
          if (metaAccounts?.accounts.length > 0) {
            dispatch(setMadsaccnt(true));
          } else {
            dispatch(setMadsaccnt(false));
          }
        }, 500);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex", // Use flexbox for layout
          justifyContent: "space-between", // Space items evenly
          alignItems: "center", // Center items vertically
        }}
      >
        <button
          onClick={() => dispatch(setMadsaccnt(false))} // Handle back button click
          className="back-button"
        >
          <IoIosArrowBack /> Back {/* Back button with icon */}
        </button>
        <div
          style={{
            display: "flex", // Flexbox for inner layout
            justifyContent: "space-between",
            alignItems: "center",
            gap: "25px", // Gap between buttons
          }}
        >
          <button
            className="meta-app-button"
            onClick={() =>
              (window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?app_id=${process.env.REACT_APP_APP_ID}&redirect_uri=${process.env.REACT_APP_FRONT_END_BASE_URL}/integration&config_id=${process.env.REACT_APP_CONFIG_ID}&response_type=code&override_default_response_type=True`)
            } // Redirect to Facebook OAuth
          >
            Connect Meta Ads {/* Button to connect Meta Ads */}
          </button>
          <IoMdVideocam
            className="onboard-video-icon onboard-video-icon-meta-utm"
            style={{ position: "unset", float: "none" }} // Style adjustments for video icon
            onClick={() => popupHandler("meta-utm")} // Open video popup on click
          />
        </div>
      </div>
      <h2 className="integration-app-heading">Meta Ads Accounts</h2>{" "}
      {/* Heading for accounts section */}
      <div
        className="integration-app-container-gads"
        style={{ marginBottom: "80px" }} // Bottom margin for spacing
      >
        {metaAccounts &&
          metaAccounts.accounts.length > 0 &&
          metaAccounts.accounts.map((madsaccnt, i) => (
            <div className="integration-app-cards-gads" key={i}>
              <button
                className="button-delete-google"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={() => handleDeleteAccount(madsaccnt?.id)} // Call delete on button click
              >
                <MdDelete size={24} color="white" />
              </button>
              <h2 style={{ color: "#fff" }}>{madsaccnt?.accountname}</h2>{" "}
              {/* Account name */}
              <p
                style={{
                  color: "#fff", // Text color
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                {madsaccnt?.accountid} {/* Account ID */}
              </p>
            </div>
          ))}
      </div>
      <h4
        style={{
          fontSize: "18px",
          color: "#fff", // Text color for heading
        }}
      >
        URL Parameter Facebook : {/* Subtitle for URL parameters */}
      </h4>
      <div
        className="code-container"
        style={{
          width: "40vw", // Width of code container
          justifyContent: "space-between", // Space between items
          minWidth: "max-content", // Minimum width adjustment
        }}
      >
        <pre ref={scriptRef} className="code">
          {" "}
          {/* Preformatted text for code */}
          <code>
            {"\n"}
            {"ad_source=facebook&ad_id={{ad.id}}\n"}{" "}
            {/* Display UTM parameters */}
            {"\n"}
          </code>
        </pre>

        <button
          onClick={copyMetaUtm} // Copy UTM parameters on click
          className="copy-button"
        >
          <FiCopy scale={0.8} size={22} /> {/* Copy icon */}
        </button>
      </div>
    </>
  );
};

export default MetaAdsFetched; // Export the component
