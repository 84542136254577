import { useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import { creativeTableData } from "../redux/reducers/creativeTableDataSlice";
import { fetchTableData } from "../redux/reducers/tableDataSlice";
import { fetchTableDataCompare } from "../redux/reducers/tableCompareDataSlice";
import { fetchGraphData } from "../redux/reducers/graphDataSlice";
import { fetchDashboardGraphData } from "../redux/reducers/dashboardGraphDataSlice";
import { fetchDashboardTrafficData } from "../redux/reducers/dashboardTrafficDataSlice";
import { fetchDashboardMetricData } from "../redux/reducers/dashboardMetricDataSlice";
import { fetchTrafficSources } from "../redux/reducers/trafficSourceSlice";


// ===============================================================
//  Agency 
// =========================================================
// =========================================================

const useAgencyHandler = () => {

    const { creativesdate } = useSelector((state) => state?.date);
    const { date } = useSelector((state) => state.date);
    const { comparedate } = useSelector((state) => state.date);
    const { attribute } = useSelector((state) => state.attribute);
    const { trafficSource } = useSelector((state) => state.trafficSource);
    const { source } = useSelector((state) => state?.creativeTableData);

    const { dashdate } = useSelector((state) => state.date);


    const dispatch = useDispatch();
    const location = useLocation();


    const reRenderPage = () => {

        if (location.pathname === '/creatives') {
            if (creativesdate) {
                dispatch(
                    creativeTableData({
                        startdate: creativesdate[0],
                        enddate: creativesdate[1],
                        source: source
                    })
                );
            }
        }
        if (location.pathname === '/reportingpage') {
            dispatch(
                fetchTableData({
                    startdate: date[0],
                    enddate: date[1],
                    attribute: attribute,
                    trafficSource: trafficSource,
                })
            );
            if (comparedate && comparedate.length === 2 && attribute && trafficSource) {
                dispatch(
                    fetchTableDataCompare({
                        startdate: comparedate[0],
                        enddate: comparedate[1],
                        attribute: attribute,
                        trafficSource: trafficSource,
                    })
                );
            }
            dispatch(
                fetchGraphData({
                    startdate: date[0],
                    enddate: date[1],
                })
            );
            dispatch(fetchTrafficSources())
        }
        if (location.pathname === '/dashboard') {
            dispatch(
                fetchDashboardGraphData({
                    startdate: dashdate[0],
                    enddate: dashdate[1],
                })
            );
            dispatch(
                fetchDashboardTrafficData({
                    startdate: dashdate[0],
                    enddate: dashdate[1],
                })
            );
            dispatch(
                fetchDashboardMetricData({
                    startdate: dashdate[0],
                    enddate: dashdate[1],
                })
            );

        }
    }



    return { reRenderPage }
}


export default useAgencyHandler
