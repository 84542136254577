// features/joyride/joyrideSlice.js
import { createSlice } from '@reduxjs/toolkit';

const joyrideSlice = createSlice({
  name: 'joyride',
  initialState: {
    runTour: false,
    steps: [],
  },
  reducers: {
    startTour(state, action) {
      state.runTour = true;
      state.steps = action.payload;
    },
    stopTour(state) {
      state.runTour = false;
    },
  },
});

export const { startTour, stopTour } = joyrideSlice.actions;
export default joyrideSlice.reducer;
