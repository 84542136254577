const useFilterDataCreative = () => {
  const filteredData = (data, searchTerm, category) => {
    console.log(category);
    console.log(data);
    // Check if data is defined and is an array
    if (!data || !Array.isArray(data)) {
      return []; // Return an empty array if data is undefined or not an array
    }
    // if (category === "all") {
    //   return data;
    // }

    const matchSearch = data.filter((item) => {
      return item.creativename.toLowerCase().includes(searchTerm.toLowerCase());
    });
    let matchesCategory;
    if (category === "all") {
      return matchSearch;
    } else if (category === "video") {
      return matchSearch.filter((item) => item.adtype === "VIDEO");
    } else if (category === "share") {
      return matchSearch.filter((item) => item.adtype === "SHARE");
    }

    // console.log(matchesCategory);
    return matchSearch;
  };

  return { filteredData };
};

export default useFilterDataCreative;
