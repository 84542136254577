import { toastSetter } from "../../components/ToastContainer";
import http from "./http";
import catchAsync from "../../utils/catchAsync";

export const getProductList = catchAsync(async () => {
  const response = await http.get("/setting/product/getproduct");

  if (response.status === 200) {
    return response;
  } else {
    throw new Error(`Failed to fetch products, status: ${response.status}`);
  }
});

// export const createProduct = catchAsync(async (productData) => {
//   const response = await http.post("/setting/product/createproduct", productData);

//   if (response.status === 201) {
//     return response;
//   } else {
//     throw new Error(`Failed to create product, status: ${response.status}`);
//   }
// });
export const createProduct = catchAsync(async (productData) => {
  try {
    const response = await http.post(
      "/setting/product/createproduct",
      productData
    );

    if (response.status === 201) {
      toastSetter("success", "Product added successfully!");
      return response;
    }
  } catch (error) {
    toastSetter("error", error.response.data.message);
    throw error;
  }
});

export const updateProduct = catchAsync(async (productData) => {
  const response = await http.put(
    "/setting/product/updateproduct",
    productData
  );
  console.log(productData);
  console.log("update");

  if (response.status === 201) {
    toastSetter("success", "updated successfully!");

    return response;
  } else {
    toastSetter("error", "Failed to update product!");
    throw new Error(`Failed to update product, status: ${response.status}`);
  }
});

export const deleteProduct = catchAsync(async (productId) => {
  const response = await http.put("/setting/product/deleteproduct", {
    id: productId,
  });

  if (response.status === 200) {
    return response;
  } else {
    throw new Error(`Failed to delete product, status: ${response.status}`);
  }
});

export const getTaxRate = catchAsync(async () => {
  const response = await http.get("/setting/taxrate/getrate");
  if (response.status === 200) {
    return response;
  } else {
    throw new Error(`Failed to fetch tax rate, status: ${response.status}`);
  }
});

export const updateTaxRate = catchAsync(async (taxValues) => {
  // const response = await http.post("/setting/taxrate/updaterate", { taxrate: taxRate, tax_on: true });
  const response = await http.post("/setting/taxrate/updaterate", taxValues);
  if (response.status === 200) {
    toastSetter("success", "Tax rate updated successfully!");
    return response;
  } else {
    throw new Error(`Failed to update tax rate, status: ${response.status}`);
  }
});
