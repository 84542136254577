import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBreakDownTableData } from "../../services/api/breakdownTableApis";

const initialState = {
  breakdownData: null,
  isFetching: false,
  isError: false,
  error: null,
  creativesClick: false,
  ids: null,
  thumbnail: null,
  breakdownMainCards: null,
};

export const breakdownTableData = createAsyncThunk(
  "breakdown/getBreakdownTableData",
  async (values, { rejectWithValue }) => {
    try {
      const response = await getBreakDownTableData(values);
      if (response.status === 200) {
        return response.data;
      }
      // else throw new Error("Failed to fetch attribute report data");
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.message || "Failed"
      );
    }
  }
);

export const breakdownSlice = createSlice({
  name: "breakdown",
  initialState,
  reducers: {
    // Reducer to set the creative state
    setbreakdownState: (state, action) => {
      state.creativesClick = action.payload;
    },
    setbreakdownIds: (state, action) => {
      state.ids = action.payload;
      state.breakdownMainCards = null;
    },

    setbreakdownThumbnail: (state, action) => {
      state.thumbnail = action.payload;
    },
    updateMainCards: (state, action) => {
      const payload = action.payload;
      console.log("Payload received:", payload);

      // Ensure breakdownMainCards is an array

      // Add payload to the array
      state.breakdownMainCards = action.payload;
    },

    // Other reducers...
  },

  extraReducers: {
    [breakdownTableData.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
    },
    [breakdownTableData.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = false;
      state.breakdownData = payload;
      state.error = null;
    },
    [breakdownTableData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
  },
});
export const {
  setbreakdownState,
  updateMainCards,
  setSelectAdRows,
  setbreakdownIds,
  setbreakdownThumbnail,
  updateSelectedAdRows,
} = breakdownSlice.actions;
export default breakdownSlice.reducer;
