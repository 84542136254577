// import React, { useState } from "react";
// import "./styles.css";
// import { useSelector, useDispatch } from "react-redux";
// import { DatePicker } from "../../components";
// import { setBehaviourDate } from "../../redux/reducers/dateSlice";
// import { setTrafficPredictive } from "../../redux/reducers/predictiveBehaviorSlice";

// const ColTrafCampDateComp = ({
//   // trafficSource,
//   // handleTrafficChange,
//   setSelectedId,
//   setInterval,
// }) => {
//   const { behaviourdate } = useSelector((state) => state.date);
//   const { campaign } = useSelector((state) => state.predictiveData);

//   const newData = campaign?.data;

//   const dispatch = useDispatch();

//   const [selectedDates, setSelectedDates] = useState([]);
//   const [isPopupOpen, setPopupOpen] = useState(false);
//   const [trafficSource, setTrafficSource] = useState(() => {
//     const savedTrafficSource = localStorage.getItem("trafficSource");
//     return savedTrafficSource ? savedTrafficSource : "Facebook";
//   });

//   const [searchTerm, setSearchTerm] = useState(""); // Track the search input
//   const [filteredData, setFilteredData] = useState(newData || []); // Filtered campaign list based on search
//   const [isInputFocused, setInputFocused] = useState(false); // Track if input is focused

//   const openPopup = () => setPopupOpen(true);
//   const closePopup = () => setPopupOpen(false);

//   const handleTrafficSourceChange = (event) => {
//     let selectedTrafficSource = event.target.value;

//     setTrafficSource(selectedTrafficSource); // Update local state
//     dispatch(setTrafficPredictive(event.target.value));
//     localStorage.setItem("trafficSource", selectedTrafficSource); // Persist in localStorage
//   };

//   const updateSelectedDates = (dates) => {
//     setSelectedDates(dates);
//     dispatch(setBehaviourDate(dates));
//     closePopup();
//   };

//   const cancelSelectedDates = () => {
//     setSelectedDates([]);
//   };

//   const handleSelectedId = (id) => {
//     setSelectedId(id);
//   };

//   const handleSearchChange = (e) => {
//     const searchValue = e.target.value;
//     setSearchTerm(searchValue);

//     // Filter the campaign list based on the search input
//     if (searchValue) {
//       const filtered = newData.filter((campaign) =>
//         campaign.campaign_name.toLowerCase().includes(searchValue.toLowerCase())
//       );
//       setFilteredData(filtered);
//     } else {
//       setFilteredData(newData); // Reset to the full list if no search query
//     }
//   };

//   const handleFocusInput = () => {
//     // Show full list of campaigns only when input is focused
//     if (!isInputFocused) {
//       setInputFocused(true);
//       setFilteredData(newData); // Show all campaigns
//     }
//   };

//   const handleBlurInput = () => {
//     // Hide the dropdown when the input loses focus
//     setInputFocused(false);
//   };

//   const handleSelectCampaign = (id) => {
//     handleSelectedId(id);
//     setSearchTerm(""); // Clear the search input after selection
//     setFilteredData(newData); // Reset the filtered list
//   };

//   return (
//     <div className="component">
//       <div style={{ marginLeft: "20px" }}>
//         <DatePicker
//           openPopup={openPopup}
//           date={behaviourdate}
//           selectedDates={selectedDates}
//           isPopupOpen={isPopupOpen}
//           closePopup={closePopup}
//           updateSelectedDates={updateSelectedDates}
//           cancelSelectedDates={cancelSelectedDates}
//           setDate={setBehaviourDate}
//         />
//       </div>

//       <div>
//         <label className="label-name">Column Interval</label>
//         <select
//           onChange={(e) => setInterval(e.target.value)}
//           className="select-box-value-trafficSource"
//           style={{ border: "1px solid #D5D900" }}
//         >
//           <option value="weekly" className="option">
//             Weekly
//           </option>
//           <option value="monthly" className="option">
//             Monthly
//           </option>
//         </select>
//       </div>

//       <div>
//         <label className="label-name">Traffic Source</label>
//         <select
//           value={trafficSource}
//           className="select-box-value-trafficSource"
//           onChange={handleTrafficSourceChange}
//           style={{ border: "1px solid #D5D900" }}
//         >
//           <option value="all" className="option">
//             All
//           </option>
//           <option value="Facebook" className="option">
//             Facebook
//           </option>
//           <option value="Google" className="option">
//             Google
//           </option>
//           {/* {allTrafficeSource?.map((source, i) => (
//                         <option key={source + i} value={source} className="option">
//                             {source}
//                         </option>
//                     ))} */}
//         </select>
//       </div>

//       <div>
//         <label className="label-name">Campaign</label>
//         <select
//           className="select-box-value-campaign"
//           id="select-box"
//           style={{ border: "1px solid #D5D900" }}
//           onChange={(e) => handleSelectedId(e.target.value)}
//           disabled={trafficSource === "all"}
//         >
//           {trafficSource !== "all" ? (
//             newData?.map((campaign) => (
//               <option
//                 key={campaign.campaignid} // Use campaignid as the key
//                 value={campaign.campaignid} // Use campaignid for the value
//                 className="option"
//                 onClick={() => handleSelectedId(Number(campaign.campaignid))}
//               >
//                 {campaign.campaign_name} {/* Display the campaign_name */}
//               </option>
//             ))
//           ) : (
//             <option>All</option>
//           )}
//         </select>
//       </div>
//     </div>
//   );
// };

// export default ColTrafCampDateComp;

// import React, { useState } from "react";
// import "./styles.css";
// import { useSelector, useDispatch } from "react-redux";
// import { DatePicker } from "../../components";
// import { setBehaviourDate } from "../../redux/reducers/dateSlice";
// import { setTrafficPredictive } from "../../redux/reducers/predictiveBehaviorSlice";
// import Select from "react-select";
// import { color } from "framer-motion";

// const ColTrafCampDateComp = ({ setSelectedId }) => {
//   const { behaviourdate } = useSelector((state) => state.date);
//   const { campaign } = useSelector((state) => state.predictiveData);
//   const newData = campaign?.data;
//   const dispatch = useDispatch();

//   const [selectedDates, setSelectedDates] = useState([]);
//   const [isPopupOpen, setPopupOpen] = useState(false);
//   const [trafficSource, setTrafficSource] = useState(() => {
//     const savedTrafficSource = localStorage.getItem("trafficSource");
//     return savedTrafficSource ? savedTrafficSource : "Facebook";
//   });
//   const customStyles = {
//     control: (base) => ({
//       ...base,
//       backgroundColor: "inherit", // Inherit background color from parent
//       border: "1px solid #D5D900", // Set border color
//       padding: "8px",
//       color: "white", // Set the text color of the input to white
//       width: "350px",
//     }),
//     singleValue: (base) => ({
//       ...base,
//       color: "white", // Ensure the selected value text is white
//     }),
//     placeholder: (base) => ({
//       ...base,
//       color: "white", // Set the placeholder text color to white
//     }),
//     menu: (base) => ({
//       ...base,
//       width: "400px",
//       marginRight: "200px",
//     }),
//     option: (base, state) => ({
//       ...base,
//       backgroundColor: state.isSelected ? "#FF7B00" : base.backgroundColor, // Highlight selected option
//       color: state.isSelected ? "white" : base.color, // Change color of selected option
//       width: "500px",
//     }),
//   };

//   const [searchTerm, setSearchTerm] = useState(""); // Track the search input
//   const [filteredData, setFilteredData] = useState(newData || []); // Filtered campaign list based on search
//   const [isInputFocused, setInputFocused] = useState(false); // Track if input is focused

//   const openPopup = () => setPopupOpen(true);
//   const closePopup = () => setPopupOpen(false);

//   const handleTrafficSourceChange = (event) => {
//     const selectedTrafficSource = event.target.value;
//     setTrafficSource(selectedTrafficSource);
//     dispatch(setTrafficPredictive(selectedTrafficSource));
//     localStorage.setItem("trafficSource", selectedTrafficSource);
//   };

//   const updateSelectedDates = (dates) => {
//     setSelectedDates(dates);
//     dispatch(setBehaviourDate(dates));
//     closePopup();
//   };

//   const cancelSelectedDates = () => {
//     setSelectedDates([]);
//   };

//   const handleSelectedId = (id) => {
//     console.log(id);
//     setSelectedId(id);
//   };

//   const handleSearchChange = (e) => {
//     const searchValue = e.target.value;
//     setSearchTerm(searchValue);

//     // Filter the campaign list based on the search input
//     if (searchValue) {
//       const filtered = newData.filter((campaign) =>
//         campaign.campaign_name.toLowerCase().includes(searchValue.toLowerCase())
//       );
//       setFilteredData(filtered);
//     } else {
//       setFilteredData(newData); // Reset to the full list if no search query
//     }
//   };

//   const handleFocusInput = () => {
//     // Show full list of campaigns only when input is focused
//     if (!isInputFocused) {
//       setInputFocused(true);
//       setFilteredData(newData); // Show all campaigns
//     }
//   };

//   const handleBlurInput = () => {
//     // Hide the dropdown when the input loses focus
//     setInputFocused(false);
//   };
//   const [selectedCampaign, setSelectedCampaign] = useState(null); // To store selected campaign

//   const handleSelectCampaign = (selectedOption) => {
//     setSelectedCampaign(selectedOption); // Update selected campaign
//     setSelectedId(selectedOption.value); // Send selected campaign ID to parent component
//   };

//   return (
//     <div className="component">
//       <div style={{ marginLeft: "20px" }}>
//         <DatePicker
//           openPopup={openPopup}
//           date={behaviourdate}
//           selectedDates={selectedDates}
//           isPopupOpen={isPopupOpen}
//           closePopup={closePopup}
//           updateSelectedDates={updateSelectedDates}
//           cancelSelectedDates={cancelSelectedDates}
//           setDate={setBehaviourDate}
//         />
//       </div>

//       <div>
//         <label className="label-name">Column Interval</label>
//         <select
//           className="select-box-value-trafficSource"
//           style={{ border: "1px solid #D5D900" }}
//         >
//           <option value="Lorem 1">Lorem 1</option>
//           <option value="Lorem 2">Lorem 2</option>
//           <option value="Lorem 3">Lorem 3</option>
//         </select>
//       </div>

//       <div>
//         <label className="label-name">Traffic Source</label>
//         <select
//           value={trafficSource}
//           className="select-box-value-trafficSource"
//           onChange={handleTrafficSourceChange}
//           style={{ border: "1px solid #D5D900" }}
//         >
//           <option value="Facebook">Facebook</option>
//           <option value="Google">Google</option>
//         </select>
//       </div>
//       <div>
//         <label className="label-name">Campaign</label>
//         <Select
//           value={selectedCampaign} // This will hold the selected campaign
//           onChange={handleSelectCampaign} // This handles the change in selection
//           options={newData?.map((campaign) => ({
//             value: campaign.campaignid, // campaign ID
//             label: campaign.campaign_name, // campaign name
//           }))} // Mapping your data into options
//           placeholder="Search campaigns..."
//           getOptionLabel={(e) => e.label} // Use `label` for the display name
//           getOptionValue={(e) => e.value} // Use `value` for the campaign ID
//           isSearchable={true} // Enable search
//           styles={customStyles}
//         />
//       </div>
//     </div>
//   );
// };

// export default ColTrafCampDateComp;

import React, { useRef, useState } from "react";
import "./styles.css";
import Select from "react-select";

import { useSelector, useDispatch } from "react-redux";
import { DatePicker } from "../../components";
import { setBehaviourDate } from "../../redux/reducers/dateSlice";
import { setTrafficPredictive } from "../../redux/reducers/predictiveBehaviorSlice";

const ColTrafCampDateComp = ({
  // trafficSource,
  // handleTrafficChange,
  setSelectedId,
  setTimeInterval,
  isPredictive,
  selectedId,
}) => {
  const inputRef = useRef(null);

  const { behaviourdate } = useSelector((state) => state.date);
  console.log(behaviourdate);
  const { campaign } = useSelector((state) => state.predictiveData);

  const newData = campaign?.data;

  const dispatch = useDispatch();

  const [selectedDates, setSelectedDates] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [trafficSource, setTrafficSource] = useState(() => {
    const savedTrafficSource = localStorage.getItem("trafficSource");
    return savedTrafficSource ? savedTrafficSource : "All";
  });

  const [searchTerm, setSearchTerm] = useState(""); // Track the search input
  console.log("searchTerm", searchTerm);
  const [filteredData, setFilteredData] = useState(newData || []); // Filtered campaign list based on search
  const [isInputFocused, setInputFocused] = useState(false); // Track if input is focused

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);

  const handleTrafficSourceChange = (event) => {
    let selectedTrafficSource = event.target.value;

    setTrafficSource(selectedTrafficSource); // Update local state
    dispatch(setTrafficPredictive(event.target.value));
    localStorage.setItem("trafficSource", selectedTrafficSource); // Persist in localStorage
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: "inherit",
      border: "1px solid #D5D900",

      color: "white",
      width: "350px",
      height: "15px",
    }),
    input: (base) => ({
      ...base,
      color: "white",
      width: "10px",
    }),
    singleValue: (base) => ({
      ...base,
      color: "white",
    }),
    placeholder: (base) => ({
      ...base,
      color: "white", // Set the placeholder text color to white
    }),
    menu: (base) => ({
      ...base,
      width: "500px",
      marginLeft: "-150px",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#FF7B00" : base.backgroundColor, // Highlight selected option
      color: state.isSelected ? "white" : base.color, // Change color of selected option
      width: "500px",
      height: "30px", // Reduce height of each option in the dropdown
      padding: "8px", // Adjust padding to make the options smaller
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      display: trafficSource === "all" ? "none" : "block", // Hide dropdown indicator when 'all' is selected
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: trafficSource === "all" ? "none" : "block", // Hide separator when 'all' is selected
    }),
  };

  const updateSelectedDates = (dates) => {
    setSelectedDates(dates);
    dispatch(setBehaviourDate(dates));
    closePopup();
  };

  const cancelSelectedDates = () => {
    setSelectedDates([]);
  };

  const handleSelectedId = (id) => {
    setSelectedId(id);
  };

  const handleSearchChange = (e) => {
    console.log(e);
    const searchValue = e;
    setSearchTerm(searchValue);
    localStorage.setItem("Search", e);

    // Filter the campaign list based on the search input
    if (searchValue) {
      const filtered = newData.filter((campaign) =>
        campaign.campaign_name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(newData); // Reset to the full list if no search query
    }
  };

  const handleSelectChange = (selectedOption) => {
    console.log(selectedOption);
    if (selectedOption === null) {
      setSelectedId("all");

      setSearchTerm(""); // Clear search term if the value is null (i.e., the cross is clicked)
      setFilteredData(newData); // Reset the filtered data
    } else {
      setSearchTerm(selectedOption.label); // Set the search term when an option is selected
      setSelectedId(selectedOption.value); // Set the selected ID (optional)
    }
  };

  const handleFocusInput = () => {
    setSearchTerm(""); // Clear the search term as soon as the input is focused
    setFilteredData(newData); // Reset the filtered data to show the full list of campaigns

    // If there is any text, move the cursor to the end of the input field
    if (inputRef.current && inputRef.current.inputElement) {
      const inputElement = inputRef.current.inputElement;
      const length = inputElement.value.length;
      inputElement.setSelectionRange(length, length); // Move the cursor to the end
    }
  };

  const handleBlurInput = () => {
    // Hide the dropdown when the input loses focus
    setInputFocused(false);
  };

  const handleSelectCampaign = (id) => {
    handleSelectedId(id);
    setSearchTerm(""); // Clear the search input after selection
    setFilteredData(newData); // Reset the filtered list
  };

  return (
    <div className="component">
      <div style={{ marginLeft: "20px" }}>
        <DatePicker
          isPredictive={isPredictive}
          openPopup={openPopup}
          date={behaviourdate}
          selectedDates={selectedDates}
          isPopupOpen={isPopupOpen}
          closePopup={closePopup}
          updateSelectedDates={updateSelectedDates}
          cancelSelectedDates={cancelSelectedDates}
          setDate={setBehaviourDate}
        />
      </div>

      <div>
        <label className="label-name">Column Interval</label>
        <select
          onChange={(e) => setTimeInterval(e.target.value)}
          className="select-box-value-trafficSource"
          style={{ border: "1px solid #D5D900" }}
        >
          <option value="monthly" className="option">
            Monthly
          </option>
          <option value="weekly" className="option">
            Weekly
          </option>
        </select>
      </div>
      <div>
        <label className="label-name">Traffic Source</label>
        <select
          value={trafficSource}
          className="select-box-value-trafficSource"
          onChange={handleTrafficSourceChange}
          style={{ border: "1px solid #D5D900" }}
        >
          <option value="all" className="option">
            All
          </option>
          <option value="Facebook" className="option">
            Facebook
          </option>
          <option value="Google" className="option">
            Google
          </option>
          {/* {allTrafficeSource?.map((source, i) => (
                        <option key={source + i} value={source} className="option">
                            {source}
                        </option>
                    ))} */}
        </select>
      </div>
      <div className="last">
        <label className="label-name">Campaign</label>
        <Select
          ref={inputRef}
          value={
            trafficSource === "all" || selectedId === "all"
              ? { value: "all", label: "All" } // Set the value to "All" when trafficSource is "all"
              : filteredData.find(
                (campaign) => campaign.campaignid === searchTerm
              )
          } // Match the selected campaign by ID
          onChange={handleSelectChange} // Handle campaign selection
          options={[
            { value: "all", label: "All" },
            ...filteredData.map((campaign) => ({
              value: campaign.campaignid, // Use campaignid as value
              label: campaign.campaign_name, // Display campaign_name as label
            })),
          ]}
          placeholder="Search campaigns..."
          onInputChange={handleSearchChange} // Handle input changes for search
          onFocus={handleFocusInput} // Handle focus to show all campaigns
          onBlur={handleBlurInput} // Handle blur to hide dropdown
          isDisabled={trafficSource === "all"} // Disable the select when trafficSource is 'all'
          styles={customStyles}
          isClearable={selectedId === "all" ? false : true} // This will add the clear (cross) button
        />
      </div>
    </div>
  );
};

export default ColTrafCampDateComp;
