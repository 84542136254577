import React, { useEffect, useState } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const ErrorPage1 = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/integration");
  };

  return (
    <div style={styles.errorContainer}>
      <div style={{ marginLeft: "20px", marginTop: "12px" }}>
        <BiErrorCircle
          size={20}
          style={{ color: "orange", marginBottom: "-4px" }}
        />{" "}
        <span style={{ color: "orange", fontWeight: "700" }}>ALERT: </span>
      </div>
      <div style={styles.errorBody}>
        <p>
          Your page views are nearing their limit, with <strong>90%</strong>{" "}
          utilize.
        </p>
      </div>
    </div>
  );
};

const ErrorPage2 = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/contact-admin");
  };

  return (
    <div style={styles.errorContainer}>
      <div style={{ marginLeft: "20px", marginTop: "12px" }}>
        <BiErrorCircle
          size={20}
          style={{ color: "red", marginBottom: "-4px" }}
        />{" "}
        <span style={{ color: "red", fontWeight: "700" }}>ERROR: </span>
      </div>
      <div style={styles.errorBody}>
        <p>
          Page view limit exceeded. Please upgrade your plan to continue, or
          contact your administrator for assistance . After that you will need
          to log out and sign in again for the changes to take effect
        </p>
      </div>
    </div>
  );
};

const styles = {
  errorContainer: {
    width: "95%",
    backgroundColor: "rgba(27, 28, 30, 0.868)",
    border: "1px solid #cdac00",
    borderLeft: "10px solid #f37601",
    borderRadius: "12px",
    color: "white",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    margin: "auto auto",
  },
  errorBody: {
    padding: "20px",
    color: "white",
    fontSize: "16px",
    lineHeight: "2",
    marginLeft: "20px",
    marginTop: "-5px",
  },
  link: {
    color: "#f37601",
    fontWeight: "700",
    cursor: "pointer",
    textDecoration: "none",
  },
};

export { ErrorPage1, ErrorPage2 };
