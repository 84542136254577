import { createSlice } from '@reduxjs/toolkit';

const madsaccntSlice = createSlice({
  name: 'madsaccnt',
  initialState: false, 
  reducers: {
    setMadsaccnt(state, action) {
      return action.payload;
    },
  },
});

export const { setMadsaccnt } = madsaccntSlice.actions;
export default madsaccntSlice.reducer;
