import catchAsync from "../../utils/catchAsync";
import http from "./http";

export const resourceUseage = catchAsync(async () => {
  try {
    const response = await http.get(`/setting/resource_useage`);

    if (response.status === 200) {
      return response; // Return response data
    }
  } catch (error) {
    throw error;
  }
});
