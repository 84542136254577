import React, { useState } from "react";
import { DatePicker } from "../../components";
import ExportReport from "./ExportReport";
import { setCompareDate } from "../../redux/reducers/dateSlice";
import { useDispatch, useSelector } from "react-redux";
import { clearTableCompareData } from "../../redux/reducers/tableCompareDataSlice";
import { MdOutlineCancel } from "react-icons/md";


const CustomTitle = ({
  date,
  selectedDates,
  isPopupOpen,
  openPopup,
  closePopup,
  updateSelectedDates,
  cancelSelectedDates,
  setDate,
  tableData,
  selectedCompareDates,
  updatedSelectedCompareDates,
  comparedate,
  setSelectedCompareDates
}) => {
  const [isPopupOpenCompare, setPopupOpenCompare] = useState(false);
  const { tableCompareData, isCompareFetching } = useSelector((state) => state.compareTable);

  const openPopupCompare = () => {
    setPopupOpenCompare(true);
  };

  const closePopupCompare = () => {
    setPopupOpenCompare(false);
  };

  const dispatch = useDispatch()
  const clearCompareDates = () => {
    dispatch(setCompareDate([]))
    setSelectedCompareDates([])
    dispatch(clearTableCompareData());
  }



  return (
    <div className="custom-title-container">
      {/* Move Attribute Report outside of custom-btn-container */}
      <span className="attribute-report">Attribute Report</span>

      {/* Create a new container for date pickers and export button */}
      <span className="custom-btn-container">
        <DatePicker
          className={`reportdata`}
          openPopup={openPopup}
          date={date}
          selectedDates={selectedDates}
          isPopupOpen={isPopupOpen}
          closePopup={closePopup}
          updateSelectedDates={updateSelectedDates}
          cancelSelectedDates={cancelSelectedDates}
          setDate={setDate}
        />
        <div>
          <p className="title-compare">Compare To: </p>
          <div style={{ display: 'flex', position: 'relative' }}>
            <DatePicker
              date={comparedate}
              selectedDates={selectedCompareDates}
              isPopupOpen={isPopupOpenCompare}
              openPopup={openPopupCompare}
              closePopup={closePopupCompare}
              updateSelectedDates={updatedSelectedCompareDates}
              cancelSelectedDates={cancelSelectedDates}
              setDate={setCompareDate}
            />
            {tableCompareData && !isCompareFetching && <MdOutlineCancel title="Clear Comparison" onClick={clearCompareDates} style={{ cursor: 'pointer', position: 'absolute', right: '-8px', top: '-8px' }} size={20} />}
          </div>

        </div>
        {tableData && <ExportReport tableData={tableData} />}
      </span>
    </div>
  );
};

export default CustomTitle;
