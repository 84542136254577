import React from "react";
import ResetPassword from "./ResetPassword";

const ResetPasswordPage = () => {
  // Extract token from URL
  const url = window.location.search;
  const startIndex = url.indexOf("?") + 1;
  const token = url.slice(startIndex);

  return <ResetPassword initialToken={token} />;
};

export default ResetPasswordPage;
