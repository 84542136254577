import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import SharedForm from "./SharedForm"; // Adjust the import path
import { useDispatch } from "react-redux";
import {
  fetchProfileData,
  updateProfileData,
} from "../../redux/reducers/profileDataSlice";
import { getAgencyDataAll } from "../../redux/reducers/agencySlice";

const EditClient = ({ setEditPopUp, profileToEdit }) => {
  const dispatch = useDispatch();
  console.log("profileToEdit", profileToEdit);
  console.log("render");
  const initialValues =
    profileToEdit && profileToEdit.length > 0
      ? {
          email: profileToEdit[0].email || "",
          name: profileToEdit[0].name || "",
          phone: profileToEdit[0].phone || "",
          company: profileToEdit[0].company || "",
          currency: profileToEdit[0].currency || "",
          timezone: profileToEdit[0].timezone || "",
        }
      : {
          email: "",
          name: "",
          phone: "",
          company: "",
          currency: "",
          timezone: "",
        };

  const handleSubmit = async (values) => {
    await dispatch(
      updateProfileData({
        email: values.email && values.email !== "" ? values.email : null,
        phone: values.phone && values.phone !== "" ? values.phone : null,
        name: values.name && values.name !== "" ? values.name : null,
        company:
          values.company && values.company !== "" ? values.company : null,
        timezone:
          values.timezone && values.timezone !== "" ? values.timezone : null,
        currency:
          values.currency && values.currency !== "" ? values.currency : null,
      })
    );

    // Fetch profile data after update
    dispatch(getAgencyDataAll());

    console.log("Submitted values:", values);
    setEditPopUp(false);
  };

  const handleCancel = () => {
    setEditPopUp(false);
  };

  return (
    <SharedForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    />
  );
};

export default EditClient;

// import React from "react";
// import { Formik } from "formik";
// import * as Yup from "yup";
// import SharedForm from "./SharedForm"; // Adjust the import path
// import { useDispatch, useSelector } from "react-redux";
// import { updateProfileData } from "../../redux/reducers/profileDataSlice";

// const EditClient = ({ setEditPopUp }) => {
//   const dispatch = useDispatch();
//   const { profileData } = useSelector((state) => state.profile);

//   console.log("Profile Data:", profileData);

//   const initialValues = profileData
//     ? {
//         email: profileData.email || "",
//         name: profileData.name || "",
//         phone: profileData.phone || "",
//         company: profileData.company || "",
//         currency: profileData.currency || "USD",
//         timezone: profileData.timezone || "UTC",
//       }
//     : {
//         email: "",
//         name: "",
//         phone: "",
//         company: "",
//         currency: "USD",
//         timezone: "UTC",
//       };

//   const handleSubmit = (values) => {
//     dispatch(
//       updateProfileData({
//         email: values.email || null,
//         phone: values.phone || null,
//         name: values.name || null,
//         company: values.company || null,
//         timezone: values.timezone || null,
//         currency: values.currency || null,
//       })
//     );
//     console.log("Submitted values:", values);
//     setEditPopUp(false);
//     localStorage.removeItem("n_workspace_id");
//   };

//   const handleCancel = () => {
//     setEditPopUp(false);
//   };

//   return (
//     <SharedForm
//       initialValues={initialValues}
//       onSubmit={handleSubmit}
//       onCancel={handleCancel}
//     />
//   );
// };

// export default EditClient;
