import React from 'react';

const DropDownHOC = (WrappedComponent) => {
    return () => {
        const agencyId = localStorage.getItem('t_agency_id');
        if (!agencyId) return null;
        return <WrappedComponent />;
    };
};

export default DropDownHOC;