import { RouterProvider } from "react-router-dom";
import { router } from "./lib/routes";
import { ToastContainer } from "./components";
import { useEffect, useState } from "react";
import "./index.css";
import Joyride from "react-joyride";
import { useSelector, useDispatch } from "react-redux";
import { stopTour } from "./redux/reducers/joyrideSlice";
import TrackoBotAi from "./TrackoBotAi";
import { resourceUseage } from "./services/api/resourceApis";

const clearLocalStorageValues = () => {
  // Read the persisted state from localStorage
  const persistedState = localStorage.getItem("persist:trackocity");
  localStorage.removeItem("gadsaccnt");
  localStorage.removeItem("madsaccnt");
  localStorage.removeItem("metaAccessToken");
  localStorage.removeItem("metaCustomers");
  // localStorage.removeItem("refreshcalled");

  if (persistedState) {
    try {
      // Parse the persisted state
      const state = JSON.parse(persistedState);

      // Remove the parts of the state you want to clear
      delete state.auth;
      delete state.table;
      delete state.profile;
      delete state.graphData;
      delete state.dashboardGraphData;
      delete state.dashboardMetricData;
      delete state.integration;
      delete state.dashboardTrafficData;
      delete state.googleAdAccounts;

      // Only keep the parts you want to preserve
      const newState = JSON.stringify(state);

      localStorage.setItem("persist:trackocity", newState);
    } catch (error) {
      console.log("Failed to parse or modify persisted state", error);
    }
  }
};

function App() {
  const dispatch = useDispatch();
  const { runTour, steps } = useSelector((state) => state.joyride);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      clearLocalStorageValues();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const [workSpaceId, setWorkSpaceId] = useState(
    localStorage.getItem("t_workSpaceId")
  );
  const [refreshCount, setRefreshCount] = useState(
    localStorage.getItem("refreshcalled")
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setWorkSpaceId(localStorage.getItem("t_workSpaceId"));
      setRefreshCount(localStorage.getItem("refreshcalled"));
    }, 1000); // Polling interval for localStorage updates

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (workSpaceId && !refreshCount) {
      resourceUseage().then((res) => {
        const refreshedValue = Math.round(parseFloat(res?.data?.data));
        localStorage.setItem("refreshcalled", refreshedValue);
        setRefreshCount(refreshedValue);
      });
    }
  }, [workSpaceId, refreshCount]);

  return (
    <>
      {/* <TrackoBotAi /> */}
      <ToastContainer />
      {/* {runTour && (
        <Joyride
          steps={steps}
          styles={{
            options: {
              arrowColor: "#e3ffeb",
              backgroundColor: "#e3ffeb",
              overlayColor: "rgba(79, 26, 0, 0.4)",
              primaryColor: "#ff7b00",
              textColor: "#000",
            },
          }}
          run={runTour}
          callback={(data) => {
            if (data.status === "finished" || data.status === "skipped") {
              dispatch(stopTour());
            }
          }}
          continuous={true} // Make it continuous if you want
          showProgress={true} // Optional: Show a progress indicator
          showSkipButton={true} // Optional: Show a skip button
          disableOverlay={true} // Optional: Disable the overlay
          spotlightClicks={true} // Optional: Spotlight clicks
          showBeacon={false}
        />
      )} */}
      <RouterProvider router={router} />
    </>
  );
}

export default App;
