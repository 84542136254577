import React, { useEffect, useState } from "react";
import {
  setGoogleAds,
  setLinkedIn,
  setMeta,
} from "../../services/api/integrationApis";
import { toastSetter } from "../../components/ToastContainer";
import { fetchGoogleAdAccounts } from "../../redux/reducers/googleAdAccountsSlice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { normalizePath } from "../../utils";
import { fetchLinkedInAccounts } from "../../redux/reducers/linkedInAccountSlice";

const LinkedInAds = ({
  setIsPopupOpen,
  setPopupChildren,
  linkedInCustomers,
  linkedInAccessToken,
  setLadsaccnt,
  setInte,
  expire_in,
  refresh_token,
  refresh_token_expire_in,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // State to hold selected Google Ads accounts
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Retrieve input type and system ID from local storage
  const inputType = localStorage.getItem("t_popupInputType");
  const systemid = localStorage.getItem("t_systemid");

  useEffect(() => {
    if (linkedInCustomers && linkedInCustomers.length === 1) {
      setSelectedOptions([linkedInCustomers[0]]); // Automatically select the first (and only) option
    }
  }, [linkedInCustomers]);

  // Function to handle option selection
  const handleOptionChange = (item) => {
    if (inputType === "radio") {
      // For radio input, allow only one selection
      setSelectedOptions([item]);
    } else {
      // For checkbox input, allow up to 3 selections
      if (selectedOptions.includes(item)) {
        // If already selected, remove it
        setSelectedOptions(selectedOptions.filter((option) => option !== item));
      } else {
        // If not selected and less than 3 selected, add it
        if (selectedOptions.length < 3) {
          setSelectedOptions([...selectedOptions, item]);
        } else {
          // Alert if trying to select more than 3 accounts
          const infoMessage = "You can only select up to 3 LinkedIn accounts.";
          toastSetter("info", infoMessage);
        }
      }
    }
  };

  // Form submission handler
  // const formSubmitHandler = (e) => {
  //   e.preventDefault();
  //   console.log(selectedOptions);

  //   // Call the API to set Google Ads
  //   selectedOptions.forEach((account) => {
  //     setLinkedIn({
  //       accessToken: linkedInAccessToken,
  //       expire_in: expire_in,
  //       refresh_token: refresh_token,
  //       refresh_token_expire_in: refresh_token_expire_in,
  //       account: account,
  //       account_name: account,
  //     })
  //       .then((res) => {
  //         if (res.status && res.status === 200) {
  //           // Successful response handling
  //           console.log(res, "ycqutdvuwdvwpd");
  //           toastSetter("success", `LinkedIn Success`);
  //           dispatch(fetchLinkedInAccounts()).unwrap(); // Fetch updated accounts
  //           dispatch(setLadsaccnt(true)); // Set account status to true
  //         }
  //       })
  //       .catch((error) => {
  //         // Error handling
  //         console.log("Login Error:", error);
  //         toastSetter("error found");
  //         dispatch(setLadsaccnt(false)); // Set account status to false
  //       })
  //       .finally(() => {
  //         // Close the popup and reset children
  //         setIsPopupOpen(false);
  //         setPopupChildren("");
  //         normalizePath(location, navigate); // Normalize the path after submission
  //       });
  //   });
  // };
  const formSubmitHandler = async (e) => {
    e.preventDefault();

    // Create an array of promises for each LinkedIn account
    const promises = selectedOptions.map((account) =>
      setLinkedIn({
        accessToken: linkedInAccessToken,
        expire_in: expire_in,
        refresh_token: refresh_token,
        refresh_token_expire_in: refresh_token_expire_in,
        account: account,
        account_name: account,
        systemid: localStorage.t_systemid,
      })
    );

    try {
      const results = await Promise.all(promises);
      console.log(results);

      // Handle the results after all promises are resolved
      results.forEach((res) => {
        if (res.status && res.status === 201) {
          toastSetter("success", "LinkedIn Ads Success");
          setIsPopupOpen(false);
          setPopupChildren("");
          normalizePath(location, navigate); // Normalize the path after submission
          window.location.reload();
        } else {
          toastSetter(
            "error",
            `LinkedIn Ads Error: ${res.message || "Unknown error"}`
          );
        }
      });

      // After successful API calls, update state and fetch accounts
      dispatch(fetchLinkedInAccounts()).unwrap(); // Fetch updated accounts
      dispatch(setLadsaccnt(true)); // Set account status to true
    } catch (error) {
      // Handle any errors during the API calls
      console.error("Error during LinkedIn Ads setup:", error);
      toastSetter("error", "LinkedIn Ads Error");
      dispatch(setLadsaccnt(false)); // Set account status to false on error
    } finally {
      // Close the popup and reset children
      setIsPopupOpen(false);
      setPopupChildren("");
      normalizePath(location, navigate); // Normalize the path after submission
    }
  };

  // Handle clicks on the disabled button
  const handleDisabledButtonClick = () => {
    if (selectedOptions.length < 1) {
      const alertMessage = "Please select at least one Google Ads account.";
      toastSetter("info", alertMessage); // Alert if no account is selected
    }
  };

  return (
    <div className="integration-popup-container">
      <h2 className="integration-popup-heading">LinkedInAds</h2>
      <label className="integration-popup-form-label">
        Select LinkedIn Ads Account (
        {inputType === "radio" ? "only 1" : "up to 3"})
      </label>
      <form className="integration-popup-form">
        {linkedInCustomers && // Render checkboxes for each Google customer
          linkedInCustomers.map((item, index) => (
            <span style={{ width: "100%" }} key={index}>
              <div className="integration-popup-input input-checkbox">
                <input
                  type={inputType} // Input type based on the selection mode (radio/checkbox)
                  value={item}
                  checked={selectedOptions.includes(item)} // Check if the option is selected
                  onChange={() => handleOptionChange(item)} // Handle option change
                  style={{ cursor: "pointer", marginRight: "10px" }}
                  disabled={linkedInCustomers.length === 1} // Disable input if only one item exists
                />
                <span>{item}</span> {/* Display account name */}
              </div>
            </span>
          ))}
      </form>
      <button
        type="button"
        className={
          selectedOptions.length < 1
            ? "integration-popup-submit integration-popup-submit-disabled"
            : "integration-popup-submit"
        }
        onClick={
          selectedOptions.length < 1
            ? handleDisabledButtonClick // Show alert if button is disabled
            : formSubmitHandler // Submit the form if valid
        }
      >
        Submit
      </button>
    </div>
  );
};

export default LinkedInAds;
