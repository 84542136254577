import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
// import "./style.css";

// Validation schema for the form
const validationSchema = Yup.object().shape({
  product_name: Yup.string().required("Product name is required"),
  cost_price: Yup.number()
    .required("Cost price is required")
    .min(0, "Cost price must be 0 or greater") // Allows 0
    .typeError("Cost price must be a number"),
  sale_price: Yup.number()
    .required("Sale price is required")
    .min(0, "Sale price must be 0 or greater") // Allows 0
    .typeError("Sale price must be a number"),
});

const ProductForm = ({ initialValues, onSubmit, onCancel }) => {
  return (
    <div className="pop-tool">
      <div className="editPopup">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="row-edit">
                <div className="one-row">
                  {errors.product_name && touched.product_name ? (
                    <div className="error-shared">{errors.product_name}</div>
                  ) : (
                    <label className="label">Product Name:</label>
                  )}
                  <Field
                    className={`input ${
                      errors.product_name && touched.product_name
                        ? "error-input"
                        : ""
                    }`}
                    name="product_name"
                    placeholder="Enter Product name"
                  />
                </div>

                <div className="one-row">
                  {errors.cost_price && touched.cost_price ? (
                    <div className="error-shared">{errors.cost_price}</div>
                  ) : (
                    <label className="label">Cost Price:</label>
                  )}
                  <Field
                    className={`input ${
                      errors.cost_price && touched.cost_price
                        ? "error-input"
                        : ""
                    }`}
                    name="cost_price"
                    placeholder="Enter Cost price"
                    type="number"
                  />
                </div>
              </div>

              <div className="row-edit">
                <div className="one-row">
                  {errors.sale_price && touched.sale_price ? (
                    <div className="error-shared">{errors.sale_price}</div>
                  ) : (
                    <label className="label">Sale Price:</label>
                  )}
                  <Field
                    className={`input ${
                      errors.sale_price && touched.sale_price
                        ? "error-input"
                        : ""
                    }`}
                    style={{ width: "93%" }}
                    name="sale_price"
                    placeholder="Enter Sale price"
                    type="number"
                  />
                </div>
              </div>

              <div className="row-buttons">
                <button className="cancel" type="button" onClick={onCancel}>
                  Cancel
                </button>
                <button className="save" type="submit">
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ProductForm;
