import React, { useState, useEffect } from "react";
import "./styles.css";

import CodeTab from "./Codetab";
import AppsTab from "./AppsTab";
import { DropDownComp, Popup } from "../../components";
import VideoTutorialPopups from "./VideoTutorialPopups";
import Razorpay from "./Razorpay";
import Shopify from "./Shopify";
import WooCommerce from "./WooCommerce";
import GoogleAds from "./GoogleAds";
import MetaAds from "./MetaAds";
import Pabbly from "./Pabbly";
import Cashfree from "./Cashfree";

import { useDispatch, useSelector } from "react-redux";
import {
  getLocalStorageItem,
  secondsToFutureDate,
  setLocalStorageItem,
} from "../../utils";
import { fetchGoogleAdAccounts } from "../../redux/reducers/googleAdAccountsSlice";
import catchAsync from "../../utils/catchAsync";
import { normalizePath } from "../../utils";
import GoogleAdsFetched from "./GoogleAdsFetched";
import { setGadsaccnt } from "../../redux/reducers/gadsaccntSlice";
import { setMadsaccnt } from "../../redux/reducers/madsaccntSlice";
import { extractCustomerIDsAndRefreshToken } from ".";
import { fetchMetaAccounts } from "../../redux/reducers/IntegrationSlice";
import { setLinkedIn, setMeta } from "../../services/api/integrationApis";
import { useLocation, useNavigate } from "react-router-dom";
import MetaAdsFetched from "./MetaAdsFetched";
import ProfileDropDown from "../../components/profileDropDown/ProfileDropDown";
import LinkedInAds from "./LinkedInAds";
import { setLadsaccnt } from "../../redux/reducers/ladsaccntSlice";

import { fetchLinkedInAccounts } from "../../redux/reducers/linkedInAccountSlice";
import LinkedInFetched from "./LinkedInFetched";
import { ErrorPage1, ErrorPage2 } from "../../components/errorPages/ErrorPage";

const Integration = () => {
  const [inte, setInte] = useState(false);
  // Hooks for managing state and navigation
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(
    localStorage.getItem("selectedTab") || "Apps"
  );

  const expire_in = localStorage.getItem("expire_in");
  const refresh_token = localStorage.getItem("refresh_token");
  const refresh_token_expire_in = localStorage.getItem(
    "refresh_token_expire_in"
  );

  // const expire_in = secondsToFutureDate(5183999);
  // const refresh_token =
  //   "AQXE-iOjzZW7YthdFBRIbdHxRY4DKrUV1YtBnOXOvwW3_dhAz5_QefaZe14DG5o4CLdFKr7jLV-oM7pdM6BNFIOyFEmnX2mingM5OYV0v5Dc0YVBJPTdW1HP1HbVuxSukEh97jMfODEdNIc5tBG0_50hcXT4_lZM9Aj4RwFSOdhDWBJPYrIwpVL9GeaCjLeuaQ7dWcDGX_P5LQLpSwKssfznKn7e2j_CAjD7hZkCsX-dyhiuLU6H7l4PCOPlHANUIcaQmlBLEPv2mv7WZUzK3Vqgxn-nbhaMNICX-rRC2UxprUD9Niz158XGav1muQ-4GNl3qz7BNRxpRWnT8elmumwI_uwGYQ";
  // const refresh_token_expire_in = secondsToFutureDate(31535999);

  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to manage popup visibility
  const [popupChildren, setPopupChildren] = useState(""); // State to manage content of the popup
  console.log(popupChildren);
  const [clearConnected, setClearConnected] = useState(false);
  const { currentClient } = useSelector((state) => state.agency);
  const accessToken =
    "AQVb_abd8_9ldjevHMb9pjUdKke7pRWuxUcTfKkIwQorz52_IrFAafJWiWZFjQ8iCPx_ymbDoPeMQMc_FD_hM0dS96xGJAGKYMXthhPiyOCwgauI7zT_Ji1yaWCGZIDr76Eb5bCGywG9GnmW5J1oTGpycJR87RBhyrMwe3WylFJ_zz_5YXzEi1AZZVWnEWq9tqPu3CrIHeIrAlpA6JmC37yRVoEEU2ejbuAtKew1-XKLVHzLKEpoa2dSZTrPwDCC4UvECDrqp2Q4GazrUkPtiV0QdE87jZOOh9SJS1vVp5kkVuMAE7MxiGz6zoQmyWjFqJEN3VF6Cl1BRKrqrmKqTtVBFpZ3-A";
  useEffect(() => {
    localStorage.setItem("selectedTab", selectedTab);
  }, [selectedTab]);

  // Function to handle tab click
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  // Function to open popup with specific content
  const popupHandler = (content) => {
    console.log(content);
    setPopupChildren(content);
    setIsPopupOpen(true);
  };

  // Function to close popup and perform clean-up
  const closePopup = () => {
    // popupChildren === "meta-utm" && handleCopyScriptTour();
    setIsPopupOpen(false);
    setPopupChildren("");
    // localStorage.removeItem("gadsaccnt");
    // localStorage.removeItem("madsaccnt");
    // localStorage.removeItem("metaAccessToken");
    // localStorage.removeItem("metaCustomers");
    normalizePath(location, navigate);
  };

  const dispatch = useDispatch();

  const [signatureKey, setSignatureKey] = useState("");

  // Redux state selectors
  const { metaAccounts } = useSelector((state) => state.integration);
  console.log(metaAccounts);
  const { googleAdAccounts } = useSelector((state) => state.googleAdAccounts);
  const { linkedInAccounts } = useSelector((state) => state.linkedIn);
  const gadsaccnt = useSelector((state) => state.gadsaccnt);
  const madsaccnt = useSelector((state) => state.madsaccnt);
  const ladsaccnt = useSelector((state) => state.ladsaccnt);

  // State for Google and Meta account data
  const [googleCustomers, setGoogleCustomers] = useState([]);
  const [googleRefreshToken, setGoogleRefreshToken] = useState(null);
  const [metaAccessToken, setMetaAccessToken] = useState(
    () => getLocalStorageItem("metaAccessToken", "") || ""
  );
  const [linkedInAccessToken, setLinkedInAccessToken] = useState(
    () => getLocalStorageItem("linkedInAccessToken", "") || ""
  );
  const [metaCustomers, setMetaCustomers] = useState(() =>
    getLocalStorageItem("metaCustomers", [] || [])
  );
  const [linkedInCustomers, setLinkedInCustomers] = useState(() =>
    getLocalStorageItem("linkedInCustomers", [] || [])
  );

  // Fetch Google Ad accounts when Google account state changes
  useEffect(() => {
    dispatch(fetchGoogleAdAccounts()).unwrap();
  }, [gadsaccnt, currentClient]);

  // Fetch Meta accounts when Meta account state changes
  useEffect(() => {
    dispatch(fetchMetaAccounts()).unwrap();
  }, [madsaccnt, currentClient]);

  useEffect(() => {
    dispatch(fetchLinkedInAccounts()).unwrap();
  }, [ladsaccnt, currentClient]);

  useEffect(() => {
    const generateRandomString = () => {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < 20; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    };

    setSignatureKey(generateRandomString());
  }, [popupChildren, isPopupOpen]);

  // Effect to handle customer IDs and refresh token extraction from URL
  useEffect(() => {
    const { customerIDs, refreshToken } = extractCustomerIDsAndRefreshToken(
      window.location.href
    );

    if (customerIDs.length > 0 && refreshToken) {
      setGoogleCustomers(customerIDs);
      setGoogleRefreshToken(refreshToken);
      popupHandler("Google Ads");
    } else {
      console.log("No accessible customers found");
    }
  }, []);

  // Link Google Ads account
  const onLinkAdsAccount = (g_id) => {
    console.log("Hello");
    console.log(g_id);
    const id = g_id || null;
    console.log(id);
    console.log(process.env.REACT_APP_BASE_URL);
    window.location.href = `${process.env.REACT_APP_BASE_URL}/google/authorize/${id}`;
  };

  //https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?response_type=code&client_id=137526578448-2057b6id3ogcousvr39ov9ghguin940t.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A5000%2Fgoogle%2Foauth2callback&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadwords&state=cbfb1c7deca3de7db765433a0beb9cca53198af448b7b27f3e3cebd644c1a225&access_type=offline&prompt=consent&include_granted_scopes=true&service=lso&o2v=1&ddm=1&flowName=GeneralOAuthFlow
  //https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?response_type=code&client_id=137526578448-2057b6id3ogcousvr39ov9ghguin940t.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A5000%2Fgoogle%2Foauth2callback&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadwords&state=60b54bd3fe73844e66c2173ff0c7e0377331dfadf45e2491d2436fb1c7e30a6d&access_type=offline&prompt=consent&include_granted_scopes=true&service=lso&o2v=1&ddm=1&flowName=GeneralOAuthFlow
  // Link LinkedIn Ads account
  // const onLinkLinkedInAdsAccount = (id) => {
  //   console.log(id);
  //   console.log("called");
  //   // console.log("called link");
  //   localStorage.setItem("socialSite", "linkedIn");
  //   // window.location.href = `${process.env.REACT_APP_BASE_URL}/Linkedin/authorize/${id}`;
  //   window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86ldwctf7zb6a0&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fintegration&scope=r_ads,r_ads_reporting`;
  // };
  const onLinkLinkedInAdsAccount = (id) => {
    console.log(id);
    console.log("called");

    // Store the social site in localStorage
    localStorage.setItem("socialSite", "linkedIn");

    // Base URL for LinkedIn OAuth authorization
    let authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86ldwctf7zb6a0&redirect_uri=https%3A%2F%2Fapp.trackocity.io%2Fintegration&scope=r_ads,r_ads_reporting`;

    // Redirect to the authorization URL
    window.location.href = authUrl;
  };

  //https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86ldwctf7zb6a0&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fintegration&scope=r_ads,r_ads_reporting/id=47

  // List accessible customers and handle any errors
  const listAccessibleCustomers = catchAsync(async (type, id, social) => {
    console.log(social);
    if (!type) return;

    localStorage.setItem("t_systemid", id ? id : "Null");
    localStorage.setItem("t_popupInputType", type);

    try {
      if (social == "linkedIn") {
        // for linkedin
        onLinkLinkedInAdsAccount(id);
      } else {
        // for google
        onLinkAdsAccount(id);
      }
    } catch (error) {
      console.log("Error fetching accessible customers:", error);
    }
  });

  // Connect Google Ad accounts
  const connectGoogleAdAccounts = async () => {
    try {
      if (!googleAdAccounts || googleAdAccounts.accounts.length === 0) {
        await listAccessibleCustomers("checkbox", null, "google");
      } else {
        dispatch(setGadsaccnt(true));
      }
    } catch (error) {
      console.log("Error in checkGoogleAdAccounts:", error);
      dispatch(setGadsaccnt(false));
    }
  };
  // Connect LinkedIn Ad accounts
  const connectLinkedInAdAccounts = async () => {
    console.log("linkedin ads");
    try {
      if (!linkedInAccounts || linkedInAccounts.accounts.length === 0) {
        await listAccessibleCustomers("checkbox", null, "linkedIn");
      } else {
        dispatch(setLadsaccnt(true));
      }
    } catch (error) {
      console.log("Error in checkGoogleAdAccounts:", error);
      dispatch(setLadsaccnt(false));
    }
  };

  // Connect Meta Ad accounts  t
  const connectMetaAdAccounts = () => {
    console.log("hello");
    try {
      if (!metaAccounts || metaAccounts.accounts.length <= 0) {
        window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?app_id=${process.env.REACT_APP_APP_ID}&redirect_uri=${process.env.REACT_APP_FRONT_END_BASE_URL}/integration&config_id=${process.env.REACT_APP_CONFIG_ID}&response_type=code&override_default_response_type=True`;
      } else if (metaAccounts && metaAccounts.accounts.length > 0) {
        dispatch(setMadsaccnt(true));
      }
    } catch (error) {
      console.log("Error in connectMetaAdAccounts:", error);
    }
  };

  // Handle Facebook callback for token exchange
  const handleFacebookCallback = async (shortLivedToken) => {
    console.log("Hello");
    // const params = new URLSearchParams(window.location.search);
    // const shortLivedToken = params.get("code");
    console.log("calledFacebook");
    if (shortLivedToken) {
      try {
        const response = await fetch(
          `https://graph.facebook.com/v19.0/oauth/access_token?client_id=${process.env.REACT_APP_APP_ID}&redirect_uri=${process.env.REACT_APP_FRONT_END_BASE_URL}/integration&client_secret=${process.env.REACT_APP_CLIENT_SECRET}&code=${shortLivedToken}`
        );
        const data = await response.json();
        const longLivedToken = data.access_token;

        const adAccountsUrl = `https://graph.facebook.com/v19.0/me/adaccounts?access_token=${longLivedToken}&fields=id,name`;
        const res = await fetch(adAccountsUrl);
        const metadata = await res.json();

        console.log("Long-lived token:", longLivedToken);
        console.log("Ad accounts metadata:", metadata);

        setMetaAccessToken(longLivedToken);
        setMetaCustomers(metadata.data);

        if (metadata.data.length > 3) {
          popupHandler("Meta Ads");
        } else {
          autoFormSubmitHandler(longLivedToken, metadata.data);
        }
      } catch (error) {
        console.log("Error fetching ad accounts:", error.message);
      }
    }
  };

  // Handle LinkedIn
  const handleLinkedInCallback = async (params) => {
    console.log("Hello");
    const code = params.get("code");

    console.log(params);

    const clientId = "86ldwctf7zb6a0";
    const clientSecret = "WPL_AP1.63N7ClabLLvPTMJX.7dHt6A==";
    const redirectUri = "https://app.trackocity.io/integration";
    const grantType = "authorization_code";

    const authorizationCode = code;

    const url = new URL("https://www.linkedin.com/oauth/v2/accessToken");
    url.searchParams.append("grant_type", grantType);
    url.searchParams.append("code", authorizationCode);
    url.searchParams.append("redirect_uri", redirectUri);
    url.searchParams.append("client_id", clientId);
    url.searchParams.append("client_secret", clientSecret);
    try {
      // Fetch the access token from LinkedIn

      const tokenResponse = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        // body: body.toString(), // Send the URL-encoded parameters
      });

      console.log(tokenResponse);

      if (!tokenResponse.ok) {
        throw new Error(`Error fetching access token: ${tokenResponse.status}`);
      }

      console.log("tokenResponse", tokenResponse);

      const tokenData = await tokenResponse?.json();
      console.log(tokenData);

      const expire_in = secondsToFutureDate(tokenData?.expire_in);
      const refresh_token = tokenData?.refresh_token;
      const refresh_token_expire_in = secondsToFutureDate(
        tokenData?.refresh_token_expires_in
      );
      localStorage.setItem("expire_in", expire_in);
      localStorage.setItem("refresh_token", tokenData?.refresh_token);
      localStorage.setItem("refresh_token_expire_in", refresh_token_expire_in);
      console.log(tokenData);

      const accessToken = tokenData?.access_token;
      if (!accessToken) {
        throw new Error("Access token is missing");
      }
      localStorage.setItem("access__token", accessToken);
      setLinkedInAccessToken(accessToken);

      // const expire_in = secondsToFutureDate(tokenData?.expire_in);
      // const refresh_token_expire_in = secondsToFutureDate(
      //   tokenData?.refresh_token_expires_in
      // );
      // localStorage.setItem("expire_in", expire_in);
      // localStorage.setItem("refresh_token", tokenData?.refresh_token);
      // localStorage.setItem("refresh_token_expire_in", refresh_token_expire_in);
      // console.log(tokenData);
      // const accessToken =
      //   "AQVb_abd8_9ldjevHMb9pjUdKke7pRWuxUcTfKkIwQorz52_IrFAafJWiWZFjQ8iCPx_ymbDoPeMQMc_FD_hM0dS96xGJAGKYMXthhPiyOCwgauI7zT_Ji1yaWCGZIDr76Eb5bCGywG9GnmW5J1oTGpycJR87RBhyrMwe3WylFJ_zz_5YXzEi1AZZVWnEWq9tqPu3CrIHeIrAlpA6JmC37yRVoEEU2ejbuAtKew1-XKLVHzLKEpoa2dSZTrPwDCC4UvECDrqp2Q4GazrUkPtiV0QdE87jZOOh9SJS1vVp5kkVuMAE7MxiGz6zoQmyWjFqJEN3VF6Cl1BRKrqrmKqTtVBFpZ3-A";

      const adAccountsUrl = `${process.env.REACT_APP_BASE_URL}/linkedinads/getaccounts?token=${accessToken}`; // Add token as query parameter

      const adAccountsResponse = await fetch(adAccountsUrl, {
        method: "POST",
      });

      if (!adAccountsResponse.ok) {
        throw new Error(
          `Error fetching ad accounts: ${adAccountsResponse.status}`
        );
      }

      const LinkedInData = await adAccountsResponse.json();

      // function extractSponsoredAccountNumbers(LinkedInData) {
      //   let sponsoredAccountNumbers = [];
      //   console.log(sponsoredAccountNumbers);

      //   LinkedInData.elements.forEach((element) => {
      //     // Extract the account number from the 'account' key and add it to the result array
      //     let accountNumber = element.account.split(":")[3]; // Assuming the structure urn:li:sponsoredAccount:<accountNumber>
      //     sponsoredAccountNumbers.push(accountNumber);
      //   });

      //   // Return or display the result
      //   console.log(sponsoredAccountNumbers);
      //   return sponsoredAccountNumbers;
      // }

      // console.log(LinkedInData);

      // let customerIds = extractSponsoredAccountNumbers(LinkedInData);
      // setLinkedInCustomers(customerIds);

      // const newLinkedData = LinkedInData.elements;

      // // You can process the data and implement your logic here
      // // For example:

      // if (newLinkedData.length > 0) {
      //   popupHandler("Linkedin");
      // } else {
      //   const localData = {
      //     // access_token: accessToken,
      //     // expire_in: localStorage.getItem("expire_in"),
      //     // refresh_token: localStorage.getItem("refresh_token"),
      //     // refresh_token_expire_in: localStorage.getItem(
      //     //   "refresh_token_expire_in"
      //     access_token: accessToken,
      //     expire_in: localStorage.getItem("expire_in"),
      //     refresh_token: localStorage.getItem("refresh_token"),
      //     refresh_token_expire_in: localStorage.getItem(
      //       "refresh_token_expire_in"
      //     ),
      //     account: customerIds,
      //     account_name: customerIds,
      //   };
      //   autoFormSubmitHandlerLink(localData, newLinkedData);
      // }

      // const LinkedInData = await adAccountsResponse.json();
      // const LinkedInData = {
      //   elements: [
      //     {
      //       account: "urn:li:sponsoredAccount:513280305",

      //       changeAuditStamps: {
      //         created: { actor: "urn:li:unknown:0", time: 1731389593000 },

      //         lastModified: { actor: "urn:li:unknown:0", time: 1731389593000 },
      //       },

      //       role: "ACCOUNT_BILLING_ADMIN",

      //       user: "urn:li:person:Bj9GMgdz28",
      //     },
      //     {
      //       account: "urn:li:sponsoredAccount:51380205",

      //       changeAuditStamps: {
      //         created: { actor: "urn:li:unknown:0", time: 1731389593000 },

      //         lastModified: { actor: "urn:li:unknown:0", time: 1731389593000 },
      //       },

      //       role: "ACCOUNT_BILLING_ADMIN",

      //       user: "urn:li:person:Bj9GMgdz28",
      //     },
      //   ],

      //   paging: { count: 2147483647, links: [], start: 0, total: 1 },
      // };

      function extractSponsoredAccountNumbers(LinkedInData) {
        let sponsoredAccountNumbers = [];
        console.log(sponsoredAccountNumbers);

        LinkedInData.elements.forEach((element) => {
          // Extract the account number from the 'account' key and add it to the result array
          let accountNumber = element.account.split(":")[3]; // Assuming the structure urn:li:sponsoredAccount:<accountNumber>
          sponsoredAccountNumbers.push(accountNumber);
        });

        // Return or display the result
        console.log(sponsoredAccountNumbers);
        return sponsoredAccountNumbers;
      }

      console.log(LinkedInData);

      let customerIds = extractSponsoredAccountNumbers(LinkedInData);
      console.log(customerIds);
      setLinkedInCustomers(customerIds);

      const newLinkedData = LinkedInData.elements;

      // You can process the data and implement your logic here
      // For example:

      if (newLinkedData.length > 3) {
        // Only trigger the popupHandler when there is data
        popupHandler("Linkedin");
      } else {
        // Loop through each sponsoredAccountNumber and call the function for each one if there is no data in newLinkedData
        customerIds.forEach((accountNumber) => {
          console.log(accountNumber);
          const localData = {
            access_token: accessToken,
            expire_in: expire_in,
            refresh_token: refresh_token,

            refresh_token_expire_in: refresh_token_expire_in,
            account: accountNumber, // Using an array for account
            accoun_name: accountNumber, // Using an array for account_name
            systemid: localStorage.getItem("t_systemid"),
          };

          // Call the autoFormSubmitHandlerLink for each sponsored account
          autoFormSubmitHandlerLink(localData, newLinkedData);
        });
      }

      //below is for the 2nd api which is now commented out
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  // useEffect(() => {
  //   popupHandler("Linkedin");
  //   setLinkedInCustomers(["513280305", "513267305"]);
  // }, []);

  // useEffect(() => {
  //   const fetchAdAccountData = async () => {
  //     const accessTok =
  //       "AQVb_abd8_9ldjevHMb9pjUdKke7pRWuxUcTfKkIwQorz52_IrFAafJWiWZFjQ8iCPx_ymbDoPeMQMc_FD_hM0dS96xGJAGKYMXthhPiyOCwgauI7zT_Ji1yaWCGZIDr76Eb5bCGywG9GnmW5J1oTGpycJR87RBhyrMwe3WylFJ_zz_5YXzEi1AZZVWnEWq9tqPu3CrIHeIrAlpA6JmC37yRVoEEU2ejbuAtKew1-XKLVHzLKEpoa2dSZTrPwDCC4UvECDrqp2Q4GazrUkPtiV0QdE87jZOOh9SJS1vVp5kkVuMAE7MxiGz6zoQmyWjFqJEN3VF6Cl1BRKrqrmKqTtVBFpZ3-A"; // Use your actual token here
  //     const adAccountsUrl = `${process.env.REACT_APP_BASE_URL}/linkedinads/getaccounts?token=${accessTok}`; // Add token as query parameter

  //     try {
  //       const adAccountsResponse = await fetch(adAccountsUrl, {
  //         method: "POST",
  //         // No need to pass Authorization header
  //         // headers: {
  //         //   Authorization: `Bearer ${accessTok}`,
  //         // },
  //       });

  //       if (!adAccountsResponse.ok) {
  //         throw new Error(
  //           `Error fetching ad account data: ${adAccountsResponse.statusText}`
  //         );
  //       }

  //       const data = await adAccountsResponse.json();
  //       console.log(data);
  //       console.log("Ad account data:", data); // You can also set this data to state here
  //     } catch (error) {
  //       console.error("Error fetching ad account data:", error);
  //     }
  //   };

  //   // Call the async function
  //   fetchAdAccountData();
  // }, []);

  // Automatically submit form with retrieved Meta data
  const autoFormSubmitHandler = (metaAccessToken, metaCustomers) => {
    console.log("autoFormSubmitHandler", metaAccessToken, metaCustomers);
    setMeta({
      accessToken: metaAccessToken,
      expireon: null,
      accountinfo: metaCustomers,
    })
      .then((res) => {
        console.log(res);

      })
      .catch((error) => {
        console.log("Login Error:", error);
      })
      .finally(() => {
        setIsPopupOpen(false);
        setPopupChildren("");
        normalizePath(location, navigate);
      });
  };
  const autoFormSubmitHandlerLink = (localData, newLinkedData) => {
    console.log(localData);
    setLinkedIn({
      accessToken: localData.access_token,
      expire_in: localData.expire_in,
      refresh_token: localData.refresh_token,
      refresh_token_expire_in: localData.refresh_token_expire_in,
      account: localData.account,
      account_name: localData.account_name,
      systemid: localData.systemid,
    })
      .then((res) => {

        console.log(res);
      })
      .catch((error) => {
        console.log("Login Error:", error);
      })
      .finally(() => {
        setIsPopupOpen(false);
        setPopupChildren("");
        normalizePath(location, navigate);
      });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const site = localStorage.getItem("socialSite") || undefined;
    if (site == "linkedIn") {
      handleLinkedInCallback(params);
      localStorage.removeItem("socialSite");
    } else {
      handleFacebookCallback(code);
    }
  }, []);

  // Video tutorial URLs mapping
  const videoUrls = {
    "Code Section Video": "https://www.youtube.com/embed/RvPWNQmNurg?rel=0",
    "Meta Video Tutorial":
      "https://www.youtube.com/embed/sUEgGsCCQ-g?si=SaXZHyKncEE3cNYf?rel=0",
    "Google Video Tutorial":
      "https://www.youtube.com/embed/ror0KKwhISw?si=LVhuWRTmyvEPVgGX?rel=0",
    "Razorpay Video Tutorial":
      "https://www.youtube.com/embed/YJN0FsqIMd8?si=OWuZQWbybd55ahmn?rel=0",
    /*"Cashfree Video Tutorial":
      "https://www.youtube.com/embed/YJN0FsqIMd8?si=OWuZQWbybd55ahmn?rel=0",*/
    "google-utm":
      "https://www.youtube.com/embed/DAhVIbWNXlE?si=RtEqQWHBo8pAtrPw?rel=0",
    "meta-utm":
      "https://www.youtube.com/embed/3uWtNQ1CYcQ?si=YM-zqbTMjlAHJMxt?rel=0",
    // Add more mappings as needed
  };

  const currentVideoUrl = videoUrls[popupChildren]; // Current video URL based on popup content

  // Component mapping for dynamic rendering
  const componentMap = {
    "Razorpay Integration": Razorpay,
    "Cashfree Integration": Cashfree,
    "Shopify Integration": Shopify,
    "WooCommerce Integration": WooCommerce,
    "Google Ads": GoogleAds,
    "Meta Ads": MetaAds,
    "Pabbly Integration": Pabbly,
    Linkedin: LinkedInAds,
  };

  const componentProps = {
    "Razorpay Integration": {
      setIsPopupOpen,
      setPopupChildren,
      signatureKey,
      setInte,
    },
    "Cashfree Integration": {
      setIsPopupOpen,
      setPopupChildren,
      signatureKey,
      setInte,
    },
    "Shopify Integration": { setIsPopupOpen, setPopupChildren, setInte },
    "WooCommerce Integration": {
      setIsPopupOpen,
      setPopupChildren,
      signatureKey,
      setInte,
    },
    "Google Ads": {
      setIsPopupOpen,
      setPopupChildren,
      googleCustomers,
      googleRefreshToken,
      setGadsaccnt,
      setInte,
    },
    "Meta Ads": {
      setIsPopupOpen,
      setPopupChildren,
      metaCustomers,
      metaAccessToken,
      setMadsaccnt,
      setInte,
    },
    Linkedin: {
      setIsPopupOpen,
      setPopupChildren,
      linkedInCustomers,
      linkedInAccessToken,
      setLadsaccnt,
      setInte,
      expire_in,
      refresh_token,
      refresh_token_expire_in,
    },
    "Pabbly Integration": { setIsPopupOpen, setPopupChildren, setInte },
  };

  const CurrentComponent = componentMap[popupChildren];
  const props = componentProps[popupChildren];

  const [refreshCount, setRefreshCount] = useState(
    localStorage.getItem("refreshcalled")
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshCount(localStorage.getItem("refreshcalled"));
    }, 1000); // Polling interval for localStorage updates

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="integration-container">
        <div
          className="integration-header-section"
          style={{ paddingRight: "60px" }}
        >
          <div className="client-heading">
            <span className="integration-header-nav-section">
              <button
                className={`integration-header-nav-button ${selectedTab === "Apps" ? "active-integration-tab" : ""
                  }`}
                onClick={() => handleTabClick("Apps")}
              >
                Apps
              </button>
              <button disabled className="button-separator"></button>
              <button
                className={`integration-header-nav-button integration-header-nav-button-utm ${selectedTab === "Code" ? "active-integration-tab" : ""
                  }`}
                onClick={() => handleTabClick("Code")}
              >
                Code
              </button>
            </span>
            <DropDownComp />
          </div>
          <ProfileDropDown />
        </div>
        <div
          className="integration-body-section"
          style={{ overflowY: "scroll" }}
        >
          {refreshCount >= 90 && refreshCount < 100 && (
            <div
              className="error-in-page"
              style={{ top: "0", zIndex: "97", marginBottom: "30px" }}
            >
              <ErrorPage1 />
            </div>
          )}

          {refreshCount >= 100 && (
            <div
              className="error-in-page"
              style={{ top: "0", zIndex: "97", marginBottom: "30px" }}
            >
              <ErrorPage2 />
            </div>
          )}

          {refreshCount < 100 &&
            selectedTab === "Apps" &&
            !gadsaccnt &&
            !madsaccnt &&
            !ladsaccnt && (
              <AppsTab
                popupHandler={popupHandler}
                connectGoogleAdAccounts={connectGoogleAdAccounts}
                connectMetaAdAccounts={connectMetaAdAccounts}
                connectLinkedInAdAccounts={connectLinkedInAdAccounts}
                inte={inte}
              />
            )}
          {refreshCount < 100 &&
            selectedTab === "Apps" &&
            gadsaccnt &&
            !madsaccnt &&
            !ladsaccnt && (
              <GoogleAdsFetched
                setGadsaccnt={setGadsaccnt}
                googleAdAccounts={googleAdAccounts}
                listAccessibleCustomers={listAccessibleCustomers}
                popupHandler={popupHandler}
              />
            )}
          {refreshCount < 100 &&
            selectedTab === "Apps" &&
            ladsaccnt &&
            !madsaccnt &&
            !gadsaccnt && (
              <LinkedInFetched
                setLadsaccnt={setLadsaccnt}
                linkedInAccounts={linkedInAccounts}
                listAccessibleCustomers={listAccessibleCustomers}
                popupHandler={popupHandler}
              />
            )}
          {refreshCount < 100 &&
            selectedTab === "Apps" &&
            !gadsaccnt &&
            !ladsaccnt &&
            madsaccnt && (
              <MetaAdsFetched
                setMadsaccnt={setMadsaccnt}
                metaAccounts={metaAccounts}
                connectMetaAdAccounts={connectMetaAdAccounts}
                popupHandler={popupHandler}
              />
            )}
          {refreshCount < 100 && selectedTab === "Code" && (
            <CodeTab popupHandler={popupHandler} />
          )}
        </div>
      </div>
      <Popup isOpen={isPopupOpen} onClose={closePopup}>
        {CurrentComponent && <CurrentComponent {...props} />}

        {currentVideoUrl && (
          <VideoTutorialPopups currentVideoUrl={currentVideoUrl} />
        )}
      </Popup>
    </>
  );
};

export default Integration;

// import React, { useState, useEffect } from "react";
// import "./styles.css";

// import CodeTab from "./Codetab";
// import AppsTab from "./AppsTab";
// import { DropDownComp, Popup } from "../../components";
// import VideoTutorialPopups from "./VideoTutorialPopups";
// import Razorpay from "./Razorpay";
// import Shopify from "./Shopify";
// import WooCommerce from "./WooCommerce";
// import GoogleAds from "./GoogleAds";
// import MetaAds from "./MetaAds";
// import Pabbly from "./Pabbly";
// import Cashfree from "./Cashfree";

// import { useDispatch, useSelector } from "react-redux";
// import {
//   getLocalStorageItem,
//   secondsToFutureDate,
//   setLocalStorageItem,
// } from "../../utils";
// import { fetchGoogleAdAccounts } from "../../redux/reducers/googleAdAccountsSlice";
// import catchAsync from "../../utils/catchAsync";
// import { normalizePath } from "../../utils";
// import GoogleAdsFetched from "./GoogleAdsFetched";
// import { setGadsaccnt } from "../../redux/reducers/gadsaccntSlice";
// import { setMadsaccnt } from "../../redux/reducers/madsaccntSlice";
// import { extractCustomerIDsAndRefreshToken } from ".";
// import { fetchMetaAccounts } from "../../redux/reducers/IntegrationSlice";
// import { setLinkedIn, setMeta } from "../../services/api/integrationApis";
// import { useLocation, useNavigate } from "react-router-dom";
// import MetaAdsFetched from "./MetaAdsFetched";
// import ProfileDropDown from "../../components/profileDropDown/ProfileDropDown";
// import LinkedInAds from "./LinkedInAds";
// import { setLadsaccnt } from "../../redux/reducers/ladsaccntSlice";

// import { fetchLinkedInAccounts } from "../../redux/reducers/linkedInAccountSlice";
// import LinkedInFetched from "./LinkedInFetched";

// const Integration = () => {
//   const [inte, setInte] = useState(false);
//   // Hooks for managing state and navigation
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [selectedTab, setSelectedTab] = useState(
//     localStorage.getItem("selectedTab") || "Apps"
//   );

//   // const expire_in = localStorage.getItem("expire_in");
//   // const refresh_token = localStorage.getItem("refresh_token");
//   // const refresh_token_expire_in = localStorage.getItem(
//   //   "refresh_token_expire_in"
//   // );

//   const expire_in = secondsToFutureDate(5183999);
//   const refresh_token =
//     "AQXE-iOjzZW7YthdFBRIbdHxRY4DKrUV1YtBnOXOvwW3_dhAz5_QefaZe14DG5o4CLdFKr7jLV-oM7pdM6BNFIOyFEmnX2mingM5OYV0v5Dc0YVBJPTdW1HP1HbVuxSukEh97jMfODEdNIc5tBG0_50hcXT4_lZM9Aj4RwFSOdhDWBJPYrIwpVL9GeaCjLeuaQ7dWcDGX_P5LQLpSwKssfznKn7e2j_CAjD7hZkCsX-dyhiuLU6H7l4PCOPlHANUIcaQmlBLEPv2mv7WZUzK3Vqgxn-nbhaMNICX-rRC2UxprUD9Niz158XGav1muQ-4GNl3qz7BNRxpRWnT8elmumwI_uwGYQ";
//   const refresh_token_expire_in = secondsToFutureDate(31535999);

//   const [isPopupOpen, setIsPopupOpen] = useState(false); // State to manage popup visibility
//   const [popupChildren, setPopupChildren] = useState(""); // State to manage content of the popup
//   console.log(popupChildren);
//   const [clearConnected, setClearConnected] = useState(false);
//   const { currentClient } = useSelector((state) => state.agency);
//   const accessToken =
//     "AQVb_abd8_9ldjevHMb9pjUdKke7pRWuxUcTfKkIwQorz52_IrFAafJWiWZFjQ8iCPx_ymbDoPeMQMc_FD_hM0dS96xGJAGKYMXthhPiyOCwgauI7zT_Ji1yaWCGZIDr76Eb5bCGywG9GnmW5J1oTGpycJR87RBhyrMwe3WylFJ_zz_5YXzEi1AZZVWnEWq9tqPu3CrIHeIrAlpA6JmC37yRVoEEU2ejbuAtKew1-XKLVHzLKEpoa2dSZTrPwDCC4UvECDrqp2Q4GazrUkPtiV0QdE87jZOOh9SJS1vVp5kkVuMAE7MxiGz6zoQmyWjFqJEN3VF6Cl1BRKrqrmKqTtVBFpZ3-A";
//   useEffect(() => {
//     localStorage.setItem("selectedTab", selectedTab);
//   }, [selectedTab]);

//   // Function to handle tab click
//   const handleTabClick = (tabName) => {
//     setSelectedTab(tabName);
//   };

//   // Function to open popup with specific content
//   const popupHandler = (content) => {
//     console.log(content);
//     setPopupChildren(content);
//     setIsPopupOpen(true);
//   };

//   // Function to close popup and perform clean-up
//   const closePopup = () => {
//     // popupChildren === "meta-utm" && handleCopyScriptTour();
//     setIsPopupOpen(false);
//     setPopupChildren("");
//     // localStorage.removeItem("gadsaccnt");
//     // localStorage.removeItem("madsaccnt");
//     // localStorage.removeItem("metaAccessToken");
//     // localStorage.removeItem("metaCustomers");
//     normalizePath(location, navigate);
//   };

//   const dispatch = useDispatch();

//   const [signatureKey, setSignatureKey] = useState("");

//   // Redux state selectors
//   const { metaAccounts } = useSelector((state) => state.integration);
//   console.log(metaAccounts);
//   const { googleAdAccounts } = useSelector((state) => state.googleAdAccounts);
//   const { linkedInAccounts } = useSelector((state) => state.linkedIn);
//   const gadsaccnt = useSelector((state) => state.gadsaccnt);
//   const madsaccnt = useSelector((state) => state.madsaccnt);
//   const ladsaccnt = useSelector((state) => state.ladsaccnt);

//   // State for Google and Meta account data
//   const [googleCustomers, setGoogleCustomers] = useState([]);
//   const [googleRefreshToken, setGoogleRefreshToken] = useState(null);
//   const [metaAccessToken, setMetaAccessToken] = useState(
//     () => getLocalStorageItem("metaAccessToken", "") || ""
//   );
//   const [linkedInAccessToken, setLinkedInAccessToken] = useState(
//     () => getLocalStorageItem("linkedInAccessToken", "") || ""
//   );
//   const [metaCustomers, setMetaCustomers] = useState(() =>
//     getLocalStorageItem("metaCustomers", [] || [])
//   );
//   const [linkedInCustomers, setLinkedInCustomers] = useState(() =>
//     getLocalStorageItem("linkedInCustomers", [] || [])
//   );

//   // Fetch Google Ad accounts when Google account state changes
//   useEffect(() => {
//     dispatch(fetchGoogleAdAccounts()).unwrap();
//   }, [gadsaccnt, currentClient]);

//   // Fetch Meta accounts when Meta account state changes
//   useEffect(() => {
//     dispatch(fetchMetaAccounts()).unwrap();
//   }, [madsaccnt, currentClient]);

//   useEffect(() => {
//     dispatch(fetchLinkedInAccounts()).unwrap();
//   }, [ladsaccnt, currentClient]);

//   useEffect(() => {
//     const generateRandomString = () => {
//       const characters =
//         "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//       let result = "";
//       const charactersLength = characters.length;
//       for (let i = 0; i < 20; i++) {
//         result += characters.charAt(
//           Math.floor(Math.random() * charactersLength)
//         );
//       }
//       return result;
//     };

//     setSignatureKey(generateRandomString());
//   }, [popupChildren, isPopupOpen]);

//   // Effect to handle customer IDs and refresh token extraction from URL
//   useEffect(() => {
//     const { customerIDs, refreshToken } = extractCustomerIDsAndRefreshToken(
//       window.location.href
//     );

//     if (customerIDs.length > 0 && refreshToken) {
//       setGoogleCustomers(customerIDs);
//       setGoogleRefreshToken(refreshToken);
//       popupHandler("Google Ads");
//     } else {
//       console.log("No accessible customers found");
//     }
//   }, []);

//   // Link Google Ads account
//   const onLinkAdsAccount = (g_id) => {
//     console.log(g_id);
//     const id = g_id || null;
//     window.location.href = `${process.env.REACT_APP_BASE_URL}/google/authorize/${id}`;
//   };

//   // Link LinkedIn Ads account
//   const onLinkLinkedInAdsAccount = (id) => {
//     console.log("called link");
//     localStorage.setItem("socialSite", "linkedIn");

//     // window.location.href = `${process.env.REACT_APP_BASE_URL}/Linkedin/authorize/${id}`;
//     window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86ldwctf7zb6a0&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fintegration&scope=r_ads,r_ads_reporting`;
//   };

//   // List accessible customers and handle any errors
//   // const listAccessibleCustomers = catchAsync(async (type, id, isLinkedIn) => {
//   //   console.log(isLinkedIn);
//   //   if (!type) return;

//   //   localStorage.setItem("t_systemid", id);
//   //   localStorage.setItem("t_popupInputType", type);

//   //   try {
//   //     if (isLinkedIn) {
//   //       // for linkedin
//   //       onLinkLinkedInAdsAccount(id);
//   //     } else {
//   //       // for google
//   //       onLinkAdsAccount();
//   //     }
//   //   } catch (error) {
//   //     console.log("Error fetching accessible customers:", error);
//   //   }
//   // });

//   const listAccessibleCustomers = catchAsync(async (type, id, social) => {
//     console.log(social);
//     if (!type) return;

//     localStorage.setItem("t_systemid", id ? id : "Null");
//     localStorage.setItem("t_popupInputType", type);

//     try {
//       if (social == "linkedIn") {
//         // for linkedin
//         onLinkLinkedInAdsAccount(id);
//       } else {
//         // for google
//         onLinkAdsAccount(id);
//       }
//     } catch (error) {
//       console.log("Error fetching accessible customers:", error);
//     }
//   });

//   // const listAccessibleCustomersgit = catchAsync(async (type, id, social) => {
//   //   if (!type) return;

//   //   localStorage.setItem("t_systemid", id);
//   //   localStorage.setItem("t_popupInputType", type);

//   //   try {
//   //     onLinkAdsAccount(id);
//   //   } catch (error) {
//   //     console.log("Error fetching accessible customers:", error);
//   //   }
//   // });

//   // Connect Google Ad accounts
//   const connectGoogleAdAccounts = async () => {
//     try {
//       if (!googleAdAccounts || googleAdAccounts.accounts.length === 0) {
//         await listAccessibleCustomers("checkbox", null, "google");
//       } else {
//         dispatch(setGadsaccnt(true));
//       }
//     } catch (error) {
//       console.log("Error in checkGoogleAdAccounts:", error);
//       dispatch(setGadsaccnt(false));
//     }
//   };
//   // Connect LinkedIn Ad accounts
//   const connectLinkedInAdAccounts = async () => {
//     console.log("linkedin ads");
//     try {
//       if (!linkedInAccounts || linkedInAccounts.accounts.length === 0) {
//         await listAccessibleCustomers("checkbox", null, "linkedIn");
//       } else {
//         dispatch(setLadsaccnt(true));
//       }
//     } catch (error) {
//       console.log("Error in checkGoogleAdAccounts:", error);
//       dispatch(setLadsaccnt(false));
//     }
//   };

//   // Connect Meta Ad accounts  t
//   const connectMetaAdAccounts = () => {
//     console.log("hello");
//     try {
//       if (!metaAccounts || metaAccounts.accounts.length <= 0) {
//         window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?app_id=${process.env.REACT_APP_APP_ID}&redirect_uri=${process.env.REACT_APP_FRONT_END_BASE_URL}/integration&config_id=${process.env.REACT_APP_CONFIG_ID}&response_type=code&override_default_response_type=True`;
//       } else if (metaAccounts && metaAccounts.accounts.length > 0) {
//         dispatch(setMadsaccnt(true));
//       }
//     } catch (error) {
//       console.log("Error in connectMetaAdAccounts:", error);
//     }
//   };

//   // Handle Facebook callback for token exchange
//   const handleFacebookCallback = async (shortLivedToken) => {
//     console.log("Hello");
//     // const params = new URLSearchParams(window.location.search);
//     // const shortLivedToken = params.get("code");
//     console.log("calledFacebook");
//     if (shortLivedToken) {
//       try {
//         const response = await fetch(
//           `https://graph.facebook.com/v19.0/oauth/access_token?client_id=${process.env.REACT_APP_APP_ID}&redirect_uri=${process.env.REACT_APP_FRONT_END_BASE_URL}/integration&client_secret=${process.env.REACT_APP_CLIENT_SECRET}&code=${shortLivedToken}`
//         );
//         const data = await response.json();
//         const longLivedToken = data.access_token;

//         const adAccountsUrl = `https://graph.facebook.com/v19.0/me/adaccounts?access_token=${longLivedToken}&fields=id,name`;
//         const res = await fetch(adAccountsUrl);
//         const metadata = await res.json();

//         console.log("Long-lived token:", longLivedToken);
//         console.log("Ad accounts metadata:", metadata);

//         setMetaAccessToken(longLivedToken);
//         setMetaCustomers(metadata.data);

//         if (metadata.data.length > 3) {
//           popupHandler("Meta Ads");
//         } else {
//           autoFormSubmitHandler(longLivedToken, metadata.data);
//         }
//       } catch (error) {
//         console.log("Error fetching ad accounts:", error.message);
//       }
//     }
//   };

//   // Handle LinkedIn
//   const handleLinkedInCallback = async (params) => {
//     // console.log("Hello");
//     // const code = params.get("code");

//     // console.log(params);

//     // const clientId = "86ldwctf7zb6a0";
//     // const clientSecret = "WPL_AP1.63N7ClabLLvPTMJX.7dHt6A==";
//     // const redirectUri = "https://app.trackocity.io/integration";
//     // const grantType = "authorization_code";
//     // // const authorizationCode = code;
//     // const authorizationCode = code;

//     // const url = new URL("https://www.linkedin.com/oauth/v2/accessToken");
//     // url.searchParams.append("grant_type", grantType);
//     // url.searchParams.append("code", authorizationCode);
//     // url.searchParams.append("redirect_uri", redirectUri);
//     // url.searchParams.append("client_id", clientId);
//     // url.searchParams.append("client_secret", clientSecret);
//     // try {
//     //   // Fetch the access token from LinkedIn

//     //   const tokenResponse = await fetch(url, {
//     //     method: "POST",
//     //     headers: {
//     //       "Content-Type": "application/x-www-form-urlencoded",
//     //     },
//     //     // body: body.toString(), // Send the URL-encoded parameters
//     //   });

//     //   console.log(tokenResponse);

//     //   if (!tokenResponse.ok) {
//     //     throw new Error(`Error fetching access token: ${tokenResponse.status}`);
//     //   }

//     //   console.log("tokenResponse", tokenResponse);

//     //   const tokenData = await tokenResponse?.json();
//     //   console.log(tokenData);

//     // const expire_in = secondsToFutureDate(tokenData?.expire_in);
//     // const refresh_token_expire_in = secondsToFutureDate(
//     //   tokenData?.refresh_token_expires_in
//     // );
//     // localStorage.setItem("expire_in", expire_in);
//     // localStorage.setItem("refresh_token", tokenData?.refresh_token);
//     // localStorage.setItem("refresh_token_expire_in", refresh_token_expire_in);
//     // console.log(tokenData);
//     // const accessToken = tokenData?.access_token;

//     // const expire_in = secondsToFutureDate(tokenData?.expire_in);
//     // const refresh_token_expire_in = secondsToFutureDate(
//     //   tokenData?.refresh_token_expires_in
//     // );
//     // localStorage.setItem("expire_in", expire_in);
//     // localStorage.setItem("refresh_token", tokenData?.refresh_token);
//     // localStorage.setItem("refresh_token_expire_in", refresh_token_expire_in);
//     // console.log(tokenData);
//     const accessToken =
//       "AQVb_abd8_9ldjevHMb9pjUdKke7pRWuxUcTfKkIwQorz52_IrFAafJWiWZFjQ8iCPx_ymbDoPeMQMc_FD_hM0dS96xGJAGKYMXthhPiyOCwgauI7zT_Ji1yaWCGZIDr76Eb5bCGywG9GnmW5J1oTGpycJR87RBhyrMwe3WylFJ_zz_5YXzEi1AZZVWnEWq9tqPu3CrIHeIrAlpA6JmC37yRVoEEU2ejbuAtKew1-XKLVHzLKEpoa2dSZTrPwDCC4UvECDrqp2Q4GazrUkPtiV0QdE87jZOOh9SJS1vVp5kkVuMAE7MxiGz6zoQmyWjFqJEN3VF6Cl1BRKrqrmKqTtVBFpZ3-A";
//     localStorage.setItem("access__token", accessToken);
//     setLinkedInAccessToken(accessToken);

//     if (!accessToken) {
//       throw new Error("Access token is missing");
//     }

//     const adAccountsUrl = `${process.env.REACT_APP_BASE_URL}/linkedinads/getaccounts?token=${accessToken}`; // Add token as query parameter

//     const adAccountsResponse = await fetch(adAccountsUrl, {
//       method: "POST",
//     });

//     if (!adAccountsResponse.ok) {
//       throw new Error(
//         `Error fetching ad accounts: ${adAccountsResponse.status}`
//       );
//     }

//     // const LinkedInData = await adAccountsResponse.json();

//     // function extractSponsoredAccountNumbers(LinkedInData) {
//     //   let sponsoredAccountNumbers = [];
//     //   console.log(sponsoredAccountNumbers);

//     //   LinkedInData.elements.forEach((element) => {
//     //     // Extract the account number from the 'account' key and add it to the result array
//     //     let accountNumber = element.account.split(":")[3]; // Assuming the structure urn:li:sponsoredAccount:<accountNumber>
//     //     sponsoredAccountNumbers.push(accountNumber);
//     //   });

//     //   // Return or display the result
//     //   console.log(sponsoredAccountNumbers);
//     //   return sponsoredAccountNumbers;
//     // }

//     // console.log(LinkedInData);

//     // let customerIds = extractSponsoredAccountNumbers(LinkedInData);
//     // setLinkedInCustomers(customerIds);

//     // const newLinkedData = LinkedInData.elements;

//     // // You can process the data and implement your logic here
//     // // For example:

//     // if (newLinkedData.length > 0) {
//     //   popupHandler("Linkedin");
//     // } else {
//     //   const localData = {
//     //     // access_token: accessToken,
//     //     // expire_in: localStorage.getItem("expire_in"),
//     //     // refresh_token: localStorage.getItem("refresh_token"),
//     //     // refresh_token_expire_in: localStorage.getItem(
//     //     //   "refresh_token_expire_in"
//     //     access_token: accessToken,
//     //     expire_in: localStorage.getItem("expire_in"),
//     //     refresh_token: localStorage.getItem("refresh_token"),
//     //     refresh_token_expire_in: localStorage.getItem(
//     //       "refresh_token_expire_in"
//     //     ),
//     //     account: customerIds,
//     //     account_name: customerIds,
//     //   };
//     //   autoFormSubmitHandlerLink(localData, newLinkedData);
//     // }

//     // const LinkedInData = await adAccountsResponse.json();
//     const LinkedInData = {
//       elements: [
//         {
//           account: "urn:li:sponsoredAccount:513280305",

//           changeAuditStamps: {
//             created: { actor: "urn:li:unknown:0", time: 1731389593000 },

//             lastModified: { actor: "urn:li:unknown:0", time: 1731389593000 },
//           },

//           role: "ACCOUNT_BILLING_ADMIN",

//           user: "urn:li:person:Bj9GMgdz28",
//         },
//         {
//           account: "urn:li:sponsoredAccount:51380205",

//           changeAuditStamps: {
//             created: { actor: "urn:li:unknown:0", time: 1731389593000 },

//             lastModified: { actor: "urn:li:unknown:0", time: 1731389593000 },
//           },

//           role: "ACCOUNT_BILLING_ADMIN",

//           user: "urn:li:person:Bj9GMgdz28",
//         },
//       ],

//       paging: { count: 2147483647, links: [], start: 0, total: 1 },
//     };
//     console.log(accessToken);

//     function extractSponsoredAccountNumbers(LinkedInData) {
//       let sponsoredAccountNumbers = [];
//       console.log(sponsoredAccountNumbers);

//       LinkedInData.elements.forEach((element) => {
//         // Extract the account number from the 'account' key and add it to the result array
//         let accountNumber = element.account.split(":")[3]; // Assuming the structure urn:li:sponsoredAccount:<accountNumber>
//         sponsoredAccountNumbers.push(accountNumber);
//       });

//       // Return or display the result
//       console.log(sponsoredAccountNumbers);
//       return sponsoredAccountNumbers;
//     }

//     console.log(LinkedInData);

//     let customerIds = extractSponsoredAccountNumbers(LinkedInData);
//     console.log(customerIds);
//     setLinkedInCustomers(customerIds);

//     const newLinkedData = LinkedInData.elements;

//     // You can process the data and implement your logic here
//     // For example:

//     if (newLinkedData.length > 0) {
//       // Only trigger the popupHandler when there is data
//       popupHandler("Linkedin");
//     } else {
//       // Loop through each sponsoredAccountNumber and call the function for each one if there is no data in newLinkedData
//       customerIds.forEach((accountNumber) => {
//         console.log(accountNumber);
//         const localData = {
//           access_token: accessToken,
//           expire_in: secondsToFutureDate(5183999),
//           refresh_token:
//             "AQXE-iOjzZW7YthdFBRIbdHxRY4DKrUV1YtBnOXOvwW3_dhAz5_QefaZe14DG5o4CLdFKr7jLV-oM7pdM6BNFIOyFEmnX2mingM5OYV0v5Dc0YVBJPTdW1HP1HbVuxSukEh97jMfODEdNIc5tBG0_50hcXT4_lZM9Aj4RwFSOdhDWBJPYrIwpVL9GeaCjLeuaQ7dWcDGX_P5LQLpSwKssfznKn7e2j_CAjD7hZkCsX-dyhiuLU6H7l4PCOPlHANUIcaQmlBLEPv2mv7WZUzK3Vqgxn-nbhaMNICX-rRC2UxprUD9Niz158XGav1muQ-4GNl3qz7BNRxpRWnT8elmumwI_uwGYQ",
//           refresh_token_expire_in: secondsToFutureDate(31535999),
//           account: accountNumber, // Using an array for account
//           accoun_name: accountNumber, // Using an array for account_name
//           systemid: localStorage.getItem("t_systemid"),
//         };

//         // Call the autoFormSubmitHandlerLink for each sponsored account
//         autoFormSubmitHandlerLink(localData, newLinkedData);
//       });
//     }

//     //below is for the 2nd api which is now commented out
//     // } catch (error) {
//     //   console.error("Error:", error.message);
//     // }
//   };

//   // useEffect(() => {
//   //   popupHandler("Linkedin");
//   //   setLinkedInCustomers(["513280305", "513267305"]);
//   // }, []);

//   // useEffect(() => {
//   //   const fetchAdAccountData = async () => {
//   //     const accessTok =
//   //       "AQVb_abd8_9ldjevHMb9pjUdKke7pRWuxUcTfKkIwQorz52_IrFAafJWiWZFjQ8iCPx_ymbDoPeMQMc_FD_hM0dS96xGJAGKYMXthhPiyOCwgauI7zT_Ji1yaWCGZIDr76Eb5bCGywG9GnmW5J1oTGpycJR87RBhyrMwe3WylFJ_zz_5YXzEi1AZZVWnEWq9tqPu3CrIHeIrAlpA6JmC37yRVoEEU2ejbuAtKew1-XKLVHzLKEpoa2dSZTrPwDCC4UvECDrqp2Q4GazrUkPtiV0QdE87jZOOh9SJS1vVp5kkVuMAE7MxiGz6zoQmyWjFqJEN3VF6Cl1BRKrqrmKqTtVBFpZ3-A"; // Use your actual token here
//   //     const adAccountsUrl = `${process.env.REACT_APP_BASE_URL}/linkedinads/getaccounts?token=${accessTok}`; // Add token as query parameter

//   //     try {
//   //       const adAccountsResponse = await fetch(adAccountsUrl, {
//   //         method: "POST",
//   //         // No need to pass Authorization header
//   //         // headers: {
//   //         //   Authorization: `Bearer ${accessTok}`,
//   //         // },
//   //       });

//   //       if (!adAccountsResponse.ok) {
//   //         throw new Error(
//   //           `Error fetching ad account data: ${adAccountsResponse.statusText}`
//   //         );
//   //       }

//   //       const data = await adAccountsResponse.json();
//   //       console.log(data);
//   //       console.log("Ad account data:", data); // You can also set this data to state here
//   //     } catch (error) {
//   //       console.error("Error fetching ad account data:", error);
//   //     }
//   //   };

//   //   // Call the async function
//   //   fetchAdAccountData();
//   // }, []);

//   // Automatically submit form with retrieved Meta data
//   const autoFormSubmitHandler = (metaAccessToken, metaCustomers) => {
//     console.log("autoFormSubmitHandler", metaAccessToken, metaCustomers);
//     setMeta({
//       accessToken: metaAccessToken,
//       expireon: null,
//       accountinfo: metaCustomers,
//     })
//       .then((res) => {
//         console.log(res);
//       })
//       .catch((error) => {
//         console.log("Login Error:", error);
//       })
//       .finally(() => {
//         setIsPopupOpen(false);
//         setPopupChildren("");
//         normalizePath(location, navigate);
//       });
//   };
//   const autoFormSubmitHandlerLink = (localData, newLinkedData) => {
//     console.log(localData);
//     setLinkedIn({
//       accessToken: localData.access_token,
//       expire_in: localData.expire_in,
//       refresh_token: localData.refresh_token,
//       refresh_token_expire_in: localData.refresh_token_expire_in,
//       account: localData.account,
//       account_name: localData.account_name,
//       systemid: localData.systemid,
//     })
//       .then((res) => {
//         console.log(res);
//       })
//       .catch((error) => {
//         console.log("Login Error:", error);
//       })
//       .finally(() => {
//         setIsPopupOpen(false);
//         setPopupChildren("");
//         normalizePath(location, navigate);
//       });
//   };

//   useEffect(() => {
//     const params = new URLSearchParams(window.location.search);
//     const code = params.get("code");
//     const site = localStorage.getItem("socialSite") || undefined;
//     if (site == "linkedIn") {
//       handleLinkedInCallback(params);
//       localStorage.removeItem("socialSite");
//     } else {
//       handleFacebookCallback(code);
//     }
//   }, []);

//   // Video tutorial URLs mapping
//   const videoUrls = {
//     "Code Section Video": "https://www.youtube.com/embed/RvPWNQmNurg?rel=0",
//     "Meta Video Tutorial":
//       "https://www.youtube.com/embed/sUEgGsCCQ-g?si=SaXZHyKncEE3cNYf?rel=0",
//     "Google Video Tutorial":
//       "https://www.youtube.com/embed/ror0KKwhISw?si=LVhuWRTmyvEPVgGX?rel=0",
//     "Razorpay Video Tutorial":
//       "https://www.youtube.com/embed/YJN0FsqIMd8?si=OWuZQWbybd55ahmn?rel=0",
//     /*"Cashfree Video Tutorial":
//       "https://www.youtube.com/embed/YJN0FsqIMd8?si=OWuZQWbybd55ahmn?rel=0",*/
//     "google-utm":
//       "https://www.youtube.com/embed/DAhVIbWNXlE?si=RtEqQWHBo8pAtrPw?rel=0",
//     "meta-utm":
//       "https://www.youtube.com/embed/3uWtNQ1CYcQ?si=YM-zqbTMjlAHJMxt?rel=0",
//     // Add more mappings as needed
//   };

//   const currentVideoUrl = videoUrls[popupChildren]; // Current video URL based on popup content

//   // Component mapping for dynamic rendering
//   const componentMap = {
//     "Razorpay Integration": Razorpay,
//     "Cashfree Integration": Cashfree,
//     "Shopify Integration": Shopify,
//     "WooCommerce Integration": WooCommerce,
//     "Google Ads": GoogleAds,
//     "Meta Ads": MetaAds,
//     "Pabbly Integration": Pabbly,
//     Linkedin: LinkedInAds,
//   };

//   const componentProps = {
//     "Razorpay Integration": {
//       setIsPopupOpen,
//       setPopupChildren,
//       signatureKey,
//       setInte,
//     },
//     "Cashfree Integration": {
//       setIsPopupOpen,
//       setPopupChildren,
//       signatureKey,
//       setInte,
//     },
//     "Shopify Integration": { setIsPopupOpen, setPopupChildren, setInte },
//     "WooCommerce Integration": {
//       setIsPopupOpen,
//       setPopupChildren,
//       signatureKey,
//       setInte,
//     },
//     "Google Ads": {
//       setIsPopupOpen,
//       setPopupChildren,
//       googleCustomers,
//       googleRefreshToken,
//       setGadsaccnt,
//       setInte,
//     },
//     "Meta Ads": {
//       setIsPopupOpen,
//       setPopupChildren,
//       metaCustomers,
//       metaAccessToken,
//       setMadsaccnt,
//       setInte,
//     },
//     Linkedin: {
//       setIsPopupOpen,
//       setPopupChildren,
//       linkedInCustomers,
//       linkedInAccessToken,
//       setLadsaccnt,
//       setInte,
//       expire_in,
//       refresh_token,
//       refresh_token_expire_in,
//     },
//     "Pabbly Integration": { setIsPopupOpen, setPopupChildren, setInte },
//   };

//   const CurrentComponent = componentMap[popupChildren];
//   const props = componentProps[popupChildren];

//   return (
//     <>
//       <div className="integration-container">
//         <div
//           className="integration-header-section"
//           style={{ paddingRight: "60px" }}
//         >
//           <div className="client-heading">
//             <span className="integration-header-nav-section">
//               <button
//                 className={`integration-header-nav-button ${
//                   selectedTab === "Apps" ? "active-integration-tab" : ""
//                 }`}
//                 onClick={() => handleTabClick("Apps")}
//               >
//                 Apps
//               </button>
//               <button disabled className="button-separator"></button>
//               <button
//                 className={`integration-header-nav-button integration-header-nav-button-utm ${
//                   selectedTab === "Code" ? "active-integration-tab" : ""
//                 }`}
//                 onClick={() => handleTabClick("Code")}
//               >
//                 Code
//               </button>
//             </span>
//             <DropDownComp />
//           </div>
//           {/* <ProfileDropDown /> */}
//         </div>
//         <div
//           className="integration-body-section"
//           style={{ overflowY: "scroll" }}
//         >
//           {selectedTab === "Apps" && !gadsaccnt && !madsaccnt && !ladsaccnt && (
//             <AppsTab
//               popupHandler={popupHandler}
//               connectGoogleAdAccounts={connectGoogleAdAccounts}
//               connectMetaAdAccounts={connectMetaAdAccounts}
//               connectLinkedInAdAccounts={connectLinkedInAdAccounts}
//               inte={inte}
//             />
//           )}
//           {selectedTab === "Apps" && gadsaccnt && !madsaccnt && !ladsaccnt && (
//             <GoogleAdsFetched
//               setGadsaccnt={setGadsaccnt}
//               googleAdAccounts={googleAdAccounts}
//               listAccessibleCustomers={listAccessibleCustomers}
//               popupHandler={popupHandler}
//             />
//           )}
//           {selectedTab === "Apps" && ladsaccnt && !madsaccnt && !gadsaccnt && (
//             <LinkedInFetched
//               setLadsaccnt={setLadsaccnt}
//               linkedInAccounts={linkedInAccounts}
//               listAccessibleCustomers={listAccessibleCustomers}
//               popupHandler={popupHandler}
//             />
//           )}
//           {selectedTab === "Apps" && !gadsaccnt && !ladsaccnt && madsaccnt && (
//             <MetaAdsFetched
//               setMadsaccnt={setMadsaccnt}
//               metaAccounts={metaAccounts}
//               connectMetaAdAccounts={connectMetaAdAccounts}
//               popupHandler={popupHandler}
//             />
//           )}
//           {selectedTab === "Code" && <CodeTab popupHandler={popupHandler} />}
//         </div>
//       </div>
//       <Popup isOpen={isPopupOpen} onClose={closePopup}>
//         {CurrentComponent && <CurrentComponent {...props} />}

//         {currentVideoUrl && (
//           <VideoTutorialPopups currentVideoUrl={currentVideoUrl} />
//         )}
//       </Popup>
//     </>
//   );
// };

// export default Integration;
