import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTableData } from "../../services/api/tableDataApis";

const initialState = {
  tableData: null,
  isFetching: false,
  isError: false,
  error: null,
};

export const fetchTableData = createAsyncThunk(
  "tableData/fetchTableData",
  async (values, { rejectWithValue }) => {
    try {
      const response = await getTableData(values);
      if (response.status === 200) {
        return response.data;
      }
      // else throw new Error("Failed to fetch attribute report data");
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.message || "Failed"
      );
    }
  }
);

export const tableDataSlice = createSlice({
  name: "tableData",
  initialState,
  extraReducers: {
    [fetchTableData.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
    },
    [fetchTableData.fulfilled]: (state, { payload }) => {
      console.log(payload);
      state.isFetching = false;
      state.isError = false;
      state.tableData = payload;
      state.error = null;
    },
    [fetchTableData.rejected]: (state, { payload }) => {
      console.log(payload);
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
  },
});

export default tableDataSlice.reducer;
