import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  attribute: "last",
};

const attributeSlice = createSlice({
  name: 'attribute',
  initialState,
  reducers: {
    setAttribute(state, action) {
      state.attribute = action.payload;
    }
  },
});

export const { setAttribute } = attributeSlice.actions;

export default attributeSlice.reducer;
