import React, { useRef } from "react";
import { FiCopy } from "react-icons/fi"; // Importing copy icon
import { useSelector } from "react-redux"; // Redux selector for state management
import { toastSetter } from "../../components/ToastContainer"; // Function to show toast notifications
import { shortenURL } from "../../utils/shortenUrl"; // Utility to shorten URLs
import { shoIntegration } from "../../services/api/integrationApis"; // Import shoIntegration function

// API call to set CashFree configurations
import { useFormik } from "formik"; // Formik for form management
import * as Yup from "yup"; // Yup for form validation
import { setCashfree } from "../../services/api/integrationApis";
const Cashfree = ({ signatureKey, setIsPopupOpen, setPopupChildren }) => {
  // Retrieve workspace ID and access token from local storage
  const workSpaceId = localStorage.getItem("t_workSpaceId");
  const accessToken = localStorage.getItem("t_accessToken");
  const textRef = useRef(null); // Reference for the URL text
  const fullURL = `https://backend.trackocity.io/clientpayment/${workSpaceId}/cashfreewebhook`; // Full URL for webhook
  // Function to copy the webhook URL to clipboard
  const copyText = () => {
    const tempTextArea = document.createElement("textarea"); // Create a temporary textarea
    tempTextArea.value = fullURL; // Set the value to the full URL
    document.body.appendChild(tempTextArea); // Append it to the body
    tempTextArea.select(); // Select the text
    document.execCommand("copy"); // Copy the text to clipboard
    document.body.removeChild(tempTextArea); // Remove the temporary textarea
    toastSetter("success", "Copied to clipboard"); // Show success message
  };
  // Validation schema for the form fields
  const validationSchema = Yup.object().shape({
    // cashFreeApiSecret: Yup.string().required("Field is required"), // Required field
    // cashFreeApiKey: Yup.string().required("Field is required"), // Required field
    // cashFreeClientSecret: Yup.string().required("Field is required"), // Required field
    // cashFreeSignature: Yup.string().required("Field is required"), // Required field
  });
  // Formik setup for form management
  const formik = useFormik({
    initialValues: {
      // cashFreeApiSecret: "", // Initial value for API Secret
      // cashFreeApiKey: "", // Initial value for API Key
      // cashFreeClientSecret: "", // Initial value for Client Secret
      // cashFreeSignature: signatureKey, // Signature key passed as prop
    },
    validationSchema, // Use the defined validation schema
    onSubmit: (values, { setSubmitting }) => {
      console.log("Hello");
      setSubmitting(true); // Set submitting state to true

      // Submit the Cashfree API details
      setCashfree({})
        .then((res) => {
          console.log("Cashfree configuration submitted:", res);

          // Show success toast after Cashfree setup is successful

          // After successful Cashfree setup, fetch the updated integration status
          shoIntegration() // Re-fetch the integration status
            .then((integrationStatus) => {
              // Update localStorage based on the integration status
              const cashfreeConnected = integrationStatus.cashfree || false;
              localStorage.setItem(
                "Cashfree_connected",
                cashfreeConnected ? "true" : "false"
              );

              // Optionally, you can also update other app connections if needed
              console.log("Updated Integration Status:", integrationStatus);

              // Show success toast for integration status update
            })
            .catch((error) => {
              console.error("Error fetching integration status:", error);
              toastSetter("error", "Error fetching integration status");
            });
        })
        .catch((error) => {
          console.error("Error setting Cashfree configuration:", error);
          toastSetter("error", "Error submitting Cashfree configuration");
        })
        .finally(() => {
          setSubmitting(false); // Reset submitting state
          setIsPopupOpen(false); // Close the popup
          setPopupChildren(""); // Clear popup content
        });
    },
  });
  return (
    <div className="integration-popup-container">
      <h2 className="integration-popup-heading">Cashfree</h2>
      <form
        className="integration-popup-form"
        onSubmit={formik.handleSubmit} // Uncomment to enable form submission
      >
        {/* API Secret Input */}
        {/* <span style={{ width: "100%" }}>
          <label className="integration-popup-form-label">API Secret</label>
          <input
            id="cashFreeApiSecret"
            name="cashFreeApiSecret"
            type="text"
            className="integration-popup-input"
            value={formik.values.cashFreeApiSecret} // Controlled input
            onChange={formik.handleChange} // Handle change event
            onBlur={formik.handleBlur} // Handle blur event
          />
        </span> */}
        {/* API Key Input */}
        {/* <span style={{ width: "100%" }}>
          <label className="integration-popup-form-label">API Key</label>
          <input
            id="cashFreeApiKey"
            name="cashFreeApiKey"
            type="text"
            className="integration-popup-input"
            value={formik.values.cashFreeApiKey} // Controlled input
            onChange={formik.handleChange} // Handle change event
            onBlur={formik.handleBlur} // Handle blur event
          />
        </span> */}
        {/* Client Secret Input */}
        {/* <span style={{ width: "100%" }}>
          <label className="integration-popup-form-label">Client Secret</label>
          <input
            id="cashFreeClientSecret"
            name="cashFreeClientSecret"
            type="text"
            className="integration-popup-input"
            value={formik.values.cashFreeClientSecret} // Controlled input
            onChange={formik.handleChange} // Handle change event
            onBlur={formik.handleBlur} // Handle blur event
          />
        </span> */}
        {/* Webhook URL Display with Copy Button */}
        <span style={{ width: "100%" }}>
          <label className="integration-popup-form-label">Webhook URL</label>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative",
            }}
            className="integration-popup-input"
          >
            <p ref={textRef} className="text-dec-none webhook-link">
              {shortenURL(fullURL, 60)} {/* Shortened URL for display */}
            </p>
            <button
              type="button"
              className="copy-button"
              style={{ top: "0", left: "0" }}
              onClick={copyText} // Trigger copy function on click
              onMouseEnter={() => {
                textRef.current.style.textDecoration = "underline"; // Underline on hover
                textRef.current.setAttribute("data-tooltip", "Click to copy"); // Show tooltip
              }}
              onMouseLeave={() => {
                textRef.current.style.textDecoration = "none"; // Remove underline
                textRef.current.removeAttribute("data-tooltip"); // Hide tooltip
              }}
            >
              <span className="tooltip-text">Click to copy</span>
              <FiCopy scale={0.8} size={22} /> {/* Copy icon */}
            </button>
          </div>
        </span>
        {/* Signature Key Input */}
        {/* <span style={{ width: "100%" }}>
          <label className="integration-popup-form-label">Signature Key</label>
          <input
            id="cashFreeSignature"
            name="cashFreeSignature"
            type="text"
            className="integration-popup-input"
            value={formik.values.cashFreeSignature} // Controlled input
            readOnly // Make this input read-only
          />
        </span> */}
      </form>
      {/* Submit Button */}
      <button
        type="button"
        className="integration-popup-submit"
        disabled={formik.isSubmitting} // Disable button if form is submitting
        onClick={formik.handleSubmit} // Trigger Formik's submit handler
      >
        Submit
      </button>
    </div>
  );
};
export default Cashfree;
