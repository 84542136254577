import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";

const EmailVerify = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      
      navigate("/sign-in");
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="email-verify-container">
      <span className="email-verify-span">
        <h2 className="email-verify-heading">Email Verification:</h2>
        <p className="email-verify-para">
          Your email has been successfully verified.
        </p>
      </span>
      <span className="email-verify-span">
        <h2 className="email-verify-heading">Onboarding:</h2>
        <p className="email-verify-para">
          {/* For a smooth experience, we are redirecting you to book an onboarding
          call with us. */}
          For a smooth experience, we are redirecting you to Sign-in Page.
        </p>
      </span>
    </div>
  );
};

export default EmailVerify;
