import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getPabblyAuthorization,
  setPabbly,
} from "../../services/api/integrationApis";
import { shoIntegration } from "../../services/api/integrationApis";
import { toastSetter } from "../../components/ToastContainer";

const Pabbly = ({ setIsPopupOpen, setPopupChildren }) => {
  // Retrieve workspace ID from local storage
  const workSpaceId = localStorage.getItem("t_workSpaceId");

  // Define validation schema (currently empty)
  const validationSchema = Yup.object().shape({});

  // State to hold the authorization key
  const [authorization, setAuthorization] = useState("");

  // Formik setup for form management
  const formik = useFormik({
    initialValues: {
      endpoint: `https://backend.trackocity.io/clientchannel/${workSpaceId}/pabblyorderendpoint`, // Set the endpoint URL
      authorization: authorization, // Set the authorization key
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true); // Set submitting state to true

      setPabbly({})
        .then((res) => {
          console.log("Pabbly configuration submitted:", res);

          // After successful Pabbly setup, call getIntegrationStatus to fetch updated connection status
          shoIntegration() // Fetch updated integration status
            .then((integrationStatus) => {
              // Update localStorage based on the integration status
              const pabblyConnected = integrationStatus.pabbly;
              localStorage.setItem("Pabbly_connected", pabblyConnected);

              // Optionally, you can also update other integration statuses if needed
              console.log("Updated Integration Status:", integrationStatus);

              // Show success toast
            })
            .catch((error) => {
              console.error("Error fetching integration status:", error);
              toastSetter("error", "Error updating integration status");
            });
        })
        .catch((error) => {
          console.error("Error submitting Pabbly configuration:", error);
          toastSetter("error", "Error submitting Pabbly configuration");
        })
        .finally(() => {
          setSubmitting(false); // Reset submitting state
          setIsPopupOpen(false); // Close the popup
          setPopupChildren(""); // Clear popup content
        });
    },
  });

  // Fetch the Pabbly authorization key when the component mounts
  useEffect(() => {
    getPabblyAuthorization()
      .then((res) => {
        setAuthorization(res.key); // Set the authorization key from response
        formik.setValues({
          ...formik.values,
          authorization: res.key, // Update Formik values with the new key
        });
      })
      .catch((error) => {
        console.log("Authorization Error:", error); // Log error if any
      });
  }, [setAuthorization]); // Dependency array includes formik and setAuthorization

  return (
    <div className="integration-popup-container">
      <h2 className="integration-popup-heading">Pabbly</h2>
      <form className="integration-popup-form">
        <span style={{ width: "100%" }}>
          <label className="integration-popup-form-label">Endpoint</label>
          <input
            id="endpoint"
            name="endpoint"
            type="text"
            className="integration-popup-input"
            value={formik.values.endpoint} // Set the endpoint input value
            readOnly // Make the endpoint input read-only
          />
        </span>
        <span style={{ width: "100%" }}>
          <label className="integration-popup-form-label">Authorization</label>
          <input
            id="authorization"
            name="authorization"
            type="text"
            className="integration-popup-input"
            value={formik.values.authorization} // Set the authorization input value
            readOnly // Make the authorization input read-only
          />
        </span>
      </form>
      <button
        type="button"
        className="integration-popup-submit"
        disabled={formik.isSubmitting} // Disable the button if submitting
        onClick={formik.handleSubmit} // Trigger Formik's submit handler
      >
        Submit
      </button>
    </div>
  );
};

export default Pabbly;
