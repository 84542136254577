import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  numbers: [],
};

const buttonSlice = createSlice({
  name: "buttons",
  initialState,
  reducers: {
    toggleNumber: (state, action) => {
      const number = action.payload;
      if (!state.numbers) {
        state.numbers = [];
      }

      if (state.numbers.includes(number)) {
        state.numbers = state.numbers.filter((num) => num !== number);
      } else {
        state.numbers.push(number);
      }
    },
  },
});

export const { toggleNumber } = buttonSlice.actions;

export default buttonSlice.reducer;
