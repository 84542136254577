import { toastSetter } from "../../components/ToastContainer";
import catchAsync from "../../utils/catchAsync";
import http from "./http";

export const getTableData = catchAsync(async (values) => {

  try {
    const { startdate, enddate, attribute, trafficSource } = values;

    const response = await http.get(`/reporting/table`, {
      params: { startdate, enddate, attribute, traffic: trafficSource },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    throw error;
  }
});
export const getTableCompareData = catchAsync(async (values) => {

  try {
    const { startdate, enddate, attribute, trafficSource } = values;


    const response = await http.get(`/reporting/table`, {
      params: { startdate, enddate, attribute, traffic: trafficSource },
    });


    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    throw error;
  }
});

export const getTableSalesData = catchAsync(async (values) => {
  try {
    const { startdate, enddate, adid, channel } = values;

    const response = await http.get(`/reporting/tablesaledata`, {
      params: { startdate, enddate, adid, channel },
    });

    if (response.status === 200) {
      return response; // Return response data
    }
  } catch (error) {
    throw error;
  }
});

export const getTrafficSource = catchAsync(async () => {
  try {
    const response = await http.get(`/reporting/source`);

    if (response.status === 200) {
      return response; // Return response data
    }
  } catch (error) {
    throw error;
  }
});
