import React, { useState } from "react";
import { MdClear } from "react-icons/md";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { setUserdata } from "../../redux/reducers/userJourneySlice";

const PopUpTwo = ({ isOpen, onClose, children }) => {
  // const [display, setDisplay] = useState(null);
  const data = useSelector((state) => state.userJourney);
  console.log("userjourney", data);
  console.log(isOpen);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setUserdata(""));
    // setDisplay("none");
    onClose();
  };
  // const handleTwoPopUp = () => {
  //   // dispatch(setUserdata(""));
  //   // onClose();
  // };
  return (
    <>
      {isOpen && ( // Use && for conditional rendering
        <div
          className="popup-overlaytwo"
          // style={{ display: display }}
          // onClick={handleTwoPopUp}
        >
          <div
            className="popup-contenttwo"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="close-buttontwo" onClick={handleClose}>
              <MdClear size={18} />
            </button>
            {/* Render children or other content here */}
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default PopUpTwo;
