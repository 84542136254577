// import React, { useState, useEffect } from "react";
// import "./style.css";
// import { useDispatch, useSelector } from "react-redux";
// import { RiDeleteBin6Line } from "react-icons/ri";
// import { FaCirclePlus } from "react-icons/fa6";
// import { FiEdit } from "react-icons/fi";
// import {
//   fetchUTMSources,
//   createNewUTMSource,
//   deleteExistingUTMSource,
//   updateExistingUTMSource,
// } from "../../redux/reducers/utmSourceSlice";
// import UTMForm from "./utmForm";
// import { CreativeTableLoader } from "../../components/loadingComponent/SkeletonLoader";
// import toast from "react-hot-toast";

// const TagDetails = () => {
//   const { currentClient } = useSelector((state) => state.agency);

//   const dispatch = useDispatch();
//   useEffect(() => {
//     dispatch(fetchUTMSources());
//   }, [dispatch, currentClient]);
//   const {
//     utmSources: products,
//     loading,
//     error,
//   } = useSelector((state) => state.utmSources);
//   const [activeTab, setActiveTab] = useState("Database");
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isEditing, setIsEditing] = useState(false); // State to track if editing
//   const [currentProduct, setCurrentProduct] = useState({
//     id: null,
//     displayname: "",
//     utm_field: "",
//     value: "",
//     utm_subfield: "",
//   });

//   useEffect(() => {
//     dispatch(fetchUTMSources());
//   }, [dispatch]);

//   const handleTabClick = (tabName) => setActiveTab(tabName);

//   const handleOpenModal = (product = null) => {
//     if (product) {
//       setIsEditing(true);
//       setCurrentProduct(product);
//     } else {
//       setIsEditing(false);
//       setCurrentProduct({
//         id: null,
//         displayname: "",
//         utm_field: "",
//         value: "",
//         utm_subfield: "",
//       });
//     }
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//     setCurrentProduct({
//       id: null,
//       displayname: "",
//       utm_field: "",
//       value: "",
//       utm_subfield: "",
//     });
//     setIsEditing(false);
//   };

//   const handleOpenEdit = (prod) => {
//     const productToEdit = products.find((item) => item.id === prod);
//     handleOpenModal(productToEdit);
//   };

//   const handleAddOrUpdateProduct = (values) => {
//     if (isEditing) {
//       // Include `id` for updates
//       dispatch(
//         updateExistingUTMSource({ id: currentProduct.id, ...values })
//       ).then(() => {
//
//         dispatch(fetchUTMSources());
//         handleCloseModal();
//       });
//     } else {
//       dispatch(createNewUTMSource(values)).then(() => {
//
//         dispatch(fetchUTMSources());
//         handleCloseModal();
//       });
//     }
//   };

//   const handleDelete = (id) => {
//     dispatch(deleteExistingUTMSource(id)).then(() => {
//
//       dispatch(fetchUTMSources());
//     });
//   };

//   return (
//     <div className="tag-container" style={{ marginBottom: "-105px" }}>
//       <div className="setting-profile-main">
//         <div className="testing-comp" style={{ marginLeft: "-50px" }}>
//           <div className="button-tag-database">
//             <div
//               className={`tab-button ${
//                 activeTab === "Database" ? "active" : ""
//               }`}
//               style={{ background: "#242628" }}
//               onClick={() => handleTabClick("Database")}
//             >
//               URL
//             </div>
//           </div>

//           <div className="tag-profile-container">
//             {activeTab === "Database" && (
//               <>
//                 <div className="source-item-boxs">
//                   <div
//                     className="item-box-mini"
//                     style={{
//                       color: "#d5d900",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     <div className="item-box-mini-text">
//                       <p className="mini-text" style={{ padding: "10px" }}>
//                         Display Name
//                       </p>
//                       <p className="mini-text" style={{ padding: "10px" }}>
//                         UTM Field
//                       </p>
//                       <p className="mini-text" style={{ padding: "10px" }}>
//                         Value
//                       </p>
//                       <p className="mini-text" style={{ padding: "10px" }}>
//                         UTM Sub-field
//                       </p>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="item-box">
//                   {loading ? (
//                     <CreativeTableLoader />
//                   ) : error ? (
//                     <p>Error: {error}</p>
//                   ) : products && products.length > 0 ? (
//                     products.map((product) => (
//                       <div className="item-box-mini" key={product.id}>
//                         <div
//                           className="item-box-mini-text"
//                           style={{ borderRadius: "5px" }}
//                         >
//                           <p className="mini-text" style={{ padding: "10px" }}>
//                             {product.displayname}
//                           </p>
//                           <p className="mini-text" style={{ padding: "10px" }}>
//                             {product.utm_field}
//                           </p>
//                           <p className="mini-text" style={{ padding: "10px" }}>
//                             {product.value}
//                           </p>
//                           <p className="mini-text" style={{ padding: "10px" }}>
//                             {product.utm_subfield}
//                           </p>
//                         </div>
//                         <button
//                           className="profile-btn"
//                           onClick={() => handleOpenEdit(product.id)}
//                         >
//                           <FiEdit size={24} color="white" />
//                         </button>
//                         <button
//                           className="profile-btn"
//                           onClick={() => handleDelete(product.id)}
//                         >
//                           <RiDeleteBin6Line size={24} color="white" />
//                         </button>
//                       </div>
//                     ))
//                   ) : (
//                     <p>No UTM sources available</p>
//                   )}
//                 </div>

//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "center",
//                     marginBottom: "-15px",
//                   }}
//                 >
//                   <button
//                     onClick={() => handleOpenModal()}
//                     className="client-add-btn"
//                   >
//                     <img
//                       alt="client-add"
//                       className="client-add"
//                       src="clientadd.png"
//                     />
//                   </button>
//                 </div>
//               </>
//             )}
//           </div>
//         </div>
//       </div>

//       {isModalOpen && (
//         <div className="modal">
//           <div className="modal-content">
//             <UTMForm
//               product={isEditing ? currentProduct : null}
//               initialValues={currentProduct}
//               onSubmit={handleAddOrUpdateProduct}
//               onCancel={handleCloseModal}
//             />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default TagDetails;
import React, { useState, useEffect } from "react";
// import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaCirclePlus } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";
import {
  fetchUTMSources,
  createNewUTMSource,
  deleteExistingUTMSource,
  updateExistingUTMSource,
} from "../../redux/reducers/utmSourceSlice";
import UTMForm from "./utmForm";
import {
  CreativeTableLoader,
  SettingsTableLoaderTag,
} from "../../components/loadingComponent/SkeletonLoader";
import toast from "react-hot-toast";

const TagDetails = () => {
  const { currentClient } = useSelector((state) => state.agency);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUTMSources());
  }, [dispatch, currentClient]);
  const {
    utmSources: products,
    loading,
    error,
  } = useSelector((state) => state.utmSources);
  const [activeTab, setActiveTab] = useState("Database");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // State to track if editing
  const [currentProduct, setCurrentProduct] = useState({
    id: null,
    displayname: "",
    utm_field: "",
    value: "",
    utm_subfield: "",
  });
  const [hoveredEditButtonId, setHoveredEditButtonId] = useState(null); // To track hovered Edit button
  const [clickedEditButtonId, setClickedEditButtonId] = useState(null); // To track clicked Edit button

  useEffect(() => {
    dispatch(fetchUTMSources());
  }, [dispatch]);

  const handleTabClick = (tabName) => setActiveTab(tabName);

  const handleOpenModal = (product = null) => {
    if (product) {
      setIsEditing(true);
      setCurrentProduct(product);
    } else {
      setIsEditing(false);
      setCurrentProduct({
        id: null,
        displayname: "",
        utm_field: "",
        value: "",
        utm_subfield: "",
      });
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentProduct({
      id: null,
      displayname: "",
      utm_field: "",
      value: "",
      utm_subfield: "",
    });
    setIsEditing(false);
    setClickedEditButtonId(null);
  };

  const handleOpenEdit = (prod) => {
    const productToEdit = products.find((item) => item.id === prod);
    handleOpenModal(productToEdit);
  };

  const handleAddOrUpdateProduct = (values) => {
    if (isEditing) {
      // Include `id` for updates
      dispatch(
        updateExistingUTMSource({ id: currentProduct.id, ...values })
      ).then(() => {
        toast.success("UTM updated successfully");
        dispatch(fetchUTMSources());
        handleCloseModal();
      });
    } else {
      dispatch(createNewUTMSource(values)).then(() => {
        toast.success("UTM created successfully");
        dispatch(fetchUTMSources());
        handleCloseModal();
      });
    }
  };

  const handleDelete = (id) => {
    dispatch(deleteExistingUTMSource(id)).then(() => {
      toast.success("UTM deleted successfully");
      dispatch(fetchUTMSources());
    });
  };

  return (
    <div className="main-container-settings">
      {/* <div className="testing-comp" style={{ marginLeft: "-50px" }}> */}
      {/* <div className="skeleton"> */}
      {/* <divskjkfnjsdfjk className="testing-comp"> */}
      <div className="button-tag-database">
        <div
          className={`tab-button ${activeTab === "Database" ? "active" : ""}`}
          style={{ background: "#242628" }}
          onClick={() => handleTabClick("Database")}
        >
          URL
        </div>
      </div>

      {activeTab === "Database" && (
        <>
          <div className="item-box-minis-settings">
            <div
              className="item-box-mini-settings"
              style={{
                color: "#d5d900",
                fontWeight: "bold",
              }}
            >
              <div className="item-box-mini-text-settings">
                <div className="new-mini">
                  <p className="mini-text" style={{ padding: "10px" }}>
                    Display Name
                  </p>
                  <p className="mini-text" style={{ padding: "10px" }}>
                    UTM Field
                  </p>
                  <p className="mini-text" style={{ padding: "10px" }}>
                    Value
                  </p>
                  <p className="mini-text" style={{ padding: "10px" }}>
                    UTM Sub-field
                  </p>
                </div>
                <div className="hide"></div>
                <div className="hide"></div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="item-box-settings-tab">
        {loading ? (
          <SettingsTableLoaderTag />
        ) : error ? (
          <p>Error: {error}</p>
        ) : products && products.length > 0 ? (
          products.map((product) => (
            <div className="item-box-mini" key={product.id}>
              <div
                className="item-box-mini-text"
                style={{ borderRadius: "5px" }}
              >
                <p className="mini-text" style={{ padding: "10px" }}>
                  {product.displayname}
                </p>
                <p className="mini-text" style={{ padding: "10px" }}>
                  {product.utm_field}
                </p>
                <p className="mini-text" style={{ padding: "10px" }}>
                  {product.value}
                </p>
                <p className="mini-text" style={{ padding: "10px" }}>
                  {product.utm_subfield}
                </p>
              </div>
              <button
                className="profile-btn-edit"
                onMouseEnter={() => setHoveredEditButtonId(product.id)} // Set hovered product ID
                onMouseLeave={() => setHoveredEditButtonId(null)} // Reset on mouse leave
                onClick={() => {
                  handleOpenModal(product); // Open the modal when clicked
                  setClickedEditButtonId(product.id); // Mark as clicked
                }}
              >
                <FiEdit
                  size={20}
                  color={
                    clickedEditButtonId === product.id ||
                    hoveredEditButtonId === product.id
                      ? "yellow"
                      : "white"
                  } // Change color based on hover or click
                />
              </button>

              <button
                className="profile-btn-delete"
                onClick={() => handleDelete(product.id)}
              >
                <RiDeleteBin6Line size={20} color="white" />
              </button>
            </div>
          ))
        ) : (
          <p>No UTM sources available</p>
        )}
      </div>
      <div className="button-selection">
        <button
          onClick={() => handleOpenModal()}
          className="client-add-btn-tag"
        >
          <img
            alt="client-add"
            className="client-add-settings"
            src="clientadd.png"
          />
        </button>
      </div>
      {/* </divskjkfnjsdfjk> */}

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <UTMForm
              product={isEditing ? currentProduct : null}
              initialValues={currentProduct}
              onSubmit={handleAddOrUpdateProduct}
              onCancel={handleCloseModal}
            />
          </div>
        </div>
      )}
      {/* </div> */}
    </div>
  );
};

export default TagDetails;
