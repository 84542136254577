import React from "react";
import { FcGoogle } from "react-icons/fc";
import { fbLogo } from "../../assets";

const TrafficSourceCell = ({ row }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "2px",
        width: "max-content",
      }}
    >
      <span style={{ marginLeft: "11px" }}>{row.src}</span>
      <p style={{ marginBottom: "4px" }}>{row.trafficSource}</p>
    </div>
  );
};

export default TrafficSourceCell;
