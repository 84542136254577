import catchAsync from "../../utils/catchAsync";
import http from "./http";

export const getBreakDownTableData = catchAsync(async (values) => {
  const { startdate, enddate, creativeId } = values;

  try {

    const response = await http.get(`/creative/adtable/facebook`, {
      params: { startdate, enddate, creativeId },
    });

    if (response.status === 200) {
      return response;
    } else {
      throw new Error(`Failed to fetch trackid, status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching trackid:", error);
    throw error;
  }
});
