import React, { useEffect, useRef, useState } from "react";
// import debounce from "lodash/debounce"; // Import debounce function from lodash
import { searchLogo } from "../../assets";
import { MdClear } from "react-icons/md";
import { useSelector } from "react-redux";
import useFilterData from "../../hooks/useFilterData";

const SearchFilterInput = ({
  filterText,
  setFilterText,
  setFilteredData,
  tableData,
  setSelectedAdList,
}) => {
  console.log(filterText);
  const inputRef = useRef(null); // Create a ref for the input field
  const [showSuggestions, setShowSuggestions] = useState(false); // State to control suggestion container visibility
  const { tableCompareData } = useSelector((state) => state.compareTable);
  console.log(tableCompareData);

  const debouncedHandleFilterTextChange = (value) => {
    setFilterText(value.trim());
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    debouncedHandleFilterTextChange(value);
    setShowSuggestions(true);
  };

  useEffect(() => {
    inputRef.current.focus();
  }, [filterText]);

  // Update filtered data when filterText changes
  useEffect(() => {
    if (filterText.trim() === "") {
      setFilteredData([tableData, tableCompareData]);
      setSelectedAdList("");
      return;
    }
  }, [filterText.trim(), tableData, tableCompareData]);

  const { filterData } = useFilterData();

  // Handler for clicking on a suggestion
  const handleSuggestionClick = (filterType) => {
    filterText.trim() !== "" && setSelectedAdList(filterType);
    filterText.trim() !== "" &&
      setFilteredData([
        filterData(filterType, filterText.trim()),
        tableCompareData,
      ]);
    // filterText.trim() !== "" && setFilteredData([filterData(filterType), tableCompareData]);
    setShowSuggestions(false); // Hide suggestions when a suggestion is clicked
    inputRef.current.blur(); // Programmatically blur the input field
  };

  const clearSearch = () => {
    setFilterText("");
    setFilteredData([tableData, tableCompareData]);
    setSelectedAdList("");
    setShowSuggestions(false); // Hide suggestions when a suggestion is clicked
    inputRef.current.blur(); // Programmatically blur the input field
  };

  return (
    <>
      <div className="search-button-container">
        <img src={searchLogo} alt="search" />
        <input
          ref={inputRef} // Assign the ref to the input field
          className="search-button"
          placeholder="Search"
          value={filterText}
          onChange={handleInputChange} // Use the debounced handler for input change
          onFocus={() => setShowSuggestions(true)}
          onBlur={() => setShowSuggestions(false)}
        />
        {/* {showSuggestions && filterText.trim() !== "" && ( */}
        {filterText.trim() !== "" && (
          <MdClear
            size={16}
            color="#707172"
            scale={0.8}
            onClick={clearSearch}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
      {showSuggestions && filterText.trim() !== "" && (
        <div
          className="search-suggest-container"
          onMouseDown={(e) => e.preventDefault()} // Prevent blur on suggestion click
        >
          <ul className="search-suggest-list">
            <li
              style={{ padding: "5px 0", borderBottom: "0.979px solid #fff" }}
              onMouseUp={() => handleSuggestionClick("campaign")} // Use onMouseUp instead of onClick
            >
              <span className="search-suggest-item">
                <p>Campaign Name</p> <h5>contains :</h5>
                <div className="search-input-filter-text-container">
                  {filterText.trim()}
                </div>
              </span>
            </li>
            <li
              style={{ padding: "5px 0", borderBottom: "0.979px solid #fff" }}
              onMouseUp={() => handleSuggestionClick("ad_set")} // Use onMouseUp instead of onClick
            >
              <span className="search-suggest-item">
                <p>AdSet Name </p> <h5>contains :</h5>{" "}
                <div className="search-input-filter-text-container">
                  {filterText.trim()}
                </div>
              </span>
            </li>
            <li
              style={{ padding: "5px 0" }}
              onMouseUp={() => handleSuggestionClick("ad")} // Use onMouseUp instead of onClick
            >
              <span className="search-suggest-item">
                <p>Ad Name </p> <h5>contains :</h5>{" "}
                <div className="search-input-filter-text-container">
                  {filterText.trim()}
                </div>
              </span>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default SearchFilterInput;
