import { toastSetter } from "../../components/ToastContainer";
import catchAsync from "../../utils/catchAsync";
import http from "./http";

export const setRazorpay = catchAsync(async (values) => {
  try {
    const response = await http.post(
      `/clientpayment/razorpaycredentials`,
      values
    );

    if (response.status === 200) {
      const { data } = response;
      const successMessage = response
        ? data.message
        : "Razorpay Credentials Updated Successfully";
      toastSetter("success", successMessage);
    }
    return response;
  } catch (error) {
    const errorMessage = error
      ? error.response?.data?.message
      : "Razorpay Credentials Update Failed";
    toastSetter("error", errorMessage);
    throw error;
  }
});
export const setCashfree = catchAsync(async (values) => {
  try {
    const response = await http.post(
      `/clientpayment/cashfreecredentials `,
      values
    );

    if (response.status === 200) {
      const { data } = response;
      const successMessage = response
        ? data.message
        : "CashFree Credentials Updated Successfully";
      toastSetter("success", successMessage);
    }
    return response;
  } catch (error) {
    const errorMessage = error
      ? error.response?.data?.message
      : "CashFree Credentials Update Failed";
    toastSetter("error", errorMessage);
    throw error;
  }
});

// Function to get integration statuses (Shopify, Razorpay, WooCommerce)
export const shoIntegration = catchAsync(async () => {
  try {
    // Make GET request to the API endpoint to fetch integration statuses
    const response = await http.get(`/integration/integration`);

    if (response.status === 200) {
      const { data } = response; // Extract data from response
      return data; // Return the data (shopify, razorpay, woocommerce status)
    }
    return response;
  } catch (error) {
    // Handle error, and optionally show a toast message
    const errorMessage = error
      ? error.response?.data?.message
      : "Failed to fetch integration status";
    toastSetter("error", errorMessage); // Optionally display error notification
    throw error;
  }
});

// Delete Google Account
export const deleteGoogleAccount = async (values) => {
  console.log(values);
  const { id } = values;
  try {
    const response = await http.put(
      `/integration/google/deleteaccount?id=${id}`
    );

    if (response.status === 200) {
      const { data } = response;
      const successMessage = response
        ? data?.message
        : "Google Ad Account Removed Successfully";
      toastSetter("success", successMessage);
      return data;
    }
    // throw new Error(data?.message || "Failed to delete Google account");
  } catch (error) {
    const errorMessage = error
      ? error?.response?.data?.message
      : "Failed to delete Google account";
    toastSetter("error", errorMessage);
    throw error;
    // throw new Error(error?.response?.data?.message || "Failed to delete Google account");
  }
};

// Delete Facebook Account
export const deleteFacebookAccount = async (values) => {
  const { id } = values;
  try {
    const response = await http.put(
      `/integration/facebook/deleteaccount?id=${id}`
    );

    if (response.status === 200) {
      const { data } = response;
      const successMessage = response
        ? data?.message
        : "Facebook Account Removed Successfully";
      toastSetter("success", successMessage);
      return response;
    }
    return null;
  } catch (error) {
    const errorMessage = error
      ? error.response?.data?.message
      : "Failed to delete Facebook account";
    toastSetter("error", errorMessage);
    throw error;
  }
};
export const deleteLinkedInAccount = async (values) => {
  const { id } = values;
  try {
    const response = await http.put(
      `/integration/linkedin/deleteaccount?id=${id}`
    );

    if (response.status === 200) {
      const { data } = response;
      const successMessage = response
        ? data?.message
        : "Facebook Account Removed Successfully";
      toastSetter("success", successMessage);
      return response;
    }
    return null;
  } catch (error) {
    const errorMessage = error
      ? error.response?.data?.message
      : "Failed to delete Facebook account";
    toastSetter("error", errorMessage);
    throw error;
  }
};

export const setShopify = catchAsync(async (values) => {
  try {
    const response = await http.post(
      `/clientchannel/shopifyintegration`,
      values
    );
    if (response.status === 200) {
      const { data } = response;
      const successMessage = response
        ? data.message
        : "Shopify Credentials Updated Successfully";
      toastSetter("success", successMessage);
    }
    return response;
  } catch (error) {
    const errorMessage = error
      ? error.response?.data?.message
      : "Shopify Credentials Update Failed";
    toastSetter("error", errorMessage);
    throw error;
  }
});

export const setWooCommerce = catchAsync(async (values) => {
  try {
    const response = await http.post(
      `/clientchannel/woocommerceintegration`,
      values
    );
    if (response.status === 200) {
      const { data } = response;
      const successMessage = response
        ? data.message
        : "WooCommerce Credentials Updated Successfully";
      toastSetter("success", successMessage);
    }
    return response;
  } catch (error) {
    const errorMessage = error
      ? error.response?.data?.message
      : "WooCommerce Credentials Update Failed";
    toastSetter("error", errorMessage);
    throw error;
  }
});

export const setPabbly = catchAsync(async (values) => {
  try {
    const response = await http.post(
      `/clientchannel/pabblycredentials`,
      values
    );
    if (response.status === 200) {
      const { data } = response;
      const successMessage = response ? data.message : "Success";
      toastSetter("success", successMessage);
    }
    return response;
  } catch (error) {
    const errorMessage = error ? error.response?.data?.message : "Failed";
    toastSetter("error", errorMessage);
    throw error;
  }
});

export const setGoogleAds = catchAsync(async (values) => {
  console.log(http);
  const { systemid, customerId, refresh_token } = values;
  try {
    const response = await http.post(
      `/google/clientaccount?customerId=${encodeURIComponent(
        customerId
      )}&refresh_token=${encodeURIComponent(
        refresh_token
      )}&systemid=${systemid}`
    );
    if (response.status === 200) {
      console.log(response, "agcduqwdipqwdb iqw d");
      // const { data } = response;
      // const successMessage = response ? data.message : "Successfull";
      toastSetter("success", `Google Ads Success`);
    } else toastSetter("error", `Google Ads Error`);
    return response;
  } catch (error) {
    // const errorMessage = error ? error.response?.data?.message : "Failed";
    toastSetter("error", `Google Ads Error`);
    throw error;
  }
});

export const setMeta = catchAsync(async (values) => {
  try {
    const response = await http.post(`/facebook/clientcredentials`, values);
    if (response.status === 200) {
      const { data } = response;
      const successMessage =
        response && data.message ? data.message : "Successfull";
      toastSetter("success", successMessage);
    }
    return response;
  } catch (error) {
    const errorMessage =
      error && error.response?.data?.message
        ? error.response?.data?.message
        : "Failed";
    toastSetter("error", errorMessage);
    throw error;
  }
});

export const setMetaPixel = catchAsync(async (values) => {
  try {
    const response = await http.post(`/facebook/clientpixel`, values);
    if (response.status === 200) {
      const { data } = response;
      const successMessage =
        response && data.message ? data.message : "Successfull";
      toastSetter("success", successMessage);
    }
    return response;
  } catch (error) {
    const errorMessage =
      error && error.response?.data?.message
        ? error.response?.data?.message
        : "Failed";
    toastSetter("error", errorMessage);
    throw error;
  }
});

export const getIntegration = catchAsync(async () => {
  try {
    const response = await http.get(`/integration/code`);
    if (response.status === 200) {
      const { data } = response;
      return data;
    }
    return response;
  } catch (error) {
    const errorMessage = error ? error.response?.data?.message : "Failed";
    toastSetter("error", errorMessage);
    throw error;
  }
});

export const getPabblyAuthorization = catchAsync(async () => {
  try {
    const response = await http.get(`/clientchannel/pabblyread`);
    if (response.status === 200) {
      const { data } = response;
      return data;
    }
    return response;
  } catch (error) {
    const errorMessage = error ? error.response?.data?.message : "Failed";
    toastSetter("error", errorMessage);
    throw error;
  }
});

export const getMetaAccounts = catchAsync(async () => {
  try {
    const response = await http.get(`/integration/facebook`);
    if (response.status === 200) {
      // const { data } = response;
      return response;
    }
    return response;
  } catch (error) {
    const errorMessage = error ? error.response?.data?.message : "Failed";
    // toastSetter("error", errorMessage);
    throw error;
  }
});

export const getGoogleAdsAccounts = catchAsync(async () => {
  console.log(http);
  try {
    const response = await http.get(`/integration/google`);
    if (response.status === 200) {
      // const { data } = response;
      return response;
    }
    return response;
  } catch (error) {
    const errorMessage = error ? error.response?.data?.message : "Failed";
    // toastSetter("error", errorMessage);
    throw error;
  }
});
export const getLinkedInAccounts = catchAsync(async () => {
  try {
    const response = await http.get(`/integration/linkedin`);
    if (response.status === 200) {
      // const { data } = response;
      return response;
    }
    return response;
  } catch (error) {
    const errorMessage = error ? error.response?.data?.message : "Failed";
    // toastSetter("error", errorMessage);
    throw error;
  }
});

export const setLinkedIn = catchAsync(async (values) => {
  console.log(values);
  console.log(http);
  console.log("Hello");
  try {
    const response = await http.post(`/linkedinads/createaccount`, values);
    if (response.status === 200) {
      const { data } = response;
      const successMessage =
        response && data.message ? data.message : "Successfull";
      toastSetter("success", successMessage);
    }
    return response;
  } catch (error) {
    console.log(error);
    const errorMessage =
      error && error.response?.data?.message
        ? error.response?.data?.message
        : "Failed";
    toastSetter("error", errorMessage);
    throw error;
  }
});
