// import React, { useEffect, useState } from "react";
// import ApexCharts from "react-apexcharts";
// import plusMetrix from "../../assets/icons/plusMetrix.png";
// import { FaRupeeSign } from "react-icons/fa";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   breakdownToggleMetrix,
//   setDetaultMetrixBreakdown,
// } from "../../redux/reducers/metrixSlice";
// import { fallBackImg } from "../../assets";
// import { CreativeChartLoader } from "../loadingComponent/SkeletonLoader";

// const AdMetricChart = ({ setDummyMetricButton }) => {
//   const dispatch = useDispatch();
//   const { breakdownData, breakdownMainCards, thumbnail } = useSelector(
//     (state) => state.breakdown
//   );
//   const { breakdownNumbers } = useSelector((state) => state.metrix);
//   const [series, setSeries] = useState([]);
//   const [visibleMetrics, setVisibleMetrics] = useState({
//     Spend: false,
//     Revenue: false,
//     Sales: false,
//     CPC: false,
//     Click: false,
//     Impressions: false,
//     CPM: false,
//     CPA: false,
//     "CTR %": false,
//     "CR %": false,
//     ROAS: false,
//   });
//   console.log(visibleMetrics);
//   const [newArray] = useState([
//     "Spend",
//     "Click",
//     "CPM",
//     "CPC",
//     "Impressions",
//     "Sales",
//     "Revenue",
//     "CTR %",
//     "CR %",
//     "CPA",
//     "ROAS",
//   ]);
//   const [openDropdown, setOpenDropdown] = useState(false);
//   const [loading, setLoading] = useState(true); // New loading state

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setLoading(false); // Set loading to false after 2 seconds
//     }, 2000);

//     return () => clearTimeout(timer); // Cleanup e timer
//   }, []);

//   useEffect(() => {
//     // Initialize default metrics if breakdownNumbers is empty
//     if (breakdownNumbers?.length === 0) {
//       dispatch(setDetaultMetrixBreakdown());
//       toggleMetric("Spend");
//       toggleMetric("Sales");
//     } else {
//       breakdownNumbers.forEach((item) => toggleMetric(item));
//     }
//   }, [breakdownNumbers, dispatch]);

//   useEffect(() => {
//     updateSeries();
//   }, [visibleMetrics, breakdownMainCards]);

//   const handleItemAppear = (item) => {
//     dispatch(breakdownToggleMetrix(item));
//     setVisibleMetrics((prev) => {
//       const updatedMetrics = { ...prev, [item]: !prev[item] };
//       console.log(updatedMetrics);
//       return updatedMetrics;
//     });
//   };

//   const formattedSpend = (breakdownData?.spend / 1000 || 0).toFixed(2) + "K";
//   const handleDropdownClick = () => {
//     setOpenDropdown((prev) => !prev);
//   };

//   const toggleMetric = (metric) => {
//     setVisibleMetrics((prev) => {
//       const updatedMetrics = { ...prev, [metric]: !prev[metric] };
//       return updatedMetrics;
//     });
//   };

//   const handleMetricSelection = (item) => {
//     dispatch(breakdownToggleMetrix(item));
//     toggleMetric(item);
//     setOpenDropdown(false);
//   };

//   const updateSeries = () => {
//     if (!Array.isArray(breakdownMainCards)) return;
//     const dataMapping = {
//       Spend: breakdownMainCards?.map((card) => card.spend || 0.1) || [],
//       Click: breakdownMainCards?.map((card) => card.click || 0.1) || [],
//       Impressions:
//         breakdownMainCards?.map((card) => card.impression || 0.1) || [],
//       Sales: breakdownMainCards?.map((card) => card.sales || 0.1) || [],
//       Revenue: breakdownMainCards?.map((card) => card.revenue || 0.1) || [],
//       CPC: breakdownMainCards?.map((card) => card.cpc || 0.1) || [],
//       CPM: breakdownMainCards?.map((card) => card.cpm || 0.1) || [],
//       CPA: breakdownMainCards?.map((card) => card.cpa || 0.1) || [],
//       "CTR %": breakdownMainCards?.map((card) => card.ctr || 0.1) || [],
//       "CR %": breakdownMainCards?.map((card) => card.cr || 0.1) || [],
//       ROAS: breakdownMainCards?.map((card) => card.roas || 0.1) || [],
//     };

//     const updatedSeries = breakdownNumbers
//       ?.map((name) => {
//         if (visibleMetrics[name]) {
//           return {
//             name: name,
//             data: dataMapping[name] || [],
//             type: "bar",
//           };
//         }
//         return null;
//       })
//       .filter(Boolean);

//     setSeries(updatedSeries);
//   };

//   const updateOptions = () => {
//     const ranges = getYAxisRanges();
//     const yAxisOptions = series?.map((metric, index) => ({
//       seriesName: metric.name,
//       min: ranges[metric.name]?.min || 0,
//       max: ranges[metric.name]?.max || 10,
//       labels: {
//         show: true,
//         formatter: function (value) {
//           return Math.floor(value);
//         },
//       },
//       opposite: index % 2 !== 0,
//     }));
//     return {
//       chart: {
//         type: "bar",
//         stacked: false,
//         height: 300,
//         toolbar: {
//           tools: {
//             download:
//               '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">\
//             <path fill="none" d="M0 0h24v24H0V0z"></path>\
//             <path fill="black" d="M13 3h-2v8.59L7.71 9.29l-1.42 1.42L12 15l5.71-5.71-1.42-1.42L13 11.59V3zm-8 15v2h18v-2H5z"></path>\
//             </svg>',
//           },
//         },
//       },
//       plotOptions: {
//         bar: {
//           horizontal: false,
//           columnWidth: "22%",
//           endingShape: "rounded",
//         },
//       },
//       dataLabels: { enabled: false },
//       xaxis: {
//         categories: breakdownMainCards?.map((item) => item.ad_name) || [],
//         labels: { style: { colors: "white", fontSize: "14px" } },
//       },
//       yaxis: yAxisOptions,
//       fill: { opacity: 1 },
//       tooltip: {
//         enabled: true,
//         y: {
//           formatter: (val) => {
//             if (val === 0.1) {
//               return undefined;
//             }
//             return val.toFixed(2);
//           },
//         },
//         fixed: {
//           enabled: true,
//           position: "topLeft",
//           offsetX: 0,
//           offsetY: -10,
//         },
//         style: { fontSize: "14px", fontFamily: undefined },
//         onDatasetHover: { highlightDataSeries: true },
//       },
//       colors: [
//         "#FF7300",
//         "#D5D900",
//         "#FF7B31",
//         "#FFE766",
//         "#FF5733",
//         "#C70039",
//         "#900C3F",
//         "#581845",
//         "#FFDAE0",
//       ],
//       legend: {
//         show: false,
//         position: "top",
//         horizontalAlign: "center",
//         labels: { colors: "#ffffff8f", fontSize: "14px" },
//       },
//     };
//   };

//   const getYAxisRanges = () => {
//     const ranges = {};
//     series.forEach((metric) => {
//       const data = metric.data || [];
//       if (data.length > 0) {
//         const allEqual = data.every((value) => value === 0.1);
//         const min = 0;
//         let maxValue = Math.max(...data);
//         let max;

//         if (allEqual) {
//           max = 10;
//         } else {
//           if (maxValue < 5) {
//             max = maxValue + 5;
//           } else if (maxValue > 5 && maxValue < 20) {
//             max = maxValue + 10;
//           } else {
//             max = maxValue + 300;
//           }
//         }

//         ranges[metric.name] = { min, max };
//       } else {
//         ranges[metric.name] = { min: 0, max: 10 };
//       }
//     });
//     return ranges;
//   };

//   const isDisabled = breakdownNumbers?.length >= 4;

//   const handleImageError = (event) => {
//     event.target.src = fallBackImg;
//   };

//   return (
//     <div>
//       <div className="button-container">
//         <div className="adMetricHead">
//           <div className="adMetricHeading">Ad Breakdown</div>
//         </div>
//         <div className="chart-id">160724-111-VID1-E-SHN-C1breakdown</div>
//         <div className="chart-id-boxes-main">
//           <div className="chart-id-box">
//             <img
//               style={{ height: "100%", width: "120px" }}
//               src={thumbnail}
//               alt=""
//               onError={handleImageError}
//             />
//           </div>
//           <div className="chart-id-boxes">
//             <div className="chart-id-box">
//               <p>Total spend</p>
//               <p>
//                 <FaRupeeSign />
//                 {formattedSpend}
//               </p>
//             </div>
//             <div className="chart-id-box">
//               <p>Avg ROAS</p>
//               <p>
//                 {breakdownData?.spend === 0
//                   ? "n/a"
//                   : (breakdownData?.revenue / breakdownData?.spend).toFixed(2)}
//               </p>
//             </div>
//             <div className="chart-id-box">
//               <p># ads</p>
//               <p>{breakdownData?.ads?.length}</p>
//             </div>
//           </div>
//         </div>
//         <div className="ad-metricChart">
//           <div className="metric-chart-button">
//             {Array.isArray(breakdownNumbers) &&
//               breakdownNumbers.map((item, index) => (
//                 <button
//                   onClick={() => handleItemAppear(item)}
//                   key={item}
//                   style={{
//                     background: index % 2 === 0 ? "#FF7B00" : "#D5D900",
//                   }}
//                   className="metrix-item"
//                 >
//                   <div className="metrix-num">{index + 1}</div>
//                   <div className="metrix-text">{item}</div>
//                 </button>
//               ))}
//             <div className="open-dropdown-body">
//               <button
//                 className="add"
//                 onClick={handleDropdownClick}
//                 disabled={isDisabled}
//                 style={{
//                   opacity: isDisabled ? 0.5 : 1,
//                   cursor: isDisabled ? "not-allowed" : "pointer",
//                 }}
//               >
//                 <img alt="plus" src={plusMetrix} />
//                 <p className="plus-text">Add metric</p>
//               </button>
//               {openDropdown && (
//                 <div className="open-dropdown">
//                   {newArray
//                     .filter((i) => !breakdownNumbers.includes(i))
//                     .map((item, index) => (
//                       <button
//                         className="open-dropdown-btn"
//                         onClick={() => handleMetricSelection(item)}
//                         key={index}
//                       >
//                         {item}
//                       </button>
//                     ))}
//                 </div>
//               )}
//             </div>
//           </div>
//           {loading ? ( // Show loader if loading
//             <CreativeChartLoader />
//           ) : (
//             <ApexCharts
//               options={updateOptions()}
//               series={series}
//               type="bar"
//               height={300}
//             />
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AdMetricChart;

import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import plusMetrix from "../../assets/icons/plusMetrix.png";
import { FaRupeeSign } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  breakdownToggleMetrix,
  setDetaultMetrixBreakdown,
} from "../../redux/reducers/metrixSlice";
import { fallBackImg } from "../../assets";
import { CreativeChartLoader } from "../loadingComponent/SkeletonLoader";

const AdMetricChart = ({ setDummyMetricButton }) => {
  const dispatch = useDispatch();
  const { breakdownData, breakdownMainCards, thumbnail } = useSelector(
    (state) => state.breakdown
  );
  const { breakdownNumbers } = useSelector((state) => state.metrix);
  const [series, setSeries] = useState([]);
  const [visibleMetrics, setVisibleMetrics] = useState({
    Spend: false,
    Revenue: false,
    Sales: false,
    CPC: false,
    Click: false,
    Impressions: false,
    CPM: false,
    CPA: false,
    "CTR %": false,
    "CR %": false,
    ROAS: false,
  });
  const [newArray] = useState([
    "Spend",
    "Click",
    "CPM",
    "CPC",
    "Impressions",
    "Sales",
    "Revenue",
    "CTR %",
    "CR %",
    "CPA",
    "ROAS",
  ]);
  console.log(visibleMetrics);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false after 2 seconds
    }, 2000);

    return () => clearTimeout(timer); // Cleanup e timer
  }, []);

  useEffect(() => {
    // Initialize default metrics if breakdownNumbers is empty
    if (breakdownNumbers?.length === 0) {
      dispatch(setDetaultMetrixBreakdown());
      toggleMetric("Spend");
      toggleMetric("Sales");
    } else {
      breakdownNumbers.forEach((item) => toggleMetric(item));
    }
  }, [dispatch]);

  useEffect(() => {
    updateSeries();
  }, [visibleMetrics, breakdownMainCards]);

  const handleItemAppear = (item) => {
    dispatch(breakdownToggleMetrix(item));
    setVisibleMetrics((prev) => {
      const updatedMetrics = { ...prev, [item]: !prev[item] };
      return updatedMetrics;
    });
  };

  const formattedSpend = (breakdownData?.spend / 1000 || 0).toFixed(2) + "K";
  const handleDropdownClick = () => {
    setOpenDropdown((prev) => !prev);
  };

  const toggleMetric = (metric) => {
    setVisibleMetrics((prev) => {
      const updatedMetrics = { ...prev, [metric]: !prev[metric] };
      return updatedMetrics;
    });
  };

  const handleMetricSelection = (item) => {
    dispatch(breakdownToggleMetrix(item));
    toggleMetric(item);
    setOpenDropdown(false);
  };

  const updateSeries = () => {
    if (!Array.isArray(breakdownMainCards)) return;
    const dataMapping = {
      Spend: breakdownMainCards?.map((card) => card.spend || 0.1) || [],
      Click: breakdownMainCards?.map((card) => card.click || 0.1) || [],
      Impressions:
        breakdownMainCards?.map((card) => card.impression || 0.1) || [],
      Sales: breakdownMainCards?.map((card) => card.sales || 0.1) || [],
      Revenue: breakdownMainCards?.map((card) => card.revenue || 0.1) || [],
      CPC: breakdownMainCards?.map((card) => card.cpc || 0.1) || [],
      CPM: breakdownMainCards?.map((card) => card.cpm || 0.1) || [],
      CPA: breakdownMainCards?.map((card) => card.cpa || 0.1) || [],
      "CTR %": breakdownMainCards?.map((card) => card.ctr || 0.1) || [],
      "CR %": breakdownMainCards?.map((card) => card.cr || 0.1) || [],
      ROAS: breakdownMainCards?.map((card) => card.roas || 0.1) || [],
    };

    const updatedSeries = breakdownNumbers
      ?.map((name) => {
        if (visibleMetrics[name]) {
          return {
            name: name,
            data: dataMapping[name] || [],
            type: "bar",
          };
        }
        return null;
      })
      .filter(Boolean);

    setSeries(updatedSeries);
  };

  const updateOptions = () => {
    const ranges = getYAxisRanges();
    const yAxisOptions = series?.map((metric, index) => ({
      seriesName: metric.name,
      min: ranges[metric.name]?.min || 0,
      max: ranges[metric.name]?.max || 10,
      labels: {
        show: true,
        formatter: function (value) {
          return Math.floor(value);
        },
      },
      opposite: index % 2 !== 0,
    }));
    return {
      chart: {
        type: "bar",
        stacked: false,
        height: 300,
        toolbar: {
          tools: {
            download:
              '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">\
            <path fill="none" d="M0 0h24v24H0V0z"></path>\
            <path fill="black" d="M13 3h-2v8.59L7.71 9.29l-1.42 1.42L12 15l5.71-5.71-1.42-1.42L13 11.59V3zm-8 15v2h18v-2H5z"></path>\
            </svg>',
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "22%",
          endingShape: "rounded",
        },
      },
      dataLabels: { enabled: false },
      xaxis: {
        categories: breakdownMainCards?.map((item) => item.ad_name) || [],
        labels: { style: { colors: "white", fontSize: "14px" } },
      },
      yaxis: yAxisOptions,
      fill: { opacity: 1 },
      tooltip: {
        enabled: true,
        y: {
          formatter: (val) => {
            if (val === 0.1) {
              return undefined;
            }
            return val.toFixed(2);
          },
        },
        fixed: {
          enabled: true,
          position: "topLeft",
          offsetX: 0,
          offsetY: -10,
        },
        style: { fontSize: "14px", fontFamily: undefined },
        onDatasetHover: { highlightDataSeries: true },
      },
      colors: [
        "#FF7300",
        "#D5D900",
        "#FF7B31",
        "#FFE766",
        "#FF5733",
        "#C70039",
        "#900C3F",
        "#581845",
        "#FFDAE0",
      ],
      legend: {
        show: false,
        position: "top",
        horizontalAlign: "center",
        labels: { colors: "#ffffff8f", fontSize: "14px" },
      },
    };
  };

  const getYAxisRanges = () => {
    const ranges = {};
    series.forEach((metric) => {
      const data = metric.data || [];
      if (data.length > 0) {
        const allEqual = data.every((value) => value === 0.1);
        const min = 0;
        let maxValue = Math.max(...data);
        let max;

        if (allEqual) {
          max = 10;
        } else {
          if (maxValue < 5) {
            max = maxValue + 5;
          } else if (maxValue > 5 && maxValue < 20) {
            max = maxValue + 10;
          } else {
            max = maxValue + 300;
          }
        }

        ranges[metric.name] = { min, max };
      } else {
        ranges[metric.name] = { min: 0, max: 10 };
      }
    });
    return ranges;
  };

  const isDisabled = breakdownNumbers?.length >= 4;

  const handleImageError = (event) => {
    event.target.src = fallBackImg;
  };

  return (
    <div>
      <div className="button-container" style={{ display: "block" }}>
        <div className="adMetricHead">
          <div className="adMetricHeading">Ad Breakdown</div>
        </div>
        <div className="chart-id">160724-111-VID1-E-SHN-C1breakdown</div>
        <div className="chart-id-boxes-main">
          <div className="chart-id-box">
            <img
              style={{ height: "100%", width: "120px" }}
              src={thumbnail}
              alt=""
              onError={handleImageError}
            />
          </div>
          <div className="chart-id-boxes">
            <div className="chart-id-box">
              <p>Total spend</p>
              <p>
                <FaRupeeSign />
                {formattedSpend}
              </p>
            </div>
            <div className="chart-id-box">
              <p>Avg ROAS</p>
              <p>
                {breakdownData?.spend === 0
                  ? "n/a"
                  : (breakdownData?.revenue / breakdownData?.spend).toFixed(2)}
              </p>
            </div>
            <div className="chart-id-box">
              <p># ads</p>
              <p>{breakdownData?.ads?.length}</p>
            </div>
          </div>
        </div>
        <div className="ad-metricChart">
          <div className="metric-chart-button">
            {Array.isArray(breakdownNumbers) &&
              breakdownNumbers.map((item, index) => (
                <button
                  onClick={() => handleItemAppear(item)}
                  key={item}
                  style={{
                    background: index % 2 === 0 ? "#FF7B00" : "#D5D900",
                  }}
                  className="metrix-item"
                >
                  <div className="metrix-num">{index + 1}</div>
                  <div className="metrix-text">{item}</div>
                </button>
              ))}
            <div className="open-dropdown-body">
              <button
                className="add"
                onClick={handleDropdownClick}
                disabled={isDisabled}
                style={{
                  opacity: isDisabled ? 0.5 : 1,
                  cursor: isDisabled ? "not-allowed" : "pointer",
                }}
              >
                <img alt="plus" src={plusMetrix} />
                <p className="plus-text">Add metric</p>
              </button>
              {openDropdown && (
                <div className="open-dropdown">
                  {newArray
                    .filter((i) => !breakdownNumbers.includes(i))
                    .map((item, index) => (
                      <button
                        className="open-dropdown-btn"
                        onClick={() => handleMetricSelection(item)}
                        key={index}
                      >
                        {item}
                      </button>
                    ))}
                </div>
              )}
            </div>
          </div>
          {loading ? ( // Show loader if loading
            <CreativeChartLoader />
          ) : (
            <ApexCharts
              options={updateOptions()}
              series={series}
              type="bar"
              height={300}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdMetricChart;
