import toast, { Toaster } from 'react-hot-toast';


export const toastSetter = (status, message) => {
    switch (status) {
      case 'success':
        toast.success(message);
        break;
      case 'error':
        toast.error(message);
        break;
      case 'info':
        toast(message); // Default toast for info
        break;
      default:
        // console.log('No toast for this status');
    }
  };


function ToastContainer() {

  return (
    <>
      <Toaster  position="top-center"
        reverseOrder={false}
        gutter={8}
        toastOptions={{
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },
        }} />
    </>
  );
}

export default ToastContainer;