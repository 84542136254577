// profileApis.js

import catchAsync from "../../utils/catchAsync";
import http from "./http";

export const getProfileDetails = catchAsync(async () => {
  try {
    const response = await http.get(`/auth/getprofile`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
});

export const updateProfileDetails = catchAsync(async (values) => {
  try {
    const response = await http.put(`/auth/updateprofile`, values);

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const sendEmailChangeOTP = catchAsync(async (values) => {
  try {
    const response = await http.post(`/auth/send_otp`, values); // body = {"email": new one}

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const verifyEmailChangeOTP = catchAsync(async (values) => {
  try {
    const response = await http.put(`/auth/validate_otp`, values); // {"email": new one, "otp": Enter by user}

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});
