import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDashboardGraphData } from "../../services/api/dashboardApis";

const initialState = {
  dashboardGraphData: null,
  isFetching: false,
  isError: false,
  error: null,
};

export const fetchDashboardGraphData = createAsyncThunk(
  "dashboardGraphData/fetchDashboardGraphData",
  async (values, { rejectWithValue }) => {
    try {
      const response = await getDashboardGraphData(values);
      if (response.status == 200) {
        return response.data;
      } 
      // else throw new Error("Failed to fetch graph data");
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.message || "Failed"
      );
    }
  }
);

// the transformData function
function transformData(originalData) {
  const formattedData = [];

  const transformations = {
    roi: "ROI",
    revenue: "Total Revenue",
    aov: "AOV",
    cpa: "CPA",
    spend: "Spend",
    sales: "Sales",
    profit: "Profit",
    cr: "Cr",
  };

  const orderedKeys = ["roi", "revenue", "sales", "aov","spend", "cpa","profit","cr"];


  for (const key of orderedKeys) {
    if (originalData[key]) {
      const newData = {
        title: key.toUpperCase(),
        subtitle: transformations[key],
        data: originalData[key].data.map((item) => ({
          value: item.value,
          date: item.date,
        })),
        total: originalData[key].total,
        compare: originalData[key].compare,
      };
      formattedData.push(newData);
    }
  }

  return formattedData;
}


export const dashboardGraphDataSlice = createSlice({
  name: "dashboardGraphData",
  initialState,
  extraReducers: {
    [fetchDashboardGraphData.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
    },
    [fetchDashboardGraphData.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = false;
      state.dashboardGraphData = payload && transformData(payload);
      state.error = null;
    },
    [fetchDashboardGraphData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.dashboardGraphData = null; // Clear the data on error
      state.error = payload;
    },
  },
});

export default dashboardGraphDataSlice.reducer;
