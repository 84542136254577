import React from "react";
import { searchLogo } from "../../assets";
import { MdClear } from "react-icons/md";
import { useDispatch } from "react-redux"; // Added import for dispatch
import useFilterData from "../../hooks/useFilterData";

const SearchInput = ({ filterCreativeText, setFilterCreativeText }) => {

  const handleInputChange = (e) => {
    setFilterCreativeText(e.target.value);
  };

  const handleClearInput = () => {
    setFilterCreativeText("");
  };

  return (
    <div className="search-button-container">
      <img src={searchLogo} alt="search" />
      <input
        className="search-button"
        placeholder="Search"
        value={filterCreativeText}
        onChange={handleInputChange} // Handle input change
      />
      {filterCreativeText && (
        <MdClear
          onClick={handleClearInput}
          size={16}
          color="#707172"
          scale={0.8}
          style={{ cursor: "pointer" }}
        />
      )}
    </div>
  );
};

export default SearchInput;
