// import React from "react";
// import { Formik, Form, Field } from "formik";
// import * as Yup from "yup";
// import "./style.css";

// // Validation schema for the form
// const validationSchema = Yup.object().shape({
//   displayname: Yup.string().required("Display Name is required"),
//   utm_field: Yup.string().required("UTM Field is required"),
//   value: Yup.string().required("Value is required"),
//   utm_subfield: Yup.string().required("UTM Sub-field is required"),
// });

// const UTMForm = ({ initialValues, onSubmit, onCancel, product }) => {
//   console.log(product);
//   // Log initial values on component render
//   console.log("Initial form values:", initialValues);

//   return (
//     <div className="pop-tool">
//       <div className="editPopup">
//         <Formik
//           initialValues={initialValues}
//           validationSchema={validationSchema}
//           validateOnChange={true} // For real-time validation
//           validateOnBlur={true} // For real-time validation
//           onSubmit={(values, { setSubmitting }) => {
//             // Log the form values when submitted
//             console.log("Form submitted with values:", values);
//             setSubmitting(false);
//             onSubmit(values);
//           }}
//         >
//           {({ errors, touched }) => (
//             <Form>
//               <div className="row-edit">
//                 <div className="one-row">
//                   {errors.displayname && touched.displayname ? (
//                     <div className="error-shared">{errors.displayname}</div>
//                   ) : (
//                     <label className="label">Display Name:</label>
//                   )}
//                   <Field
//                     className={`input ${
//                       errors.displayname && touched.displayname
//                         ? "error-input"
//                         : ""
//                     }`}
//                     name="displayname"
//                     placeholder="Enter display name"
//                   />
//                 </div>

//                 <div className="one-row">
//                   {errors.utm_field && touched.utm_field ? (
//                     <div className="error-shared">{errors.utm_field}</div>
//                   ) : (
//                     <label className="label">UTM Field:</label>
//                   )}
//                   <Field
//                     className={`input ${
//                       errors.utm_field && touched.utm_field ? "error-input" : ""
//                     }`}
//                     name="utm_field"
//                     placeholder="Enter UTM field"
//                   />
//                 </div>
//               </div>

//               <div className="row-edit">
//                 <div className="one-row">
//                   {errors.value && touched.value ? (
//                     <div className="error-shared">{errors.value}</div>
//                   ) : (
//                     <label className="label">Value:</label>
//                   )}
//                   <Field
//                     className={`input ${
//                       errors.value && touched.value ? "error-input" : ""
//                     }`}
//                     name="value"
//                     placeholder="Enter value"
//                   />
//                 </div>

//                 <div className="one-row">
//                   {errors.utm_subfield && touched.utm_subfield ? (
//                     <div className="error-shared">{errors.utm_subfield}</div>
//                   ) : (
//                     <label className="label">UTM Sub-field:</label>
//                   )}
//                   <Field
//                     className={`input ${
//                       errors.utm_subfield && touched.utm_subfield
//                         ? "error-input"
//                         : ""
//                     }`}
//                     name="utm_subfield"
//                     placeholder="Enter UTM sub-field"
//                   />
//                 </div>
//               </div>

//               <div className="row-buttons">
//                 <button className="cancel" type="button" onClick={onCancel}>
//                   Cancel
//                 </button>
//                 <button className="save" type="submit">
//                   Submit
//                 </button>
//               </div>
//             </Form>
//           )}
//         </Formik>
//       </div>
//     </div>
//   );
// };

// export default UTMForm;

import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
// import "./style.css";

// Validation schema for the form
const validationSchema = Yup.object().shape({
  displayname: Yup.string().required("Display Name is required"),
  utm_field: Yup.string().required("UTM Field is required"),
  value: Yup.string().required("Value is required"),
  utm_subfield: Yup.string().required("UTM Sub-field is required"),
});

const UTMForm = ({ onSubmit, onCancel, product }) => {
  console.log(product);

  // Determine initial values based on the product prop
  const initialValues = {
    displayname: product?.displayname || "",
    utm_field: product?.utm_field || "",
    value: product?.value || "",
    utm_subfield: product?.utm_subfield || "",
  };

  console.log(initialValues);

  return (
    <div className="pop-tool">
      <div className="editPopup">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize // Add this line to enable reinitialization
          validateOnChange={true} // For real-time validation
          validateOnBlur={true} // For real-time validation
          onSubmit={(values, { setSubmitting }) => {
            console.log("Form submitted with values:", values);
            setSubmitting(false);
            onSubmit(values);
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="row-edit">
                <div className="one-row">
                  {errors.displayname && touched.displayname ? (
                    <div className="error-shared">{errors.displayname}</div>
                  ) : (
                    <label className="label">Display Name:</label>
                  )}
                  <Field
                    className={`input ${
                      errors.displayname && touched.displayname
                        ? "error-input"
                        : ""
                    }`}
                    name="displayname"
                    placeholder="Enter display name"
                  />
                </div>

                <div className="one-row">
                  {errors.utm_field && touched.utm_field ? (
                    <div className="error-shared">{errors.utm_field}</div>
                  ) : (
                    <label className="label">UTM Field:</label>
                  )}
                  <Field
                    className={`input ${
                      errors.utm_field && touched.utm_field ? "error-input" : ""
                    }`}
                    name="utm_field"
                    placeholder="Enter UTM field"
                  />
                </div>
              </div>

              <div className="row-edit">
                <div className="one-row">
                  {errors.value && touched.value ? (
                    <div className="error-shared">{errors.value}</div>
                  ) : (
                    <label className="label">Value:</label>
                  )}
                  <Field
                    className={`input ${
                      errors.value && touched.value ? "error-input" : ""
                    }`}
                    name="value"
                    placeholder="Enter value"
                  />
                </div>

                <div className="one-row">
                  {errors.utm_subfield && touched.utm_subfield ? (
                    <div className="error-shared">{errors.utm_subfield}</div>
                  ) : (
                    <label className="label">UTM Sub-field:</label>
                  )}
                  <Field
                    className={`input ${
                      errors.utm_subfield && touched.utm_subfield
                        ? "error-input"
                        : ""
                    }`}
                    name="utm_subfield"
                    placeholder="Enter UTM sub-field"
                  />
                </div>
              </div>

              <div className="row-buttons">
                <button className="cancel" type="button" onClick={onCancel}>
                  Cancel
                </button>
                <button className="save" type="submit">
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UTMForm;
