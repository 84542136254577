import React from "react";
import Chart from "react-apexcharts";
import { AreaChartLoader } from "../loadingComponent/SkeletonLoader";
import { simplifyNumber, transformToInternationalNumber } from "../../utils";
import { width } from "@mui/system";
import { useDispatch } from "react-redux";
import { setJouney, setJourney } from "../../redux/reducers/graphDataSlice";
import { setJourneys } from "../../redux/reducers/journeySlice";

function AreaChart({ graphData }) {
  const { data, isError, errorMessage, isFetching } = graphData;
  const dispatch = useDispatch();
  const newdates = [];
  const newMonths = [];
  // Create an empty set
  let monthSet = new Set();

  const convertDates = (date) => {
    const currentDate = new Date(date);
    const day = currentDate.getDate();
    const month = currentDate.getMonth();
    newMonths.push(month);
    const monthName = currentDate.toLocaleString("en-US", { month: "short" });
    monthSet.add(monthName);

    return `${day}`;
  };

  const handleClick = () => {
    dispatch(setJourneys(true));
  };

  // Extracting dates and sales/revenue data from the new structure
  const dates = data && data !== null ? Object.keys(data.data) : [];

  if (dates) {
    dates.map((date) => {
      const newDateValue = convertDates(date);
      newdates.push(newDateValue);
    });
  }

  const salesData = dates.map((date) => data.data[date][0].sales);
  const revenueData = dates.map((date) => data.data[date][0].revenue);

  return (
    <React.Fragment>
      {isError ? (
        <div>Error: {errorMessage}</div>
      ) : isFetching ? (
        <AreaChartLoader />
      ) : (
        <>
          <div className="area-head">
            <h2
              style={{
                color: "#fff",
                fontSize: "16px",
                fontWeight: "700",
                marginBottom: "10px",
              }}
            >
              REVENUE vs SALES
            </h2>
            <div className="report-head">
              <div className="sales">
                <p className="yellow"></p>
                <p>Sales</p>
              </div>
              <div className="revenue">
                <p className="orange"></p>
                <p>Revenue</p>
              </div>
              <img
                onClick={handleClick}
                alt="ing"
                style={{ height: "20px", width: "20px" }}
                src="sales&revenue.png"
              ></img>
            </div>
          </div>

          <div className="body-head">
            <span className="data-item remove-border">
              <h2>
                {data &&
                  data?.revenue &&
                  transformToInternationalNumber(data.revenue)}
              </h2>
              <p>Total Revenue</p>
            </span>

            <span
              style={{
                padding: "0 30px 0 0",
              }}
            >
              <h2 style={{ color: "#c7cb00" }}>
                {data &&
                  data?.sales !== null &&
                  transformToInternationalNumber(data.sales)}
              </h2>
              <p>Total Sales</p>
            </span>
          </div>
          <Chart
            type="line"
            width={"100%"}
            height={220}
            series={[
              {
                name: "Sales",
                type: "line",
                data: salesData,
              },
              {
                name: "Revenue",
                type: "bar",
                data: revenueData,
              },
            ]}
            options={{
              chart: {
                type: "area",
                toolbar: {
                  show: false,
                },
              },
              colors: ["#c7cb00", "#ff7b00"], // Colors for sales and revenue
              legend: {
                show: false,
                position: "bottom",
              },
              stroke: {
                width: 4, // Set the width of the line for sales
                curve: "smooth",
              },
              xaxis: {
                categories: newdates,
                labels: {
                  style: {
                    fontSize: 12,
                    fontWeight: "500",
                    colors: "#9c9c9d",
                    display: "flex",
                    flexDirection: "column",
                  },
                },
                tickAmount: 22, // Set the tick amount for the x-axis
                rotate: 0,
              },
              yaxis: [
                {
                  labels: {
                    style: {
                      fontSize: 12,
                      fontWeight: "500",
                      colors: "#9c9c9d",
                    },
                  },
                  title: {
                    text: "Sales",
                    style: { fontSize: 14, color: "#c7cb00" },
                  },
                  opposite: true, // Placing the Y-axis on the right side
                  tickAmount: 7,
                },
                {
                  labels: {
                    formatter: function (value) {
                      // // Example: Change the format to international numbers or add currency
                      // console.log(simplifyNumber(value))
                      return simplifyNumber(value);
                      // return value
                    },
                    style: {
                      fontSize: 12,
                      fontWeight: "500",
                      colors: "#9c9c9d",
                    },
                  },
                  title: {
                    text: "Revenue",
                    style: { fontSize: 16, color: "#ff7b00" },
                  },
                },
              ],
              tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                  const date = Object.keys(data.data)[dataPointIndex];
                  const salesValue = series[0][dataPointIndex];
                  const revenueValue = series[1][dataPointIndex];

                  return (
                    '<div class="arrow_box">' +
                    '<span class="date"><spanr>Date: </spanr>' +
                    '<span class="content">' +
                    date +
                    "</span>" +
                    "</span>" +
                    '<span class="itemtool"><span class="titletool">Revenue: </span>' +
                    '<span class="content">' +
                    revenueValue +
                    "</span>" +
                    "</span>" +
                    '<span class="itemtool"><span class="titletool">Sales: </span>' +
                    '<span class="content">' +
                    salesValue +
                    "</span>" +
                    "</span>" +
                    "</div>"
                  );
                },
              },
            }}
          />
          <div className="reporting-months">
            {Array.from(monthSet).map((mon) => (
              <div key={mon}>
                <span className="mone">{mon}</span>
              </div>
            ))}
          </div>
        </>
      )}
    </React.Fragment>
  );
}

export default AreaChart;
