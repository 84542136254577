import React from "react";

const VideoTutorialPopups = (props) => {
  // Destructure the currentVideoUrl from props
  const { currentVideoUrl } = props;

  return (
    <div
      className="integration-popup-container" // Container for the popup
      style={{
        height: "500px", // Set height of the popup
        width: "920px", // Set width of the popup
        padding: "32px", // Add padding around the content
      }}
    >
      <iframe
        id="youtube-video" // Unique ID for the iframe element
        className="video-player" // Class name for styling the video player
        style={{
          width: "100%", // Make iframe width 100% of the container
          height: "100%", // Make iframe height 100% of the container
          border: "none", // Remove border from the iframe
        }}
        src={currentVideoUrl} // Set the source URL of the video
        frameBorder="0" // Disable the frame border
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" // Permissions for video playback
        referrerPolicy="strict-origin-when-cross-origin" // Set referrer policy for security
        allowFullScreen // Allow the video to be played in full screen
      />
    </div>
  );
};

export default VideoTutorialPopups;
