import "./styles.css";
import plusMetrix from "../../assets/icons/plusMetrix.png";
import DatePicker from "../DatePicker/DatePicker";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setDetaultMetrix,
  toggleMetrix,
} from "../../redux/reducers/metrixSlice";
import { toastSetter } from "../../components/ToastContainer";

function Metrix({
  openPopup,
  creativesdate,
  selectedDates,
  isPopupOpen,
  closePopup,
  updateSelectedDates,
  cancelSelectedDates,
  setCreativesDate,
}) {
  const [openDropdown, setOpenDropdown] = useState(false);

  // Get current metrics from Redux state
  const newState = useSelector((state) => state.metrix.numbers);
  const { source } = useSelector(
    (state) => state?.creativeTableData
  );
  const dispatch = useDispatch();

  const [newArray, setNewArray] = useState([]);
  useEffect(() => {
    let defaultMetrixItems = ["Spend", "ROAS", "Sales", "CPA"];

    if (source === 'youtube') {
      defaultMetrixItems = ["Spend", "CPM", "Reported Sales", "CPC"];
      setNewArray(["Spend", "Reported Sales", "Click", "CPA", "CPM", "CPC", "CTR %", "AOV", "Engage rate %", "Hook rate %", "Completion rate %", "CPNV",
      ]);
    } else {
      setNewArray([
        "Revenue",
        "Spend",
        "Sales",
        "Click",
        "ROAS",
        "CPA",
        "CPM",
        "CPC",
        "CTR %",
        "CR %",
        "AOV",
        "Engage rate %",
        "Hook rate %",
        "Completion rate %",
        "CPNV",
      ]);
    }

    dispatch(setDetaultMetrix(defaultMetrixItems));

  }, [source, dispatch]);







  function getColor(index) {
    return index % 2 === 0 ? "#FF7B00" : "#D5D900";
  }

  function handleClick() {
    if (newState.length >= 6) {
      toastSetter(
        "error",
        "You can only select 6 items. Click on a selected item to remove it and choose another."
      );
    } else {
      setOpenDropdown((prev) => !prev);
    }
  }

  function handleItemSelected() {
    setOpenDropdown((prev) => !prev);
  }

  function handleMetrix(item) {
    const newMetric = item;
    dispatch(toggleMetrix(newMetric));
  }

  function handleItemAppear(item) {
    dispatch(toggleMetrix(item));
  }

  return (
    <div className="metrix-main">
      <div className="metrix-container">
        <div className="metrix-body">
          {Array.isArray(newState) &&
            newState.map((item, index) => {
              return (
                <button
                  onClick={() => handleItemAppear(item)}
                  key={item.id}
                  style={{ background: getColor(index) }} // Apply dynamic color
                  className="metrix-item"
                >
                  <div className="metrix-num">{index + 1}</div>{" "}
                  <div className="metrix-text">{item}</div>
                </button>
              );
            })}

          <div className="open-dropdown-body">
            {newState && newState.length < 6 ? (
              <button className="add" onClick={handleClick}>
                <img alt="plus" src={plusMetrix} />
                <p className="plus-text">Add metric</p>
              </button>
            ) : null}
            {openDropdown && (
              <div onClick={handleItemSelected} className="open-dropdown">
                {newArray
                  .filter((i) => !newState.includes(i) && i)
                  .map((item, index) => {
                    if (source === 'youtube' && item === 'Sales') {
                      item = 'Reported Sales'
                    }
                    return (
                      <button
                        className="open-dropdown-btn"
                        onClick={() => handleMetrix(item)}
                        key={index}
                      >
                        {item}
                      </button>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <DatePicker
          openPopup={openPopup}
          date={creativesdate}
          selectedDates={selectedDates}
          isPopupOpen={isPopupOpen}
          closePopup={closePopup}
          updateSelectedDates={updateSelectedDates}
          cancelSelectedDates={cancelSelectedDates}
          setDate={setCreativesDate}
        />
      </div>
    </div>
  );
}

export default Metrix;
