import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReportingGraphData } from "./../../services/api/graphDataApis";

const initialState = {
  data: null,
  isFetching: false,
  isError: false,
  errorMessage: null,
  journey: false,
};

export const fetchGraphData = createAsyncThunk(
  "graphData/fetchGraphData",
  async (values, { rejectWithValue }) => {
    try {
      const response = await getReportingGraphData(values);
      if (response.status === 200) {
        return response.data;
      }
      // else throw new Error("Failed to fetch graph data");
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.message || "Failed"
      );
    }
  }
);

export const graphDataSlice = createSlice({
  name: "graphData",
  initialState,
  reducers: {
    setJourney(state, action) {
      state.journey = !state.journey;
    },
  },
  extraReducers: {
    [fetchGraphData.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
    },
    [fetchGraphData.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = false;
      state.data = payload;
      state.errorMessage = null;
    },
    [fetchGraphData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
  },
});

export const { setJourney } = graphDataSlice.actions;

export default graphDataSlice.reducer;
