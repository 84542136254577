import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDashboardTrafficData } from "../../services/api/dashboardApis";

const initialState = {
  dashboardTrafficData: null,

  isFetching: false,
  isError: false,
  error: null,
};

export const fetchDashboardTrafficData = createAsyncThunk(
  "dashboardTrafficData/fetchDashboardTrafficData",
  async (values, { rejectWithValue }) => {
    try {
      const response = await getDashboardTrafficData(values);
      if (response.status === 200) {
        console.log(response);
        return response.data;
      }
      // else throw new Error("Failed to fetch traffic data");
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.message || "Failed"
      );
    }
  }
);

export const dashboardTrafficDataSlice = createSlice({
  name: "dashboardTrafficData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardTrafficData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchDashboardTrafficData.fulfilled, (state, action) => {
        state.isFetching = false;
        console.log("payload", action.payload);
        state.dashboardTrafficData = action.payload && [
          {
            ...action.payload.pageview,
            title: "Page View",
            data: action.payload?.pageview?.data,
          },
          {
            ...action.payload.session,

            title: "Unique Session",
            data: action.payload?.session?.data,
          },
          {
            ...action.payload.localsession,

            title: "Session",
            data: action.payload?.localsession?.data,
          },
          {
            ...action.payload.nuser,

            title: "New Website Visitor",
            data: action.payload?.nuser?.data,
          },

          //   // {
          //   //   value: action.payload.user,
          //   //   label: "Session",
          //   // },
          //   // {
          //   //   value: action.payload.unique_user,
          //   //   label: "User",
          //   // },
          //   // {
          //   //   value:
          //   //     `${action.payload.cr}%`,
          //   //   label: "CR (Conversion Rate)",
          //   // },
        ];
      })
      .addCase(fetchDashboardTrafficData.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.error = action.error.message;
      });
  },
});

export default dashboardTrafficDataSlice.reducer;
