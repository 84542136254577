import React from "react";
import "./buttonDots.css";
function ButtonDots() {
  return (
    <div className="buttonDots">
      <div className="button-line">
        <div className="minion"></div>
        <div className="minion"></div>
      </div>
      <div className="button-line">
        <div className="minion"></div>
        <div className="minion"></div>
      </div>
      <div className="button-line">
        <div className="minion"></div>
        <div className="minion"></div>
      </div>
    </div>
  );
}

export default ButtonDots;
