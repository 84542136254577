import React, { useEffect, useState } from "react";
import { DropDownComp } from "../../components";
import ProfileDropDown from "../../components/profileDropDown/ProfileDropDown";
import ColTrafCampDateComp from "./ColTrafCampDateComp";

import LeftTable from "./LeftTable";
import CohortTable from "./CohortTable";
import SalesVelocityGraph from "./SalesVelocityGraph";
import SalesVelocityTable from "./SalesVelocityTable";
import {
  fetchPredictiveTableData,
  fetchSalesVelocityTableData,
} from "../../redux/reducers/predictiveBehaviorSlice";
import { useDispatch, useSelector } from "react-redux";
import { getSalesVelocityTable } from "../../services/api/predictiveBehaviorApis";
import { cohortTableData } from "../../redux/reducers/predictiveBehaviorCohortTableSlice";
import { ErrorPage2, ErrorPage1 } from "../../components/errorPages/ErrorPage";
import {
  CohortTableLoader,
  SalesVelocityGraphLoader,
} from "../../components/loadingComponent/SkeletonLoader";

const PredictiveBehavior = () => {
  // Initial check for trafficSource in localStorage
  const [selectedId, setSelectedId] = useState("");
  const [timeInterval, setTimeInterval] = useState("monthly");
  const { currentClient } = useSelector((state) => state.agency);
  const [isPredictive, setIsPredictive] = useState(true);
  console.log("isPredictive", isPredictive);

  useEffect(() => {
    const savedTrafficSource = localStorage.getItem("trafficSource");
    if (!savedTrafficSource) {
      // If no trafficSource in localStorage, set default value "Facebook"
      localStorage.setItem("trafficSource", "Facebook");
    }
  }, []);

  const { trafficSource } = useSelector((state) => state.predictiveData);

  // Local state to track trafficSource and set it from localStorage
  const [trafficSources, setTrafficSource] = useState(() => {
    return localStorage.getItem("trafficSource") || "Facebook"; // Default to "Facebook" if nothing is found
  });

  const [refreshCount, setRefreshCount] = useState(
    localStorage.getItem("refreshcalled")
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshCount(localStorage.getItem("refreshcalled"));
    }, 1000); // Polling interval for localStorage updates

    return () => clearInterval(interval);
  }, []);

  // State to handle toggle for including/excluding "0 Day Sales"
  const [isZeroDayIncluded, setIsZeroDayIncluded] = useState(false);
  const handleToggle = () => {
    setIsZeroDayIncluded(!isZeroDayIncluded);
  };

  const dispatch = useDispatch();
  const { behaviourdate } = useSelector((state) => state.date);

  const { salesVelocityTableData, isVelocityFetching, isFetching, error } =
    useSelector((state) => state.predictiveData);

  // Fetching Sales Velocity Table Data
  useEffect(() => {
    if (
      behaviourdate &&
      behaviourdate.length === 2 &&
      behaviourdate[0] !== undefined &&
      behaviourdate[1] !== undefined
    ) {
      let data = {
        startdate: behaviourdate[0],
        enddate: behaviourdate[1],
        channel: trafficSource,

        include_dayzero: isZeroDayIncluded,
      };
      if (trafficSource !== "all") {
        data = { ...data, channel: trafficSource };
      }
      if (selectedId !== "all" && selectedId !== "") {

        data = { ...data, campaign: selectedId, };
      }
      dispatch(fetchSalesVelocityTableData(data));
    }
  }, [
    behaviourdate,
    currentClient,
    trafficSource,
    selectedId,
    isZeroDayIncluded,
    dispatch,
  ]);

  // Fetching Predictive Table Data
  useEffect(() => {
    if (
      behaviourdate &&
      behaviourdate.length === 2 &&
      behaviourdate[0] !== undefined &&
      behaviourdate[1] !== undefined
    ) {
      let data = {
        startdate: behaviourdate[0],
        enddate: behaviourdate[1],
        channel: trafficSource,
      };
      if (trafficSource !== "all") {
        dispatch(fetchPredictiveTableData(data));
      }
    }
  }, [behaviourdate, trafficSource, dispatch, currentClient]);

  // Handle trafficSource change and update localStorage
  // const handleTrafficSourceChange = (event) => {
  //   const selectedTrafficSource = event.target.value;
  //   setTrafficSource(selectedTrafficSource); // Update state
  //   localStorage.setItem("trafficSource", selectedTrafficSource); // Persist in localStorage
  // };

  useEffect(() => {
    let data = {
      startdate: behaviourdate[0],
      enddate: behaviourdate[1],
      interval: timeInterval,
      channel: trafficSource,
    };

    if (trafficSource !== "all") {
      data = { ...data, channel: trafficSource };
    }
    if (selectedId !== "all" && selectedId !== "") {

      data = { ...data, campaign: selectedId, };
    }

    dispatch(cohortTableData(data)); // Pass required parameters
  }, [
    dispatch,
    behaviourdate,
    trafficSource,
    selectedId,
    timeInterval,
    currentClient,
  ]);

  return (
    <div className="profile-container">
      {/* Header Section */}
      <div className="profile-header-section" style={{ paddingRight: "60px" }}>
        <div className="client-heading">
          <h1 className="profile-heading">Behaviour</h1>
          <DropDownComp />
          <ProfileDropDown />
        </div>
      </div>

      {/* Body Section */}
      <div className="profile-body-section-behaviour">
        <div className="behaviour-table-card">
          <ColTrafCampDateComp
            style={{ position: "sticky" }}
            setSelectedId={setSelectedId}
            setTimeInterval={setTimeInterval}
            isPredictive={isPredictive}
            selectedId={selectedId}
          />
        </div>

        {refreshCount >= 90 && refreshCount < 100 && (
          <div
            className="error-in-page"
            style={{
              top: "0",
              zIndex: "97",
              marginTop: "15px",
              marginBottom: "30px",
              marginLeft: "-10px",
            }}
          >
            <ErrorPage1 />
          </div>
        )}

        {refreshCount >= 100 && (
          <div
            className="error-in-page"
            style={{
              top: "0",
              zIndex: "97",
              marginTop: "15px",
              marginBottom: "30px",
              marginLeft: "-10px",
            }}
          >
            <ErrorPage2 />
          </div>
        )}

        {/* <div
            className="error-in-page"
            style={{ position: "sticky", top: "0", zIndex: "97",marginTop:"15px",marginBottom:"30px"}}
          >
                <ErrorPage /> 
             
          </div>   */}

        {refreshCount < 100 && (
          <div className="behaviour-container">
            <LeftTable />
            <CohortTable />
          </div>
        )}

        {/* Graph and Sales Velocity Table Section */}
        {refreshCount < 100 && (
          <div
            className="behaviour-container"
            style={{ flexDirection: "column", gap: "20px" }}
          >
            {isVelocityFetching ? (
              <SalesVelocityGraphLoader />
            ) : (
              <SalesVelocityGraph
                traffic={salesVelocityTableData?.traffic}
                value={salesVelocityTableData?.value}
                isZeroDayIncluded={isZeroDayIncluded}
                handleToggle={handleToggle}
              />
            )}
            {isVelocityFetching ? (
              <CohortTableLoader />
            ) : (
              <SalesVelocityTable
                salesVelocityTableData={salesVelocityTableData}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PredictiveBehavior;
