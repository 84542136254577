
const CommonTableDropdown = ({ label, options, selectedValue, onChange }) => {
    return (
        <div className="">
            {label && <label className="label-name">{label}</label>}
            <select
                className="select-box-value-attribute"
                value={selectedValue}
                onChange={(e) => {
                    console.log('eeeee ', e.target.value)
                    onChange(e.target.value)
                }}
                id='select-box'
            >
                {/* <option value="">Select an option</option> */}
                {options.map((option, index) => (
                    <option key={index} className="option" value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default CommonTableDropdown;
