import React from "react";
import "./styles.css";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import Cols from "./Cols";
function Column({ cols }) {
  console.log(cols);
  return (
    <div className="column-styles">
      <SortableContext items={cols} strategy={verticalListSortingStrategy}>
        {cols.map((col) => (
          <Cols id={col.id} title={col.col} key={col.id}></Cols>
        ))}
      </SortableContext>
    </div>
  );
}

export default Column;
