import React from "react";
import { IoMdSearch } from "react-icons/io";
function CustomizeDataTwo() {
  const lines = [
    {
      text: "Impression To Lead",
    },
    {
      text: "Click to lead CR%",
    },
    {
      text: "Click to lead CR%",
    },

    {
      text: "Click to lead CR%",
    },
    {
      text: "Click to lead CR%",
    },
    {
      text: "Click to lead CR%",
    },
    {
      text: "Click to lead CR%",
    },
    {
      text: "Click to lead CR%",
    },
    {
      text: "Click to lead CR%",
    },
    {
      text: "Click to lead CR%",
    },
    {
      text: "Click to lead CR%",
    },
    {
      text: "Click to lead CR%",
    },
  ];
  const handleCheckboxChange = () => {};
  return (
    <div className="custom-two-container">
      <div className="custom-two-part-one">
        <IoMdSearch className="custom-search-icon" />
        <input placeholder="Search" className="search-custom"></input>
        <p className="metric-custom">Create Custom metric</p>
      </div>
      <p className="custom-line">EVERYONE WITH ACCESS TO THIS BUSINESS</p>
      <div className="custom-two-part-two">
        {lines.map((line, index) => (
          <div key={index} className="ticks">
            <input
              type="checkbox"
              id={`line-${index}`}
              className="line-checkbox"
              checked={line.checked}
              onChange={() => handleCheckboxChange(index)}
            />
            <label htmlFor={`line-${index}`} className="line-text">
              {line.text}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CustomizeDataTwo;
