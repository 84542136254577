// import React, { useState } from "react";
// import { ChatOpenAI } from "@langchain/openai";
// import { useSelector } from "react-redux";

// const TrackoBotAi = () => {
//   const [input, setInput] = useState("");
//   const [response, setResponse] = useState("");
//   const table = useSelector((state) => state.table);

//   const llm = new ChatOpenAI({
//     model: "gpt-4o-mini",
//     temperature: 0,
//     openAIApiKey:
//       "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", // This passes the API key
//   });

//   const sendMessage = async () => {
//     let answer = "";

//     try {
//       // Send the query to Langchain and process the response
//       answer = await llm.invoke(input);
//       console.log("AI Answer:", answer);

//       // Assuming the answer should be the AOV or some other field
//       setResponse(answer);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   // Helper function to find AOV or other fields
//   const getFieldValue = (field) => {
//     if (!table) return null;

//     // Handle fields like 'AOV' and compute their sum, or specific values
//     if (field === "AOV") {
//       const totalAOV = table?.tableData?.AOV;
//       return totalAOV;
//     }

//     return null; // Default case
//   };

//   // Example of how you'd integrate the AI and the table query
//   const processQuery = async () => {
//     // AI assistant parses query and understands AOV
//     if (input.toLowerCase().includes("aov")) {
//       const totalAOV = getFieldValue("AOV");
//       console.log(totalAOV)
//       setResponse(`The total AOV is: ${totalAOV}`);
//     } else {
//       await sendMessage();
//     }
//   };

//   return (
//     <div style={{ marginLeft: "200px" }}>
//       <h1>ChatGPT Assistant</h1>
//       <textarea
//         value={input}
//         onChange={(e) => setInput(e.target.value)}
//         placeholder="Ask a question..."
//       />
//       <button onClick={processQuery}>Send</button>
//       {/* {response && (
//         <div>
//           <h3>Response:</h3>
//           <p>{response}</p>
//         </div>
//       )} */}
//     </div>
//   );
// };

// export default TrackoBotAi;

import React, { useState } from "react";
import { ChatOpenAI } from "@langchain/openai";
import { useSelector } from "react-redux";

const TrackoBotAi = () => {
  const [input, setInput] = useState("");
  const [response, setResponse] = useState("");
  const table = useSelector((state) => state.table); // Redux state

  // Initialize the LangChain ChatOpenAI instance
  const llm = new ChatOpenAI({
    model: "gpt-4",
    temperature: 0,
    openAIApiKey: "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", // Replace with your OpenAI key
  });

  // Function to prepare and send the query to ChatGPT
  const sendMessage = async () => {
    if (!table) {
      setResponse("No table data found to analyze.");
      return;
    }

    try {
      // Serialize the `table` data
      const tableDescription = JSON.stringify(table, null, 2);

      console.log(tableDescription)

      // Build a prompt with the table data included
      const prompt = `
      Below is a JSON representation of the table data. Use this to answer the user's question:
      
      Table Data:
      ${tableDescription}
      
      User Query:
      ${input}
      `;

      // Send the prompt to LangChain
      const answer = await llm.invoke([
        {
          role: "system",
          content: "You are a helpful assistant that analyzes table data.",
        },
        { role: "user", content: prompt },
      ]);

      setResponse(answer?.text || "No response received from the AI.");
    } catch (error) {
      console.error("Error querying the AI:", error);
      setResponse("An error occurred while processing your query.");
    }
  };

  return (
    <div style={{ marginLeft: "200px" }}>
      <h1>TrackoBot AI Assistant</h1>
      <textarea
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Ask a question about the table data..."
        rows={5}
        style={{ width: "100%", marginBottom: "10px" }}
      />
      <button onClick={sendMessage} style={{ marginBottom: "20px" }}>
        Send
      </button>
      {response && (
        <div>
          <h3>Response:</h3>
          <p>{response}</p>
        </div>
      )}
    </div>
  );
};

export default TrackoBotAi;

// import React, { useState } from "react";
// import { ChatOpenAI } from "@langchain/openai";

// const TrackoBotAi = () => {
//   const [input, setInput] = useState("");
//   const [response, setResponse] = useState("");

//   // Your JSON data
//   const tableData = {
//     AOV: 2752.9,
//     CPA: 1111.8,
//     CPC: 10.17,
//     CPM: 92.4,
//     "CR %": 0.91,
//     "CTR %": 0.91,
//     CancelOrder: 0,
//     CancelRev: 0,
//     Clicks: 3826,
//     Cost: 0,
//     "Gross Margin %": 59.6,
//     "Gross Profit": 57439,
//     Impression: 421297,
//     "New Visits": 166,
//     "New Visits %": 4.34,
//   };

//   const llm = new ChatOpenAI({
//     model: "gpt-4",
//     temperature: 0,
//     openAIApiKey: "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", // Replace with your OpenAI key
//   });

//   const processQuery = async () => {
//     try {
//       // Serialize the table data
//       const serializedData = JSON.stringify(tableData, null, 2);

//       // Build the prompt
//       const prompt = `
//       I am providing you with some table data in JSON format. Use this data to answer any questions the user asks.
      
//       Table Data:
//       ${serializedData}
      
//       User Query:
//       ${input}
//       `;

//       // Send the prompt to ChatGPT
//       const answer = await llm.invoke([
//         { role: "system", content: "You are an AI assistant that analyzes table data and answers user questions accurately." },
//         { role: "user", content: prompt },
//       ]);

//       setResponse(answer?.text || "No response received from the AI.");
//     } catch (error) {
//       console.error("Error querying the AI:", error);
//       setResponse("An error occurred while processing your query.");
//     }
//   };

//   return (
//     <div style={{ marginLeft: "200px" }}>
//       <h1>TrackoBot AI Assistant</h1>
//       <textarea
//         value={input}
//         onChange={(e) => setInput(e.target.value)}
//         placeholder="Ask a question about the table data..."
//         rows={5}
//         style={{ width: "100%", marginBottom: "10px" }}
//       />
//       <button onClick={processQuery} style={{ marginBottom: "20px" }}>
//         Send
//       </button>
//       {response && (
//         <div>
//           <h3>Response:</h3>
//           <p>{response}</p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default TrackoBotAi;
