import React, { useCallback, useMemo, useRef, useState } from "react";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import ReactDOM from "react-dom";
import { MdArrowDropUp } from "react-icons/md";
import DataTable from "react-data-table-component";

import { ReportDetailsLoader } from "../../components";
import {
  transformToInternationalNumber,
  truncateString,
  formatNumber,
  calculateComparison,
} from "../../utils";
import SalesPopup from "./SalesPopup";
import UserJourney from "./UserJourney";
import { useSelector } from "react-redux";
import ExpandableComponent from "./ExpandableComponent";
import RenderReportingCell from "./RenderReportingCell";

// let customToggle;

// Select the element by its data-column-id attribute
const columnElement = document.querySelector('[data-column-id="1"]');
const select = { selector: (row) => console.log(row) };

// Apply the sticky styles to the selected element
if (columnElement) {
  columnElement.style.position = "sticky";
  columnElement.style.left = "0";
  columnElement.style.zIndex = "1";
  // Adjust other styles as needed, such as backgroundColor
}
const customStyles = {
  headRow: {
    style: {
      backgroundColor: "#27292D",
      color: "#DBFF00",
      borderBottom: "1px solid white",
    },
  },
  cells: {
    style: {
      backgroundColor: "#27292D",
      color: "white",
    },
  },
};



const columns2 = (openPopup, columnConfigs) => {
  return [
    {
      name: "CAMPAIGN NAME",
      selector: (row) => row.category,
      columnName: "category",
      sortable: true,
      cell: (row) => (
        <div className="sticky-header-cell">
          <span className="sticky-column" title={row?.category}>
            {truncateString(row?.category, 30)}
          </span>
        </div>
      ),
      minWidth: "273px",
      style: {
        position: "sticky",
        top: "0px",
        left: "48px",
        zIndex: 1,
      },
      headerStyle: {
        position: "sticky",
        top: 0,
        zIndex: 1,
      },
    },
    ...columnConfigs.map(({ name, field }) => ({
      name,
      selector: (row) => row[field],
      columnName: field,
      sortable: true,
      minWidth: name === "New Visits* %" && "110px",
      cell: (row) =>
        //RenderReportingCell is Helper function to render columns
        RenderReportingCell(row, field, `${field}Comparison`, openPopup),
    })),
  ];
};

const ReportDetails = ({
  filteredData,
  table,
  trafficSource,
  selectedAdList,
  columnConfigs

  // toggle
}) => {
  const sortingRef = useRef({
    mainTableColumn: "",
    mainTableSortDirection: "asc",
    childTableColumn: "",
    childTableSortDirection: "asc",
    subchildTableColumn: "",
    subChildTableSortDirection: "asc",
  });
  // customToggle = toggle;
  // console.log(selectedAdList);
  // console.log("new", filteredData);
  const { tableData, isError, error, isFetching } = table;
  const { isCompareFetching, isCompareError } = useSelector(
    (state) => state.compareTable
  );

  const data = useSelector((state) => state.userJourney);
  // console.log("userjourney", data);
  const secondFilter =
    Array.isArray(filteredData) && filteredData.length > 1
      ? filteredData[1]
      : null;

  // console.log(secondFilter);
  const secondFilterLength = secondFilter?.campaign?.length;

  // const calculateComparison = (value, compValue, secondFilterLength) => {
  //   // console.log(value, " : ", value, compValue, " : ", compValue, secondFilterLength," : " ,secondFilterLength)
  //   if (secondFilterLength === 0) return null;

  //   if (compValue === -1 || compValue === 0) return null;

  //   // const comparison = formatNumber((value - compValue) / compValue);
  //   const comparison = formatNumber(((value - compValue) / compValue) * 100);

  //   return comparison === 0 ? null : comparison;
  // };

  const mappedData = useMemo(() => {
    if (!Array.isArray(filteredData) || !filteredData[0]?.campaign) return [];

    return filteredData[0].campaign.map((item) => {
      const compareItem =
        filteredData[1]?.campaign?.find(
          (compare) => compare.campaign_id === item.campaign_id
        ) || null;

      // Keys to exclude from processing
      const excludeKeys = ["campaign_id", "campaign_name", "ad_sets"];

      // Get all keys from item excluding the ones we don't want to process
      const properties = Object.keys(item).filter(
        (key) => !excludeKeys.includes(key)
      );

      // Format item properties
      const formattedValues = {};
      properties.forEach((prop) => {
        formattedValues[prop] = formatNumber(item[prop]);
      });

      // Initialize compValues with formatted item values
      let compValues = {};

      const comparisons = {};
      if (compareItem) {
        // Update compValues with formatted compareItem properties
        Object.keys(compareItem).forEach((prop) => {
          compValues[prop] = formatNumber(compareItem[prop]);
        });

        // Calculate comparisons
        properties.forEach((prop) => {
          const comparisonName = `${prop}Comparison`;
          comparisons[comparisonName] = calculateComparison(
            formattedValues[prop],
            compValues[prop],
            secondFilterLength
          );
        });
      }

      return {
        id: item.campaign_id,
        category: item.campaign_name,
        ad_sets: item.ad_sets,
        ...formattedValues,
        ...comparisons,
      };
    });
  }, [filteredData, secondFilterLength, formatNumber]);

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isPopupOpentwo, setPopupOpentwo] = useState(false);
  const [currentadid, setCurrentadid] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});

  const openPopup = useCallback((value) => {
    setCurrentadid(value);
    setPopupOpen(true);
  }, []);

  const closePopup = () => {
    setCurrentadid(null);
    setPopupOpen(false);
  };

  const closePopupJorney = () => {
    // setCurrentadid(null);
    setPopupOpentwo(false);
  };

  const handleRowExpand = useCallback((row) => {
    setExpandedRows((prev) => ({
      ...prev,
      [row.id]: !prev[row.id],
    }));
  }, []);

  const [dir, setDir] = useState(null);
  const handleSort = useCallback((column, direction) => {
    sortingRef.current.mainTableColumn = column.columnName;
    sortingRef.current.mainTableSortDirection = direction;
    setDir({
      dir: sortingRef.current.mainTableSortDirection,
      columnName: sortingRef.current.mainTableColumn,
    });
  }, []);

  const sortedData = useMemo(() => {
    if (!sortingRef.current.mainTableColumn) return mappedData;

    return [...mappedData].sort((a, b) => {
      if (typeof a[sortingRef.current.mainTableColumn] === "string") {
        // For strings, use localeCompare()
        return sortingRef.current.mainTableSortDirection === "asc"
          ? a[sortingRef.current.mainTableColumn].localeCompare(
            b[sortingRef.current.mainTableColumn]
          )
          : b[sortingRef.current.mainTableColumn].localeCompare(
            a[sortingRef.current.mainTableColumn]
          );
      } else {
        // For numbers, use subtraction
        return sortingRef.current.mainTableSortDirection === "asc"
          ? a[sortingRef.current.mainTableColumn] -
          b[sortingRef.current.mainTableColumn]
          : b[sortingRef.current.mainTableColumn] -
          a[sortingRef.current.mainTableColumn];
      }
    });
  }, [
    mappedData,
    sortingRef.current.mainTableSortDirection,
    sortingRef.current.mainTableColumn,
    filteredData,
  ]);

  return (
    <>
      <div className="small-box-cont">
        <DataTable
          className="custom-data-table2"
          noHeader
          columns={columns2(openPopup, columnConfigs)}
          // columns={columns2(openPopup, handleSort)}
          data={isFetching || isCompareFetching ? [] : sortedData}
          pointerOnHover
          striped
          responsive
          pagination
          expandableRows
          onSort={handleSort}
          sortServer
          expandableRowExpanded={(row) =>
            selectedAdList === "ad_set" || selectedAdList === "ad"
          }
          expandableRowsComponent={(row) => {
            const currentCampaign = row.data;

            const originalCampaign = filteredData[0]?.campaign.find(
              (campaign) => campaign.campaign_id === currentCampaign.id
            );
            const originalAdSets = originalCampaign
              ? originalCampaign.ad_sets
              : [];

            const compareCampaign = filteredData[1]?.campaign.find(
              (campaign) => campaign.campaign_id === currentCampaign.id
            );

            const compareAdSets = compareCampaign
              ? compareCampaign.ad_sets
              : [];

            return (
              <ExpandableComponent
                sortingRef={sortingRef}
                originalAdSets={originalAdSets}
                compareAdSets={compareAdSets}
                trafficSource={trafficSource}
                selectedAdList={selectedAdList}
                expandedRows={expandedRows}
                handleRowExpand={handleRowExpand}
                openPopup={openPopup}
                columnConfigs={columnConfigs}
              />
            );
          }}
          customStyles={customStyles}
          expandableIcon={{
            collapsed: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="yellow"
                viewBox="0 0 24 24"
              >
                <path fill="white" color="yellow" d="M7 10l5 5 5-5H7z" />
              </svg>
            ),
            expanded: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill="yellow"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path fill="yellow" d="M7 14l5-5 5 5" />
              </svg>
            ),
          }}
          noDataComponent={
            isFetching && !isError ? (
              <ReportDetailsLoader />
            ) : isCompareFetching && !isCompareError ? (
              <ReportDetailsLoader />
            ) : (
              <div
                style={{
                  width: "100%",
                  margin: "0 auto",
                  backgroundColor: "#1b1c1e",
                  color: "#fff",
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                {error}
              </div>
            )
          }
        />
        {isPopupOpen && (
          <SalesPopup
            setPopupOpentwo={setPopupOpentwo}
            isPopupOpen={isPopupOpen}
            isPopupFn={setPopupOpen}
            closePopup={closePopup}
            adid={currentadid}
            trafficSource={trafficSource}
            fixedHeader
            fixedHeaderScrollHeight="300px"
            className="scrollable-container"
          />
        )}
      </div>
      <div>
        {data?.userdata?.length > 0 && (
          <UserJourney isOpen={isPopupOpentwo} closePopup={closePopupJorney} />
        )}
      </div>
    </>
  );
};

export default React.memo(ReportDetails);
