import http from "../../services/api/http";
import catchAsync from "../../utils/catchAsync";

// Fetch UTM Sources
export const getUTMSources = catchAsync(async () => {
  const response = await http.get("/setting/utm_source/get_utmsource");
  if (response.status === 200) {
    return response;
  } else {
    throw new Error(`Failed to fetch UTM sources, status: ${response.status}`);
  }
});

// Create UTM Source
export const createUTMSource = catchAsync(async (utmData) => {
  console.log("Creating UTM Source with data:", utmData); // Confirm data being sent
  const response = await http.post("/setting/utm_source/create_utmsource", utmData);
  if (response.status === 201) {
    return response;
  } else {
    throw new Error(`Failed to create UTM source, status: ${response.status}`);
  }
});


// Update UTM Source
export const updateUTMSource = catchAsync(async (utmData) => {
  const response = await http.put("/setting/utm_source/update_utmsource", utmData);
  if (response.status === 200) {
    return response;
  } else {
    throw new Error(`Failed to update UTM source, status: ${response.status}`);
  }
});

// Delete UTM Source
export const deleteUTMSource = catchAsync(async (utmId) => {
  const response = await http.put("/setting/utm_source/delete_utmsource", { id: utmId });
  if (response.status === 200) {
    return response;
  } else {
    throw new Error(`Failed to delete UTM source, status: ${response.status}`);
  }
});
