export function extractCustomerIDsAndRefreshToken(url) {
  // Checking if the URL contains a query string
  if (!url.includes("?")) {
    console.log("URL does not contain a query string");
    return { customerIDs: [], refreshToken: null };
  }

  // Extracting the query parameter part of the URL
  const queryString = url.split("?")[1];

  // Decoding the URL-encoded string
  const decodedQueryString = decodeURIComponent(queryString);

  // Extracting the resource_names parameter value
  const resourceNamesParam = decodedQueryString
    .split("&")
    .find((param) => param.startsWith("resource_names="));

  // Extracting the refresh_token parameter value
  const refreshTokenParam = decodedQueryString
    .split("&")
    .find((param) => param.startsWith("refresh_token="));

  // Checking if resourceNamesParam is undefined
  if (!resourceNamesParam) {
    console.log("resource_names parameter not found");
    return { customerIDs: [], refreshToken: null };
  }

  const resourceNamesValue = resourceNamesParam.split("=")[1];

  // Removing the square brackets and single quotes from the value
  const cleanedResourceNames = resourceNamesValue.replace(/\[|\]|'/g, "");

  // Splitting the cleaned string into an array of customer IDs
  const customerIDs = cleanedResourceNames.split(", ");

  // Extracting the refresh_token value
  const refreshToken = refreshTokenParam ? refreshTokenParam.split("=")[1] : null;

  return { customerIDs, refreshToken };
}

// This function first gets the current date and time, adds 59 days to it

export const addMetaExpiry = (value) => {
  const timestampInSeconds = value;

  // Create a new Date object by multiplying the timestamp by 1000 to convert it to milliseconds
  const date = new Date(timestampInSeconds * 1000);

// Extract the date components
const year = date.getFullYear();
const month = String(date.getMonth() + 1).padStart(2, '0');
const day = String(date.getDate()).padStart(2, '0');
const hours = String(date.getHours()).padStart(2, '0');
const minutes = String(date.getMinutes()).padStart(2, '0');
const seconds = String(date.getSeconds()).padStart(2, '0');

// Formatted date string
const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
};
