import { createSlice } from '@reduxjs/toolkit';

const metautmSlice = createSlice({
  name: 'metautm',
  initialState: false, 
  reducers: {
    setMetautm(state, action) {
      return action.payload;
    },
  },
});

export const { setMetautm } = metautmSlice.actions;
export default metautmSlice.reducer;
