import { createSlice } from '@reduxjs/toolkit';

const gadsaccntSlice = createSlice({
  name: 'gadsaccnt',
  initialState: false, 
  reducers: {
    setGadsaccnt(state, action) {
      return action.payload;
    },
  },
});

export const { setGadsaccnt } = gadsaccntSlice.actions;
export default gadsaccntSlice.reducer;
