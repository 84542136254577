import { useSelector } from "react-redux";


// ===============================================================
//  hook for filtering data based on search on Reporting page table
// =========================================================
// =========================================================

const useFilterData = () => {


    const { tableData } = useSelector((state) => state.table);

    // Function to filter the data based on filterText (for campaign_id, campaign_name, ad_set_name, and ad_set_id)
    const filterData = (filterType, filterText) => {
        const lowercasedFilterText = filterText?.toLowerCase();

        const newFilteredData = {
            campaign: tableData?.campaign.reduce((acc, campaign) => {
                let campaignMatchFound = false;

                // Filter ad sets and ads
                const filteredAdSets = campaign.ad_sets.reduce((adSetAcc, adSet) => {
                    let adSetMatchFound = false;

                    const filteredAds = adSet.ads.filter((ad) => {
                        const adName = ad.ad_name?.toLowerCase() || "";
                        if (adName.includes(lowercasedFilterText) && filterType === "ad") {
                            adSetMatchFound = true;
                            return true;
                        }
                        return false;
                    });

                    const adSetName = adSet.ad_set_name?.toLowerCase() || "";
                    const adSetId = adSet.ad_set_id?.toString() || ""; // Convert ad_set_id to string for comparison
                    if (
                        (adSetName.includes(lowercasedFilterText) || adSetId === lowercasedFilterText) &&
                        filterType === "ad_set"
                    ) {
                        adSetMatchFound = true;
                        adSetAcc.push({
                            ...adSet,
                            ads: adSet.ads, // Keep all ads if ad set name or ID matches
                        });
                    } else if (filteredAds.length > 0) {
                        adSetAcc.push({
                            ...adSet,
                            ads: filteredAds,
                        });
                    }

                    return adSetAcc;
                }, []);

                const campaignName = campaign.campaign_name?.toLowerCase() || "";
                const campaignId = campaign.campaign_id?.toString() || ""; // Convert campaign_id to string for comparison

                if (
                    (campaignName.includes(lowercasedFilterText) ||
                        campaignId === lowercasedFilterText) && // Match by campaign_name or campaign_id
                    filterType === "campaign"
                ) {
                    campaignMatchFound = true;
                    acc.push({
                        ...campaign,
                        ad_sets: campaign.ad_sets, // Keep all ad sets and ads if campaign name or ID matches
                    });
                } else if (filteredAdSets.length > 0) {
                    acc.push({
                        ...campaign,
                        ad_sets: filteredAdSets,
                    });
                }

                return acc;
            }, []),
        };

        return newFilteredData;
    };

    return { filterData }
}


export default useFilterData