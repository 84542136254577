// profileSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProfileDetails, updateProfileDetails } from "../../services/api/profileApis";

const initialState = {
  profileData: null,
  isFetching: false,
  isError: false,
  error: null,
};

export const fetchProfileData = createAsyncThunk(
  "profileData/fetchProfileData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getProfileDetails();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed");
    }
  }
);

export const updateProfileData = createAsyncThunk(
  "profileData/updateProfileData",
  async (values, { rejectWithValue }) => {
    try {
      const response = await updateProfileDetails(values);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed");
    }
  }
)

export const profileDataSlice = createSlice({
  name: "profileData",
  initialState,
  extraReducers: {
    [fetchProfileData.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
    },
    [fetchProfileData.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = false;
      state.profileData = payload;
      state.error = null;
    },
    [fetchProfileData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
    [updateProfileData.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
    },
    [updateProfileData.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = false;
      state.error = null;
    },
    [updateProfileData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    }
  },
});

export default profileDataSlice.reducer;
