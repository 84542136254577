import React, { useEffect, useState } from "react";
import { BsDashLg } from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import { useDispatch } from "react-redux";

const CalenderCard = ({ onApply, onCancel, setDate, onClose, date }) => {
  const [startDate, setStartDate] = useState(
    date && date[0] ? new Date(date[0]) : null
  );
  const [endDate, setEndDate] = useState(
    date && date[1] ? new Date(date[1]) : null
  );
  const [tempStartDate, setTempStartDate] = useState(startDate);
  const [tempEndDate, setTempEndDate] = useState(endDate);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedRange, setSelectedRange] = useState(""); // State to track the selected range

  const dispatch = useDispatch();

  const updateSelectedRange = (start, end) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset today's time to 00:00:00 for comparison

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Get the current week's Sunday
    const startOfThisWeek = new Date(today);
    startOfThisWeek.setDate(today.getDate() - today.getDay()); // Sunday of this week (20th Oct 2024)

    // Get the previous week's Sunday and Saturday
    const startOfLastWeek = new Date(startOfThisWeek);
    startOfLastWeek.setDate(startOfThisWeek.getDate() - 7);
    const endOfLastWeek = new Date(startOfLastWeek);
    endOfLastWeek.setDate(startOfLastWeek.getDate() + 6);

    const differenceInDays = (end - start) / (1000 * 60 * 60 * 24);
    console.log(differenceInDays);

    // Check if both dates are today
    if (
      start.toDateString() === today.toDateString() &&
      end.toDateString() === today.toDateString()
    ) {
      setSelectedRange("Today");
    }
    // Check if both dates are yesterday
    else if (
      start.toDateString() === yesterday.toDateString() &&
      end.toDateString() === yesterday.toDateString()
    ) {
      setSelectedRange("Yesterday");
    }
    // Check if the selected range is exactly from this week's Sunday to today
    else if (
      start.toDateString() === startOfThisWeek.toDateString() &&
      end.toDateString() === today.toDateString()
    ) {
      setSelectedRange("This Week(Sun-Today)");
    }
    // Check if the selected range is last week (Sunday to Saturday)
    else if (
      start.toDateString() === startOfLastWeek.toDateString() &&
      end.toDateString() === endOfLastWeek.toDateString()
    ) {
      setSelectedRange("Last Week(Sun-Sat)");
    } else {
      switch (differenceInDays) {
        case 6:
          setSelectedRange("Last 7 days");
          break;
        case 13:
          setSelectedRange("Last 14 days");
          break;
        case 27:
          setSelectedRange("Last 28 days");
          break;
        case 29:
          setSelectedRange("Last 30 days");
          break;
        case 89:
          setSelectedRange("Last 90 days");
          break;
        case 119:
          setSelectedRange("Last 120 days");
          break;
        default:
          setSelectedRange(""); // No match for custom or non-predefined ranges
          break;
      }
    }
  };

  useEffect(() => {
    if (!startDate || !endDate) {
      handleToday();
    }
  }, []);

  useEffect(() => {
    // Restore the selected range if the dates match previously applied dates
    if (startDate && endDate) {
      const differenceInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
      console.log(differenceInDays);
      switch (differenceInDays) {
        case 0:
          setSelectedRange("Today");
          break;
        case 1:
          setSelectedRange("Yesterday");
          break;
        case 6:
          setSelectedRange("Last 7 days");
          break;
        case 13:
          setSelectedRange("Last 14 days");
          break;
        case 27:
          setSelectedRange("Last 28 days");
          break;
        case 29:
          setSelectedRange("Last 30 days");
          break;
        case 89:
          setSelectedRange("Last 90 days");
          break;
        case 119:
          setSelectedRange("Last 120 days");
          break;
        default:
          setSelectedRange(""); // Custom or manual selection
          break;
      }
    }
  }, [startDate, endDate]);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setTempStartDate(start);
    setTempEndDate(end);

    if (start && end) {
      updateSelectedRange(start, end); // Call to update the selected range
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      updateSelectedRange(startDate, endDate); // Call to update the selected range on reopen
    }
  }, [startDate, endDate]);

  const handleClear = () => {
    setTempStartDate(null);
    setTempEndDate(null);
    setSelectedDates([]);
    onCancel(selectedDates);
  };

  const handleApply = () => {
    if (tempStartDate && tempEndDate) {
      const selected = [formatDate(tempStartDate), formatDate(tempEndDate)];
      setSelectedDates(selected);
      setStartDate(tempStartDate);
      setEndDate(tempEndDate);
      dispatch(setDate(selected));
      onApply(selected);
      onClose();

      // Check for matching date ranges and update selectedRange
      const differenceInDays =
        (tempEndDate - tempStartDate) / (1000 * 60 * 60 * 24);
      console.log(differenceInDays);

      switch (differenceInDays) {
        case 0:
          setSelectedRange("Today");
          break;
        case 1:
          setSelectedRange("Yesterday");
          break;
        case 6:
          setSelectedRange("Last 7 days");
          break;
        case 13:
          setSelectedRange("Last 14 days");
          break;
        case 27:
          setSelectedRange("Last 28 days");
          break;
        case 29:
          setSelectedRange("Last 30 days");
          break;
        case 89:
          setSelectedRange("Last 90 days");
          break;
        case 119:
          setSelectedRange("Last 120 days");
          break;
        default:
          setSelectedRange(""); // Custom or manual selection
          break;
      }
    }
  };

  const handleCancel = () => {
    setTempStartDate(startDate);
    setTempEndDate(endDate);
    onClose();
  };

  const handleLastDays = (days) => {
    const today = new Date();
    const endDateRange = new Date(today);
    endDateRange.setDate(today.getDate() - 1);

    const startDateRange = new Date(endDateRange);
    startDateRange.setDate(endDateRange.getDate() - (days - 1));

    setTempStartDate(startDateRange);
    setTempEndDate(endDateRange);
  };

  const handleThisWeek = () => {
    const today = new Date();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay());

    setTempStartDate(startOfWeek);
    setTempEndDate(today);
  };

  const handleLastWeek = () => {
    const today = new Date();
    const endOfLastWeek = new Date(today);
    endOfLastWeek.setDate(today.getDate() - today.getDay() - 1);
    const startOfLastWeek = new Date(endOfLastWeek);
    startOfLastWeek.setDate(endOfLastWeek.getDate() - 6);

    setTempStartDate(startOfLastWeek);
    setTempEndDate(endOfLastWeek);
  };

  const handleToday = () => {
    const today = new Date();
    setTempStartDate(today);
    setTempEndDate(today);
    setSelectedRange("Today"); // Set 'Today' as selected
  };

  const handleLastDay = () => {
    handleLastDays(1);
    setSelectedRange("Yesterday"); // Set 'Yesterday' as selected
  };

  const handleLast7Days = () => {
    handleLastDays(7);
    setSelectedRange("Last 7 days"); // Set 'Last 7 days' as selected
  };

  const handleLast14Days = () => {
    handleLastDays(14);
    setSelectedRange("Last 14 days");
  };

  const handleLast28Days = () => {
    handleLastDays(28);
    setSelectedRange("Last 28 days");
  };

  const handleLast30Days = () => {
    handleLastDays(30);
    setSelectedRange("Last 30 days");
  };

  const handleLast90Days = () => {
    handleLastDays(90);
    setSelectedRange("Last 90 days");
  };

  const handleLast120Days = () => {
    handleLastDays(120);
    setSelectedRange("Last 120 days");
  };

  const handleThisWeekDates = () => {
    handleThisWeek();
    setSelectedRange("This Week(Sun-Today)"); // Set 'This Week' as selected
  };

  const handleLastWeekDates = () => {
    handleLastWeek();
    setSelectedRange("Last Week(Sun-Sat)"); // Set 'Last Week' as selected
  };

  const formatDate = (date) => {
    return date ? date.toDateString().slice(4) : "";
  };

  return (
    <div className="calender-card-container">
      <div className="calender-total-container">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            borderBottom: "1px solid #fff",
          }}
        >
          <div className="handle-last-dates-container">
            <p className="custom-style">Custom</p>
            <div className="options-container">
              <p
                className="options-style"
                onClick={handleToday}
                style={{ color: selectedRange === "Today" ? "yellow" : "#fff" }} // Yellow if selected
              >
                Today
              </p>
              <p
                className="options-style"
                onClick={handleLastDay}
                style={{
                  color: selectedRange === "Yesterday" ? "yellow" : "#fff",
                }}
              >
                Yesterday
              </p>
              <p
                className="options-style"
                onClick={handleThisWeekDates}
                style={{
                  color:
                    selectedRange === "This Week(Sun-Today)"
                      ? "yellow"
                      : "#fff",
                }}
              >
                This Week(Sun-Today)
              </p>
              <p
                className="options-style"
                onClick={handleLastWeekDates}
                style={{
                  color:
                    selectedRange === "Last Week(Sun-Sat)" ? "yellow" : "#fff",
                }}
              >
                Last Week(Sun-Sat)
              </p>
              <p
                className="options-style"
                onClick={handleLast7Days}
                style={{
                  color: selectedRange === "Last 7 days" ? "yellow" : "#fff",
                }}
              >
                Last 7 days
              </p>
              <p
                className="options-style"
                onClick={handleLast14Days}
                style={{
                  color: selectedRange === "Last 14 days" ? "yellow" : "#fff",
                }}
              >
                Last 14 days
              </p>
              <p
                className="options-style"
                onClick={handleLast28Days}
                style={{
                  color: selectedRange === "Last 28 days" ? "yellow" : "#fff",
                }}
              >
                Last 28 days
              </p>
              <p
                className="options-style"
                onClick={handleLast30Days}
                style={{
                  color: selectedRange === "Last 30 days" ? "yellow" : "#fff",
                }}
              >
                Last 30 days
              </p>
              <p
                className="options-style"
                onClick={handleLast90Days}
                style={{
                  color: selectedRange === "Last 90 days" ? "yellow" : "#fff",
                }}
              >
                Last 90 days
              </p>
              <p
                className="options-style"
                onClick={handleLast120Days}
                style={{
                  color: selectedRange === "Last 120 days" ? "yellow" : "#fff",
                }}
              >
                Last 120 days
              </p>
            </div>
          </div>

          <div className="calender-container">
            <div className="date-boxes-container">
              <div className="date-box">
                <p className="start-end-date-label-style">Start Date</p>
                <p className="dates-field">
                  {tempStartDate ? formatDate(tempStartDate) : ""}
                </p>
              </div>
              <p className="start-end-date-label-style">
                <BsDashLg size={20} />
              </p>
              <div className="date-box">
                <p className="start-end-date-label-style">End Date</p>
                <p className="dates-field">
                  {tempEndDate ? formatDate(tempEndDate) : ""}
                </p>
              </div>
            </div>

            <DatePicker
              selected={tempStartDate}
              startDate={tempStartDate}
              endDate={tempEndDate}
              onChange={handleDateChange}
              selectsRange
              inline
              monthsShown={2}
              maxDate={new Date()}
              disabledKeyboardNavigation
            />
          </div>
        </div>

        <div className="buttons-container">
          <div className="apply-cancel-container">
            <button onClick={handleCancel} className="apply-cancel-buttons">
              Cancel
            </button>
            <button onClick={handleApply} className="apply-cancel-buttons">
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalenderCard;
