import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createClient,
  getAgency,
  getAgencyAll,
} from "../../services/api/agencyApis";

// Async thunk to fetch agency data
export const getAgencyData = createAsyncThunk(
  "agency/getAgencyData", // Renamed for clarity
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAgency();
      if (response.status === 200) {
        return response.data; // Return the successful response data
      } else {
        return rejectWithValue("Unexpected response status"); // Catch any non-200 statuses
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.message || "Failed"
      );
    }
  }
);
export const getAgencyDataAll = createAsyncThunk(
  "agency/getAgencyDataAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAgencyAll();
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue("Unexpected response status");
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.message || "Failed"
      );
    }
  }
);
export const signClient = createAsyncThunk(
  "auth/signClient",
  async (values, { rejectWithValue }) => {
    try {
      const response = await createClient(values);
      console.log(response);
      // Check if the response status is 201 (created)
      if (response.status === 201) {
        return response.data.meta.arg; // Return the data from the response
      } else {
        return rejectWithValue(
          "Failed to create client: Unexpected status code " + response.status
        );
      }
    } catch (error) {
      // Access the error message safely
      const errorMessage =
        error.response?.data?.message ||
        "Failed to create client due to an unexpected error.";
      return rejectWithValue(errorMessage);
    }
  }
);

// Initial state
const initialState = {
  agencyAccounts: [], // Changed to an empty array for consistency
  currentClient: null,
  loading: false,
  error: null,
  agencyAccountsAll: [],
  loadingAll: false,
  errorAll: null,
  isFetchingClient: false,
  isErrorClient: false,
  errorClient: null,
};

// Create the slice
const agencySlice = createSlice({
  name: "agency",
  initialState,
  reducers: {
    setClient(state, action) {

      state.currentClient = action.payload;
      localStorage.setItem("t_workSpaceId", action.payload.workspace); // Consistency with Axios interceptor
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAgencyData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAgencyData.fulfilled, (state, action) => {
        state.loading = false;
        state.agencyAccounts = action.payload;
        state.error = null;
      })
      .addCase(getAgencyData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAgencyDataAll.pending, (state) => {
        state.loadingAll = true;
        state.errorAll = null;
      })
      .addCase(getAgencyDataAll.fulfilled, (state, action) => {

        state.loadingAll = false;
        state.agencyAccounts = action.payload;
        state.agencyAccountsAll = action.payload;
        state.errorAll = null;
      })
      .addCase(getAgencyDataAll.rejected, (state, action) => {
        state.loadingAll = false;
        state.errorAll = action.payload;
      })
      .addCase(signClient.pending, (state) => {
        state.isFetchingClient = true;
        state.isErrorClient = false;
      })
      .addCase(signClient.fulfilled, (state, { payload }) => {
        state.isFetchingClient = false;
        state.isErrorClient = false;
        state.errorClient = null;
      })
      .addCase(signClient.rejected, (state, { payload }) => {
        state.isFetchingClient = false;
        state.isErrorClient = true;
        state.errorClient = payload;
      });
  },
});

// Export the reducer and actions
export const { setClient } = agencySlice.actions;
export default agencySlice.reducer;
