import React from "react";
import "./styles.css";
import CustomizeDataOne from "./CustomizeDataOne";
import CustomizeDataTwo from "./CustomizeDataTwo";
import CustomizeDataThree from "./CustomizeDataThree";

function CustomizeColumns({ click, set }) {
  const handleSet = () => {
    set((prev) => !prev);
  };
  return (
    click && (
      <>
        <div onClick={handleSet} className="cutomize-columns-overlay ">
          {" "}
        </div>
        <div className="cutomize-container">
          <div className="title-cutomize">Customise Column</div>
          <div className="cutomize-data">
            <div className="cutomize-part-one">
              <div className="cutomize-data-one">
                <CustomizeDataOne />
              </div>
              <div className="cutomize-data-two">
                <CustomizeDataTwo />
              </div>
              <div className="cutomize-data-three">
                <CustomizeDataThree />
              </div>
            </div>

            <div className="cutomize-buttons">
              <button onClick={handleSet} className="cutomize-cancel">
                Cancel
              </button>
              <button className="cutomize-apply">Apply</button>
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default CustomizeColumns;
