import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getProductList,
  createProduct,
  updateProduct,
  deleteProduct,
  getTaxRate,
  updateTaxRate,
} from "../../services/api/productApis";
// import toast, { Toaster } from "react-hot-toast";
import { toastSetter } from "../../components/ToastContainer";

// Async Thunks for fetching, creating, updating, and deleting products
export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async (_, { rejectWithValue }) => {
    // Create a delay function
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    try {
      // Wait for 30 seconds before proceeding
      await delay(0); // 30000 milliseconds = 30 seconds

      const response = await getProductList();
      if (!response.data || response.data.length === 0) {
        throw new Error("No products found");
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to fetch products"
      );
    }
  }
);

// export const createNewProduct = createAsyncThunk('products/createProduct', async (productData, { rejectWithValue }) => {
//   try {
//     const response = await createProduct(productData);
//     if (response.status === 201) {
//       toast.success("Product added successfully!");
//       return response.data; // Return the new product
//     } else if (response.status == 409) {
//       console.log('response', response)
//       toast.error("1.Each sale price must be unique and can be assigned to only one product")
//       toast.error('2', response.message)
//       // throw Error(response.message)
//     }
//   } catch (error) {
//     console.log('errorrr', error)
//     toast.error(error?.message)
//     return rejectWithValue(error.response?.data?.message || error.message || 'Failed to create product');
//   }
// });

export const createNewProduct = createAsyncThunk(
  "products/createProduct",
  async (productData, { rejectWithValue }) => {
    try {
      const response = await createProduct(productData);
      if (response.status === 409) {
        toastSetter("error", response.message);
      }
      return response.data; // Return the new product
    } catch (error) {
      console.log("four ", error);
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to create product"
      );
    }
  }
);

export const updateExistingProduct = createAsyncThunk(
  "products/updateProduct",
  async (productData, { rejectWithValue }) => {
    try {
      const response = await updateProduct(productData);
      console.log("update");
      // if (response.status == 201) {
      //   toast.success("Product updated successfully!");
      // }
      return response.data; // Return the updated product
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to update product"
      );
    }
  }
);

export const deleteExistingProduct = createAsyncThunk(
  "products/deleteProduct",
  async (productId, { rejectWithValue }) => {
    try {
      const response = await deleteProduct(productId);
      if (response.status === 200) {
        return productId; // Return the productId to remove it from the Redux state
      } else {
        throw new Error(`Failed to delete product with id ${productId}`);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to delete product"
      );
    }
  }
);

// Tax Rate Thunks
export const fetchTaxRate = createAsyncThunk(
  "products/fetchTaxRate",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getTaxRate();
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to fetch tax rate"
      );
    }
  }
);

export const saveTaxRate = createAsyncThunk(
  "products/saveTaxRate",
  async (taxValues, { rejectWithValue }) => {
    try {
      let update = await updateTaxRate(taxValues);
      console.log("updatess", update);
      return update?.status;
    } catch (error) {
      console.log("this ierro", error);
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to update tax rate"
      );
    }
  }
);

// Initial State
const initialState = {
  products: [],
  taxRate: null,
  loading: false,
  taxLoading: false, // For tax-specific loading
  error: null,
  taxError: null, // For tax-specific errors
};

// Product Slice
const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Products Cases
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
        state.error = null;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Create New Product Cases
      .addCase(createNewProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.products.push(action.payload); // Add new product to the list
      })
      .addCase(createNewProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update Existing Product Cases
      .addCase(updateExistingProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateExistingProduct.fulfilled, (state, action) => {
        state.loading = false;
        const updatedProduct = action.payload;
        if (updatedProduct && updatedProduct.id) {
          const index = state.products.findIndex(
            (product) => product.id === updatedProduct.id
          );
          if (index !== -1) {
            state.products[index] = updatedProduct; // Update the product in the list
          }
        } else {
          console.error("Updated product has no valid id:", updatedProduct);
        }
      })
      .addCase(updateExistingProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete Existing Product Cases
      .addCase(deleteExistingProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteExistingProduct.fulfilled, (state, action) => {
        state.loading = false;
        const productId = action.payload;
        if (productId) {
          state.products = state.products.filter(
            (product) => product.id !== productId
          );
        } else {
          console.error("Deleted product has no valid id:", productId);
        }
      })
      .addCase(deleteExistingProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch Tax Rate Cases
      .addCase(fetchTaxRate.pending, (state) => {
        state.taxLoading = true;
        state.taxError = null;
      })
      .addCase(fetchTaxRate.fulfilled, (state, action) => {
        state.taxLoading = false;
        state.taxRate = action.payload; // Store the parsed tax rate as a number
        state.taxError = null;
      })
      .addCase(fetchTaxRate.rejected, (state, action) => {
        state.taxLoading = false;
        state.taxError = action.payload;
      })

      // Save Tax Rate Cases
      .addCase(saveTaxRate.pending, (state) => {
        state.taxLoading = true;
        state.taxError = null;
      })
      .addCase(saveTaxRate.fulfilled, (state, action) => {
        state.taxLoading = false;
        state.taxRate = action.payload; // Update the state with the new tax rate
      })
      .addCase(saveTaxRate.rejected, (state, action) => {
        state.taxLoading = false;
        state.taxError = action.payload;
      });
  },
});

export default productSlice.reducer;
