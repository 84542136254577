import { createSlice } from "@reduxjs/toolkit";

const journeySlice = createSlice({
  name: "journey",
  initialState: false,
  reducers: {
    setJourneys(state, action) {
      return action.payload;
    },
  },
});

export const { setJourneys } = journeySlice.actions;
export default journeySlice.reducer;
