import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { verifyEmailChangeOTP } from "../../services/api/profileApis";

const validationSchema = Yup.object().shape({
  otp: Yup.string().required("OTP is required"),
});

function ChangeEmailForm({
  setIsPopupOpen,
  profileData,
  newEmail,
  setEmailChanged,
}) {
  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setEmailChanged(false);
      setSubmitting(true);
      console.log("Current Email Value:", values, newEmail);

      verifyEmailChangeOTP({ email: newEmail, otp: values.otp })
        .then((res) => {
          console.log(res);
        })
        .finally(() => {
          setSubmitting(false);
          setIsPopupOpen(false);
          setEmailChanged(true);
          console.log("form submitted !!!!");
        });
    },
  });

  return (
    <div className="integration-popup-container">
      <h2 className="integration-popup-heading">Verify Email</h2>
      <form
        className="integration-popup-form"
        // onSubmit={formik.handleSubmit}
      >
        <span style={{ width: "100%" }}>
          <label className="integration-popup-form-label">OTP</label>
          <input
            id="otp"
            name="otp"
            type="text"
            className="integration-popup-input"
            value={formik.values.otp}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </span>
      </form>
      <button
        type="button"
        className="integration-popup-submit"
        disabled={formik.isSubmitting}
        onClick={formik.handleSubmit}
      >
        Submit
      </button>
    </div>
  );
}

export default ChangeEmailForm;
