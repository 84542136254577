import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getPredictiveTable,
  getSalesVelocityTable,
} from "../../services/api/predictiveBehaviorApis";

const initialState = {
  salesVelocityTableData: null,
  isVelocityFetching: false,
  isVelocityError: false,
  VelocityError: null,
  isFetching: false,
  isError: false,
  error: null,
  trafficSource: "Facebook",
  campaign: null,
};

export const fetchSalesVelocityTableData = createAsyncThunk(
  "salesVelocityTableData/fetchSalesVelocityTableData",
  async (values, { rejectWithValue }) => {
    try {
      console.log("Hello");
      const response = await getSalesVelocityTable(values);
      console.log(response);
      if (response.status === 200) {
        console.log("response");
        console.log(response);
        return response.data;
      }

      // else throw new Error("Failed to fetch attribute report data");
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || error.message || "Failed"
      );
    }
  }
);
export const fetchPredictiveTableData = createAsyncThunk(
  "predictiveTableData/fetchPrediciveTableData",
  async (values, { rejectWithValue }) => {
    try {

      const response = await getPredictiveTable(values);
      if (response.status === 200) {
        return response;
      }
      // else throw new Error("Failed to fetch attribute report data");
    } catch (error) {
      console.timeLog(error.message);
      return rejectWithValue(
        error.response?.data?.message || error.message || "Failed"
      );
    }
  }
);

export const predictiveDataSlice = createSlice({
  name: "predictiveData",
  initialState,
  reducers: {
    setTrafficPredictive: (state, action) => {
      state.trafficSource = action.payload;
    },
  },
  extraReducers: {
    [fetchSalesVelocityTableData.pending]: (state) => {
      state.isVelocityFetching = true;
      state.isVelocityError = false;
    },
    [fetchSalesVelocityTableData.fulfilled]: (state, { payload }) => {
      state.isVelocityFetching = false;
      state.isVelocityError = false;
      state.salesVelocityTableData = payload;
      state.VelocityError = null;
    },
    [fetchSalesVelocityTableData.rejected]: (state, { payload }) => {
      state.isVelocityFetching = false;
      state.isVelocityError = true;
      state.VelocityError = payload;
    },
    [fetchPredictiveTableData.pending]: (state) => {
      state.isFetching = true;
      state.VelocityError = false;
    },
    [fetchPredictiveTableData.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = false;
      state.campaign = payload;
      state.error = null;
    },
    [fetchPredictiveTableData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
  },
});

export const { setTrafficPredictive } = predictiveDataSlice.actions;

export default predictiveDataSlice.reducer;
