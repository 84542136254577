import React from "react";
import "./CircularOutlineChart.css";
import { useSelector } from "react-redux";
import { transformToInternationalNumber } from "../../utils";



const CircularOutlineChart = ({
  traffic,
  value,
  color1 = "#FF7B00",
  color2 = "#787575",
  color1Percent = 5,
  color2Percent = 95,
  label = "Completed",
}) => {
  const { salesVelocityTableData, isVelocityFetching, isFetching, error } =
    useSelector((state) => state.predictiveData);
  console.log(salesVelocityTableData?.sales_conversion, traffic);
  const chartStyle = {
    background: `conic-gradient(${color1} 0% ${salesVelocityTableData?.sales_conversion / traffic
      }%, ${color2} 0% 100%)`,
  };

  return (
    <div className="chart-outline" style={chartStyle}>
      <div className="chart-inner">
        <div
          className="chart-text"
          style={{ display: "flex", flexDirection: "column", gap: "20px" }}
        >
          <div>
            <p style={{ fontSize: "1rem", fontWeight: "bold" }}>
              {parseFloat((salesVelocityTableData?.sales_conversion / traffic).toFixed(2))}%
            </p>
            <p style={{ fontSize: "0.8rem" }}>
              of contacts made <br /> a purchase
            </p>
          </div>
          <div>
            <p style={{ fontSize: "1rem", fontWeight: "bold" }}>{transformToInternationalNumber(value)}</p>
            <p style={{ fontSize: "0.8rem" }}>
              Total first order <br /> Revenue
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CircularOutlineChart;
