import React from "react";
import "./styles.css";

const ScheduleDemoPage = () => {
  return (
    <div className="schedule-demo-page-container">
      <div className="item item1">
        <h1>Lorem ipsum dolor sit amet consectetur.</h1>
        <ol>
          <li>Lorem ipsum dolor sit.</li>
          <li>Lorem ipsum, dolor sit amet consectetur adipisicing.</li>
          <li>Lorem ipsum dolor sit amet consectetur.</li>
          <li>Lorem ipsum dolor sit.</li>
          <li>Lorem, ipsum dolor.</li>
        </ol>
      </div>
      <div className="item item2">
        <form className="schedule-demo-form-container">
          <div className="schedule-demo-form-section">
            <label className="schedule-demo-form-label">
              What's your first name?
            </label>
            <div className="schedule-demo-form-input-container">
              <input
                type="text"
                name=""
                id=""
                className="schedule-demo-form-input"
              />
            </div>
          </div>
          <div className="schedule-demo-form-section">
            <label className="schedule-demo-form-label">
              What's your email?
            </label>
            <p>Please enter your business email</p>
            <div className="schedule-demo-form-input-container">
              <input
                type="text"
                name=""
                id=""
                className="schedule-demo-form-input"
              />
            </div>
          </div>
          <div className="schedule-demo-form-section">
            <label className="schedule-demo-form-label">
              What's your phone number?
            </label>
            <p>We won't spam call you</p>
            <div className="schedule-demo-form-input-container">
              <input
                type="text"
                name=""
                id=""
                className="schedule-demo-form-input"
              />
            </div>
          </div>
          <div className="schedule-demo-form-section">
            <label className="schedule-demo-form-label">
              What's your company name?
            </label>
            <div className="schedule-demo-form-input-container">
              <input
                type="text"
                name=""
                id=""
                className="schedule-demo-form-input"
              />
            </div>
          </div>
          {/* <div className="schedule-demo-form-section">
            <label className="schedule-demo-form-label">
              Are you looking to sign up as a Brand or an Agency?
            </label>
            <div className="schedule-demo-form-input-container">
              <input
                type="text"
                name=""
                id=""
                className="schedule-demo-form-input"
              />
            </div>
          </div> */}
        </form>
      </div>
      <div className="item item3">Third Item</div>
    </div>
  );
};

export default ScheduleDemoPage;
