import React from "react";

const IntegrationCardTitle = (props) => {
  // Destructure the title prop from props
  const { title } = props;

  return (
    <div
      style={{
        display: "flex", // Use flexbox for layout
        justifyContent: "space-between", // Space items evenly
        alignItems: "center", // Align items vertically centered
        marginBottom: "20px", // Space below the card title
      }}
    >
      {/* Render the title as a heading with no bottom margin */}
      <h2 className="integration-app-heading" style={{ marginBottom: 0 }}>
        {title} {/* Display the title passed as a prop */}
      </h2>
    </div>
  );
};

export default IntegrationCardTitle;
