import React from "react";
import "./styles.css";
function CustomizeDataOne() {
  return (
    <div className="main-left">
      <div className="break custom-one">
        <h5>Custom metrics</h5>
        <p className="yellow-text">Everyone with access to</p>
      </div>
      <div className="break custom-two">
        <h5>Performance</h5>
        <p>Ad relevance diagnostics</p>
        <p>Cost</p>
      </div>
      <div className="break custom-three">
        <h5>Engagement</h5>
        <p>Page post</p>
        <p>Calling</p>
        <p>Messaging</p>
        <p>Media</p>
        <p>Clicks</p>
        <p>Awareness</p>
      </div>
      <div className="break custom-four">
        <h5>Conversions</h5>
        <p>Standard events</p>
        <p>Custom conversions</p>
        <p>Custom events</p>
      </div>
      <div className="break custom-five">
        <h5>Object names and IDs</h5>
        <p>Status and dates</p>
        <p>Goal, budget & schedule</p>
        <p>Targeting</p>
      </div>
    </div>
  );
}

export default CustomizeDataOne;
