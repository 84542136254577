import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import {
  capitalizeFirstLetter,
  simplifyNumber,
  formatNumber,
} from "../../utils";

const MiniCharts = ({ mainTitle, data, title, index, roundupValues }) => {

  // Fetch dashboard graph data from Redux store
  const { dashboardGraphData } = useSelector(
    (state) => state.dashboardGraphData
  );

  const categories = Array.isArray(data) ? data.map((item) => item?.date) : [];

  const seriesData = Array.isArray(data) ? data.map((item) => item?.value) : [];

  const { dashboardMetricData } = useSelector(
    (state) => state?.dashboardMetricData
  );
  let reverseSeriesData;
  if (mainTitle === "Overall") {
    reverseSeriesData = Array.isArray(dashboardGraphData[4]?.data)
      ? dashboardGraphData[4]?.data.map((item) => item?.value)
      : [];
  } else {
    const newItem =
      dashboardMetricData &&
      dashboardMetricData?.filter(
        (i) => i.title.toUpperCase() === mainTitle.toUpperCase()
      )[0];
    reverseSeriesData = Array.isArray(newItem?.data[4]?.data)
      ? newItem?.data[4]?.data.map((item) => item?.value)
      : [];
  }

  // Chart options configuration
  const chartOptions = {
    chart: {
      type: "area",
      toolbar: {
        show: false, // Hide toolbar
      },
    },
    xaxis: {
      categories: categories,
      tickAmount: 6,
      axisBorder: {
        show: false,
      },
      labels: {
        rotate: 0,
        show: true,
        formatter: function (value) {
          // Check if it's the last category
          const date = new Date(value);
          const month = String(date.getMonth() + 1);
          const day = String(date.getDate());
          return day;
        },
        style: {
          fontSize: "0.8rem",
          fontWeight: "500",
          colors: "white",
        },
      },
    },
    yaxis:
      title === "REVENUE"
        ? [
          {
            min: 0,
            labels: {
              show: false,
              formatter: function (value, date) {
                // console.log(value);
                return simplifyNumber(formatNumber(value));
              },
              style: {
                fontSize: "0.8rem",
                fontWeight: "500",
                colors: "#9c9c9d",
              },
            },
          },
          {
            min: 0,
            labels: {
              show: false,
              formatter: function (value) {
                // console.log(value);
                return simplifyNumber(formatNumber(value));
              },
              style: {
                fontSize: "0.8rem",
                fontWeight: "500",
                colors: "#9c9c9d",
              },
            },

            opposite: true, // Place Y-axis on the right side
          },
        ]
        : [
          {
            labels: {
              show: false,
              formatter: function (value) {
                return formatNumber(value);
              },
              style: {
                fontSize: "0.9rem",
                fontWeight: "500",
                colors: "#9c9c9d",
              },
            },
          },
        ],
    // colors: title === "REVENUE" ? ["#c7cb00", "#ff7b00"] : ["#c7cb00"],
    colors: title === "REVENUE" ? ["#ff7b00", "#c7cb00"] : ["#c7cb00"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 4,
      curve: "smooth",
    },
    legend: {
      show: false,
    },

    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const selectedData = title === "REVENUE" ? reverseSeriesData : series;
        const date = new Date(categories[dataPointIndex]);
        const formattedDate = `${date.getDate()}-${date.getMonth() + 1
          }-${date.getFullYear()}`;

        // Check if series is defined and contains data for the current seriesIndex and dataPointIndex
        if (
          series &&
          series[seriesIndex] &&
          series[seriesIndex][dataPointIndex] !== undefined
        ) {
          const value = series[seriesIndex][dataPointIndex];
          let tooltipContent = '<div class="arrow_box">';

          tooltipContent += `<span class="date"><span>Date:</span> <span>${formattedDate}</span></span>`;

          if (title === "REVENUE") {
            // Display both Revenue and Spend values for the REVENUE chart
            tooltipContent += `<span class="itemtool"><span class="titletool">Revenue:</span> <span class="content">${roundupValues(series[0][dataPointIndex], "REVENUE")}</span></span>`;
            tooltipContent += `<span class="itemtool"><span class="titletool">Spend:</span> <span class="content">${roundupValues(series[1][dataPointIndex], "SPEND")}</span></span>`;
          } else {
            // For other charts, display the value with the chart title
            tooltipContent += `<span class="itemtool"><span class="titletool">${title === "CPA" || title === "AOV" || title === "ROI"
              ? title
              : capitalizeFirstLetter(title)
              }
              : </span> <span class="content">${roundupValues(value, title)}</span></span>`;
          }

          tooltipContent += "</div>";

          return tooltipContent;
        } else {
          // Handle case where series or specific elements are undefined
          return '<div class="arrow_box">Data not available</div>';
        }
      },
    },

    grid: {
      show: true,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: true, //or just here to disable only x axis grids
        },
      },
      yaxis: {
        lines: {
          show: false, //or just here to disable only y axis
        },
      },
    },
  };

  // Determine series based on title
  const series =
    title === "REVENUE"
      ? [
        { name: "Revenue", data: seriesData },
        { name: "Spend", data: reverseSeriesData },
      ]
      : [{ name: capitalizeFirstLetter(title), data: seriesData }];

  return (
    <>
      <Chart
        options={chartOptions}
        series={series}
        type="area"
        width={"100%"}
        height={"100%"}
        className="mini-chart"
      />

    </>
  );
};

export default MiniCharts;
