import "./cohortTableStyles.css";
import React, { useMemo } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { CohortTableLoader, cohortTableLoader } from "../../components/loadingComponent/SkeletonLoader";
import { formatBigNumber } from "../../utils";



const divStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",
  borderColor: "#1E40AF",
  position: "relative",
};

// const hrStyle = {
//   backgroundColor: "rgb(219 134 55)", // Equivalent to `bg-red-700`
//   width: "250px",
//   position: "absolute",
//   transform: "rotate(-31deg)",
//   left: "-127px",
//   bottom: "0",
//   border: "none",
//   height: "0.1px",
// };

const customStyles = {
  rows: {
    style: {
      // backgroundColor: "#FF7B00CC",
      backgroundColor: "#232428",
      color: "#fff",
      border: "none",
      fontfamily: "Montserrat",
    },
  },
  cells: {
    style: {
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
};



const columnConfigs = [
  // { name: "A new_customer", field: "new_customer" },
  // { name: "First Purchase", field: "firstpurchase" },
  { name: " Day 1-7", field: "day7" },
  { name: "Day 8-14", field: "day14" },
  { name: "Day 15-30", field: "days30" },
  { name: "Day 31-60", field: "day60" },
  { name: "Day 61-90", field: "day90" },
  { name: "Day 91-180", field: "day180" },
  { name: "Day 181-360", field: "day365" },
  { name: "Day 360+", field: "day365plus" },

];

let formulaOfPercentSale = (dayxy, firstpurchaseValue) => {
  let result = ((dayxy) * 100) / firstpurchaseValue;
  return parseFloat(result.toFixed(1));
};

const columns = [
  {
    name: "First Purchase",
    selector: (row) => row.firstpurchase,
    cell: (row) => formatBigNumber(row.firstpurchase),
    minWidth: '80px',
    style: {
      backgroundColor: "rgb(255 123 0)",
    },

  },
  ...columnConfigs.map(({ name, field }) => ({
    name: name,
    selector: (row) => row[field],
    cell: (row) => {
      return row[field] && (
        <div style={divStyle}>
          <span className="cohort-col-top-span" >{formatBigNumber(row[field])}</span>
          {/* <span className="cohort-col-top-span" >{row[field]}</span> */}
          {/* <hr style={hrStyle} /> */}
          <span className="cohort-col-bottom-span" >{formulaOfPercentSale(row[field], row?.firstpurchase)}%</span>
        </div>)
    },
    minWidth: '80px',
    conditionalCellStyles: [
      {
        when: (row) => {
          // Ensure row[field] has a value (not null, undefined, or empty string)
          return row[field] != null && row[field] !== "";
        },
        style: {
          background: "linear-gradient(to left top, rgb(255 123 0 / 80%) 50%, rgb(232 120 16) 50%)",
          color: "white",
          height: "60px",
          // outline: '1px solid',
          position: "relative",
          alignItems: "normal",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
      {
        when: (row) => row[field] === null,
        style: {
          // background: "#1B1E1C",
          background: "#232428",
          color: "white",
          height: "60px",
          // outline: '1px solid',
          position: "relative",
          alignItems: "normal",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    ],
  }))
];


const CohortTable = () => {


  const { tableData, isFetching, isError, error } = useSelector((state) => state.cohortTableData);

  let cohortTableData = tableData?.map(({ firstpurchase, day7, day14, days30, day60, day90, day180, day365, day365plus }) => ({ firstpurchase, day7, day14, days30, day60, day90, day180, day365, day365plus }));

  const memoizedData = useMemo(() => cohortTableData?.reverse(), [cohortTableData]);

  return (
    <div className="cohort-table" style={{ overflow: "hidden" }}>
      {isFetching ? <CohortTableLoader />
        :
        <DataTable columns={columns} data={memoizedData} customStyles={customStyles} />}
    </ div>
  );
};

export default CohortTable;

