import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createUser, userLogin } from "../../services/api/authApis";

const initialState = {
  isFetching: false,
  isError: false,
  error: null,
};

export const signUp = createAsyncThunk(
  "auth/signUp",
  async (values, { rejectWithValue }) => {
    try {
      const response = await createUser(values);
      // console.log(response,"AUTH SLICE")
      if (response.status !== 201) return rejectWithValue(response);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const signIn = createAsyncThunk(
  "auth/signIn",
  async (values, { rejectWithValue }) => {
    try {
      const response = await userLogin(values);
      if (response.status !== 200) return rejectWithValue(response);
      // return response.data;
      // console.log(response,"AUTH SLICE")
      return response
    } catch (error) {
      // return rejectWithValue(error.response.data);
      // console.log(error)
      return rejectWithValue(error.response);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [signIn.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
    },
    [signIn.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = false;
      state.error = null;
    },
    [signIn.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
    [signUp.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
    },
    [signUp.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = false;
      state.error = null;
    },
    [signUp.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
  },
});

export default authSlice.reducer;
