import React from "react";
import { creativescardbg, fallBackImg } from "../../assets";
import { RxVideo } from "react-icons/rx";
import { LuImage } from "react-icons/lu";
import { useSelector } from "react-redux";
import { roundupValues, truncateString } from "../../utils";
import { CreativeCardLoader } from "../loadingComponent/SkeletonLoader";

const CreativeCard = ({
  card,
  opentheVideo,
  setOpentheVideo,
  setCreativeInsightData,
  creativeCards,
}) => {
  // console.log(card);
  const newState = useSelector((state) => state.metrix.numbers);
  const { isFetching } = useSelector((state) => state?.creativeTableData);
  // console.log(newState);

  const creativeInsightHandler = (id) => {
    // Filter the card array to get the card with the matching ID
    const [selectedCard] = creativeCards.filter((card) => card.id === id);

    // Log the filtered array (for debugging)
    // console.log(selectedCard);

    // Open the video
    setOpentheVideo(true);

    // Set the selected card directly, instead of an array
    setCreativeInsightData(selectedCard);
  };



  // Function to dynamically access object properties using a string key
  const getCardValue = (card, key) => {
    // console.log(key);
    // Convert key to camelCase for matching with card object keys

    const camelCaseKey = key?.toLowerCase();


    if (camelCaseKey === "reported sales") {
      return Math.round(card['sales'])
    }
    if (camelCaseKey === "revenue" || camelCaseKey === "spend") {
      return Math.round(card[camelCaseKey])
    }
    if (camelCaseKey === "cpa" || camelCaseKey === 'aov') {
      return roundupValues(card[camelCaseKey])
    }
    if (camelCaseKey === "engage rate %") {
      return card["engage_rate"];
    }
    if (camelCaseKey === "ctr %") {
      return card["ctr"];
    }
    if (camelCaseKey === "cr %") {
      return card["cr"];
    }
    if (camelCaseKey === "hook rate %") {
      return card["hookrate"];
    }
    if (camelCaseKey === "completion rate %") {
      return card["completion_rate"];
    }
    if (camelCaseKey === "cpnv") {
      return card["cpnv"];
    } else {
      return card[camelCaseKey] !== undefined
        ? card[camelCaseKey]
        : camelCaseKey === "sales"
          ? card["orders"]
          : "N/A";
    }
  };

  const handleImageError = (event) => {
    event.target.src = fallBackImg; // Replace with your fallback image path
  };

  return (
    <div
      className="creative-card"
      onClick={() => creativeInsightHandler(card.id)}
    >
      <div className="image-container">
        <img
          className="card-img"
          src={card.thumbnail}
          alt=""
          onError={handleImageError}
        />
        <div className="creative-card-icon">
          {card.adtype === "VIDEO" ? <RxVideo /> : <LuImage />}{" "}
          <p>{card.adtype === "VIDEO" ? "Video" : "Image"}</p>
        </div>
      </div>
      {
        isFetching ? <CreativeCardLoader /> :
          <div className="creative-card-details">
            <h5>{truncateString(card?.creative, 20)}</h5>
            {newState.length > 0 &&
              newState.map((item) => {
                const cardValue = getCardValue(card, item);
                const formattedItem =
                  item &&
                    ["engage_rate", "hookrate", "completion_rate"].includes(
                      item.toLowerCase()
                    )
                    ? `${item} %`
                    : item;

                return (
                  <p key={item}>
                    {formattedItem} <span>{cardValue}</span>
                  </p>
                );
              })}
          </div>
      }
    </div>
  );
};

export default CreativeCard;
