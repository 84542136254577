import "./style.css";
function Message() {
  return (
    <div className="message">
      <span className="notice">NOTICE:</span>
      <p>
        Trackocity is best experienced on a desktop. For optimal performance,
        please log in using a laptop or desktop computer.
      </p>
    </div>
  );
}

export default Message;
