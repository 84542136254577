import { useDispatch, useSelector } from "react-redux";
import DatePicker from "../DatePicker/DatePicker";
import { useEffect, useState } from "react";
import { setPopupDate } from "../../redux/reducers/dateSlice";
import Video from "../Video/Video";
import CreativeChart from "../charts/CreativeChart";
import { setCreativeState } from "../../redux/reducers/creativeTableDataSlice";
import { FaMeta } from "react-icons/fa6";
import { fallBackImg } from "../../assets";
import { roundupValues } from "../../utils";
import { IoInformationCircleOutline } from "react-icons/io5";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css';


function CreativePopup({ creativeInsightData }) {
  const dispatch = useDispatch();
  const [selectedDatespopup, setSelectedDatespopup] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const { popupdate } = useSelector((state) => state.date);
  const formatDate = (date) => {
    return date ? date.toDateString().slice(4) : "";
  };
  const { creativesClick, source } = useSelector((state) => state.creativeTableData);

  const handleClick = () => {
    dispatch(setCreativeState(false));
  };

  const setCurrentDayRange = () => {
    const today = new Date();
    const selected = [formatDate(today), formatDate(today)];
    setSelectedDatespopup(selected);
  };

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const updateSelectedDates = (dates) => {
    setSelectedDatespopup(dates);
    closePopup();
  };

  const cancelSelectedDates = () => {
    setSelectedDatespopup([]);
  };

  useEffect(() => {
    setCurrentDayRange();
  }, []);

  function formatInsightDate(dateString) {
    const date = new Date(dateString);
    const options = {
      year: "numeric", // e.g., 2024
      month: "short", // e.g., Aug
      day: "numeric", // e.g., 20
    };
    const formattedDate = date?.toLocaleDateString("en-US", options);
    return formattedDate.replace(",", "");
  }

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  let { engagement, p25, p50, p100, status } =
    creativesClick && creativeInsightData;
  let creativeChartData = { engagement, p25, p50, p100 };

  const PerformanceMetricsItems = ({ data }) => {
    let orderedKeys = [
      "creative",
      "creation_at",
      "impression",
      "click",
      "engagement",
      "revenue",
      "spend",
      "orders",
      "roas",
      "cpa",
      "cpm",
      "cpc",
      "ctr",
      "cr",
      "aov",
      "vv3s",
      "sec30",
      "thruplay",
      "engage_rate",
      "hookrate",
      "holdrate",
      "completion_rate",
      "videolength",
    ];
    if (source === 'youtube') {
      orderedKeys = [...orderedKeys, "p25", "p50", "p75", "p100"]
    }


    let upperCaseKeys = ["roas", "cpa", "cpm", "cpc", "ctr", "cr", "aov"];
    const changedName = {
      "vv3s": "video view 3s",
      "creation_at": "Creation at",
      "engage_rate": "Engage rate %",
      "completion_rate": "Completion rate %",
      "videolength": "video length (sec)",
      "hookrate": "Hook rate %",
      "holdrate": "Hold rate %",
      "p25": "Video watched 25%",
      "p50": "Video watched 50%",
      "p75": "Video watched 75%",
      "p100": "Video watched 100%",
    }

    let keyType = (key) => {
      if (Object.keys(changedName).includes(key)) {
        return changedName[key]
      }
      return upperCaseKeys.includes(key) ? key.toUpperCase() : capitalize(key);
    };
    let valueType = (key, value) => {
      if (key === "creation_at") return formatInsightDate(value);
      if (key === "revenue" || key === "spend") {
        return Math.round(value)
      }
      if (key === "cpa" || key === 'aov') {
        return roundupValues(value)
      }
      return value;
    };

    return (
      <div className="spend">
        {orderedKeys.map(
          (key) =>
            data[key] !== undefined && (
              <div key={key} className="performance-metrics-data-item">
                <p>{keyType(key)}
                  {
                    key === 'engage_rate' &&
                    <span id="Engage-rate-info">
                      <IoInformationCircleOutline size={15} />
                    </span>
                  }
                  {
                    key === 'hookrate' &&
                    <>
                      <span id="hook-rate-info" style={{ marginLeft: '5px' }}>
                        <IoInformationCircleOutline size={15} />
                      </span>
                      <Tooltip
                        anchorSelect="#hook-rate-info"
                        content="Hook Rate = vv3s / Impression"
                        place='top'
                        positionStrategy="fixed"
                      />
                    </>
                  }
                  {
                    key === 'completion_rate' && <>
                      <span id="complete-rate-info" style={{ marginLeft: '5px' }}>
                        <IoInformationCircleOutline size={15} />
                      </span>
                      <Tooltip
                        anchorSelect="#complete-rate-info"
                        content="Completion Rate = Complete video / vv3s"
                        place='top'
                        positionStrategy="fixed"
                      />
                    </>
                  }
                  {
                    key === 'holdrate' && <>
                      <span id="holdrate-info" style={{ marginLeft: '5px' }}>
                        <IoInformationCircleOutline size={15} />
                      </span>
                      <Tooltip
                        anchorSelect="#holdrate-info"
                        content="Hold Rate = Thruplay / vv3s"
                        place='top'
                        positionStrategy="fixed"
                      />
                    </>
                  }
                </p>
                <p className="metric-value">{valueType(key, data[key])}</p>
                <Tooltip
                  anchorSelect="#Engage-rate-info"
                  content="Engage Rate = Engagement / Impression"
                  place='top'
                  positionStrategy="fixed"
                />
              </div>
            )
        )}
      </div>
    );
  };

  let iframeSrc = "";
  let imageSrc = "";
 
  function youtubeToIframeUrl(ytUrl) {
    // Extract the video ID from the YouTube URL using a regular expression
    const regex = /(?:https?:\/\/(?:www\.)?youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S+[\?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = ytUrl.match(regex);
    
    // If a match is found, construct the embed URL
    if (match && match[1]) {
        const videoId = match[1];
        return `https://www.youtube.com/embed/${videoId}`;
    } else {
        return 'Invalid YouTube URL';
    }
}


  function getIframeSrc(htmlString) {
    let srcMatch = htmlString.match(/src="([^"]+)"/);
    
    return srcMatch ? srcMatch[1] : "";
  }

  // Check if creativeInsightData is valid and has a preview property
  if (creativeInsightData && creativeInsightData.preview) {
    if (creativeInsightData?.adtype === "VIDEO") {
      const preview = creativeInsightData.preview;
      iframeSrc = getIframeSrc(preview);
      
      if (iframeSrc !== "") 
        {
          iframeSrc = getIframeSrc(preview);
        }
           
          else {
        
        imageSrc = creativeInsightData?.thumbnail;
        if (source==='youtube')
          {
            iframeSrc=youtubeToIframeUrl(creativeInsightData?.preview)
           
          }
      }
    } else {
      
      imageSrc = creativeInsightData?.thumbnail;
    }
  } else {
    console.log(
      "creativeInsightData is null or does not have a preview property."
    );
  }

  const handleImageError = (event) => {
    event.target.src = fallBackImg; // Replace with your fallback image path
  };
  const feedButtonClick = () => {
    if (creativeInsightData?.facebookfeed) {
      window.open(creativeInsightData?.facebookfeed, "_blank");
    }
  };

  return (
    <>
      <div className="overlay"></div>
      <div className="creative-popup">
        <div className="creative-popup-heading">
          <p>Creative insights</p>
          <div className="popup-date-container">
            {/* <DatePicker
              openPopup={openPopup}
              date={popupdate}
              selectedDates={selectedDatespopup}
              isPopupOpen={isPopupOpen}
              closePopup={closePopup}
              updateSelectedDates={updateSelectedDates}
              cancelSelectedDates={cancelSelectedDates}
              setDate={setPopupDate}
            /> */}
            <button className="cross-btn" onClick={handleClick}>
              <img src="/cross.png"></img>
            </button>
          </div>
        </div>
        <div className="creative-popup-boxes">
          <div className="video-box">
            {creativeInsightData?.adtype === "VIDEO" && iframeSrc !== "" ? (
              <iframe
                src={iframeSrc}
                id="youtube-video"
                class="video-player"
                width="250"
                height="370"
                
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            ) : (
              <img
                src={imageSrc}
                id="youtube-video"
                class="video-player"
                width="250"
                height="370"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                onError={handleImageError}
              />
            )}
            {source === "facebook" && (
            <button className="feed-btn" onClick={feedButtonClick}>
             <FaMeta />
            <p>Feed</p>
             </button>
            )}
          </div>
          <div className="chart-box">
            <div className="chart-box-details">
              <span className="details">Details</span>
              <div className="chart-box-id">
                <span>ID</span>
                <span>{creativeInsightData?.creativeid.split(",").at(0)}</span>
              </div>
              {source === 'facebook' && <div className="chart-box-status">
                <span>Status</span>
                <span className="status-span">
                  <div
                    style={{
                      backgroundColor: status !== "ACTIVE" ? "red" : null,
                    }}
                    className="green-ball"
                  ></div>
                  {status === null ? "INACTIVE" : status},{" "}
                  {formatInsightDate(creativeInsightData?.creation_at)}
                </span>
              </div>
              }
            </div>
            {creativeInsightData?.adtype === "VIDEO" && (
              <div className="video-analysis">
                <div className="details">
                  Video analysis
                  <CreativeChart graphData={creativeChartData} />
                </div>
              </div>
            )}
            <div className="performance ">
              <p className="details perform">Performance metrics</p>
              <div className="perform-container">
                <PerformanceMetricsItems data={creativeInsightData} />
              </div>
            </div>
          </div>
        </div>
      </div>
      ;
    </>
  );
}

export default CreativePopup;
