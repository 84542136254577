import React, { useCallback, useMemo, useState } from "react";
import { calculateComparison, formatNumber, truncateString } from "../../utils";

import DataTable from "react-data-table-component";
import SalesPopup from "./SalesPopup";
import { MdArrowDropDown } from "react-icons/md";
import RenderReportingCell from "./RenderReportingCell";

const customStyles3 = {
  headRow: {
    style: {
      color: "white",
      background: "#1B1C1E",
      borderBottom: "1px solid white",
    },
  },
  cells: {
    style: {
      background: "#1B1C1E",
      color: "white",
      zIndex: 0,
    },
  },
};

const ExpandableSubComponent = ({
  row,
  trafficSource,
  originalAds,
  compareAds,
  openPopup,
  sortingRef,
  columnConfigs,
}) => {
  const columns = (openPopup) => {
    return [
      {
        name: "AD NAME",
        columnName: "ad_name",
        selector: (row) => row.ad_name,
        sortable: true,
        cell: (row) => (
          <span title={row.ad_name}>{truncateString(row.ad_name, 30)}</span>
        ),
        sortFunction: (a, b) => a.ad_name.localeCompare(b.ad_name),
        minWidth: "300px", // Increase the width here
        // minWidth: "290px", // Increase the width here
        style: {
          position: "sticky !important",
          left: "0px",
          zIndex: 1,
        },
        headerStyle: {
          position: "sticky ",
          top: 0,
          zIndex: 1,
        },
      },
      ...columnConfigs.map(({ name, field }) => ({
        name,
        selector: (row) => row[field],
        columnName: field,
        sortable: true,
        minWidth: name === "New Visits* %" && "110px",
        cell: (row) =>
          RenderReportingCell(row, field, `${field}Comparison`, openPopup),
      })),
    ];
  };

  // const calculateComparison = (value, compValue, expandSub) => {
  //   if (expandSub === 0) return null;

  //   if (compValue === -1 || compValue === 0) return null;

  //   const comparison = formatNumber((value - compValue) / compValue);
  //   return comparison === 0 ? null : comparison;
  // };

  const mappedData = useMemo(
    () =>
      Array.isArray(originalAds) &&
        originalAds &&
        originalAds !== null &&
        originalAds
        ? originalAds.map((item) => {
          const compareItem = compareAds?.find(
            (compare) => compare.ad_id === item.ad_id
          );

          // console.log(compareAds);
          const expandSub = compareAds?.length;
          // console.log(expandSub);

          const ad_name = item.ad_name;
          // Keys to exclude from processing
          const excludeKeys = ["ad_id", "ad_name"];
          // console.log('item ', item)

          // Get all keys from item excluding the ones we don't want to process
          const properties = Object.keys(item).filter(
            (key) => !excludeKeys.includes(key)
          );

          // Format item properties
          const formattedValues = {};
          properties.forEach((prop) => {
            formattedValues[prop] = formatNumber(item[prop]);
          });

          const comparisons = {};
          if (compareItem) {
            // Initialize compValues with formatted item values
            let compValues = {};
            if (compareItem) {
              // Update compValues with formatted compareItem properties
              Object.keys(compareItem).forEach((prop) => {
                compValues[prop] = formatNumber(compareItem[prop]);
              });
            }

            // Calculate comparisons
            properties.forEach((prop) => {
              const comparisonName = `${prop}Comparison`;
              comparisons[comparisonName] = calculateComparison(
                formattedValues[prop],
                compValues[prop],
                expandSub
              );
            });
          }

          return {
            id: item.ad_id,
            ad_name: ad_name,
            ...formattedValues,
            ...comparisons,
          };
        })
        : [],
    [originalAds, compareAds]
  );

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [currentadid, setCurrentadid] = useState(null);
  // console.log(currentadid);

  // const openPopup = (value) => {
  //   setCurrentadid(value);
  //   setPopupOpen(true);
  // };

  const closePopup = () => {
    setCurrentadid(null);
    setPopupOpen(false);
  };

  const [reRenderState, setRerenderState] = useState(null);

  const handleSort = useCallback((column, direction) => {
    sortingRef.current.subchildTableColumn = column.columnName;
    sortingRef.current.subChildTableSortDirection = direction;
    setRerenderState({
      Dir: sortingRef.current.subChildTableSortDirection,
      Col: sortingRef.current.subchildTableColumn,
    });
  }, []);

  // Sort the data
  const sortedData = useMemo(() => {
    if (!sortingRef.current.subchildTableColumn) return mappedData;

    return [...mappedData].sort((a, b) => {
      if (typeof a[sortingRef.current.subchildTableColumn] === "string") {
        // For strings, use localeCompare()
        return sortingRef.current.subChildTableSortDirection === "asc"
          ? a[sortingRef.current.subchildTableColumn].localeCompare(
            b[sortingRef.current.subchildTableColumn]
          )
          : b[sortingRef.current.subchildTableColumn].localeCompare(
            a[sortingRef.current.subchildTableColumn]
          );
      } else {
        // For numbers, use subtraction
        return sortingRef.current.subChildTableSortDirection === "asc"
          ? a[sortingRef.current.subchildTableColumn] -
          b[sortingRef.current.subchildTableColumn]
          : b[sortingRef.current.subchildTableColumn] -
          a[sortingRef.current.subchildTableColumn];
      }
    });
  }, [
    mappedData,
    sortingRef.current.subChildTableSortDirection,
    sortingRef.current.subchildTableColumn,
  ]);

  return (
    <>
      <DataTable
        className="custom-data-table2 sub-child-table"
        noHeader
        columns={columns(openPopup)}
        data={sortedData}
        pointerOnHover
        striped
        responsive
        onSort={handleSort}
        sortServer
        customStyles={customStyles3}
        expandableIcon={{
          collapsed: <MdArrowDropDown size={25} />,
          expanded: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="yellow"
              viewBox="0 0 24 24"
            >
              <path fill="yellow" d="M7 10l5 5 5-5H7z" />
            </svg>
          ),
        }}
      />
      {isPopupOpen && (
        <SalesPopup
          isPopupOpen={isPopupOpen}
          isPopupFn={setPopupOpen}
          closePopup={closePopup}
          adid={currentadid}
          trafficSource={trafficSource}
        />
      )}
    </>
  );
};

export default React.memo(ExpandableSubComponent);
