import React from "react";
import { CSVLink } from "react-csv";
import { exportLogo } from "../../assets";

const ExportReport = ({ tableData }) => {
  // Check if tableData is null or undefined
  if (!tableData) return null;

  // Check if tableData.campaign is not defined or not an array
  if (!tableData.campaign || !Array.isArray(tableData.campaign)) {
    console.log("tableData.campaign is not defined or not an array");
    return null;
  }

  // Destructure necessary properties
  const { campaign, clicks, impression, revenue, sales, spend } = tableData;

  // Convert campaign data to CSV format
  const csvData = campaign.flatMap((campaignItem) =>
    campaignItem.ad_sets.flatMap((adSet) =>
      adSet.ads.map((ad) => ({
        campaign_id: campaignItem.campaign_id,
        campaign_name: campaignItem.campaign_name,
        ad_set_id: adSet.ad_set_id,
        ad_set_name: adSet.ad_set_name,
        ad_id: ad.ad_id,
        ad_name: ad.ad_name,
        clicks: ad.clicks,
        impressions: ad.impressions,
        revenue: ad.revenue,
        sales: ad.sales,
        spend: ad.spend,
        profit: ad.revenue - ad.spend,
        roas: ad.revenue / ad.spend,
        cpa: ad.spend / ad.sales,
        cpm: (ad.spend * 1000) / ad.impressions,
        cpc: ad.spend / ad.clicks,
        ctr: (ad.clicks * 100) / ad.impressions,
      }))
    )
  );

  // Define CSV headers
  const headers = [
    { label: "Campaign ID", key: "campaign_id" },
    { label: "Campaign Name", key: "campaign_name" },
    { label: "Ad Set ID", key: "ad_set_id" },
    { label: "Ad Set Name", key: "ad_set_name" },
    { label: "Ad ID", key: "ad_id" },
    { label: "Ad Name", key: "ad_name" },
    { label: "Clicks", key: "clicks" },
    { label: "Impressions", key: "impressions" },
    { label: "Revenue", key: "revenue" },
    { label: "Sales", key: "sales" },
    { label: "Spend", key: "spend" },
    { label: "Profit", key: "profit" },
    { label: "ROAS", key: "roas" },
    { label: "CPA", key: "cpa" },
    { label: "CPM", key: "cpm" },
    { label: "CPC", key: "cpc" },
    { label: "CTR %", key: "ctr" },
  ];

  return (
    <>
      <CSVLink data={csvData} headers={headers} className="export-button">
        <img src={exportLogo} alt="export" />
      </CSVLink>
    </>
  );
};

export default ExportReport;
