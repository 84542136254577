// import React from "react";
// import { Formik, Form, Field } from "formik";
// import * as Yup from "yup";
// import { useTimezoneSelect, allTimezones } from "react-timezone-select";
// import "./styles.css";

// const currencyOptions = [
//   { label: "$ USD", value: "usd" },
//   { label: "€ EUR", value: "eur" },
//   { label: "£ GBP", value: "gbp" },
// ];

// const validationSchema = Yup.object().shape({
//   email: Yup.string().email("Invalid email").required("Email is required"),
//   phone: Yup.string()
//     .required("Phone number is required")
//     .matches(/^[0-9]+$/, "Phone number must be numeric")
//     .min(5, "Phone number must be at least 5 digits"),
//   name: Yup.string().required("Name is required"),
//   company: Yup.string()
//     .min(3, "At least 3 characters")
//     .max(20, "At most 20 characters")
//     .nullable()
//     .required("Company name is required"),
//   currency: Yup.string().required("Currency is required"),
//   timezone: Yup.string().required("Timezone is required"),
// });

// const SharedForm = ({ initialValues, onSubmit, onCancel }) => {
//   const { options } = useTimezoneSelect({ timezones: { ...allTimezones } });

//   console.log("initialValues", initialValues);
//   return (
//     <div className="pop-tool">
//       <div className="editPopup">
//         <Formik
//           initialValues={initialValues}
//           validationSchema={validationSchema}
//           onSubmit={onSubmit}
//         >
//           {({ errors, touched, setFieldValue }) => (
//             <Form>
//               <div className="row-edit">
//                 <div className="one-row">
//                   {errors.email && touched.email ? (
//                     <div className="error-shared">{errors.email}</div>
//                   ) : (
//                     <label className="label">Email:</label>
//                   )}
//                   <Field
//                     className={`input ${
//                       errors.email && touched.email ? "error-input" : ""
//                     }`}
//                     name="email"
//                     type="email"
//                     placeholder="Enter your email"
//                   />
//                 </div>

//                 <div className="one-row">
//                   {errors.name && touched.name ? (
//                     <div className="error-shared">{errors.name}</div>
//                   ) : (
//                     <label className="label">Name:</label>
//                   )}
//                   <Field
//                     className={`input ${
//                       errors.name && touched.name ? "error-input" : ""
//                     }`}
//                     name="name"
//                     placeholder="Enter your name"
//                   />
//                 </div>
//               </div>

//               <div className="row-edit">
//                 <div className="one-row">
//                   {errors.phone && touched.phone ? (
//                     <div className="error-shared">{errors.phone}</div>
//                   ) : (
//                     <label className="label">Phone Number:</label>
//                   )}
//                   <Field
//                     className={`input ${
//                       errors.phone && touched.phone ? "error-input" : ""
//                     }`}
//                     name="phone"
//                     placeholder="Enter your phone number"
//                   />
//                 </div>

//                 <div className="one-row">
//                   {errors.company && touched.company ? (
//                     <div className="error-shared">{errors.company}</div>
//                   ) : (
//                     <label className="label">Company Name:</label>
//                   )}
//                   <Field
//                     className={`input ${
//                       errors.company && touched.company ? "error-input" : ""
//                     }`}
//                     name="company"
//                     placeholder="Enter your company name"
//                   />
//                 </div>
//               </div>

//               <div className="row-edit">
//                 <div className="one-row">
//                   {errors.currency && touched.currency ? (
//                     <div className="error-shared">{errors.currency}</div>
//                   ) : (
//                     <label className="label">Currency:</label>
//                   )}
//                   <Field
//                     className={`input ${
//                       errors.currency && touched.currency ? "error-input" : ""
//                     }`}
//                     as="select"
//                     name="currency"
//                   >
//                     {currencyOptions.map((option) => (
//                       <option key={option.value} value={option.value}>
//                         {option.label}
//                       </option>
//                     ))}
//                   </Field>
//                 </div>

//                 <div className="one-row">
//                   {errors.timezone && touched.timezone ? (
//                     <div className="error-shared">{errors.timezone}</div>
//                   ) : (
//                     <label className="label">Time Zone:</label>
//                   )}
//                   <Field
//                     as="select"
//                     name="timezone"
//                     className={`input ${
//                       errors.timezone && touched.timezone ? "error-input" : ""
//                     }`}
//                     onChange={(e) => setFieldValue("timezone", e.target.value)}
//                   >
//                     <option value="" label="Select a timezone" />
//                     {options.map((option) => (
//                       <option key={option.value} value={option.label}>
//                         {option.label}
//                       </option>
//                     ))}
//                   </Field>
//                 </div>
//               </div>

//               <div className="row-buttons">
//                 <button className="cancel" type="button" onClick={onCancel}>
//                   Cancel
//                 </button>
//                 <button className="save" type="submit">
//                   Submit
//                 </button>
//               </div>
//             </Form>
//           )}
//         </Formik>
//       </div>
//     </div>
//   );
// };

// export default SharedForm;

import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import "./styles.css";
import { useSelector } from "react-redux";

const currencyOptions = [
  { label: "Select a currency", value: "" },
  { label: "£ INR", value: "INR" },
  { label: "$ USD", value: "USD" },

  { label: "£ GBP", value: "GBP" },
];

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^[0-9]+$/, "Phone number must be numeric")
    .min(5, "Phone number must be at least 5 digits"),
  name: Yup.string().required("Name is required"),
  company: Yup.string()
    .min(3, "At least 3 characters")
    .max(20, "At most 20 characters")
    .nullable()
    .required("Company name is required"),
  currency: Yup.string().required("Currency is required"),
  timezone: Yup.string().required("Timezone is required"),
});

const SharedForm = ({ initialValues, onSubmit, onCancel }) => {
  const { options } = useTimezoneSelect({ timezones: { ...allTimezones } });

  console.log("initialValues", initialValues);
  return (
    <div className="pop-tool">
      <div className="editPopup">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <div className="row-edit">
                <div className="one-row">
                  {errors.email && touched.email ? (
                    <div className="error-shared">{errors.email}</div>
                  ) : (
                    <label className="label">Email:</label>
                  )}
                  <Field
                    className={`input ${
                      errors.email && touched.email ? "error-input" : ""
                    }`}
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                  />
                </div>

                <div className="one-row">
                  {errors.name && touched.name ? (
                    <div className="error-shared">{errors.name}</div>
                  ) : (
                    <label className="label">Name:</label>
                  )}
                  <Field
                    className={`input ${
                      errors.name && touched.name ? "error-input" : ""
                    }`}
                    name="name"
                    placeholder="Enter your name"
                  />
                </div>
              </div>

              <div className="row-edit">
                <div className="one-row">
                  {errors.phone && touched.phone ? (
                    <div className="error-shared">{errors.phone}</div>
                  ) : (
                    <label className="label">Phone Number:</label>
                  )}
                  <Field
                    className={`input ${
                      errors.phone && touched.phone ? "error-input" : ""
                    }`}
                    name="phone"
                    placeholder="Enter your phone number"
                  />
                </div>

                <div className="one-row">
                  {errors.company && touched.company ? (
                    <div className="error-shared">{errors.company}</div>
                  ) : (
                    <label className="label">Company Name:</label>
                  )}
                  <Field
                    className={`input ${
                      errors.company && touched.company ? "error-input" : ""
                    }`}
                    name="company"
                    placeholder="Enter your company name"
                  />
                </div>
              </div>

              <div className="row-edit">
                <div className="one-row">
                  {errors.currency && touched.currency ? (
                    <div className="error-shared">{errors.currency}</div>
                  ) : (
                    <label className="label">Currency:</label>
                  )}
                  <Field
                    className={`input ${
                      errors.currency && touched.currency ? "error-input" : ""
                    }`}
                    as="select"
                    name="currency"
                  >
                    {currencyOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </div>

                <div className="one-row">
                  {errors.timezone && touched.timezone ? (
                    <div className="error-shared">{errors.timezone}</div>
                  ) : (
                    <label className="label">Time Zone:</label>
                  )}
                  <Field
                    as="select"
                    name="timezone"
                    className={`input ${
                      errors.timezone && touched.timezone ? "error-input" : ""
                    }`}
                    onChange={(e) => setFieldValue("timezone", e.target.value)}
                  >
                    <option value="" label="Select a timezone" />
                    {options.map((option) => (
                      <option key={option.value} value={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>

              <div className="row-buttons">
                <button className="cancel" type="button" onClick={onCancel}>
                  Cancel
                </button>
                <button className="save" type="submit">
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SharedForm;
