import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userdata: null, // Keep userdata as null or an object based on your requirements
  testsample: null,
};

const userJourneySlice = createSlice({
  name: "userJourney",
  initialState,
  reducers: {
    setUserdata: (state, action) => {
      // Directly assign the new data from action.payload to state.userdata
      state.userdata = action.payload; // Replace previous userdata with new payload
    },
    setSample: (state, action) => {
      state.testsample = action.payload;
    },
  },
});

// Exporting the actions
export const { setUserdata, setSample } = userJourneySlice.actions;

// Exporting the reducer
export default userJourneySlice.reducer;
