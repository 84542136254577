import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getUTMSources,
  createUTMSource,
  updateUTMSource,
  deleteUTMSource,
} from "../../services/api/utmSourceApis";

// Thunks for fetching, creating, updating, and deleting UTM sources
export const fetchUTMSources = createAsyncThunk(
  "utmSources/fetchUTMSources",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getUTMSources();
      if (!response.data || response.data.length === 0) {
        throw new Error("No UTM sources found");
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to fetch UTM sources"
      );
    }
  }
);

export const createNewUTMSource = createAsyncThunk(
  "utmSources/createUTMSource",
  async (utmData, { rejectWithValue }) => {
    console.log("Attempting to create UTM source:", utmData); // Add this
    try {
      const response = await createUTMSource(utmData);
      if (!response.data.id) {
        console.error("Created UTM source missing id:", response.data);
        throw new Error("Created UTM source missing id");
      }
      console.log("Hello");
      return response.data;
    } catch (error) {
      console.error("Error in createNewUTMSource:", error); // Log error details
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to create UTM source"
      );
    }
  }
);

export const updateExistingUTMSource = createAsyncThunk(
  "utmSources/updateUTMSource",
  async (utmData, { rejectWithValue }) => {
    try {
      const response = await updateUTMSource(utmData);
      console.log("API response for update:", response.data); // Check if response includes 'id'
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to update UTM source"
      );
    }
  }
);

export const deleteExistingUTMSource = createAsyncThunk(
  "utmSources/deleteUTMSource",
  async (utmId, { rejectWithValue }) => {
    try {
      const response = await deleteUTMSource(utmId);
      if (response.status === 200) {
        return utmId;
      } else {
        throw new Error(`Failed to delete UTM source with id ${utmId}`);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to delete UTM source"
      );
    }
  }
);

// Initial State
const initialState = {
  utmSources: [],
  loading: false,
  error: null,
};

// UTM Source Slice
const utmSourceSlice = createSlice({
  name: "utmSources",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch UTM Sources
      .addCase(fetchUTMSources.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUTMSources.fulfilled, (state, action) => {
        state.loading = false;
        state.utmSources = action.payload || []; // Ensure payload is an array
        state.error = null;
      })
      .addCase(fetchUTMSources.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Create New UTM Source
      .addCase(createNewUTMSource.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewUTMSource.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?.id) {
          // Check for id before pushing to array
          state.utmSources.push(action.payload);
        } else {
          console.error("New UTM source missing id:", action.payload);
        }
      })
      .addCase(createNewUTMSource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update Existing UTM Source
      .addCase(updateExistingUTMSource.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateExistingUTMSource.fulfilled, (state, action) => {
        state.loading = false;
        const updatedUTMSource = action.payload;
        if (updatedUTMSource?.id) {
          // Ensure id is present
          const index = state.utmSources.findIndex(
            (utm) => utm.id === updatedUTMSource.id
          );
          if (index !== -1) {
            state.utmSources[index] = updatedUTMSource;
          } else {
            console.error(
              "UTM source id not found in state:",
              updatedUTMSource.id
            );
          }
        } else {
          console.error("Updated UTM source missing id:", updatedUTMSource);
        }
      })
      .addCase(updateExistingUTMSource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete Existing UTM Source
      .addCase(deleteExistingUTMSource.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteExistingUTMSource.fulfilled, (state, action) => {
        state.loading = false;
        const utmId = action.payload;
        if (utmId) {
          // Check if utmId exists
          state.utmSources = state.utmSources.filter((utm) => utm.id !== utmId);
        } else {
          console.error("UTM source id missing for deletion:", utmId);
        }
      })
      .addCase(deleteExistingUTMSource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default utmSourceSlice.reducer;
