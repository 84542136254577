import React, { useEffect, useRef, useState } from "react";
import { OrderDetailsLoader, Popup } from "../../components";
import { getTableSalesData } from "../../services/api/tableDataApis";
import { useDispatch, useSelector } from "react-redux";
import { fetchTrackId } from "../../services/api/userJourneyApis";
import UserJourney from "./UserJourney";
import { setSample, setUserdata } from "../../redux/reducers/userJourneySlice";

const SalesPopup = ({
  setPopupOpentwo,
  closePopup,
  isPopupOpen,
  adid,
  trafficSource,
  isPopupFn,
}) => {
  const { date } = useSelector((state) => state.date);
  const [tableSalesData, setTableSalesData] = useState([]);
  const [userJourney, setUserJourney] = useState([]);
  const [openSales, setOpensales] = useState(false);

  // console.log("user", userJourney);
  // console.log(tableSalesData);
  const dispatch = useDispatch();
  const ulRefs = {
    names: useRef(null),
    emails: useRef(null),
    amounts: useRef(null),
    dates: useRef(null),
    tracking: useRef(null),
  };

  useEffect(() => {
    const handleScroll = (e) => {
      const { scrollTop } = e.target;
      if (ulRefs.names.current && e.target !== ulRefs.names.current)
        ulRefs.names.current.scrollTop = scrollTop;
      if (ulRefs.emails.current && e.target !== ulRefs.emails.current)
        ulRefs.emails.current.scrollTop = scrollTop;
      if (ulRefs.amounts.current && e.target !== ulRefs.amounts.current)
        ulRefs.amounts.current.scrollTop = scrollTop;
      if (ulRefs.dates.current && e.target !== ulRefs.dates.current)
        ulRefs.dates.current.scrollTop = scrollTop;
      if (ulRefs.tracking.current && e.target !== ulRefs.tracking.current)
        ulRefs.tracking.current.scrollTop = scrollTop;
    };

    const ulElements = [
      ulRefs.names.current,
      ulRefs.emails.current,
      ulRefs.amounts.current,
      ulRefs.dates.current,
      ulRefs.tracking.current,
    ];

    ulElements.forEach((ul) => {
      if (ul) ul.addEventListener("scroll", handleScroll);
    });

    return () => {
      ulElements.forEach((ul) => {
        if (ul) ul.removeEventListener("scroll", handleScroll);
      });
    };
  }, [
    tableSalesData,
    ulRefs.names,
    ulRefs.emails,
    ulRefs.amounts,
    ulRefs.dates,
  ]);

  useEffect(() => {
    getTableSalesData({
      startdate: date[0],
      enddate: date[1],
      adid: adid,
      channel: trafficSource,
    }).then((res) => {
      setTableSalesData(res?.data);
      // console.log(res?.data);
    });
  }, [date, adid, trafficSource]);

  const handleTrack = (values) => {
    // setUserOpen(true);
    // isPopupFn(false);
    // closePopup();
    fetchTrackId(values).then((data) => {
      //     setUserJourney(data.data);
      //     console.log("Data fetched:", data.data);
      dispatch(setUserdata(data.data));
      //     dispatch(setSample(""));
      setPopupOpentwo(true);
    })
      .catch((error) => {
        console.error("Error fetching track ID:", error);
      });
  };
  return (
    <div className="sales-popup">
      <Popup
        isOpen={isPopupOpen}
        onClose={closePopup}
        isPopupFn={isPopupFn}
        size="18"
        top="7px"
        right="7px"
      >
        <div
          className="sales-popup-container"
          style={{
            padding: "24px 22px 12px 22px",
            minHeight: "30vh",
            maxHeight: "10vh",
          }}
        >
          <div className="sales-popup-flex-boxes">
            <div className="title">Name</div>
            {tableSalesData && tableSalesData?.length > 0 ? (
              <ul ref={ulRefs.names}>
                {tableSalesData.map((item, index) => (
                  <li key={index}>
                    {item?.complete_name && item?.complete_name !== ""
                      ? item?.complete_name
                      : "---"}
                  </li>
                ))}
              </ul>
            ) : (
              <OrderDetailsLoader />
            )}
          </div>
          <div className="sales-popup-flex-boxes">
            <div className="title">Email/Phone</div>
            {tableSalesData && tableSalesData?.length > 0 ? (
              <ul ref={ulRefs.emails}>
                {tableSalesData.map((item, index) => (
                  <li key={index}>
                    {item?.email_phone && item?.email_phone !== ""
                      ? item?.email_phone
                      : "---"}
                  </li>
                ))}
              </ul>
            ) : (
              <OrderDetailsLoader />
            )}
          </div>
          <div className="sales-popup-flex-boxes">
            <div className="title">Amount</div>
            {tableSalesData && tableSalesData?.length > 0 ? (
              <ul ref={ulRefs.amounts}>
                {tableSalesData.map((item, index) => (
                  <li key={index}>
                    {item?.total && item?.total !== "" ? item?.total : "---"}
                  </li>
                ))}
              </ul>
            ) : (
              <OrderDetailsLoader />
            )}
          </div>
          <div className="sales-popup-flex-boxes">
            <div className="title">Received At</div>
            {tableSalesData && tableSalesData?.length > 0 ? (
              <ul ref={ulRefs.dates}>
                {tableSalesData.map((item, index) => (
                  <li key={index}>
                    {new Date(
                      item?.order_date && item?.order_date !== ""
                        ? item?.order_date
                        : "---"
                    ).toLocaleString()}
                  </li>
                ))}
              </ul>
            ) : (
              <OrderDetailsLoader />
            )}
          </div>
          <div className="sales-popup-flex-boxes">
            <div className="title">User Journey</div>
            {tableSalesData && tableSalesData?.length > 0 ? (
              <ul ref={ulRefs.tracking}>
                {tableSalesData.map((item, index) => (
                  <li
                    key={index}
                    className="cursor"
                    onClick={() => handleTrack(item?.trackid)}
                  >
                    {item.trackid}
                  </li>
                ))}
              </ul>
            ) : (
              <OrderDetailsLoader />
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default SalesPopup;
