import React, { useCallback, useMemo, useState } from "react";
import {
  calculateComparison,
  formatNumber,
  transformToInternationalNumber,
  truncateString,
} from "../../utils";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { MdArrowDropUp } from "react-icons/md";
import DataTable from "react-data-table-component";
import SalesPopup from "./SalesPopup";
import ExpandableSubComponent from "./ExpandableSubComponent";
import RenderReportingCell from "./RenderReportingCell";

const customStyles2 = {
  headRow: {
    style: {
      backgroundColor: "#313439",
      color: "#FF7B00;",
      borderBottom: "1px solid white",
    },
  },
  cells: {
    style: {
      backgroundColor: "#313439",
      color: "white",
    },
  },
};

const ExpandableComponent = React.memo(
  ({
    row,
    trafficSource,
    selectedAdList,
    originalAdSets,
    compareAdSets,
    sortingRef,
    expandedRows,
    handleRowExpand,
    openPopup,
    columnConfigs,
  }) => {
    const columns = useMemo(() => {
      return [
        {
          name: "AD SET NAME",
          columnName: "ad_set_name",
          selector: (row) => row.ad_set_name,
          sortable: true,
          cell: (row) => (
            <span title={row.ad_set_name}>
              {truncateString(row.ad_set_name, 30)}
            </span>
          ),
          sortFunction: (a, b) => a.ad_set_name.localeCompare(b.ad_set_name),
          // minWidth: "250px", // Increase the width here
          minWidth: "273px", // Increase the width here
          style: {
            position: "sticky !important",
            left: "30px",

            zIndex: 5,
          },
          headerStyle: {
            position: "sticky !important",
            top: 0,
            zIndex: "5",
            backgroundColor: "#27292D",
            color: "#DBFF00",
          },
        },
        ...columnConfigs.map(({ name, field }) => ({
          name,
          selector: (row) => row[field],
          columnName: field,
          sortable: true,
          minWidth: name === "New Visits* %" && "110px",
          cell: (row) =>
            RenderReportingCell(row, field, `${field}Comparison`, openPopup),
        })),
      ];
    }, [openPopup]);

    // const calculateComparison = (value, compValue, expandShow) => {
    //   if (expandShow === 0) return null;

    //   if (compValue === -1 || compValue === 0) return null;

    //   const comparison = formatNumber((value - compValue) / compValue);
    //   return comparison === 0 ? null : comparison;
    // };

    const mappedData = useMemo(
      () =>
        Array.isArray(originalAdSets) &&
          originalAdSets &&
          originalAdSets !== null &&
          originalAdSets
          ? originalAdSets?.map((item) => {
            const ad_set_name = item.ad_set_name;
            const compareItem = compareAdSets?.find(
              // (compare) => compare.campaign_id === item.campaign_id
              (compare) => compare.ad_set_id === item.ad_set_id
            );

            // console.log(compareAdSets);
            const expandShow = compareAdSets?.length;

            const excludeKeys = ["campaign_name", "ad_set_id", "ad_set_name"];

            // Get all keys from item excluding the ones we don't want to process
            const properties = Object.keys(item).filter(
              (key) => !excludeKeys.includes(key)
            );

            const formattedValues = {};
            properties.forEach((prop) => {
              formattedValues[prop] = formatNumber(item[prop]);
            });

            const comparisons = {};

            if (compareItem) {
              // Initialize compValues with formatted item values
              let compValues = {};
              if (compareItem) {
                // Update compValues with formatted compareItem properties
                Object.keys(compareItem).forEach((prop) => {
                  compValues[prop] = formatNumber(compareItem[prop]);
                });
              }

              // Calculate comparisons
              properties.forEach((prop) => {
                const comparisonName = `${prop}Comparison`;
                comparisons[comparisonName] = calculateComparison(
                  formattedValues[prop],
                  compValues[prop],
                  expandShow
                );
              });
            }

            return {
              // id: item.campaign_id,
              id: item.ad_set_id,
              category: item.campaign_name,

              ad_set_name: ad_set_name,
              ...formattedValues,
              ...comparisons,
            };
          })
          : [],
      [originalAdSets, compareAdSets]
    );

    const [isPopupOpen, setPopupOpen] = useState(false);
    const [currentadid, setCurrentadid] = useState(null);

    const closePopup = () => {
      setCurrentadid(null);
      setPopupOpen(false);
    };

    const [renderState, setRerenderState] = useState();

    const handleSort = useCallback((column, direction) => {
      sortingRef.current.childTableColumn = column.columnName;
      sortingRef.current.childTableSortDirection = direction;
      setRerenderState({
        dir: sortingRef.current.childTableSortDirection,
        col: sortingRef.current.childTableColumn,
      });
    }, []);

    const sortedData = useMemo(() => {
      if (!sortingRef.current.childTableColumn) return mappedData;

      return [...mappedData].sort((a, b) => {
        if (typeof a[sortingRef.current.childTableColumn] === "string") {
          // For strings, use localeCompare()
          return sortingRef.current.childTableSortDirection === "asc"
            ? a[sortingRef.current.childTableColumn].localeCompare(
              b[sortingRef.current.childTableColumn]
            )
            : b[sortingRef.current.childTableColumn].localeCompare(
              a[sortingRef.current.childTableColumn]
            );
        } else {
          // For numbers, use subtraction
          return sortingRef.current.childTableSortDirection === "asc"
            ? a[sortingRef.current.childTableColumn] -
            b[sortingRef.current.childTableColumn]
            : b[sortingRef.current.childTableColumn] -
            a[sortingRef.current.childTableColumn];
        }
      });
    }, [
      mappedData,
      sortingRef.current.childTableColumn,
      sortingRef.current.childTableSortDirection,
    ]);

    return (
      <>
        <div className="small-box-one"></div>
        <DataTable
          onSort={handleSort}
          sortServer
          className="custom-data-table2 child-table"
          noHeader
          columns={columns}
          // columns={columns(openPopup)}
          data={sortedData}
          pointerOnHover
          striped
          responsive
          expandableRows
          openPopup={openPopup}
          //   // pagination

          expandableRowExpanded={(row) => {
            // console.log(expanded)
            return Object.keys(expandedRows).length !== 0 &&
              selectedAdList === ""
              ? expandedRows[row.id]
              : selectedAdList === "campaign"
                ? expandedRows[row.id]
                : selectedAdList === "ad_set" || selectedAdList === "ad";
          }}
          // expandableRowExpanded={(row) => {
          //   let test = toggleRow.current ? expandedRows[row.id] : false
          //   return selectedAdList === "" ? expandedRows[row.id] : test
          // }}

          onRowExpandToggled={(expanded, row) => {
            handleRowExpand(row);
          }}
          expandableRowsComponent={(row) => {
            const currentCampaign = row.data;
            // console.log("row", currentCampaign);

            // const originalCampaign = originalAdSets.find(
            //   (campaign) => campaign.campaign_id === currentCampaign.id
            // );
            const originalCampaign = originalAdSets.find(
              (campaign) => campaign.ad_set_id === currentCampaign.id
            );

            const originalAds = originalCampaign ? originalCampaign.ads : [];
            // console.log(originalAdSets);
            // const compareCampaign = compareAdSets.find(
            //   (campaign) => campaign.campaign_id === currentCampaign.id
            // );
            const compareCampaign = compareAdSets.find(
              (campaign) => campaign.ad_set_id === currentCampaign.id
            );
            const compareAds = compareCampaign ? compareCampaign.ads : [];
            // console.log(compareAdSets);
            return (
              <ExpandableSubComponent
                sortingRef={sortingRef}
                originalAds={originalAds}
                compareAds={compareAds}
                trafficSource={trafficSource}
                expandedRows={expandedRows}
                handleRowExpand={handleRowExpand}
                openPopup={openPopup}
                columnConfigs={columnConfigs}
              />
            );
          }}
          customStyles={customStyles2}
          expandableIcon={{
            collapsed: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="yellow"
                viewBox="0 0 24 24"
              >
                <path fill="white" color="yellow" d="M7 10l5 5 5-5H7z" />
              </svg>
            ),
            expanded: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill="yellow"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path fill="yellow" d="M7 14l5-5 5 5" />
              </svg>
            ),
          }}
        />
        {isPopupOpen && (
          <SalesPopup
            isPopupOpen={isPopupOpen}
            isPopupFn={setPopupOpen}
            closePopup={closePopup}
            adid={currentadid}
            trafficSource={trafficSource}
          />
        )}
      </>
    );
  }
);

export default ExpandableComponent;
