import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  numbers: [],
  breakdownNumbers: [],
};

// Create the slice
const metricSlice = createSlice({
  name: "metrix",
  initialState,
  reducers: {
    toggleMetrix: (state, action) => {
      const newItem = action.payload; // Assume payload is an object with { id, text, color }

      // Find index of the item with the same id
      const index = state.numbers.findIndex((item) => item === newItem);
      if (index !== -1) {
        state.numbers.splice(index, 1);
      } else {
        state.numbers = [...state.numbers, newItem];
      }
    },

    setDetaultMetrix: (state, action) => {
      // const defaultMetrixItems = ["Spend", "ROAS", "Sales", "CPA"];
      console.log('action payload', action.payload)
      state.numbers = action.payload;
    },
    setDetaultMetrixBreakdown: (state, action) => {
      const defaultMetrixItemsbreakdown = ["Spend", "Sales"];
      state.breakdownNumbers = defaultMetrixItemsbreakdown;
    },

    breakdownToggleMetrix: (state, action) => {
      const breakdownNewItem = action.payload;
      console.log(breakdownNewItem);
      console.log("breakdown payload:", breakdownNewItem);
      console.log(state.breakdownNumbers);
      if (!Array.isArray(state.breakdownNumbers)) {
        console.error(
          "State.breakdownNumbers is not an array:",
          state.breakdownNumbers
        );
        return;
      }

      if (!breakdownNewItem || typeof breakdownNewItem === "undefined") {
        console.error(
          "Invalid payload in breakdownToggleMetrix reducer:",
          breakdownNewItem
        );
        return;
      }

      const breakdownIndex = state.breakdownNumbers.findIndex(
        (item) => item === breakdownNewItem
      );

      if (breakdownIndex !== -1) {
        // Item exists in breakdownNumbers, remove it
        state.breakdownNumbers.splice(breakdownIndex, 1);
      } else {
        // Item does not exist in breakdownNumbers, add it
        state.breakdownNumbers = [...state.breakdownNumbers, breakdownNewItem];
      }
    },

    setClearBreakdown: (state) => {
      state.breakdownNumbers = [];
    },
  },
});

export const {
  toggleMetrix,
  breakdownToggleMetrix,
  setDetaultMetrix,
  setDetaultMetrixBreakdown,
  setClearBreakdown,
} = metricSlice.actions;
export default metricSlice.reducer;
