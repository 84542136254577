import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "reduxjs-toolkit-persist";
import storage from "reduxjs-toolkit-persist/lib/storage";
import persistStore from "reduxjs-toolkit-persist/es/persistStore";

import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "reduxjs-toolkit-persist/es/constants";

// ReucerSlices are imported here
// import clientSlice from "./reducers/clientSlice";
import graphDataSlice from "./reducers/graphDataSlice";

import authSlice from "./reducers/authSlice";
import tableDataSlice from "./reducers/tableDataSlice";
import tableCompareDataSlice from "./reducers/tableCompareDataSlice";
import dateSlice from "./reducers/dateSlice";
import attributeSlice from "./reducers/attributeSlice";
import trafficSourceSlice from "./reducers/trafficSourceSlice";
import userJourneySlice from "./reducers/userJourneySlice";
import dashboardGraphDataSlice from "./reducers/dashboardGraphDataSlice";
import profileDataSlice from "./reducers/profileDataSlice";
import dashboardTrafficDataSlice from "./reducers/dashboardTrafficDataSlice";
import dashboardMetricDataSlice from "./reducers/dashboardMetricDataSlice";
import integrationSlice from "./reducers/IntegrationSlice";
import googleAdAccountsSlice from "./reducers/googleAdAccountsSlice";
import creativeTableDataSlice from "./reducers/creativeTableDataSlice";
import buttonReducer from "./reducers/buttonSlice";
import metrixReducer from "./reducers/metrixSlice";
import breakdownReducer from "./reducers/breakdownSlice";
import joyrideReducer from "./reducers/joyrideSlice";
import madsaccntReducer from "./reducers/madsaccntSlice";
import gadsaccntReducer from "./reducers/gadsaccntSlice";
import ladsaccntReducer from "./reducers/ladsaccntSlice";
import metautmReducer from "./reducers/metautmSlice";
import agencyReducer from "./reducers/agencySlice";
import productReducer from "./reducers/productSlice";
import utmSourceReducer from "./reducers/utmSourceSlice";
import predictiveDataSlice from "./reducers/predictiveBehaviorSlice";
import tableDataReducer from "./reducers/predictiveBehaviorCohortTableSlice";
import linkedInSlice from "./reducers/linkedInAccountSlice";
import journeySlice from "./reducers/journeySlice";
const REDUX_PERSIST_KEY = "trackocity";

const persistConfig = {
  key: REDUX_PERSIST_KEY,
  storage,
  // whitelist: ["auth"],
};
const combinedReducers = persistReducer(
  persistConfig,
  combineReducers({
    linkedIn: linkedInSlice,
    breakdown: breakdownReducer,
    // client: clientSlice,
    metrix: metrixReducer,
    utmSources: utmSourceReducer,
    buttons: buttonReducer,
    auth: authSlice,
    table: tableDataSlice,
    predictiveData: predictiveDataSlice,
    agency: agencyReducer,
    compareTable: tableCompareDataSlice,
    profile: profileDataSlice,
    // compareTable: tableCompareDataSlice,
    date: dateSlice,
    graphData: graphDataSlice,
    products: productReducer,
    dashboardGraphData: dashboardGraphDataSlice,
    dashboardMetricData: dashboardMetricDataSlice,
    integration: integrationSlice,
    dashboardTrafficData: dashboardTrafficDataSlice,
    attribute: attributeSlice,
    trafficSource: trafficSourceSlice,
    userJourney: userJourneySlice,
    googleAdAccounts: googleAdAccountsSlice,
    creativeTableData: creativeTableDataSlice,
    joyride: joyrideReducer,
    madsaccnt: madsaccntReducer,
    gadsaccnt: gadsaccntReducer,
    ladsaccnt: ladsaccntReducer,
    metautm: metautmReducer,
    cohortTableData: tableDataReducer,
    journeySlice: journeySlice,
  })
);
const store = configureStore({
  reducer: combinedReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
const persistedStore = persistStore(store);
export { store, persistedStore };
