import { toastSetter } from "../../components/ToastContainer";
import catchAsync from "../../utils/catchAsync";
import http from "./http";

// reporting/graphsales

export const getReportingGraphData = catchAsync(async (values) => {
  console.log(values, "values");
  try {
    const { startdate, enddate } = values;
    const response = await http.get(`/reporting/graphsales`, {
      params: { startdate, enddate },
    });
    // Handle response data if needed
    // console.log(response);
    if (response.status === 200) {
      return response; // Return response data
    }
  } catch (error) {
    // console.log("Error fetching Facebook table data:", error)
    // Display error message to the user
    // toastSetter("error", errorMessage);
    throw error; // Re-throw the error to handle it in the calling code
  }
});
