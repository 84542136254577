// import React from "react";
// import DataTable from "react-data-table-component";
// import "./SalesVelocityTable.css";

// const customStyles = {
//   headRow: {
//     style: {
//       backgroundColor: "#1B1C1E",
//     },
//   },
//   rows: {},
//   cells: {
//     style: {
//       padding: "10px",
//     },
//   },
// };

// const columns = [
//   {
//     name: "Days to 1st Sale",
//     selector: (row) => row.daysToFirstSale,
//     sortable: false,
//     cell: (row) => (
//       <div
//         style={{
//           fontWeight: row.daysToFirstSale === "Totals" ? "bold" : "normal",
//         }}
//       >
//         {row.daysToFirstSale}
//       </div>
//     ),
//   },
//   {
//     name: "Sales Conversion",
//     selector: (row) => row.salesConversion,
//     sortable: false,
//     cell: (row) => (
//       <div
//         style={{
//           fontWeight: row.daysToFirstSale === "Totals" ? "bold" : "normal",
//         }}
//       >
//         {row.salesConversion}
//       </div>
//     ),
//   },
//   {
//     name: "Value",
//     selector: (row) => row.value,
//     sortable: false,
//     cell: (row) => (
//       <div
//         style={{
//           fontWeight: row.daysToFirstSale === "Totals" ? "bold" : "normal",
//         }}
//       >
//         {row.value}
//       </div>
//     ),
//   },
//   {
//     name: "% of all contacts",
//     selector: (row) => row.percentOfContacts,
//     sortable: false,
//   },
//   {
//     name: "% of sales",
//     selector: (row) => row.percentOfSales,
//     sortable: false,
//   },
//   {
//     name: "Cumulative of sales",
//     selector: (row) => row.cumulativeSales,
//     sortable: false,
//   },
// ];

// const data = [
//   {
//     daysToFirstSale: "Totals",
//     salesConversion: "12,764",
//     value: "$2,793,820.79",
//     percentOfContacts: "",
//     percentOfSales: "",
//     cumulativeSales: "",
//   },
//   {
//     daysToFirstSale: "1 Day",
//     salesConversion: "1,939",
//     value: "$426,618",
//     percentOfContacts: "0.37%",
//     percentOfSales: "15.19%",
//     cumulativeSales: "15.19%",
//   },
//   {
//     daysToFirstSale: "2-7 Days",
//     salesConversion: "1,939",
//     value: "$426,618",
//     percentOfContacts: "0.37%",
//     percentOfSales: "15.19%",
//     cumulativeSales: "15.19%",
//   },
//   {
//     daysToFirstSale: "8-14 Days",
//     salesConversion: "1,939",
//     value: "$426,618",
//     percentOfContacts: "0.37%",
//     percentOfSales: "15.19%",
//     cumulativeSales: "15.19%",
//   },
//   {
//     daysToFirstSale: "15-30 Days",
//     salesConversion: "1,939",
//     value: "$426,618",
//     percentOfContacts: "0.37%",
//     percentOfSales: "15.19%",
//     cumulativeSales: "15.19%",
//   },
//   {
//     daysToFirstSale: "31-60 Days",
//     salesConversion: "1,939",
//     value: "$426,618",
//     percentOfContacts: "0.37%",
//     percentOfSales: "15.19%",
//     cumulativeSales: "15.19%",
//   },
// ];

// const SalesVelocityTable = ({ salesVelocityTableData }) => {
//   return (
//     <div className="sales-velocity-table-container">
//       <DataTable
//         columns={columns}
//         data={data}
//         customStyles={customStyles}
//         striped
//         pointerOnHover
//       />
//     </div>
//   );
// };

// export default SalesVelocityTable;

import React from "react";
import DataTable from "react-data-table-component";
import "./SalesVelocityTable.css";
import { transformToInternationalNumber } from "../../utils";

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "#1B1C1E",
    },
  },
  rows: {},
  cells: {
    style: {
      padding: "10px",
    },
  },
};

const columns = [
  {
    name: "Days to 1st Sale",
    selector: (row) => row.days_to_first_sale, // Corrected field name
    sortable: false,
    cell: (row) => (
      <div
        style={{
          fontWeight: row.days_to_first_sale === "Totals" ? "bold" : "normal",
        }}
      >
        {row.days_to_first_sale}
      </div>
    ),
    style: {
      background: '#1B1C1E'
    }
  },
  {
    name: "Sales Conversion",
    selector: (row) => row.sales_conversion_rate, // Corrected field for conversion rate
    sortable: false,
    cell: (row) => (
      <div
        style={{
          fontWeight: row.days_to_first_sale === "Totals" ? "bold" : "normal",
        }}
      >
        {row.sales_conversion_rate}
      </div>
    ),
  },
  {
    name: "Value",
    selector: (row) => row.average_sales_value, // Corrected field for value
    sortable: false,
    cell: (row) => (
      <div
        style={{
          fontWeight: row.days_to_first_sale === "Totals" ? "bold" : "normal",
        }}
      >
        {transformToInternationalNumber(row.average_sales_value)} {/* Format value as currency */}
      </div>
    ),
  },
  {
    name: "% of all contacts",
    selector: (row) => row.percentage_contact, // Corrected field for percentage of all contacts
    sortable: false,
  },
  {
    name: "% of sales",
    selector: (row) => row.percentage_sales, // Corrected field for percentage of sales

  },
  {
    name: "% Cumulative of sales",
    selector: (row) => row.cumulative_sales, // Corrected field for cumulative sales
    cell: (row) => {
      return row.cumulative_sales > 100 ? 100 : row.cumulative_sales
    },

    sortable: false,
  },
];

const SalesVelocityTable = ({ salesVelocityTableData }) => {
  // Extracting the table data from the prop
  const tableData = salesVelocityTableData?.table_data || [];

  // Add a "Totals" row at the bottom
  const totalsRow = {
    days_to_first_sale: "Totals", // Totals label
    sales_conversion_rate: salesVelocityTableData?.sales_conversion, // Sales conversion for total
    average_sales_value: salesVelocityTableData?.value, // Total value
    percentage_contact: "", // This can be calculated if necessary
    percentage_sales: "", // This can be calculated if necessary
    cumulative_sales: "", // This can be calculated if necessary
  };

  // Combine the table data with the totals row
  const data = [totalsRow, ...tableData];

  return (
    <div className="sales-velocity-table-container">
      <DataTable
        columns={columns}
        data={data}
        customStyles={customStyles}
        striped
        pointerOnHover
      />
    </div>
  );
};

export default SalesVelocityTable;
