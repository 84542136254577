// src/data/appData.js
// Import logos for various apps from the assets directory
import {
  googleAdsLogo,
  metaLogo,
  pabblyLogo,
  razorpayLogo,
  shopifyLogo,
  woocommerceLogo,
  cashfreelogo,
  linkedinlogo,
} from "../../assets";
// Define the appData array, which contains information about different categories of apps
export const appData = [
  {
    // Title for the first category: Ad Tracking Platforms
    title: "Ad Tracking Platforms",
    apps: [
      {
        // App details for Google Ads
        name: "Google Ads",
        logo: googleAdsLogo, // Logo for Google Ads
        description: "Click to integrate with Google Ads Campaigns", // Brief description of the app
        videoTutorial: "Google Video Tutorial", // Link or title for the tutorial video
        integrationPopup: "Google Ads", // Title for the integration popup
      },
      {
        // App details for Meta
        name: "Meta",
        logo: metaLogo, // Logo for Meta
        description: "Click here to let us manage your Meta audiences", // Brief description of the app
        videoTutorial: "Meta Video Tutorial", // Link or title for the tutorial video
        integrationPopup: "Meta Ads", // Title for the integration popup
      },
      {
        // App details for Meta
        name: "LinkedIn",
        logo: linkedinlogo, // Logo for Linkedin
        description: "Click here to let us manage your LinkedIn audiences", // Brief description of the app
        // videoTutorial: "Meta Video Tutorial", // Link or title for the tutorial video
        integrationPopup: "Linkedin", // Title for the integration popup
      },
    ],
  },
  {
    // Title for the second category: Payment Processors
    title: "Payment Processors",
    apps: [
      {
        // App details for Razorpay
        name: "Razorpay",
        logo: razorpayLogo, // Logo for Razorpay
        description: "Click to integrate with Razorpay", // Brief description of the app
        videoTutorial: "Razorpay Video Tutorial", // Link or title for the tutorial video
        integrationPopup: "Razorpay Integration", // Title for the integration popup
      },
      {
        // App details for Cashfree
        name: "Cashfree",
        logo: cashfreelogo, // Logo for Cashfree
        description: "Click to integrate with Cashfree", // Brief description of the app
        //videoTutorial: "Cashfree Video Tutorial", // Link or title for the tutorial video
        integrationPopup: "Cashfree Integration", // Title for the integration popup
      },
    ],
  },
  {
    // Title for the third category: Stores and Checkouts
    title: "Stores and Checkouts",
    apps: [
      {
        // App details for Shopify
        name: "Shopify",
        logo: shopifyLogo, // Logo for Shopify
        description: "Integrate with our super advanced Shopify App", // Brief description of the app
        integrationPopup: "Shopify Integration", // Title for the integration popup
      },
      {
        // App details for Woo Commerce
        name: "Woo Commerce",
        logo: woocommerceLogo, // Logo for Woo Commerce
        description: "Integrate with Woo Commerce", // Brief description of the app
        integrationPopup: "WooCommerce Integration", // Title for the integration popup
      },
      {
        // App details for Pabbly
        name: "Pabbly",
        logo: pabblyLogo, // Logo for Pabbly
        description: "Integrate with Pabbly", // Brief description of the app
        integrationPopup: "Pabbly Integration", // Title for the integration popup
      },
    ],
  },
];
