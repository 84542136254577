import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import AdMetricChart from "../../components/charts/AdMetricChart";
import BreakdownTable from "../../components/CreativeComp/BreakdownTable";
import {
  breakdownTableData,
  updateMainCards,
} from "../../redux/reducers/breakdownSlice";
import { setClearBreakdown } from "../../redux/reducers/metrixSlice";
import { IoIosArrowBack } from "react-icons/io"; // Import the back arrow icon

function AdBreakdown() {
  const { creativesdate } = useSelector((state) => state?.date);
  const [sortBy, setSortBy] = useState("spend");
  const [sortDirection, setSortDirection] = useState("desc");
  // console.log(creativesdate);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate
  const { ids } = useSelector((state) => state.breakdown);
  const { breakdownData } = useSelector((state) => state.breakdown);
  const [selectedAdRows, setSelectedAdRows] = useState([]);
  console.log(breakdownData);
  function handleClear() {
    dispatch(setClearBreakdown());
  }
  const datanew = (breakdownData?.ads || [])
    .map((item) => {
      const spend = parseFloat(item.spend) || 0;
      const click = parseFloat(item.click) || 0;
      const sales = parseFloat(item.sales) || 0;
      const revenue = parseFloat(item.revenue) || 0;
      const impression = parseFloat(item.impression) || 0;

      return {
        ...item,
        cpa: sales === 0 ? -1 : parseFloat((spend / sales).toFixed(2)),
        cpc: click === 0 ? -1 : parseFloat((spend / click).toFixed(2)),
        cpm:
          impression === 0
            ? -1
            : parseFloat(((spend * 1000) / impression).toFixed(2)),
        cr: click === 0 ? -1 : parseFloat((sales * 100) / click).toFixed(2),
        ctr:
          impression === 0
            ? -1
            : parseFloat(((click * 100) / impression).toFixed(2)),
        roas: spend === 0 ? -1 : parseFloat(revenue / spend).toFixed(2),
      };
    })
    // .map((item) => console.log("new", item.ctr))
    .sort((a, b) => {
      if (typeof a[sortBy] === "string") {
        return sortDirection === "asc"
          ? a[sortBy].localeCompare(b[sortBy])
          : b[sortBy].localeCompare(a[sortBy]);
      } else {
        return sortDirection === "asc"
          ? a[sortBy] - b[sortBy]
          : b[sortBy] - a[sortBy];
      }
    });

  console.log(datanew);

  // useEffect(() => {

  // }[])

  useEffect(() => {
    if (creativesdate) {
      dispatch(
        breakdownTableData({
          startdate: creativesdate[0],
          enddate: creativesdate[1],
          creativeId: ids,
        })
      );
    }
  }, [dispatch, creativesdate, ids]);

  const [breakDownCards, setBreakDownCards] = useState(
    JSON.parse(localStorage.getItem("breakDownCards"))
      ? JSON.parse(localStorage.getItem("breakDownCards"))
      : []
  );

  console.log(breakDownCards);
  // useEffect(() => {
  //   dispatch(updateMainCards([]));
  // }, []);
  useEffect(() => {
    // console.log(breakDownCards);
    localStorage.setItem(
      "breakDownCards",
      JSON.stringify(breakDownCards ? breakDownCards : [])
    );

    dispatch(updateMainCards(breakDownCards));
  }, [breakDownCards, dispatch]);

  const [breakDownInsightData, setBreakDownInsightData] = useState(null);

  return (
    <div>
      <div>
        <div className="creative-header-section">
          <h2 className="profile-heading">Top Creatives</h2>
        </div>

        <div className="ad-breakdown-main-body">
          <button
            onClick={() => navigate("/creatives")} // Navigate to the creatives page
            className="back-button"
          >
            <IoIosArrowBack /> Back
          </button>
          <div className="ad-breakdown-body">
            <AdMetricChart />
          </div>
          <div className="table">
            <BreakdownTable
              ids={ids}
              setBreakDownInsightData={setBreakDownInsightData}
              setBreakDownCards={setBreakDownCards}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdBreakdown;
