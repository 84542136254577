// import React, { useEffect, useState } from "react";
// import "./styles.css";
// import { useNavigate } from "react-router-dom";
// import EditClient from "./EditClient";
// import NewClient from "./NewClient"; // Import the NewClient component
// import { useDispatch, useSelector } from "react-redux";
// import { getAgencyDataAll } from "../../redux/reducers/agencySlice";
// import { fetchProfileData } from "../../redux/reducers/profileDataSlice";

// const arr = [
//   {
//     name: "ABC media",
//     id: "234jio",
//     date: "17-09-2023",
//   },
//   {
//     name: "XYZ Corp",
//     id: "567abc",
//     date: "18-09-2023",
//   },
//   {
//     name: "Tech Solutions",
//     id: "890def",
//     date: "19-09-2023",
//   },
// ];

// function ClientProfile() {
//   const dispatch = useDispatch();
//   const { agencyAccountsAll } = useSelector((state) => state.agency);

//   const [editPopUp, setEditPopUp] = useState(false);
//   const [newPopUp, setNewPopUp] = useState(false);
//   const navigate = useNavigate();
//   const [profileIcon, setProfileIcon] = useState(false);
//   useEffect(() => {
//     dispatch(getAgencyDataAll());
//   }, []);

//   // const [profileToEdit, setProfileToEdit] = useState("");
//   // console.log(profileToEdit);
//   const handleEditPopup = (workspace) => {
//     localStorage.setItem("n_workspace_id", workspace);
//     dispatch(fetchProfileData());
//     // const toEditProfile = agencyAccountsAll.filter(
//     //   (item) => item.workspace === workspace
//     // );
//     // setProfileToEdit(toEditProfile);

//     // console.log(toEditProfile); // Example usage

//     setProfileIcon(false); // Close the profile icon dropdown
//     setEditPopUp(true);
//     setNewPopUp(false); // Close NewClient if it was open
//   };
//   const handleNewPopup = () => {
//     setProfileIcon(false); // Close the profile icon dropdown
//     setNewPopUp(true); // Open the new client popup
//     setEditPopUp(false); // Close EditClient if it was open
//   };

//   const handleClosePopup = () => {
//     localStorage.removeItem("n_workspace_id");
//     // setProfileToEdit("");
//     setEditPopUp(false);
//     setNewPopUp(false);
//   };

//   const handleProfileClick = () => {
//     setProfileIcon((prev) => !prev);
//   };

//   const handleProfilebtnClick = () => {
//     setProfileIcon(false);
//     navigate("/profile");
//   };

//   const handleClientbtnClick = () => {
//     setProfileIcon(false);
//     navigate("/client-profile");
//   };

//   return (
//     <div>
//       <div className="profile-header-section">
//         <div className="client-heading">
//           <h2 className="profile-heading">Client</h2>
//           <div className="icon">
//             <button onClick={handleProfileClick} className="profile-btn">
//               <img
//                 alt="profile-icon"
//                 className="profile-icon"
//                 src="Client.png"
//               />
//             </button>
//             {profileIcon && (
//               <div className="dropdown-client">
//                 <button
//                   onClick={handleProfilebtnClick}
//                   className="toggle-btn-one"
//                 >
//                   Profile
//                 </button>
//                 <button
//                   onClick={handleClientbtnClick}
//                   className="toggle-btn-two"
//                 >
//                   Client
//                 </button>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>

//       <div className="client-profile-main">
//         {editPopUp && (
//           <>
//             <div className="overlay" onClick={handleClosePopup}></div>
//             <EditClient
//               setEditPopUp={setEditPopUp}
//               // profileToEdit={profileToEdit}
//             />
//           </>
//         )}

//         {newPopUp && (
//           <>
//             <div className="overlay" onClick={handleClosePopup}></div>
//             <NewClient
//               setNewPopUp={setNewPopUp}
//               // profileToEdit={profileToEdit}
//             />
//           </>
//         )}

//         <div className="client-profile-container">
//           <p className="heading-client">
//             <span className="heading-client-text">Name</span>
//             <span className="heading-client-text">Id</span>
//             <span className="heading-client-text">Date</span>
//           </p>
//           <div className="item-box">
//             {agencyAccountsAll.map((item, index) => (
//               <div key={index} className="item-box-mini">
//                 <div className="item-box-mini-text">
//                   <p className="mini-text">{item.name}</p>
//                   <p className="line"></p>
//                   <p className="mini-text">{item.email}</p>
//                   <p className="line"></p>
//                   <p className="mini-text">{item.timezone}</p>
//                 </div>
//                 <button
//                   onClick={() => handleEditPopup(item.workspace)}
//                   className="profile-btn"
//                 >
//                   <img alt="edit" className="edit" src="edit.png" />
//                 </button>
//               </div>
//             ))}
//             <button onClick={handleNewPopup} className="client-add-btn">
//               <img
//                 alt="client-add"
//                 className="client-add"
//                 src="clientadd.png"
//               />
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ClientProfile;

import React, { useEffect, useState } from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import EditClient from "./EditClient";
import NewClient from "./NewClient"; // Import the NewClient component
import { useDispatch, useSelector } from "react-redux";
import { getAgencyDataAll } from "../../redux/reducers/agencySlice";
import { fetchProfileData } from "../../redux/reducers/profileDataSlice";
import ProfileDropDown from "../../components/profileDropDown/ProfileDropDown";
import { DropDownComp } from "../../components";

function ClientProfile() {
  const dispatch = useDispatch();
  const { agencyAccountsAll } = useSelector((state) => state.agency);

  const [editPopUp, setEditPopUp] = useState(false);
  const [newPopUp, setNewPopUp] = useState(false);
  const navigate = useNavigate();
  const [profileIcon, setProfileIcon] = useState(false);
  useEffect(() => {
    dispatch(getAgencyDataAll());
  }, []);

  const [profileToEdit, setProfileToEdit] = useState("");
  console.log(profileToEdit);
  const handleEditPopup = (workspace) => {
    localStorage.setItem("n_workspace_id", workspace);
    dispatch(fetchProfileData());
    const toEditProfile = agencyAccountsAll.filter(
      (item) => item.workspace === workspace
    );
    setProfileToEdit(toEditProfile);

    console.log(toEditProfile); // Example usage

    setProfileIcon(false); // Close the profile icon dropdown
    setEditPopUp(true);
    setNewPopUp(false); // Close NewClient if it was open
  };
  const handleNewPopup = () => {
    setProfileIcon(false); // Close the profile icon dropdown
    setNewPopUp(true); // Open the new client popup
    setEditPopUp(false); // Close EditClient if it was open
  };

  const handleClosePopup = () => {
    setProfileToEdit("");
    setEditPopUp(false);
    setNewPopUp(false);
  };

  const handleProfileClick = () => {
    setProfileIcon((prev) => !prev);
  };

  const handleProfilebtnClick = () => {
    setProfileIcon(false);
    navigate("/profile");
  };

  const handleClientbtnClick = () => {
    setProfileIcon(false);
    navigate("/client-profile");
  };

  return (
    <div>
      <div className="profile-header-section" style={{ paddingRight: "60px" }}>
        <div className="client-heading">
          <h2 className="profile-heading">Client</h2>
          {/* <div className="icon">
            <button onClick={handleProfileClick} className="profile-btn">
              <img
                alt="profile-icon"
                className="profile-icon"
                src="Client.png"
              />
            </button>
            {profileIcon && (
              <div className="dropdown-client">
                <button
                  onClick={handleProfilebtnClick}
                  className="toggle-btn-one"
                >
                  Profile
                </button>
                <button
                  onClick={handleClientbtnClick}
                  className="toggle-btn-two"
                >
                  Client
                </button>
              </div>
            )}
          </div> */}
        </div>
        <ProfileDropDown />
      </div>

      <div className="client-profile-main">
        {editPopUp && (
          <>
            <div className="overlay" onClick={handleClosePopup}></div>
            <EditClient
              setEditPopUp={setEditPopUp}
              profileToEdit={profileToEdit}
            />
          </>
        )}

        {newPopUp && (
          <>
            <div className="overlay" onClick={handleClosePopup}></div>
            <NewClient
              setNewPopUp={setNewPopUp}
              profileToEdit={profileToEdit}
            />
          </>
        )}
        <p className="heading-client">
          <span className="heading-client-text">Name</span>
          <span className="heading-client-text">Id</span>
          <span className="heading-client-text">Phone</span>
        </p>
        <div className="client-profile-container">
          <div className="item-box">
            {agencyAccountsAll.map((item, index) => (
              <div key={index} className="item-box-mini">
                <div className="item-box-mini-text">
                  <p className="mini-text">{item.name}</p>
                  <p className="line"></p>
                  <p className="mini-text-email">{item.email}</p>
                  <p className="line"></p>
                  <p className="mini-text">{item.phone}</p>
                </div>
                <button
                  onClick={() => handleEditPopup(item.workspace)}
                  className="profile-btn"
                >
                  <img alt="edit" className="edit" src="edit.png" />
                </button>
              </div>
            ))}
          </div>
        </div>
        <p className="heading-client-bottom">
          <button onClick={handleNewPopup} className="client-add-btn">
            <img alt="client-add" className="client-add" src="clientadd.png" />
          </button>
        </p>
      </div>
    </div>
  );
}

export default ClientProfile;
