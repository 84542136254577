// import React, { useEffect, useState } from "react";
// import DataTable from "react-data-table-component";
// import { useSelector, useDispatch } from "react-redux";
// import { toastSetter } from "../../components/ToastContainer";
// import { setSelectAdRows } from "../../redux/reducers/breakdownSlice";
// import { roundupValues } from "../../utils";

// const BreakdownTable = ({
//   setBreakDownInsightData,
//   setBreakDownCards,
//   ids,
// }) => {
//   const dispatch = useDispatch();
//   const [currentPage, setCurrentPage] = useState(1);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [selectedAdRows, setSelectedAdRows] = useState([]);
//   console.log(selectedAdRows);

//   // useEffect(() => {
//   //   // Trigger default sort on mount
//   //   handleSort({ selector: { name: "spend" } }, "desc");
//   // }, []);

//   const [sortField, setSortField] = useState("ad_id");
//   const [sortBy, setSortBy] = useState("spend");
//   // console.log(sortBy);
//   const [sortDirection, setSortDirection] = useState("desc");
//   // console.log(sortDirection);
//   const { breakdownData } = useSelector((state) => state.breakdown);
//   const [pageOpen, setPageOpen] = useState(false);
//   const datanew = (breakdownData?.ads || [])
//     .map((item) => {
//       const spend = parseFloat(item.spend) || 0;
//       const click = parseFloat(item.click) || 0;
//       const sales = parseFloat(item.sales) || 0;
//       const revenue = parseFloat(item.revenue) || 0;
//       const impression = parseFloat(item.impression) || 0;

//       return {
//         ...item,
//         cpa: sales === 0 ? -1 : parseFloat((spend / sales).toFixed(2)),
//         cpc: click === 0 ? -1 : parseFloat((spend / click).toFixed(2)),
//         cpm:
//           impression === 0
//             ? -1
//             : parseFloat(((spend * 1000) / impression).toFixed(2)),
//         cr: click === 0 ? -1 : parseFloat((sales * 100) / click).toFixed(2),
//         ctr:
//           impression === 0
//             ? -1
//             : parseFloat(((click * 100) / impression).toFixed(2)),
//         roas: spend === 0 ? -1 : parseFloat(revenue / spend).toFixed(2),
//       };
//     })
//     // .map((item) => console.log("new", item.ctr))
//     .sort((a, b) => {
//       if (typeof a[sortBy] === "string") {
//         return sortDirection === "asc"
//           ? a[sortBy].localeCompare(b[sortBy])
//           : b[sortBy].localeCompare(a[sortBy]);
//       } else {
//         return sortDirection === "asc"
//           ? a[sortBy] - b[sortBy]
//           : b[sortBy] - a[sortBy];
//       }
//     });

//   // useEffect(() => {
//   //   if (datanew?.length > 1) {
//   //     console.log(datanew[0], datanew[1]);
//   //     const initialSelectedRows = [datanew[0]?.ad_id, datanew[1]?.ad_id];
//   //     setSelectedAdRows(initialSelectedRows);
//   //   } else {
//   //     const singleInitial = [datanew[0]?.ad_id];
//   //     setSelectedAdRows(singleInitial);
//   //   }
//   // }, []); // Depend on `datanew` to rerun if it changes
//   // useEffect(() => {
//   //   localStorage.setItem(
//   //     "selectedAdRows",
//   //     JSON.stringify(selectedAdRows ? selectedAdRows : [])
//   //   );
//   // }, [selectedAdRows]);

//   // const [varery, setVarery] = useState([]);
//   // useEffect(() => {
//   //   const costy = localStorage.getItem("selectedAdRows");
//   //   setVarery(costy);
//   // }, []);
//   console.log(breakdownData);

//   useEffect(() => {
//     // On initial load, set breakDownCards to an empty array
//     setBreakDownCards([]);

//     // Cleanup function to run when the component unmounts
//     return () => {
//       setBreakDownCards([]); // Clear breakDownCards when unmounted
//     };
//   }, []);
//   function setChart() {
//     const initialSelectedRows = [datanew[0]?.ad_id, datanew[1]?.ad_id];
//     // Only update if selectedAdRows is different
//     if (
//       JSON.stringify(initialSelectedRows) !== JSON.stringify(selectedAdRows)
//     ) {
//       setSelectedAdRows(initialSelectedRows);
//     }
//   }

//   useEffect(() => {
//     setChart();
//   }, [datanew]); // Runs whenever datanew changes
//   const checkBreakdownCards = () => {
//     const breakdownCardsArray = datanew.filter((item) =>
//       selectedAdRows.includes(item.ad_id)
//     );
//     console.log("breakdownCardsArray", breakdownCardsArray);
//     setBreakDownCards(breakdownCardsArray);
//   };

//   useEffect(() => {
//     checkBreakdownCards();
//   }, [selectedAdRows]);

//   const handleSelectAll = () => {
//     if (selectedAdRows.length > 0) {
//       setSelectedAdRows([]);
//       return;
//     }
//     let selectAllRow = datanew
//       .filter((item, index) => {
//         const startIndex = (currentPage - 1) * 10;
//         const endIndex = startIndex + 4;
//         return index >= startIndex && index < endIndex;
//       })
//       .map((item) => item.ad_id);
//     setSelectedAdRows(selectAllRow);
//   };

//   const handleChange = (adName) => {
//     if (selectedAdRows.includes(adName)) {
//       const filteredItems = selectedAdRows.filter((item) => item !== adName);
//       setSelectedAdRows(filteredItems);
//     } else if (selectedAdRows.length >= 4) {
//       toastSetter("error", "You can only select up to 4 rows");
//     } else {
//       setSelectedAdRows((prevIds) => [...prevIds, adName]);
//     }
//   };

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   const handleRowsPerPageChange = (newRowsPerPage) => {
//     setRowsPerPage(newRowsPerPage);
//     setCurrentPage(1);
//   };

//   const handleSort = (column, direction) => {
//     // console.log(column);
//     // let columnName = column?.name?.toLowerCase();
//     let columnName = column.columnName;

//     setSortDirection(direction);
//     setSortBy(columnName);
//   };

//   function toWholeNumberRound(value) {
//     return Math.round(value);
//   }

//   const columns = [
//     {
//       name: (
//         <input
//           type="checkbox"
//           className="head-checkbox custom-checkbox"
//           checked={selectedAdRows.length > 0}
//           onChange={handleSelectAll}
//         />
//       ),
//       cell: (row) =>
//         row.ad_id !== "Total" && (
//           <input
//             type="checkbox"
//             className="row-checkbox custom-checkbox"
//             checked={selectedAdRows.includes(row.ad_id)}
//             onChange={() => handleChange(row.ad_id)}
//           />
//         ),
//       ignoreRowClick: true,
//       allowOverflow: true,
//       button: true,
//     },
//     {
//       name: "Ad Name",
//       selector: (row) => row?.ad_name,
//       columnName: "ad_id",
//       sortable: true,
//     },
//     {
//       name: "Revenue",
//       selector: (row) => toWholeNumberRound(row?.revenue),
//       sortable: true,
//       columnName: "revenue",
//       cell: (row, index) => (
//         <span
//           style={{
//             fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
//           }}
//         >
//           {toWholeNumberRound(row?.revenue)}
//         </span>
//       ),
//     },
//     {
//       name: "Spend",
//       selector: (row) => {
//         const spend = parseFloat(toWholeNumberRound(row?.spend));
//         return isNaN(spend) ? "N/A" : spend;
//       },
//       sortable: true,
//       columnName: "spend",
//       cell: (row, index) => {
//         const spend = parseFloat(toWholeNumberRound(row?.spend));
//         return (
//           <span
//             style={{
//               fontWeight:
//                 index === paginatedData?.length - 1 ? "bold" : "normal",
//             }}
//           >
//             {isNaN(spend) ? "N/A" : spend}
//           </span>
//         );
//       },
//     },
//     {
//       name: "Sales",
//       selector: (row) => toWholeNumberRound(row?.sales),
//       sortable: true,
//       columnName: "sales",
//       cell: (row, index) => (
//         <span
//           style={{
//             fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
//           }}
//         >
//           {toWholeNumberRound(row?.sales)}
//         </span>
//       ),
//     },
//     {
//       name: "Click",
//       selector: (row) => row?.click,
//       sortable: true,
//       columnName: "click",
//       cell: (row, index) => (
//         <span
//           style={{
//             fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
//           }}
//         >
//           {row?.click}
//         </span>
//       ),
//     },
//     {
//       name: "ROAS",
//       cell: (row, index) => (
//         <span
//           style={{
//             fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
//           }}
//           title={row?.roas}
//         >
//           {/* {row.roas === -1 ? "n/a" : row?.roas} */}
//           {row.roas === -1 ? "n/a" : Number(row?.roas)?.toFixed(2)}
//         </span>
//       ),
//       selector: (row) => row.roas,
//       columnName: "roas",
//       sortable: true,
//     },
//     {
//       name: "Impression",
//       cell: (row, index) => (
//         <span
//           style={{
//             fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
//           }}
//           title={row?.impression}
//         >
//           {row?.impression === -1 ? "n/a" : row?.impression}
//         </span>
//       ),
//       selector: (row) => row?.impression,
//       columnName: "impression",
//       sortable: true,
//     },
//     {
//       name: "CPA",
//       cell: (row, index) => (
//         <span
//           style={{
//             fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
//           }}
//           title={row.cpa}
//         >
//           {row.cpa === -1 ? "n/a" : roundupValues(row.cpa)}
//         </span>
//       ),
//       selector: (row) => row?.cpa,
//       columnName: "cpa",
//       sortable: true,
//     },
//     {
//       name: "CPM",
//       cell: (row, index) => (
//         <span
//           style={{
//             fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
//           }}
//           title={row?.cpm}
//         >
//           {row?.cpm === -1 ? "n/a" : roundupValues(row?.cpm)}
//         </span>
//       ),
//       selector: (row) => row?.cpm,
//       columnName: "cpm",
//       sortable: true,
//     },
//     {
//       name: "CPC",
//       cell: (row, index) => (
//         <span
//           style={{
//             fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
//           }}
//           title={row.cpc}
//         >
//           {row.cpc === -1 ? "n/a" : row.cpc}
//         </span>
//       ),
//       selector: (row) => row.cpc,
//       columnName: "cpc",
//       sortable: true,
//     },
//     {
//       name: "CTR %",
//       cell: (row, index) => (
//         <span
//           style={{
//             fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
//           }}
//           title={row.ctr}
//         >
//           {row.ctr === -1 ? "n/a" : row.ctr}
//         </span>
//       ),
//       selector: (row) => row?.ctr,
//       columnName: "ctr",
//       sortable: true,
//     },
//     {
//       name: "CR %",
//       cell: (row, index) => (
//         <span
//           style={{
//             fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
//           }}
//           title={row?.cr}
//         >
//           {row.cr === -1 ? "n/a" : Number(row?.cr).toFixed(2)}
//           {/* {row.cr === -1 ? "n/a" : row?.cr} */}
//         </span>
//       ),
//       selector: (row) => row?.cr,
//       columnName: "cr",
//       sortable: true,
//     },
//   ];

//   // // Define aggregate row
//   // const aggregateRow = {
//   //   ad_id: "Total",
//   //   revenue: breakdownData?.revenue,
//   //   spend: toWholeNumberRound(breakdownData?.spend),
//   //   sales: toWholeNumberRound(breakdownData?.sales),
//   //   click: breakdownData?.click,
//   //   impression: breakdownData?.impression,
//   //   cpc: FinalTotalCpc,
//   //   cpm: FinalTotalCpm,
//   //   cpa: TotalCPA.length === 0 ? "n/a" : TotalCPA,
//   //   cr: FinalTotalCR,

//   //   roas: FinalTotalROAS === 0 ? 0 : FinalTotalROAS,
//   //   ctr: FinalTotalCTR,
//   // };

//   // Define aggregate row
//   const aggregateRow = {
//     ad_id: "Total",
//     revenue: breakdownData?.revenue,
//     spend: toWholeNumberRound(breakdownData?.spend),
//     sales: toWholeNumberRound(breakdownData?.sales),
//     click: breakdownData?.click,
//     impression: breakdownData?.impression,

//     cpc:
//       breakdownData?.click === 0
//         ? "n/a"
//         : (breakdownData?.spend / breakdownData?.click).toFixed(2),
//     cpm:
//       breakdownData?.impression === 0
//         ? "n/a"
//         : roundupValues(
//             (breakdownData?.spend * 1000) / breakdownData?.impression
//           ),
//     cpa:
//       breakdownData?.sales === 0
//         ? "n/a"
//         : roundupValues(breakdownData?.spend / breakdownData?.sales),
//     cr:
//       breakdownData?.click === 0
//         ? "n/a"
//         : ((breakdownData?.sales * 100) / breakdownData?.click).toFixed(2),
//     roas:
//       breakdownData?.spend === 0
//         ? "n/a"
//         : (breakdownData?.revenue / breakdownData?.spend).toFixed(2),
//     ctr:
//       breakdownData?.impression === 0
//         ? "n/a"
//         : ((breakdownData?.click * 100) / breakdownData?.impression).toFixed(2),
//   };

//   // console.log(aggregateRow);

//   const injectCustomRow = (pageData) => {
//     if (pageData?.length > 0) {
//       return [...pageData, { ...aggregateRow, selectable: false }];
//     }
//     return pageData;
//   };

//   // Combine sorted data with "Total" row
//   const finalData = [...datanew];
//   // console.log(finalData.length);

//   // Pagination
//   const startIndex = (currentPage - 1) * rowsPerPage;
//   const endIndex = startIndex + rowsPerPage;
//   const pageData = finalData.slice(startIndex, endIndex);
//   // console.log(pageData);
//   const paginatedData = injectCustomRow(pageData);

//   return (
//     <div
//       className="creative-data-table"
//       style={{
//         borderRadius: "6px",
//         background: "#1B1C1E",
//         width: "100%",
//         margin: "auto",
//         padding: "20px",
//         color: "white",
//       }}
//     >
//       <DataTable
//         columns={columns}
//         data={paginatedData}
//         customStyles={{
//           headRow: {
//             style: { color: "white" },
//           },
//           headCells: {
//             style: { background: "rgba(180, 180, 180, 0.2)", color: "white" },
//           },
//           cells: {
//             style: { backgroundColor: "#1B1C1E", color: "white" },
//           },
//         }}
//         paginationComponentOptions={{
//           noRowsPerPage: true,
//           selectAllRowsItem: false,
//         }}
//         pagination
//         paginationServer
//         onChangeRowsPerPage={handleRowsPerPageChange}
//         paginationPerPage={rowsPerPage}
//         paginationTotalRows={finalData.length}
//         onChangePage={handlePageChange}
//         sortServer={true}
//         onSort={handleSort}
//       />
//     </div>
//   );
// };

// export default BreakdownTable;

import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from "react-redux";
import { toastSetter } from "../../components/ToastContainer";
import {
  setSelectAdRows,
  updateMainCards,
} from "../../redux/reducers/breakdownSlice";
import { roundupValues } from "../../utils";

const BreakdownTable = ({
  setBreakDownInsightData,
  setBreakDownCards,
  ids,
}) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedAdRows, setSelectedAdRows] = useState([]);

  // useEffect(() => {
  //   // Trigger default sort on mount
  //   handleSort({ selector: { name: "spend" } }, "desc");
  // }, []);

  const [sortField, setSortField] = useState("ad_name");
  const [sortBy, setSortBy] = useState("spend");
  // console.log(sortBy);
  const [sortDirection, setSortDirection] = useState("desc");
  // console.log(sortDirection);
  const { breakdownData } = useSelector((state) => state.breakdown);
  const [pageOpen, setPageOpen] = useState(false);
  const datanew = (breakdownData?.ads || [])
    .map((item) => {
      const spend = parseFloat(item.spend) || 0;
      const click = parseFloat(item.click) || 0;
      const sales = parseFloat(item.sales) || 0;
      const revenue = parseFloat(item.revenue) || 0;
      const impression = parseFloat(item.impression) || 0;

      return {
        ...item,
        cpa: sales === 0 ? -1 : parseFloat((spend / sales).toFixed(2)),
        cpc: click === 0 ? -1 : parseFloat((spend / click).toFixed(2)),
        cpm:
          impression === 0
            ? -1
            : parseFloat(((spend * 1000) / impression).toFixed(2)),
        cr: click === 0 ? -1 : parseFloat((sales * 100) / click).toFixed(2),
        ctr:
          impression === 0
            ? -1
            : parseFloat(((click * 100) / impression).toFixed(2)),
        roas: spend === 0 ? -1 : parseFloat(revenue / spend).toFixed(2),
      };
    })
    // .map((item) => console.log("new", item.ctr))
    .sort((a, b) => {
      if (typeof a[sortBy] === "string") {
        return sortDirection === "asc"
          ? a[sortBy].localeCompare(b[sortBy])
          : b[sortBy].localeCompare(a[sortBy]);
      } else {
        return sortDirection === "asc"
          ? a[sortBy] - b[sortBy]
          : b[sortBy] - a[sortBy];
      }
    });
  // console.log(datanew);
  // const TotalCPC = datanew
  // .map((item) => item?.cpc)
  // .filter((item) => item !== -1);
  // const FinalTotalCpc = TotalCPC.map(Number) // Convert all string values to numbers
  // .reduce((acc, element) => acc + element, 0)
  // .toFixed(2); // Sum them up

  // const TotalCPM = datanew
  // .map((item) => item?.cpm)
  // .filter((item) => item !== -1);
  // console.log(TotalCPM);
  // const FinalTotalCpm = TotalCPM.map(Number) // Convert all string values to numbers
  // .reduce((acc, element) => acc + element, 0)
  // .toFixed(2); // Sum them up
  // console.log(FinalTotalCpm);

  // const TotalCPA = datanew
  // .map((item) => item.cpa)
  // .filter((item) => item !== -1);
  // console.log(TotalCPA);

  // const TotalCTR = datanew
  // .map((item) => item.ctr)
  // .filter((item) => item !== -1);
  // const FinalTotalCTR = TotalCTR.map(Number) // Convert all string values to numbers
  // .reduce((acc, element) => acc + element, 0)
  // .toFixed(2); // Sum them up
  // console.log(FinalTotalCTR);

  // const TotalCR = datanew.map((item) => item.cr).filter((item) => item !== -1);
  // const FinalTotalCR = TotalCR.map(Number) // Convert all string values to numbers
  // .reduce((acc, element) => acc + element, 0);
  // Sum them up
  // console.log(FinalTotalCR);

  // const TotalROAS = datanew
  // .map((item) => item.roas)
  // .filter((item) => item !== -1);
  // const FinalTotalROAS = TotalROAS.map(Number) // Convert all string values to numbers
  // .reduce((acc, element) => acc + element, 0);

  // console.log(FinalTotalROAS);
  useEffect(() => {
    const timer = setTimeout(() => {
      setPageOpen((prev) => !prev);
    }, 1000); // 1000 milliseconds = 1 second

    // Cleanup function to clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  // console.log(selectedAdRows);

  // console.log(localStorage.getItem("selectedAdRows"));
  useEffect(() => {
    if (datanew?.length > 1) {
      // console.log(datanew[0].ad_name);
      const initialSelectedRows = [datanew[0]?.ad_id, datanew[1]?.ad_id];
      console.log(initialSelectedRows);
      setSelectedAdRows(initialSelectedRows);
    } else {
      const singleInitial = [datanew[0]?.ad_id];
      setSelectedAdRows(singleInitial);
    }
  }, [breakdownData]); // Depend on `datanew` to rerun if it changes
  useEffect(() => {
    localStorage.setItem(
      "selectedAdRows",
      JSON.stringify(selectedAdRows ? selectedAdRows : [])
    );
  }, [selectedAdRows]);

  useEffect(() => {
    const breakdownCardsArray = datanew.filter((item) =>
      selectedAdRows.includes(item.ad_id)
    );
    setBreakDownCards(breakdownCardsArray);
    dispatch(updateMainCards(breakdownCardsArray));
  }, [selectedAdRows]);

  const handleSelectAll = () => {
    if (selectedAdRows.length > 0) {
      setSelectedAdRows([]);
      return;
    }
    let selectAllRow = datanew
      .filter((item, index) => {
        const startIndex = (currentPage - 1) * 10;
        const endIndex = startIndex + 4;
        return index >= startIndex && index < endIndex;
      })
      .map((item) => item.ad_id);
    setSelectedAdRows(selectAllRow);
  };

  const handleChange = (adName) => {
    // console.log(selectedAdRows);
    // console.log(adName);
    setSelectedAdRows((prevIds) =>
      prevIds.includes(adName)
        ? prevIds.filter((id) => id !== adName)
        : prevIds.length >= 4
        ? (toastSetter("error", "You can only select up to 4 rows"), prevIds)
        : [...prevIds, adName]
    );
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const handleSort = (column, direction) => {
    // console.log(column);
    // let columnName = column?.name?.toLowerCase();
    let columnName = column.columnName;

    setSortDirection(direction);
    setSortBy(columnName);
  };

  function toWholeNumberRound(value) {
    return Math.round(value);
  }

  const columns = [
    {
      name: (
        <input
          type="checkbox"
          className="head-checkbox custom-checkbox"
          checked={selectedAdRows.length > 0}
          onChange={handleSelectAll}
        />
      ),
      cell: (row) =>
        row.ad_name !== "Total" && (
          <input
            type="checkbox"
            className="row-checkbox custom-checkbox"
            checked={selectedAdRows.includes(row.ad_id)}
            onChange={() => handleChange(row.ad_id)}
          />
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Ad Name",
      selector: (row) => row?.ad_name,
      columnName: "ad_name",
      sortable: true,
    },
    {
      name: "Revenue",
      selector: (row) => toWholeNumberRound(row?.revenue),
      sortable: true,
      columnName: "revenue",
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
        >
          {toWholeNumberRound(row?.revenue)}
        </span>
      ),
    },
    {
      name: "Spend",
      selector: (row) => {
        const spend = parseFloat(toWholeNumberRound(row?.spend));
        return isNaN(spend) ? "N/A" : spend;
      },
      sortable: true,
      columnName: "spend",
      cell: (row, index) => {
        const spend = parseFloat(toWholeNumberRound(row?.spend));
        return (
          <span
            style={{
              fontWeight:
                index === paginatedData?.length - 1 ? "bold" : "normal",
            }}
          >
            {isNaN(spend) ? "N/A" : spend}
          </span>
        );
      },
    },
    {
      name: "Sales",
      selector: (row) => toWholeNumberRound(row?.sales),
      sortable: true,
      columnName: "sales",
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
        >
          {toWholeNumberRound(row?.sales)}
        </span>
      ),
    },
    {
      name: "Click",
      selector: (row) => row?.click,
      sortable: true,
      columnName: "click",
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
        >
          {row?.click}
        </span>
      ),
    },
    {
      name: "ROAS",
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
          title={row?.roas}
        >
          {/* {row.roas === -1 ? "n/a" : row?.roas} */}
          {row.roas === -1 ? "n/a" : Number(row?.roas)?.toFixed(2)}
        </span>
      ),
      selector: (row) => row.roas,
      columnName: "roas",
      sortable: true,
    },
    {
      name: "Impression",
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
          title={row?.impression}
        >
          {row?.impression === -1 ? "n/a" : row?.impression}
        </span>
      ),
      selector: (row) => row?.impression,
      columnName: "impression",
      sortable: true,
    },
    {
      name: "CPA",
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
          title={row.cpa}
        >
          {row.cpa === -1 ? "n/a" : roundupValues(row.cpa)}
        </span>
      ),
      selector: (row) => row?.cpa,
      columnName: "cpa",
      sortable: true,
    },
    {
      name: "CPM",
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
          title={row?.cpm}
        >
          {row?.cpm === -1 ? "n/a" : roundupValues(row?.cpm)}
        </span>
      ),
      selector: (row) => row?.cpm,
      columnName: "cpm",
      sortable: true,
    },
    {
      name: "CPC",
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
          title={row.cpc}
        >
          {row.cpc === -1 ? "n/a" : row.cpc}
        </span>
      ),
      selector: (row) => row.cpc,
      columnName: "cpc",
      sortable: true,
    },
    {
      name: "CTR %",
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
          title={row.ctr}
        >
          {row.ctr === -1 ? "n/a" : row.ctr}
        </span>
      ),
      selector: (row) => row?.ctr,
      columnName: "ctr",
      sortable: true,
    },
    {
      name: "CR %",
      cell: (row, index) => (
        <span
          style={{
            fontWeight: index === paginatedData?.length - 1 ? "bold" : "normal",
          }}
          title={row?.cr}
        >
          {row.cr === -1 ? "n/a" : Number(row?.cr).toFixed(2)}
          {/* {row.cr === -1 ? "n/a" : row?.cr} */}
        </span>
      ),
      selector: (row) => row?.cr,
      columnName: "cr",
      sortable: true,
    },
  ];

  // // Define aggregate row
  // const aggregateRow = {
  //   ad_name: "Total",
  //   revenue: breakdownData?.revenue,
  //   spend: toWholeNumberRound(breakdownData?.spend),
  //   sales: toWholeNumberRound(breakdownData?.sales),
  //   click: breakdownData?.click,
  //   impression: breakdownData?.impression,
  //   cpc: FinalTotalCpc,
  //   cpm: FinalTotalCpm,
  //   cpa: TotalCPA.length === 0 ? "n/a" : TotalCPA,
  //   cr: FinalTotalCR,

  //   roas: FinalTotalROAS === 0 ? 0 : FinalTotalROAS,
  //   ctr: FinalTotalCTR,
  // };

  // Define aggregate row
  const aggregateRow = {
    ad_name: "Total",
    revenue: breakdownData?.revenue,
    spend: toWholeNumberRound(breakdownData?.spend),
    sales: toWholeNumberRound(breakdownData?.sales),
    click: breakdownData?.click,
    impression: breakdownData?.impression,

    cpc:
      breakdownData?.click === 0
        ? "n/a"
        : (breakdownData?.spend / breakdownData?.click).toFixed(2),
    cpm:
      breakdownData?.impression === 0
        ? "n/a"
        : roundupValues(
            (breakdownData?.spend * 1000) / breakdownData?.impression
          ),
    cpa:
      breakdownData?.sales === 0
        ? "n/a"
        : roundupValues(breakdownData?.spend / breakdownData?.sales),
    cr:
      breakdownData?.click === 0
        ? "n/a"
        : ((breakdownData?.sales * 100) / breakdownData?.click).toFixed(2),
    roas:
      breakdownData?.spend === 0
        ? "n/a"
        : (breakdownData?.revenue / breakdownData?.spend).toFixed(2),
    ctr:
      breakdownData?.impression === 0
        ? "n/a"
        : ((breakdownData?.click * 100) / breakdownData?.impression).toFixed(2),
  };

  // console.log(aggregateRow);

  const injectCustomRow = (pageData) => {
    if (pageData?.length > 0) {
      return [...pageData, { ...aggregateRow, selectable: false }];
    }
    return pageData;
  };

  // Combine sorted data with "Total" row
  const finalData = [...datanew];
  // console.log(finalData.length);

  // Pagination
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const pageData = finalData.slice(startIndex, endIndex);
  // console.log(pageData);
  const paginatedData = injectCustomRow(pageData);

  return (
    <div
      className="creative-data-table"
      style={{
        borderRadius: "6px",
        background: "#1B1C1E",
        width: "100%",
        margin: "auto",
        padding: "20px",
        color: "white",
      }}
    >
      <DataTable
        columns={columns}
        data={paginatedData}
        customStyles={{
          headRow: {
            style: { color: "white" },
          },
          headCells: {
            style: { background: "rgba(180, 180, 180, 0.2)", color: "white" },
          },
          cells: {
            style: { backgroundColor: "#1B1C1E", color: "white" },
          },
        }}
        paginationComponentOptions={{
          noRowsPerPage: true,
          selectAllRowsItem: false,
        }}
        pagination
        paginationServer
        onChangeRowsPerPage={handleRowsPerPageChange}
        paginationPerPage={rowsPerPage}
        paginationTotalRows={finalData.length}
        onChangePage={handlePageChange}
        sortServer={true}
        onSort={handleSort}
      />
    </div>
  );
};

export default BreakdownTable;
