import React from "react";

export default function Payment({}) {
  const handleOpenRazorpay = () => {
    const options = {
      key: "rzp_test_osajk4V3vKvymg",
      handler: function (response) {
        console.log(response, "Razorpay response");
      },
    };
    const rzp = new window.Razorpay(options);
    rzp.open();
  }; // { razorpay_payment_id: 'pay_OXjxHEoIMIRw5Z' } fetch from server

  const handlePayment = async () => {
    handleOpenRazorpay();
  };
  return (
    <>
      <button onClick={handlePayment}>Pay Now</button>
    </>
  );
}
