import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMetaAccounts } from "../../services/api/integrationApis";

const initialState = {
    metaAccounts: null,
    isFetching: false,
    isError: false,
    error: null,
};


// function formatMetaAccountResponse(inputData) {}

export const fetchMetaAccounts = createAsyncThunk(
    "integration/fetchMetaAccounts",
    async (values, { rejectWithValue }) => {
        try {
            const response = await getMetaAccounts(values);
            if (response.status === 200) {
                return response.data;
            } 
            // else throw new Error("Failed to fetch meta accounts");
        } catch (error) {
            return rejectWithValue(
                error.response?.data?.message || error.message || "Failed"
            );
        }
    }
);

export const integrationSlice = createSlice({
    name: "integration",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMetaAccounts.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchMetaAccounts.fulfilled, (state, action) => {
                state.isFetching = false;
                state.metaAccounts = action.payload;
            })
            .addCase(fetchMetaAccounts.rejected, (state, action) => {
                state.isFetching = false;
                state.isError = true;
                state.error = action.error.message;
            });
    },
});

export default integrationSlice.reducer;