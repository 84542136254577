import React from 'react'
import "./styles.css"
import { Link } from 'react-router-dom'

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
    <header className='terms-header'>
      <h1 className='h1'>Terms and Conditions for Trackocity</h1>
    </header>
    <section>
      <h2 className="h2">Introduction</h2>
      <p className="p">These Terms and Conditions ("Terms") govern your access to and use of Trackocity's services, software, and website (collectively, the "Service"). By accessing or using the Service, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use the Service.</p>
    </section>
    <section>
      <h2 className="h2">Use of Service</h2>
      <h3 className="h3">Eligibility</h3>
      <p className="p">You must be at least 18 years of age to use the Service. By agreeing to these Terms, you represent and warrant to us that: (a) you are at least 18 years of age; (b) you have not previously been suspended or removed from the Service; and (c) your registration and your use of the Service is in compliance with any and all applicable laws and regulations.</p>
      <h3 className="h3">Account Registration</h3>
      <p className="p">To access certain features of the Service, you must register for an account. When you register for an account, you may be required to provide us with some information about yourself, such as your email address or other contact information. You agree that the information you provide to us is accurate and that you will keep it up-to-date at all times.</p>
      <h3 className="h3">Use Restrictions</h3>
      <p className="p">You agree not to use the Service in any manner that:</p>
      <ul className="ul">
        <li className="li">Is illegal, or promotes or encourages illegal activity;</li>
        <li className="li">Infringes on the intellectual property rights of any other person or entity;</li>
        <li className="li">Is harmful, fraudulent, deceptive, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable;</li>
        <li className="li">Jeopardizes the security of your Trackocity account or anyone else's account (such as allowing someone else to log in to the Service as you).</li>
      </ul>
    </section>
    <section>
      <h2 className="h2">Intellectual Property Rights</h2>
      <h3 className="h3">Ownership</h3>
      <p className="p">The Service and all rights therein, including intellectual property rights, shall remain the property of Trackocity and/or its licensors. No rights are granted to you other than as expressly set forth herein.</p>
      <h3 className="h3">License to Use</h3>
      <p className="p">Subject to your compliance with these Terms, Trackocity grants you a limited, non-exclusive, non-transferable, revocable license to use the Service for your internal business purposes.</p>
    </section>
    <section>
      <h2 className="h2">Subscription Fees, Payments, and Trials</h2>
      <h3 className="h3">Fees</h3>
      <p className="p">We offer various subscription plans for our Service. Fees for each plan are posted on our website and may be updated from time to time. Payments are due for the entire subscription term, under the terms of the payment plan that you select during registration.</p>
      <h3 className="h3">Free Trials</h3>
      <p className="p">We may offer free trials for a limited period. Unless you cancel before the end of the trial period, your trial will automatically convert to a paid subscription, and you will be billed according to the plan you have selected.</p>
      <h3 className="h3">Cancellation and Refund</h3>
      <p className="p">You may cancel your subscription at any time. However, except as provided by law or by a specific offer, all fees are non-refundable.</p>
    </section>
    <section>
      <h2 className="h2">Data Privacy and Security</h2>
      <h3 className="h3">Data Protection</h3>
      <p className="p">Trackocity is committed to protecting the privacy and security of your data. All data is encrypted and stored on secure servers. Our privacy policy provides more information on how we collect, use, and protect your data.</p>
      <h3 className="h3">User Data</h3>
      <p className="p">You retain all rights to your data that you upload or submit to the Service. By uploading or submitting data to the Service, you grant Trackocity a worldwide, royalty-free license to use, reproduce, distribute, modify, adapt, create derivative works, make publicly available, and otherwise exploit your data, but only for the limited purposes of providing the Service to you and as otherwise permitted by Trackocity's privacy policies.</p>
    </section>
    <section>
      <h2 className="h2">Termination</h2>
      <p className="p">Trackocity may terminate your access to all or any part of the Service at any time, with or without cause, with or without notice, effective immediately. If you wish to terminate this Agreement or your Trackocity account, you may simply discontinue using the Service.</p>
    </section>
    <section>
      <h2 className="h2">Disclaimer of Warranties</h2>
      <p className="p">The Service is provided "as is". Trackocity and its suppliers and licensors hereby disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement.</p>
    </section>
    <section>
      <h2 className="h2">Limitation of Liability</h2>
      <p className="p">In no event will Trackocity, or its suppliers or licensors, be liable with respect to any subject matter of this agreement under any contract, negligence, strict liability or other legal or equitable theory for: (a) any special, incidental or consequential damages; (b) the cost of procurement for substitute products or services; (c) for interruption of use or loss or corruption of data.</p>
    </section>
    <section>
      <h2 className="h2">General Representation and Warranty</h2>
      <p className="p">You represent and warrant that your use of the Service will be in strict accordance with the Trackocity Privacy Policy, with these Terms, and with all applicable laws and regulations.</p>
    </section>
    <section>
      <h2 className="h2">Changes</h2>
      <p className="p">Trackocity reserves the right, at its sole discretion, to modify or replace any part of these Terms. It is your responsibility to check these Terms periodically for changes. Your continued use of or access to the Service following the posting of any changes to these Terms constitutes acceptance of those changes.</p>
    </section>
    <section>
      <h2 className="h2">Contact Information</h2>
      <p className="p">If you have any questions about these Terms, please contact us at <Link to="mailto:contact@trackocity.io">contact@trackocity.io</Link>.</p>
    </section>
    <section>
      <p className="p">This document is not a substitute for legal advice. You should consult with an attorney to ensure compliance with all applicable laws and regulations.</p>
    </section>
  </div>
  )
}

export default TermsAndConditions