import http from "./http";
import catchAsync from "../../utils/catchAsync";

export const getSalesVelocityTable = catchAsync(async (values) => {
  try {
    const { startdate, enddate, campaign, channel, include_dayzero } = values;

    const response = await http.get(`behaviour/sales_velocity`, {
      params: { startdate, enddate, campaign, channel, include_dayzero },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});


export const getPredictiveTable = catchAsync(async (values) => {
  try {
    const { startdate, enddate, channel } = values;

    const response = await http.get(`/behaviour/campaigndetail`, {
      params: { startdate, enddate, channel },
    });

    if (response.status === 200) {
      return response; // Return response data
    }
  } catch (error) {
    // console.log(error);
    throw error;
  }
});

export const getCohortTableData = catchAsync(async (values) => {

  const { startdate, enddate, campaign, channel, interval } = values;


  try {
    const response = await http.get(`/behaviour/sales_cohort`, {
      params: { startdate, enddate, campaign, channel, interval },
    });

    if (response.status === 200) {
      return response;
    } else {
      throw new Error(`Failed to fetch , status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching :", error);
    throw error;
  }
});


