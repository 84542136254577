import { useEffect, useState } from "react";
import "./styles.css";
import { setCreativeState } from "../../redux/reducers/creativeTableDataSlice";
import { useDispatch } from "react-redux";
import { fallBackImg } from "../../assets";

function Video({ opentheVideo, setOpentheVideo, creativeInsightData }) {
  const dispatch = useDispatch();
  const openVideo = () => {
    setOpentheVideo((prev) => !prev);
    dispatch(setCreativeState(false));
  };

  useEffect(() => {
    // Dispatch setCreativeState with false when component mounts
    dispatch(setCreativeState(false));
  }, []);

  function handleClick() {
    setOpentheVideo((prev) => !prev);
    dispatch(setCreativeState(true));
  }

  let iframeSrc = "";
  let imageSrc = "";

  function getIframeSrc(htmlString) {
    const srcMatch = htmlString.match(/src="([^"]+)"/);
    return srcMatch ? srcMatch[1] : "";
  }

  // Check if creativeInsightData is valid and has a preview property
  if (creativeInsightData && creativeInsightData.preview) {
    if (creativeInsightData?.adtype === "VIDEO") {
      const preview = creativeInsightData.preview;
      iframeSrc = getIframeSrc(preview);
      if (iframeSrc !== "") {
        iframeSrc = getIframeSrc(preview);
      } else {
        imageSrc = creativeInsightData?.thumbnail;
      }
    } else {
      imageSrc = creativeInsightData?.thumbnail;
    }
  } else {
    // console.log(
    //   "creativeInsightData is null or does not have a preview property."
    // );
  }

  const handleImageError = (event) => {
    event.target.src = fallBackImg; // Replace with your fallback image path
  };

  return (
    <div>
      {opentheVideo && (
        <div className="video">
          <div className="cross">
            <button onClick={handleClick} className="video-btn-new">
              <img className="video-btn-img" src="/creative-video.png"></img>
              <p>Creative Insignts</p>
            </button>
            <button className="" onClick={openVideo}>
              <img className="cross-img" src="/cross.png"></img>
            </button>
          </div>
          {creativeInsightData?.adtype === "VIDEO" && iframeSrc !== "" ? (
            <iframe
              id="youtube-video"
              class="video-player"
              width="250"
              height="400"
              src={iframeSrc}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          ) : (
            <img
              src={imageSrc}
              id="youtube-video"
              class="video-player"
              width="250"
              height="400"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              onError={handleImageError}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Video;
